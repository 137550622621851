import React, { useState, useEffect } from "react";
import Signin from "../../WebLayout/Signin/Signin";
import Signup from "../../WebLayout/Signup/Signup";
import ForgetPassword from "../../WebLayout/ForgetPassword/ForgetPassword";
import style from "./loginsignup.module.scss";
import OpenAccountC from "../../WebLayout/OpenAccount/OpenAccount";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Loginsignup = () => {
  const [popupvisible, setpopupvisible] = useState(false);
  const [signup, setsignup] = useState(false);
  const [forget, setforget] = useState(false);
  const [Oaccount, setOaccount] = useState(false);
  const [AccesToken, setAccesToken] = useState("");
  const history = useHistory();
  const createAccountCheck = useSelector(
    (state) => state.account.createAccountCheck
  );
  useEffect(() => {
    let res = localStorage.getItem("authToken");
    setAccesToken(res);
  }, []);

  const togglepopup = (e) => {
    e.preventDefault();
    setpopupvisible((preview) => !preview);
  };

  const signuphandle = () => {
    setpopupvisible(!popupvisible);
    setsignup(false);
  };
  useEffect(() => {
    if (createAccountCheck) {
      signinhandle();
    }
  }, [createAccountCheck]);
  const signinhandle = () => {
    setsignup(!signup);
    setpopupvisible(!popupvisible);
  };

  const forgetHandle = () => {
    setsignup(false);
    setpopupvisible(false);
    setforget(true);
  };

  const OpenAccount = () => {
    setOaccount(true);
    setpopupvisible(false);
  };

  return (
    <>
      <Signin
        forgetHandle={forgetHandle}
        signinhandle={signinhandle}
        OpenAccount={OpenAccount}
        visible={popupvisible}
        onClose={() => setpopupvisible(false)}
        // isOpen={isOpenLogin}
        // toggleLogin={toggleLogin}
        // handleSignUp={handleSignUp}
      />

      <Signup
        signuphandle={signuphandle}
        visible={signup}
        onClose={() => setsignup(false)}
        // isOpen={isOpenSignup}
        // toggleSignup={toggleSignup}
        // handleLogin={handleLogin}
      />
      <ForgetPassword
        visible={forget}
        onClose={() => setforget(false)}
        signuphandle={signuphandle}
      />
      <OpenAccountC visible={Oaccount} onClose={() => setOaccount(false)} />

      <div className={style.container}>
        <div className={style.menuItem}>
          {AccesToken ? (
            <button onClick={() => history.push("/cryptocurrency")}>
              Dashboard
            </button>
          ) : (
            <button onClick={togglepopup}>Login</button>
          )}
        </div>
      </div>
    </>
  );
};

export default Loginsignup;
