import React, { useEffect, useRef } from "react";
import { slide as Menu } from "react-burger-menu";
import SideBar from "../../sidebar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const Slider = ({
  handleMenu,
  menuOpenState,
  stateChangeHandler,
  setMenuOpenState,
  id,
  history,
  visible,
}) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    menuOpenState ? disableBodyScroll(scrollRef) : enableBodyScroll(scrollRef);
  }, [menuOpenState]);

  return (
    <Menu
      right
      disableAutoFocus
      customBurgerIcon={false}
      isOpen={menuOpenState}
      onStateChange={(state) => stateChangeHandler(state)}
      itemListElement="div"
      ref={scrollRef}
    >
      <SideBar
        mobileView={true}
        id={id}
        history={history}
        handleMenu={handleMenu}
      />
    </Menu>
  );
};

export default Slider;
