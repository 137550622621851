import img from "../../../Assets/icons/white.png";
const DataSec = {
  heading: "Send",
  buttons: [
    { name: "Cryptocurrency", link: "/redemption", popup: true },
    // { name: "CQR Liquidity Tokens('Q')", link: "/vault-token", img: img },
    { name: "CQR Liquidity Token", link: "/q-tokens", img: img },

    {
      name: "CQR Asset Tokens ('Ag/Au')",
      AgAu: true,
      img: img,
      link: "/cqr-asset-tokens",
    },

    // { name: "CQR Vest", link: "/cqr-vest" },
    // { name: "Banking", disabled: true },
  ],
};
export default DataSec;
