import React, { useState } from "react";
import styles from "./popup.module.scss";
import { useDispatch } from "react-redux";
import { userCleanUp, KYCCleanUp } from "../../../redux/action/userAction";
import { currencyLogoutCleanUp } from "../../../redux/action/currencyDetailAction";
import { swapLogoutCleanUp } from "../../../redux/action/sawapAction";
import { subscriptionCleanUp } from "../../../redux/action/subscriptionAction";
import { useHistory } from "react-router-dom";
import { remittanceCleanUp } from "../../../redux/action/remittanceAction";
import { useSelector } from "react-redux";
import { HTTP_CLIENT } from "../../../utils/services";
import { Wave } from "../loader/Loader";
const Popup = ({
  Name,
  src,
  para,
  btn,
  // onPressButton,
  onPressinnerButton,
  btn1,
  BtnShow,
  // history,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const fcmToken = useSelector((state) => state?.user?.fcmToken);
  const handleOK = async () => {
    setLoading(true);
    try {
      const res = await HTTP_CLIENT.post("user/logout/v2", {
        fcmToken: fcmToken,
      });
      setLoading(false);
      // history.push("/");
    } catch (error) {
      setLoading(false);
    }
    dispatch(userCleanUp());
    // dispatch(KYCCleanUp());
    dispatch(currencyLogoutCleanUp());
    dispatch(swapLogoutCleanUp());
    dispatch(subscriptionCleanUp());
    dispatch(remittanceCleanUp());

    localStorage.clear();
    history.push("/");
    dispatch(KYCCleanUp());
  };

  return (
    <div className={styles.container}>
      <img src={src} alt="" />
      <label>{Name}</label>
      {para && (
        <>
          <p>{para}</p>
          <div className={styles.btndiv}>
            {loading ? (
              <button onClick={handleOK}>
                <Wave />
              </button>
            ) : (
              <button onClick={handleOK}>{btn}</button>
            )}

            {BtnShow && (
              <>
                <button
                  onClick={onPressinnerButton}
                  type="submit"
                  className={styles.cancelbtn}
                >
                  {btn1}
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Popup;
