import React from "react";
import styles from "./opportunityHeader.module.scss";
import Logo from "../../../../../../../Assets/image/logo dark.svg";
import CQRComponent, {
  CQRAComponent,
  CQRBComponent,
} from "../../../../../../_Common/CQRComponent/CQRComponent";
import Oppurtunity from "../../../../../../../Assets/icons/oppertunitytotal.png";
// import A from "../../../../Assets/symbols/aSymbolBlack.png";

const OpportunityHeader = ({ location }) => {
  function numberWithCommas(n) {
    var parts = n ? n?.toString()?.split(".") : "";
    return (
      parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : ".00")
    );
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgWrapper}>
        <img src={location?.state?.detail?.image} alt="logo" />
        <span>{location?.state?.detail?.name}</span>
      </div>
      <div className={styles.imgWraaper}>
        <div className={styles.opputnityWrapper}>
          <img src={Oppurtunity} alt="oppeutunity" />
          <span>
            {numberWithCommas(
              Number(location?.state?.detail?.balance).toFixed(2)
            )}
            <p>Balance</p>
          </span>
          <label>
            <CQRComponent />
            &nbsp;
            {numberWithCommas(
              Number(
                location?.state?.detail?.pricePerShare *
                  location?.state?.detail?.balance
              ).toFixed(2)
            )}
            <p>Value</p>
          </label>
        </div>
        {location?.state?.detail?.image && (
          <img src={location?.state?.detail?.image} alt="logo" />
        )}

        <img src={Logo} className={styles.darklogo} alt="logo" />
      </div>
    </div>
  );
};

export default OpportunityHeader;
