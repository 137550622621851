import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../../../utils/AppConstants";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
import axios from "axios";
import { useAlert } from "react-alert";
import { HTTP_CLIENT } from "../../../../../utils/services";

let useCryptoCurrency = () => {
  let history = useHistory();
  const alert = useAlert();

  const [currencyData, setCurrencyData] = useState();
  const [CQRData, setCQRData] = useState();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoad(true);
    try {
      let res = await HTTP_CLIENT.get(`user/info`);
      setCurrencyData(res?.data?.wallets);
      setCQRData(res?.data?.nativeWallets);
      alert.show("Successfully Retrieved", { type: "success" });
      // history.push(res?.data?.confirmationUrl)
      // window.open(res?.data?.confirmationUrl, "_blank")
      setLoad(false);
    } catch (err) {
      alert.show(err.response.data.message, { type: "error" });
      setLoad(false);
    }
  };

  const detailData = (data) => {
    history.push({
      pathname: "/bitcoin-table",
      state: { detail: data },
    });
  };

  return {
    currencyData,
    CQRData,
    load,
    detailData,
  };
};

export default useCryptoCurrency;
