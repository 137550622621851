import React, { useState, useRef, useEffect } from "react";
import { BASE_URL } from "../../../utils/AppConstants";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
import axios from "axios";
import { useAlert } from "react-alert";

let useForgetPAssword = (onClose, signuphandle) => {
  const [active, setactive] = useState(0);
  let [inputs, setInputs] = useState({ email: "" });
  const [otp, setOtp] = useState({
    numberOne: "",
    numberTwo: "",
    numberThree: "",
    numberFour: "",
  });
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [tempAuthToken, setTempAuthToken] = useState("");
  let [inputPassword, setinputPassword] = useState({
    password: "",
    resetpassword: "",
    passwordValidation: "",
    confirmValidation: "",
  });
  const alert = useAlert();

  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);

  let handleInputChange = (e) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    let { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
    if (inputs.email.match(mailformat)) {
      setInputs((prev) => ({ ...prev, emailValidation: "" }));
    }
  };

  const changeOtpHandler = (e) => {
    let { name, value } = e.target;
    setOtp({ ...otp, [name]: value });
    name === "numberOne" && secondRef.current.focus();
    name === "numberTwo" && thirdRef.current.focus();
    name === "numberThree" && fourthRef.current.focus();
  };

  //-----------Get password value-------------
  const changePasswordHandler = (e) => {
    let { name, value } = e.target;
    setinputPassword((prev) => ({
      ...prev,
      [name]: value,
      [name === "password" ? "passwordValidation" : "confirmValidation"]: "",
      [name === "password" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    setinputPassword((prev) => ({
      ...prev,
      [name]: value,
      [name === "password" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    if (/^\S*$/.test(inputPassword?.password)) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "",
      }));
    }
    if (/^\S*$/.test(inputPassword?.resetpassword)) {
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "",
      }));
    }
  };

  //--------Submit email for verification--------
  let handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    setButtonLoading(true);
    let data = {
      email: inputs.email.toLowerCase(),
    };
    try {
      let res = await axios.post(`${BASE_URL}auth/forgot-password`, data);
      if (
        res.data.message === "Password reset email has been sent successfully!"
      ) {
        alert.show(res.data.message, { type: "success" });
        setactive(active + 1);
      }
      setButtonLoading(false);
    } catch (err) {
      if (
        err?.response?.data?.message ===
          "Account Blocked: vault Not activated within 14 days." ||
        err?.response?.data?.message !== "User with the given email not found"
      ) {
        alert.show(`${err?.response?.data?.message} Please Contact support`, {
          type: "error",
        });
      } else {
        alert.show(err?.response?.data?.message, {
          type: "error",
        });
      }
      setButtonLoading(false);
    }
  };

  // ---------verify OTP from email-----------
  const verifyOtp = async (e) => {
    setButtonLoading(true);

    e.preventDefault();
    let data = {
      code: Object.values(otp).join(""),
      email: inputs.email.toLowerCase(),
    };
    try {
      let res = await axios.post(`${BASE_URL}auth/verify-pin`, data);
      if (res.data.status) {
        alert.show(res.data.message, { type: "success" });
        setTempAuthToken(res.data.authToken);
        setactive(active + 1);
      }
      setButtonLoading(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setButtonLoading(false);
    }
  };

  // ------ Submit new Password----------
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (!isPasswordValidate()) {
      return;
    }
    setButtonLoading(true);
    let authToken = tempAuthToken;
    let data = {
      password: inputPassword.password,
    };
    try {
      let res = await axios.post(`${BASE_URL}auth/create-new-password`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.data.status) {
        alert.show("Password create successfully", {
          type: "success",
        });
        redirectPage();
      }
      setButtonLoading(false);
    } catch (err) {
      if (err?.response?.data?.message == "User already exist") {
        alert.show(err?.response?.data?.message, { type: "error" });
      }

      setButtonLoading(false);
    }
  };

  // -----------Check email validation---------
  let isValidated = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    let response = true;
    if (!inputs.email.match(mailformat)) {
      response = false;
      setInputs((prev) => ({ ...prev, emailValidation: "Email is invalid" }));
    } else {
      setInputs((prev) => ({ ...prev, emailValidation: "" }));
    }
    if (!inputs.email.trim()) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        emailValidation: "Email cannot be empty",
      }));
    }
    return response;
  };

  // ----------Check password validaton ------
  const isPasswordValidate = () => {
    let response = true;
    if (inputPassword?.password?.trim().length > 16 || "") {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        passwordValidation: "password can't be greater then 16.",
      }));
    }

    if (inputPassword?.resetpassword?.trim().length > 16 || "") {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be greater than 16.",
      }));
    }
    if (inputPassword?.password?.trim().length < 8 || "") {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        passwordValidation: "password can't be smaller then 8.",
      }));
    }
    if (!/^\S*$/.test(inputPassword?.password)) {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        passwordValidation: "Empty space is not allowed.",
      }));
    }
    if (!/^\S*$/.test(inputPassword?.resetpassword)) {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        confirmValidation: "Empty space is not allowed.",
      }));
    }
    if (inputPassword?.resetpassword?.trim().length < 8 || "") {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be smaller than 8.",
      }));
    }
    if (!inputPassword.password.trim()) {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        passwordValidation: "password Field can't be empty.",
      }));
    }
    if (!inputPassword.resetpassword.trim()) {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be empty.",
      }));
    }
    if (
      inputPassword.password.trim() !== "" &&
      inputPassword.resetpassword.trim() !== "" &&
      inputPassword.password.trim() !== inputPassword.resetpassword.trim()
    ) {
      response = false;
      setinputPassword((prev) => ({
        ...prev,
        confirmValidation: "password do not match.",
      }));
    }

    return response;
  };

  const redirectPage = () => {
    setactive(0);
    signuphandle();
    onClose();
  };

  return {
    active,
    setactive,
    ...inputs,
    ...otp,
    ...inputPassword,
    handleInputChange,
    changeOtpHandler,
    changePasswordHandler,
    handleSubmit,
    verifyOtp,
    handleSubmitPassword,
    isValidated,
    isPasswordValidate,
    redirectPage,
    secondRef,
    thirdRef,
    fourthRef,
    ButtonLoading,
  };
};

export default useForgetPAssword;
