import React, { useState } from "react";
import styles from "./popup.module.scss";
import CQRvestData from "../../../../../Data/Dashboard/CQRvestData";
import { Link } from "react-router-dom";
import { HTTP_CLIENT } from "../../../../../utils/services";
import { useAlert } from "react-alert";
import { Loader, WaveClone } from "../../../../_Common/loader/Loader";
import { userInfo } from "../../../../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import CQRComponent from "../../../../_Common/CQRComponent/CQRComponent";
import white from "../../../../../Assets/icons/white.png";
import { useHistory } from "react-router-dom";
import Modal from "../../../../_Common/Modal/Modal";

function Popup({ subData, togglepopup, recieveData }) {
  const [active, setActive] = useState(2);
  const [show, setShow] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [renewStatus, setRenewStatus] = useState(false);
  const history = useHistory();
  let data = [
    { name: "Remit CQR Dollars (CQR Vault)" },
    // { name: "Remit Fiat (Bank)" },
  ];

  const subcData = data.map((x) => x.name);
  const { subscriptionData } = useSelector((state) => state.subscription);

  const currencySelector = useSelector(
    (state) => state.currency?.currencyInfo?.nativeWallets
  );
  var balance = currencySelector?.find((item) => item.name === "QToken");
  const nativeWallet = useSelector(
    (state) => state?.currency?.currencyInfo?.nativeWallets
  );
  const { isWalletActivated } = useSelector((state) => state?.user?.userInfo);
  const filterData = nativeWallet?.find((m) => m.coinSymbol == "Q");

  const handleRenewal = (data) => {
    if (data == "recurring") {
      setRenewStatus(true);
      setActive(1);
    } else if (data == "subsction") {
      setRenewStatus(false);
      setRenewStatus(false);
      setActive(2);
    }
  };
  const showData = () => {
    setShow((prev) => !prev);
  };
  const handleSubmit = async (data) => {
    if (!isWalletActivated) {
      alert.show("Please activate your vault first", { type: "error" });
      return;
    }

    let param = {
      planId: subData._id,
      paymentAmount: subData.priceUSD,
      renewOn: "",
      amount: subData.priceUSD,
      mandate: "",
      type: "asset",
    };

    if (renewStatus) {
      param.isRenewable = true;
    } else {
      param.isRenewable = false;
    }
    if (data === "Remit CQR Dollars (CQR Vault)") {
      param.type = "asset";
      //param.paymentMethod = "Assets";
      if (Number(filterData.balance) < Number(subData.priceUSD)) {
        alert.show("Insufficient balance", { type: "error" });
        return;
      }
      setLoading(true);
    } else if (data === "Remit Fiat (Bank)") {
      history.push("/payment-selection");
      return;
      setLoading(true);
      param.type = "fiat";
      param.paymentMethod = "fiat";
    }

    // return;
    try {
      setLoading(true);
      const res = await HTTP_CLIENT.post("/subscription", param);
      await dispatch(userInfo(res.data.user));
      recieveData(res.data);
      alert.show(res.data.message, { type: "success" });
      setLoading(false);
      setShow(false);
      togglepopup();
    } catch (err) {
      alert.show(err.response.data.message, { type: "error" });
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        visible={show}
        btn
        onClose={() => {
          setShow(false);
          // onClose();
        }}
      >
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Are you sure you want this Subscription?</p>
          </div>
          <div className={styles.buttonset}>
            {loading ? (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => {
                  // DeleteData();
                }}
              >
                <WaveClone />
              </button>
            ) : (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => handleSubmit(subcData)}
              >
                Commit
              </button>
            )}

            <button
              className={styles.Delete}
              style={{ marginLeft: "3rem" }}
              onClick={() => setShow(false)}
            >
              {" "}
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.textWrapper}>
            {/* <label>{heading}</label> */}
            <div className={styles.buttonWrapper}>
              {loading ? (
                ""
              ) : (
                <div className={styles.innerTextWrapper}>
                  <label>Select Method of Settlement</label>
                  <p>Note:</p>
                  <span>
                    {/* In each case, when you select C<CQRComponent />R Dollar or
                    Fiat option, the subscription amount will be deducted from
                    either your associated C<CQRComponent />R Vault or Bank
                    account. If you don't have enough balance at the time of
                    exchange, your request will be rejected. The Subscription
                    will be deducted from your C<CQRComponent />R Vault{" "}
                    <img style={{ width: "12px" }} src={white} /> Liquidity
                    Token balance if you select first option.
                    In case you select the fiat option, the value will be
                    deducted from your associated bank account.  */}
                    If you don’t have a sufficient balance of CQRUS when you
                    subscribe, your request will be rejected.
                  </span>
                  {subData?.title !== "CQR Vest Level 1 (Lifetime)" ? (
                    <p>Please select subscription type</p>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {subData?.title !== "CQR Vest Level 1 (Lifetime)" ? (
                <>
                  {" "}
                  <div
                    className={`${
                      active === 1 ? styles.activeButton : styles.button
                    }`}
                    onClick={() => handleRenewal("recurring")}
                    // key={name}
                  >
                    <div>Recurring</div>
                  </div>
                  <div
                    className={`${
                      active === 2 ? styles.activeButton : styles.button
                    }`}
                    onClick={() => handleRenewal("subsction")}
                    // key={name}
                  >
                    <div>One time subscription </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <p
                style={{
                  display: "flex",
                  alignSelf: "flex-start",
                  marginLeft: "3.2rem",
                  fontWeight: "bold",
                }}
              >
                Please Select Method of Settlement
              </p>
              {loading ? (
                <Loader />
              ) : (
                data.map((btn) => {
                  const { name } = btn;

                  return (
                    <>
                      <div
                        className={styles.buttonRemit}
                        key={name}
                        onClick={showData}
                      >
                        <div>{name}</div>
                      </div>
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Popup;
