import React, { useEffect, useState } from "react";
import styles from "./Graph.module.scss";
import Btc from "../../../../../../../Assets/icons/Btc.svg";
import Graph from "../../../../../../_Common/BarChart/BarChart";

function BitcoinGraph({ currencyGraph, coinData }) {
  const [graphArray, setGraphArray] = useState([]);
  const [active, setActive] = useState(1);

  const handleDays = (name) => {
    if (name === "first") {
      setActive(1);
      setGraphArray(currencyGraph?.spark_line_1_day);
    } else if (name === "seven") {
      setActive(2);
      setGraphArray(currencyGraph?.spark_line_7_day);
    } else if (name === "thirty") {
      setActive(3);
      setGraphArray(currencyGraph?.spark_line_30_day);
    } else if (name === "ninty") {
      setActive(4);
      setGraphArray(currencyGraph?.spark_line_90_day);
    }
  };
  useEffect(() => {
    if (coinData) {
      setActive(1);
      setGraphArray(currencyGraph?.spark_line_1_day);
    }
  }, [coinData, currencyGraph]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.headingWrapper}>
            <div className={styles.topWrapper}>
              <h3>{coinData?.coinSymbol?.toUpperCase()} Balance</h3>{" "}
              <p>{parseFloat(coinData?.balance)?.toFixed(3)}</p>
            </div>
            <img src={coinData?.icon?.url} alt="" />
            <div className={styles.bottomWrapper}>
              <label>USD Value</label>

              <span>
                $
                {parseFloat(
                  coinData?.balance * coinData?.marketData?.rate
                )?.toFixed(4)}
              </span>
            </div>
          </div>
          <div className={styles.graphWrapper}>
            <Graph data={true} currencyGraph={graphArray} defaultColor={true} />
          </div>
          <div className={styles.buttonWrapper}>
            <button
              className={`${active === 1 ? styles.ActiveBtn : styles.btnbtn}`}
              onClick={() => handleDays("first")}
            >
              1D
            </button>
            <button
              className={`${active === 2 ? styles.ActiveBtn : styles.btnbtn}`}
              onClick={() => handleDays("seven")}
            >
              7D
            </button>
            <button
              className={`${active === 3 ? styles.ActiveBtn : styles.btnbtn}`}
              onClick={() => handleDays("thirty")}
            >
              30D
            </button>
            <button
              className={`${active === 4 ? styles.ActiveBtn : styles.btnbtn}`}
              onClick={() => handleDays("ninty")}
            >
              90D
            </button>
            {/* <button className={styles.btnbtn}>6M</button>
            <button className={styles.btnbtn}>1Y</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BitcoinGraph;
