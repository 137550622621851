import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { HTTP_CLIENT } from "../../../../../../utils/services";
import { WaveClone } from "../../../../../_Common/loader/Loader";
import styles from "./activateAd.module.scss";
const AvtiveAdModal = ({ data, id, setpopupvisible }) => {
  const [loading, setLoading] = useState(false);

  const alert = useAlert();
  const history = useHistory();

  const handleDeactivate = async () => {
    setLoading(true);

    const params = {
      adId: id,
      status: data === "active" ? "deactivated" : "active",
    };
    let authToken = localStorage.getItem("authToken");
    try {
      const res = await HTTP_CLIENT.patch(`marketplace/ad`, params);

      alert.show("Listing was updated successfully", { type: "success" });
      history.push("/marketplace");
      setLoading(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {data == "active" ? (
            <div className={styles.heading}>Deactivate this listing?</div>
          ) : (
            <div className={styles.heading}>
              Are you sure you want to activate this listing?
            </div>
          )}

          {loading ? (
            <button className={styles.loadingBtn}>
              <WaveClone />
            </button>
          ) : data == "active" ? (
            <button className={styles.btn} onClick={() => handleDeactivate()}>
              Deactive
            </button>
          ) : (
            <button className={styles.btn} onClick={() => handleDeactivate()}>
              Activate
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default AvtiveAdModal;
