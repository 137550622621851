import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import NoData from "../../../../Assets/gif/noData.gif";
import trackBottom from "../../../../Assets/icons/trackBottom.svg";
import trackTop from "../../../../Assets/icons/trackTop.svg";
import { BASE_URL } from "../../../../utils/AppConstants";
import { getTransactionData } from "../../../../utils/helpers";
import { HTTP_CLIENT } from "../../../../utils/services";
import { Wave } from "../../loader/Loader";
import styles from "./Transactions.module.scss";
// import emptyImg from "../../../../../../../../Assets/gif/empty.svg";
// import { Loader } from "../../../../../../../_Common/loader/Loader";

function Transactions({ orignalSymbol, location }) {
  const [dataTotal, setDataTotal] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.user.userInfo);

  //-----------------------------api call to get data---------------------------//

  const getUserInfo = async () => {
    setLoading(true);
    const perPage = 30;
    const page = Math.ceil(transactions.length / perPage) + 1;
    const params = {
      page: page,
      limit: perPage,
      type: "deposit",
      coinId: location?.state?.detail?._id,
    };
    try {
      let res = await HTTP_CLIENT.get(`${BASE_URL}transaction-log/user/logs`, {
        params,
      });
      setTransactions(res?.data?.data);
      setDataTotal(res?.data?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  //----------------------Pagination----------------------//
  const getUserInfoPagination = async () => {
    const perPage = 30;
    const page = Math.ceil(transactions.length / perPage) + 1;
    const params = {
      page: page,
      limit: perPage,
      type: "deposit",
      coinId: location?.state?.detail?._id,
    };
    try {
      let res = await HTTP_CLIENT.get(`${BASE_URL}transaction-log/user/logs`, {
        params,
      });
      setTransactions([...transactions, ...res?.data?.data]);
      setDataTotal(res?.data?.total);
    } catch (error) {}
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  const fetchMoreData = () => {
    getUserInfoPagination();
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} id="scrollableDiv">
        <InfiniteScroll
          dataLength={transactions.length}
          next={fetchMoreData}
          scrollableTarget="scrollableDiv"
          hasMore={
            dataTotal === transactions.length || dataTotal === 0 ? false : true
          }
          // scrollableTarget="scrollableDiv"
          loader={
            <div className={styles.loaderPosition}>
              <Wave />
            </div>
          }
        >
          {loading ? (
            <div className={styles.loaderPosition}>
              <Wave />
            </div>
          ) : (
            <table>
              {transactions?.length == 0 ? (
                <img className={styles.noData} src={NoData} alt="no data" />
              ) : (
                transactions?.map((item, key) => {
                  const {
                    type,
                    title,
                    message,
                    displayAmount,
                    memos,
                  } = getTransactionData({
                    data: item,
                    userId: currentUser?._id,
                    currentToken: orignalSymbol,
                  });

                  const isSend = type === "withdraw";
                  return (
                    <>
                      {item?.type !== "liquidityCredit" ? (
                        <tr key={key}>
                          <td>
                            <div className={styles.tabelWrapper}>
                              {isSend ? (
                                <img src={trackTop} alt="" />
                              ) : (
                                <img src={trackBottom} alt="" />
                              )}
                              <div className={styles.innerWrapper}>
                                <span style={{ fontWeight: "bold" }}>
                                  {title}
                                </span>
                                <br />

                                <span> {message}</span>
                                {memos && (
                                  <div className={styles.memoWrapper}>
                                    <p>Memo:</p>
                                    <span>
                                      {memos?.length > 30
                                        ? `${memos.substring(0, 30)}...`
                                        : memos}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className={styles.bottomtabelWrapper}>
                              <div className={styles.imgWithName}>
                                {/* <img src={orignalSymbol?.icon?.url} /> */}
                                {displayAmount}{" "}
                                {location?.state?.detail?.shortName}
                                {}
                              </div>
                              {/* <br /> */}
                              <span>
                                {moment(item?.createdAt).format("MMMM Do YYYY")}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              )}
            </table>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default Transactions;
