import React, { useState, useRef, useEffect } from "react";
import styles from "./forgetpassword.module.scss";
import Modal from "../../_Common/Modal/Modal";
import Arrowicon from "../../../Assets/icons/backTo.svg";
import Email from "../../../Assets/image/Email.svg";
import Password from "../../../Assets/image/password.svg";
import { BASE_URL } from "../../../utils/AppConstants";
import axios from "axios";
import { useAlert } from "react-alert";
import useForgetPAssword from "./useForgetPassword";
import { LoadingButton, WaveClone } from "../../_Common/loader/Loader";
import Show from "../../../Assets/icons/show.svg";
import Hide from "../../../Assets/icons/hide.svg";

const ForgetPassword = ({ visible, onClose, signuphandle }) => {
  const {
    active,
    setactive,
    passwordValidation,
    confirmValidation,
    handleInputChange,
    changeOtpHandler,
    changePasswordHandler,
    handleSubmit,
    verifyOtp,
    handleSubmitPassword,
    secondRef,
    thirdRef,
    fourthRef,
    ButtonLoading,
    emailValidation,
  } = useForgetPAssword(onClose, signuphandle);
  const [show, setShow] = useState(false);
  const [display, setDisplay] = useState(false);
  let emailActive = useRef(null)
  useEffect(() => {
    emailActive?.current?.focus();
  }, [visible])
  return (
    <>
      <Modal
        visible={visible}
        onClose={() => {
          setactive(0);
          onClose();
        }}
      >
        <div className={styles.container}>
          <form className={styles.wrapper}>
            <div className={styles.backbutton}>
              {/* <img
                src={Arrowicon}
                alt=""
                onClick={() => setactive(active - 1)}
              /> */}
            </div>
            <div className={styles.detailsection}>
              {active === 0 ? (
                <div className={styles.mainbody}>
                  <div className={styles.heading}>
                    <p>Forgot Password</p>
                    <label>Please Enter Your Email</label>
                  </div>
                  <div className={styles.formbody}>
                    <div className={styles.inputfield}>
                      <p>Enter your Email</p>
                      <div className={styles.field}>
                        <input
                          ref={emailActive}
                          onChange={(e) => handleInputChange(e)}
                          name="email"
                          type="email"
                          placeholder="Email"
                          required="required"
                        />
                        <img src={Email} alt="Email" />
                      </div>
                    </div>
                    {emailValidation ? (
                      <p style={{ color: "#E5516B" }}>{emailValidation}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : active === 1 ? (
                <>
                  <div className={styles.mainbody}>
                    <div className={styles.heading}>
                      <p>OTP</p>
                      <label>Please Enter The Four Digit Code</label>
                    </div>
                    <div className={styles.formbody}>
                      <div className={styles.inputfield}>
                        <div className={styles.fields}>
                          <input
                            autoFocus={true}
                            maxlength="1"
                            type="number"
                            name="numberOne"
                            onChange={(e) => changeOtpHandler(e)}
                            required="required"
                          />
                          <input
                            ref={secondRef}
                            maxlength="1"
                            type="number"
                            name="numberTwo"
                            onChange={(e) => changeOtpHandler(e)}
                            required="required"
                          />
                          <input
                            ref={thirdRef}
                            maxlength="1"
                            type="number"
                            name="numberThree"
                            onChange={(e) => changeOtpHandler(e)}
                            required="required"
                          />
                          <input
                            ref={fourthRef}
                            maxlength="1"
                            type="number"
                            name="numberFour"
                            onChange={(e) => changeOtpHandler(e)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.mainbody}>
                    <div className={styles.heading}>
                      <span>Change Password</span>
                    </div>

                    <div className={styles.inputfield}>
                      <div className={styles.field}>
                        <input
                          name="password"
                          type={display ? "text" : "password"}
                          onChange={(e) => changePasswordHandler(e)}
                          placeholder="New Password"
                          required="required"
                        />
                        <img
                          src={display ? Show : Hide}
                          alt=""
                          onClick={() => setDisplay(!display)}
                        />
                      </div>
                      {passwordValidation ? (
                        <p style={{ color: "#E5516B", textAlign: "left" }}>
                          {passwordValidation}
                        </p>
                      ) : (
                        ""
                      )}
                      <div className={styles.field}>
                        <input
                          type={show ? "text" : "password"}
                          name="resetpassword"
                          onChange={(e) => changePasswordHandler(e)}
                          placeholder="Re-Confirm New Password"
                          required="required"
                        />
                        <img
                          src={show ? Show : Hide}
                          alt=""
                          onClick={() => setShow(!show)}
                        />
                      </div>
                      {confirmValidation ? (
                        <p style={{ color: "#E5516B", textAlign: "left" }}>
                          {confirmValidation}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.inputfield}>
              {active === 0 ? (
                <button onClick={handleSubmit}>
                  {ButtonLoading ? <WaveClone /> : "Send"}
                </button>
              ) : active === 1 ? (
                <button onClick={verifyOtp}>
                  {ButtonLoading ? <WaveClone /> : "Verify"}
                </button>
              ) : (
                <button
                  onClick={
                    handleSubmitPassword
                    // () => redirectPage()
                  }
                >
                  {ButtonLoading ? <WaveClone /> : "Confirm"}
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ForgetPassword;
