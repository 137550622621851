import React from "react";
import Phone from "../../../../../Assets/image/phone.svg";
import message from "../../../../../Assets/image/message.svg";
import location from "../../../../../Assets/image/location.svg";
import cardImg from "../../../../../Assets/image/cardImg.svg";
import Woman from '../../../../../Assets/image/business-woman.png'
import Styles from "./rightSide.module.scss";

function rightSide() {
  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.wrapper}>
          {/* <img className={Styles.imgClass} src={cardImg} alt="" /> */}
          <img className={Styles.img} src={Woman} />
          {/* <div className={Styles.card}>
            <div className={Styles.textWrapper}>
              <div className={Styles.heading}>Info</div>
              <label>
                <img src={Phone} alt="" />
              +1 324 5654 5658
              </label>
              <label>
                <img src={message} alt="" />
                johndeo@mail.com
              </label>
              <label>
                <img src={location} alt="" />
                Main street, Downtown, USA
              </label>
            </div>
            <img className={Styles.imgClass} src={cardImg} alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default rightSide;
