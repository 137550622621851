import React, { useEffect, useState } from "react";
import { WITHDRAWAL_NETWORK_TYPES } from "../../../../../utils/withdrawalNetworkTypes";
import DropDown from "../../../../_Common/DropDown/dropDown";
import InputField from "../../../../_Common/input/input";
import styles from "./withdrawal.module.scss";

const AccountSelection = (props) => {
  const InitialState = props?.state;
  const network = props?.network?.id;
  let addressInfo = "";

  const [filterQData, setfilterQData] = useState("");
  const options = filterQData;
  const [selected, setSelected] = useState({ name: "Select Asset" });
  if (props.network?.id) {
    if (
      WITHDRAWAL_NETWORK_TYPES.BITCOIN ||
      WITHDRAWAL_NETWORK_TYPES.LITECOIN ||
      WITHDRAWAL_NETWORK_TYPES.ETHEREUM
    ) {
      addressInfo = "The address to which the withdrawal should be sent.";
    } else if (WITHDRAWAL_NETWORK_TYPES.LIGHTNING) {
      addressInfo =
        "A BOLT 11 invoice address without encoded amount. Use an invoice that allows for an arbitrary target amount.";
    } else if (WITHDRAWAL_NETWORK_TYPES.STELLAR) {
      addressInfo = "";
    }
  }

  useEffect(() => {
    setfilterQData(props?.network?.assets);
    return () => {
      setSelected({});
    };
  }, []);

  return (
    <>
      <div className={styles.DnyamicWrapper}>
        {network == WITHDRAWAL_NETWORK_TYPES.PIX ? (
          <>
            <InputField
              // attribute
              Name="pixkey"
              //heading
              name="Pix Key"
              type="text"
              //placeholder
              inputName="Pix Key"
              value={InitialState?.pixKey}
              tooltopStatus="The PIX key associated with the recipient bank account. Typically a phone number, CPF, email address, or random key."
              exclamationMark
              onclick={(e) => props.onChangeState("pixKey", e.target.value)}
            />

            <InputField
              // attribute
              Name="TaxId"
              //heading
              name="Tax Id"
              type="text"
              //placeholder
              inputName="00.000.000/0000-00"
              value={InitialState?.taxId}
              tooltopStatus="The recipient's tax id. CNPJ format (00.000.000/0000-00) for corporate identities or CPF format (000.000.000-00) for individuals."
              exclamationMark
              onclick={(e) => props.onChangeState("taxId", e.target.value)}
            />
          </>
        ) : props?.network?.type == "BANK" ? (
          <>
            <InputField
              // attribute
              Name="cbu"
              //heading
              name="CBU"
              type="text"
              //placeholder
              inputName="Cbu"
              value={InitialState?.cbu}
              tooltopStatus="The recipient's bank account number (CBU or CVU). This CBU must match the account information verified on COINQVEST."
              exclamationMark
              onclick={(e) => props.onChangeState("cbu", e.target.value)}
            />
            <InputField
              // attribute
              Name="CuitPersonal"
              //heading
              name="Cuit Personal"
              type="text"
              //placeholder
              inputName="Cuit Personal"
              value={InitialState?.cuitPersonal}
              tooltopStatus="Your personal tax id associated with the target CBU and your COINQVEST account."
              exclamationMark
              onclick={(e) =>
                props.onChangeState("cuitPersonal", e.target.value)
              }
            />

            <InputField
              // attribute
              Name="CuitCompany"
              //heading
              name="Cuit Company"
              type="text"
              //placeholder
              inputName="Cuit Company"
              value={InitialState?.cuitCompany}
              tooltopStatus="Business accounts need to provide an additional CUIT, issued towards the company that owns the bank account and is verified on COINQVEST."
              exclamationMark
              onclick={(e) =>
                props.onChangeState("cuitCompany", e.target.value)
              }
            />
          </>
        ) : network == WITHDRAWAL_NETWORK_TYPES.STELLAR ? (
          <>
            <InputField
              // attribute
              Name="address"
              //heading
              name="Destination Address"
              type="text"
              //placeholder
              inputName="Address"
              value={InitialState?.account}
              tooltopStatus="The account to which the withdrawal should be sent."
              exclamationMark
              onclick={(e) => props.onChangeState("account", e.target.value)}
            />
            <InputField
              // attribute
              Name="memo"
              //heading
              name="Memo"
              type="text"
              //placeholder
              inputName="Memo"
              value={InitialState?.memo}
              tooltopStatus="The Stellar memo used in this payment. Can be null"
              exclamationMark
              onclick={(e) => props.onChangeState("memo", e.target.value)}
            />
            <InputField
              // attribute
              Name="memotype"
              //heading
              name="Memo Type"
              type="text"
              //placeholder
              inputName="Memo Type"
              value={InitialState?.memoType}
              tooltopStatus="The type of memo, if any. Can be null"
              exclamationMark
              onclick={(e) => props.onChangeState("memoType", e.target.value)}
            />

            <DropDown
              padding="14px 2px"
              selected={selected}
              setSelected={(res) => {
                props.onChangeState("asset", res);
                setSelected({ name: res });
              }}
              name="Assets"
              tooltopStatus="This field specifies the target asset you want the recipient to be credited in. If no value is given then the target account is credited in sourceAsset."
              exclamationMark
              options={options}
            />
          </>
        ) : (
          <>
            <InputField
              // attribute
              Name="Address"
              //heading
              name="Address"
              type="text"
              //placeholder
              exclamationMark
              value={InitialState?.address}
              tooltopStatus="The address to which the withdrawal should be sent."
              inputName="Address"
              onclick={(e) => props.onChangeState("address", e.target.value)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AccountSelection;
