import styles from "./popup.module.scss";
import xSymbolBlack from "../../../../Assets/symbols/xSymbolBlack.png";
import cSymbolBlack from "../../../../Assets/symbols/cSymbolBlack.png";

const AssetTokens = () => {
  const url = "https://www.law.cornell.edu/uscode/text/12/411";
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <label>CQR Asset Tokens</label>
          <p>
            CQR Vault’s <span>“Asset Tokens”</span> represent Intentional
            Community Opportunities “ICO” / “<img src={xSymbolBlack} alt="" />
            ”; or International Crowns “IC” / “<img src={cSymbolBlack} alt="" />
            ”. These are private fungible tokens that Bona Fide Members
            typically utilize as a store of wealth in the respective commodity
            of their choice. Depending on the token parameters, CQR Asset Tokens
            may represent either 1oz of Silver or 1oz of Gold minted by a
            Government Treasury in the form of a Legal Tender Coin.
            Additionally, CQR Vault Members may also exchange these fungible
            tokens between each other via self-directed transaction resulting in
            a lawful redemption (
            <a href={url} target="_blank" rel="noreferrer">
              Title 12 U.S. Code § 411
            </a>
            ) and inter-exchange of like kind values within the CQR Vault & CQR
            Vest DeFi & DEX platforms.
          </p>
        </div>
      </div>
    </>
  );
};
export default AssetTokens;
