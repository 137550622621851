import React from "react";
import styles from "./HistoryPopUp.module.scss";

const HistoryPopup = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mainHeading}>History</div>
      <div className={styles.rowWrapper}>
        <div className={styles.leftWrapper}>Date</div>
        <div className={styles.rightWrapper}>16, Sept 2021 22:12:39</div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.leftWrapper}>Type</div>
        <div className={styles.rightWrapper}>Buy</div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.leftWrapper}>Sending Address</div>
        <div className={styles.rightWrapper}>0x12859...3dt</div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.leftWrapper}>Amount</div>
        <div className={styles.rightWrapper}>0.0005 ETH</div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.leftWrapper}>Send Amount</div>
        <div className={styles.rightWrapper}>0.0005 ETH</div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.leftWrapper}>Status</div>
        <div className={styles.Completed}>Completed</div>
      </div>
    </div>
  );
};

export default HistoryPopup;
