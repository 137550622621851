import React from "react";
import Styles from "./slider.module.scss";
import Carousel, { consts } from "react-elastic-carousel";
import BackButton from "../../../Assets/icons/Back_White.svg";
import NextButton from "../../../Assets/icons/Next_White.svg";

function Slider({ children, team }) {
  const breakpoints1 = [
    { width: 0, itemsToShow: 1, itemsToScroll: 1 },
    { width: 600, itemsToShow: 2, itemsToScroll: 1 },
    { width: 1024, itemsToShow: 3, itemsToScroll: 1 },
  ];
  const breakpoints2 = [
    { width: 600, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1024, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1400, itemsToShow: 1, itemsToScroll: 1 },
  ];
  const breakpoints3 = [
    { width: 0, itemsToShow: 1, itemsToScroll: 1 },
    { width: 600, itemsToShow: 2, itemsToScroll: 1 },
    { width: 1024, itemsToShow: 3, itemsToScroll: 1 },
  ];
  const brackpointsList = {
    1: breakpoints1,
    2: breakpoints2,
    3: breakpoints3,
  };
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <img src={BackButton} alt="" className={Styles.btnImg} />
      ) : (
        <img src={NextButton} alt="" className={Styles.btnImg} />
      );
    return (
      <button onClick={onClick} disabled={isEdge} className={Styles.btn}>
        {pointer}
      </button>
    );
  };

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.wrapper}>
          <Carousel
            breakPoints={brackpointsList[team]}
            className={Styles.innerWrapper}
            autoPlaySpeed={5000}
            enableAutoPlay
            renderArrow={myArrow}
            enableSwipe
          >
            {children}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Slider;
