import { HTTP_CLIENT } from "../utils/services";

const uploadImage = async (params) => {
  return await HTTP_CLIENT.post("storage/upload/base64", params);
};

const submitAddress = async (params) => {
  return await HTTP_CLIENT.post("/remittance-addresses", params);
};
// const deleteRemittanceCard = async (params) => {
//   return await HTTP_CLIENT.post("/remittance-addresses/{_id}", params);
// };

const getAddress = async () => {
  return await HTTP_CLIENT.get("/remittance-addresses");
};

const handleDirectWire = async (params) => {
  return await HTTP_CLIENT.post("/direct-wires", params);
};

const getBankDetail = async () => {
  return await HTTP_CLIENT.get("/settings");
};

const updateUserName = async (params) => {
  return await HTTP_CLIENT.post("/user/edit", params);
};
const handleDeleteAccount = async () => {
  return await HTTP_CLIENT.post("user/delete-account");
};

const getWire = async () => {
  return await HTTP_CLIENT.get("/direct-wires/user");
};

const getNetwork = async () => {
  return await HTTP_CLIENT.get("/coinqvest/networks");
};

const postWithdrawalData = async (data) => {
  return await HTTP_CLIENT.post("/coinqvest/request", data);
};

const getWithdrawalHistoryData = async () => {
  return await HTTP_CLIENT.get("/coinqvest/withdrawal/user");
};
const activationWalletToken = async (data) => {
  return await HTTP_CLIENT.get(
    `native-token/rates/activation?type=required&from=${
      data?.selected ? data?.selected : ""
    }&activationType=${data ? data?.activationType : ""}`
  );
};

export {
  uploadImage,
  submitAddress,
  getAddress,
  activationWalletToken,
  handleDirectWire,
  getBankDetail,
  updateUserName,
  getWire,
  handleDeleteAccount,
  getNetwork,
  postWithdrawalData,
  getWithdrawalHistoryData,
  // deleteRemittanceCard,
};
