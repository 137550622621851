import React, { useState } from "react";
import { useContext, useEffect } from "react";

import { Route, Switch } from "react-router-dom";
import ScrollToTop from "../../Components/_Common/ScrollToTop";
import DepositAddress from "../_Common/DashboardComponents/DepositAddress/DepositAddress";
import VaultToken from "./Dashboard/DashboardInnerComponent/CryptoCurrency/CqrToken/Fiat";
import CQRVaultToken from "./Dashboard/DashboardInnerComponent/CQR Vault/CqrToken/Fiat";
import Settings from "./Dashboard/Settings/Settings";
import Profile from "./Dashboard/Profile/Profile";
import History from "./Dashboard/History/History";
import { useRouteMatch } from "react-router-dom";
import CryptoCurrency from "./Dashboard/DashboardInnerComponent/CryptoCurrency/CryptoCurrency";
// import TermsAndConditions from "./Dashboard/TermsAndConditions/TermsAndConditions";
import AssetsTokens from "../DashBoardLayout/Dashboard/DashboardInnerComponent/CryptoCurrency/QTokens/QTokens";
import CryptoTableGraph from "./Dashboard/DashboardInnerComponent/CryptoCurrency/CryptoTableGraph/CryptoTableGraph";
import FiatSwap from "./Dashboard/DashboardInnerComponent/CryptoCurrency/FiatSwap/FiatSwap";
import CryptoSwap from "./Dashboard/DashboardInnerComponent/CryptoCurrency/CryptoSwap/FiatSwap";
import CQRVault from "./Dashboard/DashboardInnerComponent/CQR Vault/CQRVault";
import Send from "../_Common/DashboardComponents/SendPopup/Send";
import AssetsSwap from "../_Common/DashboardComponents/assetsSwap/assetsSwap";
import DirectDebit from "../_Common/DirectDebit/DirectDebit";
import Error from "../_Common/CommonError/Error";
import CQRvest from "./Dashboard/DashboardInnerComponent/CQR Vest/CQRvest";
import LiquidityTokens from "./Dashboard/DashboardInnerComponent/CQR Vault/CqrToken/Fiat";
import SilentWave from "./Dashboard/DashboardInnerComponent/CQR Vest/SilentWave/SilentWave";
import Subscription from "./Dashboard/Subscription/Subscription";
import PrivateChat from "../../Components/DashBoardLayout/Dashboard/Chat/PrivateChat";
import MyAssets from "./Dashboard/DashboardInnerComponent/CQR Vest/My Assets/MyAssets";
import VaultGraph from "./Dashboard/DashboardInnerComponent/CQR Vault/VaultGraph/VaultGraph";
import SubscriptionHistory from "./Dashboard/Subscription/Subscription History/SubscriptionHistory";
import QTokens from "./Dashboard/DashboardInnerComponent/CQR Vault/AssetsTokens/AssetsTokens";
import CqrLiquidityTokens from "./Dashboard/DashboardInnerComponent/CQR Vault/CqrToken/send/send";
import TokenHistory from "./Dashboard/DashboardInnerComponent/CQR Vault/AssetsTokens/TokenHistory/TokenHistory";
import PaymentSelection from "./Dashboard/DashboardInnerComponent/CQR Vault/paymentSelection/paymentSelection";
import Directwire from "./Dashboard/DashboardInnerComponent/CQR Vault/direct-wire/directWire";
import Ramittance from "./Dashboard/Ramittance/Ramittance";
import LiquidityTableGraph from "./Dashboard/DashboardInnerComponent/LiquidityTokens/LiquidityGraph";
import Marketplace from "./Dashboard/MarketPlace";
import MarketplaceDetail from "./Dashboard/MarketPlace/MarketplaceDetail";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../Context/Socket";
import { CHAT_SOCKET_TYPES } from "../../utils/ChatConstants";
import { useAlert } from "react-alert";
import Inbox from "./Dashboard/inbox";
import {
  KYCCleanUp,
  userCleanUp,
  userInfo,
} from "../../redux/action/userAction";
import { currencyLogoutCleanUp } from "../../redux/action/currencyDetailAction";
import { swapLogoutCleanUp } from "../../redux/action/sawapAction";
import { subscriptionCleanUp } from "../../redux/action/subscriptionAction";
import { remittanceCleanUp } from "../../redux/action/remittanceAction";
import { useHistory } from "react-router-dom";
import ChangePasswordScreen from "./Dashboard/changePassword/ChangePasswordScreen";
import CoinQvest from "../_Common/DashboardComponents/CoinQvest/CoinQvest";
import Withdrawal from "./Dashboard/DashboardInnerComponent/Withdrawal";
import OwnDetail from "./Dashboard/MarketPlace/OwnDetail";
import LegacySwap from "../_Common/DashboardComponents/legacySwap";
import WithdrawalDirectwire from "./Dashboard/DashboardInnerComponent/WithdrawalDirectwire";
import { setupAxios } from "../../utils/services";

const DashRoutes = () => {
  let {} = useRouteMatch();

  const socket = useContext(SocketContext);
  const userEmail = useSelector((state) => state?.user?.userInfo?.email);
  const changeUserPassword = useSelector(
    (state) => state?.user?.userInfo?.isImported
  );
  const alert = useAlert();
  const [toggle, setToggle] = useState(false);
  const [blockData, setblockData] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfoReducer = useSelector(
    (state) => state?.currency?.currencyInfo?.user
  );
  // useEffect(() => {

  useEffect(() => {
    socket.on(userEmail, (data) => {
      if (data.type == "userBlocked") {
        alert.show(
          `Your Account has been suspended by admin for the following reasons ${data?.user?.blockReason}`,
          {
            type: "error",
          }
        );
        handleLogout();
        return;
      }
    });
  }, []);

  const handleLogout = () => {
    dispatch(userCleanUp());
    dispatch(KYCCleanUp());
    dispatch(currencyLogoutCleanUp());
    dispatch(swapLogoutCleanUp());
    dispatch(subscriptionCleanUp());
    dispatch(remittanceCleanUp());

    localStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    socket.connect();
    socket.on("connect", () => {
      socket.emit(CHAT_SOCKET_TYPES.USER_CONNECT, {
        userId: userInfoReducer?._id,
      });
      socket.emit("room", userInfoReducer?._id);
    });

    socket.on("connect_error", (err) => {});
  }, []);
  return (
    <>
      <ScrollToTop />
      {changeUserPassword ? (
        <Switch>
          <Route exact path="*" component={ChangePasswordScreen} />
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path="/subscription-history"
            component={SubscriptionHistory}
          />
          <Route exact path="/Q-tokens" component={QTokens} />
          <Route exact path="/token-history" component={TokenHistory} />
          <Route exact path="/cqr-asset-tokens" component={AssetsTokens} />
          <Route exact path="/vault-graph" component={VaultGraph} />
          <Route
            exact
            path="/liquidity-graph"
            component={LiquidityTableGraph}
          />
          <Route exact path="/my-assets" component={MyAssets} />
          <Route
            exact
            path="/marketplace-details"
            component={MarketplaceDetail}
          />
          <Route exact path="/own-add-details" component={OwnDetail} />
          <Route exact path="/cryptocurrency" component={CryptoCurrency} />
          <Route exact path="/withdrawal" component={Withdrawal} />
          <Route
            exact
            path="/withdrawal-directwire"
            component={WithdrawalDirectwire}
          />
          <Route exact path="/cqr-vest" component={CQRvest} />
          <Route exact path="/marketplace" component={Marketplace} />
          <Route exact path="/vault-token" component={VaultToken} />
          <Route exact path="/crypto-swap" component={CryptoSwap} />
          <Route exact path="/fiat-swap" component={FiatSwap} />
          <Route exact path="/DepositAddress" component={DepositAddress} />
          <Route exact path="/Send" component={Send} />
          <Route exact path="/cqr-vault-token" component={CQRVaultToken} />
          <Route exact path="/cqr-vault" component={CQRVault} />
          <Route exact path="/currency-graph" component={CryptoTableGraph} />
          <Route path="/settings" component={Settings} />
          <Route path="/remittance" component={Ramittance} />
          <Route path="/profile" component={Profile} />
          <Route path="/subscription" component={Subscription} />
          <Route exact path="/assets-swap" component={AssetsSwap} />
          <Route exact path="/legacy-swap" component={LegacySwap} />
          <Route path="/silent-wave" component={SilentWave} />
          <Route path="/chat" component={PrivateChat} />
          <Route path="/inbox" component={Inbox} />
          <Route path="/error" component={Error} />
          <Route path="/history" component={History} />
          <Route path="/liquidity-tokens" component={LiquidityTokens} />
          <Route path="/payment-selection" component={PaymentSelection} />
          <Route path="/direct-wire" component={Directwire} />
          <Route path="/register-qvest" component={CoinQvest} />
        </Switch>
      )}
    </>
  );
};

export default DashRoutes;
