import React from "react";
import HistoryTable from "./SubscriptionHistoryTable/SubscriptionTable";
import BackImg from "../../../../../Assets/icons/backk.svg";
import styles from "./SubscriptionHistory.module.scss";
import { useHistory } from "react-router-dom";

const SubscriptionHistory = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.mainHeading}>
            <img src={BackImg} alt="" onClick={() => history.goBack()} />
            Subscription History
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableWrapper}>
              <HistoryTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionHistory;
