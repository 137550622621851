import React from "react";
import VideoSection from "./videos";
import { Helmet } from "react-helmet";

const Faq = () => {
  return (
    <>
      <Helmet>
        <title>FAQ's - CQR Vault</title>
        <meta
          name="description"
          content="An awesome new cash flow community for innovative people is coming very soon."
        />
        <link rel="canonical" href="https://cqrvault.org/faq" />
      </Helmet>
      <VideoSection />
    </>
  );
};

export default Faq;
