import {
  REMITTANCE_ADDRESS,
  SIGNLE_REMITTANCE_ADDRESS,
  DIRECT_WIRE_LIST,
  SAVE_BANK_DETAIL,
  CLEAR_REMITTANCE_ADDRESS_STATE,
} from "../types";

export const saveRemittanceAddress = (data) => {
  return {
    type: REMITTANCE_ADDRESS,
    payload: data,
  };
};

export const saveSignleRemittanceAddress = (data) => {
  return {
    type: SIGNLE_REMITTANCE_ADDRESS,
    payload: data,
  };
};

export const saveDirectWireList = (data) => {
  return {
    type: DIRECT_WIRE_LIST,
    payload: data,
  };
};

export const saveBankDetail = (data) => {
  return {
    type: SAVE_BANK_DETAIL,
    payload: data,
  };
};

export const remittanceCleanUp = () => {
  return {
    type: CLEAR_REMITTANCE_ADDRESS_STATE,
  };
};
