import React, { useEffect, useState } from "react";
import styles from "./History.module.scss";
import Modal from "../../../_Common/Modal/Modal";
import Popup from "../../../_Common/DashboardComponents/popup/popup";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import HistoryTable from "./HistoryTable/HistoryTable";
import BackImg from "../../../../Assets/icons/historyBack.svg";

function History() {
  const history = useHistory();

  const alert = useAlert();
  const [number, setNumber] = useState(1);
  const [popupvisible, setpopupvisible] = useState(false);
  const [isActivate, setIsActivate] = useState(false);
  const [active, setActive] = useState(false);
  const togglepopup = (e, num) => {
    e.preventDefault();
    setNumber(num);
    setpopupvisible((preview) => !preview);
  };

  //------------Refresh for update data purpose----------
  const handleRefresh = () => {};

  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <Popup data={number} />
      </Modal>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.mainHeading}>
            <img src={BackImg} alt="" onClick={() => history.goBack()} />
            History
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableWrapper}>
              <HistoryTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default History;
