import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DoneImg from "../../../../../../Assets/icons/done.svg";
import { SocketContext } from "../../../../../../Context/Socket";
import { customerRegistration } from "../../../../../../redux/action/customerRegistrationAction";
import Done from "../../../../../_Common/DashboardDone/popup";
import Modal from "../../../../../_Common/Modal/Modal";
import styles from "./Fiat.module.scss";
import { formikSchema } from "./FiatFormikSchema";
import Send from "./send/send";

function LiquidityTokens({ history }) {
  const customerSelector = useSelector((state) => state.customer);
  const options = [
    { name: "English", value: "en" },
    { name: "French", value: "fr" },
    { name: "Spanish", value: "es" },
    { name: "Deutsch", value: "de" },
    { name: "Portuguese", value: "pt" },
    { name: "Italian", value: "it" },
    { name: "Dutch", value: "nl" },
    { name: "Danish", value: "da" },
    { name: "Bokmål", value: "nb" },
    { name: "Slovenian", value: "sl" },
    { name: "Swedish", value: "sv" },
  ];
  const [selected, setSelected] = useState(options[0]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [popupvisible, setpopupvisible] = useState(false);

  const togglepopup = () => {
    setpopupvisible((preview) => !preview);
  };

  // useEffect(() => {
  //   setShow(currencySelector.currencyInfo.user.isCustomer);
  // }, []);

  useEffect(() => {
    if (
      customerSelector?.customerDataSuccess ===
      "Customer Registered successfully"
    ) {
      window.open(customerSelector?.customerData.redirectUrl, "_blank");
    }
  }, [customerSelector?.customerData]);

  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      CompanyName: "",
      Email: "",
      FamilyName: "",
      Language: { name: "English", value: "en" },
      GivenName: "",
      PhoneNumber: "",
      Address: "",
      City: "",
      PostalCode: "",
    },

    onSubmit: (values, reset) => {
      dispatch(customerRegistration(values));
      // togglepopup();
      reset.resetForm();
    },
  });
  const userEmail = useSelector((state) => state?.user?.userInfo?.email);
  const socket = useContext(SocketContext);

  return (
    <>
      <Modal visible={popupvisible} onClose={() => setpopupvisible(false)}>
        <Done Name="Thank You Your Deposit is Confirmed" src={DoneImg} />
      </Modal>
      <div className={styles.container}>
        <div className={styles.card}>
          <Send location={location} />
        </div>
      </div>
    </>
  );
}

export default LiquidityTokens;
