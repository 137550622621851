import React, { useState } from "react";
import copy from "../../../../Assets/image/copy.svg";
import styles from "./DepositAddress.module.scss";
import Doge from "../../../../Assets/icons/dogg.svg";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import DropDown from "../../DropDown/dropDown";

export default function DepositAddress({ location }) {
  const currencySelector = useSelector((state) => state.currency);
  const [copyCheck, setcopycheck] = useState(false);

  const options = currencySelector.currencyInfo.wallets;
  const [selected, setSelected] = useState(
    location
      ? location?.state?.detail
      : currencySelector.currencyInfo.wallets[1]
  );

  const CopyText = () => {
    navigator?.clipboard?.writeText(selected?.address);
    setcopycheck(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.cardwrapper}>
          <div className={styles.headingWrapper}>
            <label>Select Token</label>
            <div className={styles.Selection}>
              <DropDown
                padding="14px 20px"
                selected={selected}
                setSelected={setSelected}
                options={location ? "" : options}
                imgShow
                img={Doge}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <QRCode value={selected.address} size={100} fgColor="#000" />
            </div>
            <span>
              Your <span>{selected?.coinSymbol?.toUpperCase()}</span> Address
            </span>
            <div className={styles.inputWrapper}>
              <span>{selected.address}</span>
              <img onClick={CopyText} src={copy} alt="" />
            </div>
            <div style={{ textAlign: "center", color: "green" }}>
              {copyCheck ? "Copied" : ""}
            </div>
            <h2>Important</h2>
            <ul>
              <li>
                Send only {selected?.coinSymbol?.toUpperCase()} to this address.
                Sending any other coin or token to this address may result in
                the loss of your funds.
              </li>
              <li>Coins will be received after 1 network confirmation.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
