import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CqrRecieveButtonDataFile from "../../../../Data/Dashboard/CQRvault/CqrRecieveButtonDataFile";
import CqrSendButtonDataFile from "../../../../Data/Dashboard/CQRvault/CqrSendButtonDataFile";
import CqrSwapButtonDataFile from "../../../../Data/Dashboard/CQRvault/CqrSwapButtonDataFile";
import CQRvestData from "../../../../Data/Dashboard/CQRvestData";
import RecieveButtonDataFile from "../../../../Data/Dashboard/CryptoCurrency/RecieveButtonDataFile";
import SendButtonDtaFile from "../../../../Data/Dashboard/CryptoCurrency/SendButtonDtaFile";
import SwapButtonDataFile from "../../../../Data/Dashboard/CryptoCurrency/SwapButtonDataFile";
import DepositAddress from "../DepositAddress/DepositAddress";
import Send from "../SendPopup/Send";
import styles from "./popup.module.scss";
function Popup({ data, popupvisiblee, subData, location }) {
  const dataObj = {
    1: RecieveButtonDataFile,
    2: SendButtonDtaFile,
    3: SwapButtonDataFile,
    4: CqrRecieveButtonDataFile,
    5: CqrSendButtonDataFile,
    6: CqrSwapButtonDataFile,
    7: CQRvestData,
  };
  const { heading, buttons, text } = dataObj[data];
  const currencySelector = useSelector((state) => state.currency);
  const [popupvisible, setpopupvisible] = useState(false);
  const togglepopup = (e) => {
    e.preventDefault();
    setpopupvisible(true);
  };
  return (
    <>
      {popupvisiblee || popupvisible ? (
        data === 1 ? (
          <DepositAddress location={location} />
        ) : (
          <Send location={location} />
        )
      ) : (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.textWrapper}>
              <label>{heading}</label>
              <p>{text}</p>

              <div className={styles.buttonWrapper}>
                {buttons.map((btn) => {
                  const {
                    name,
                    link,
                    popup,
                    img,
                    disabled,
                    linkChanged,
                    seclinkchanged,
                    cqrlinkchanged,
                    AgAu,
                    kio,
                  } = btn;
                  /** When we click on CQR & Crypto popup buttons then toggle a new modal with these buttons **/
                  if (popup) {
                    return (
                      <div onClick={togglepopup} className={styles.button}>
                        {name}
                        <img src={img} />
                      </div>
                    );
                  } else if (disabled) {
                    /** When we click on CQR & Crypto popup buttons then toggle a new modal with disabled buttons **/
                    return (
                      <Link
                        className={styles.button}
                        key={name}
                        to={link}
                        style={{ cursor: "not-allowed" }}
                      >
                        {name}
                      </Link>
                    );
                  } else if (linkChanged && img) {
                    /** When we click on CQR & Crypto popup buttons then toggle a new modal with Condition changes link **/
                    return (
                      <Link
                        to={
                          currencySelector.currencyInfo.user.isWalletActivated
                            ? "/crypto-swap"
                            : "/error"
                        }
                        className={styles.button}
                        key={name}
                      >
                        Crypto to C
                        <img
                          style={{
                            width: "18px",
                            height: "18px",
                            marginTop: "2px",
                          }}
                          src={img}
                          alt=""
                        />
                        R Vault
                      </Link>
                    );
                  } else if (cqrlinkchanged && img && kio) {
                    /** When we click on CQR & Crypto popup buttons then toggle a new modal with Liquidity tokens token img button **/
                    return (
                      <Link to={link} className={styles.button} key={name}>
                        C
                        <img
                          style={{
                            width: "18px",
                            height: "18px",
                            marginTop: "2px",
                          }}
                          src={img}
                          alt=""
                        />
                        R Vest ('FNFT')
                      </Link>
                    );
                  } else if (AgAu && img) {
                    /** When we click on CQR & Crypto popup buttons then toggle a new modal with Liquidity tokens token img button **/
                    return (
                      <Link to={link} className={styles.button} key={name}>
                        C
                        <img
                          src={img}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginTop: "2px",
                          }}
                          alt=""
                        />
                        R Asset Tokens ('Ag/Au')
                      </Link>
                    );
                  } else if (seclinkchanged) {
                    return (
                      <Link
                        to={
                          currencySelector.currencyInfo.user.isWalletActivated
                            ? "/assets-swap"
                            : "/error"
                        }
                        className={styles.button}
                        key={name}
                      >
                        C
                        <img
                          style={{
                            width: "18px",
                            height: "18px",
                            marginTop: "2px",
                          }}
                          src={img}
                          // alt=""
                        />
                        R{/* {name} */}&nbsp;Vault &nbsp;To C
                        <img
                          style={{
                            width: "18px",
                            height: "18px",
                            marginTop: "2px",
                          }}
                          src={img}
                          // alt=""
                        />
                        R{/* {name} */}&nbsp;Vault
                      </Link>
                    );
                  } else if (name == "CQR Liquidity Tokens('Q')") {
                    /** When we click on CQR & Crypto popup buttons then toggle a new modal with Liquidity tokens token img button **/
                    return (
                      <Link to={link} className={styles.button} key={name}>
                        C
                        <img
                          src={img}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginTop: "2px",
                          }}
                          alt=""
                        />
                        R Liquidity Tokens ('
                        <img src={img} alt="" />
                        ')
                      </Link>
                    );
                  }
                  // else {
                  //   /** When we click on CQR & Crypto popup buttons then toggle a new modal with Rest of all buttons **/
                  //   return (
                  //     <Link to={link} className={styles.button} key={name}>
                  //       <div>{name}</div>
                  //     </Link>
                  //   );
                  // }
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;
