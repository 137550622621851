import React, { useEffect, useState } from "react";
import styles from "./settings.module.scss";
import Changepassword from "../../../../Assets/image/changepassword.svg";
import Pushnotifications from "../../../../Assets/image/pushnotifications.svg";
import Defaultcurrency from "../../../../Assets/image/defaultcurrency.svg";
import optionArrow from "../../../../Assets/image/option-arrow.svg";
import Editprofile from "../../../../Assets/icons/Editprofile.svg";
import showImg from "../../../../Assets/icons/show.svg";
import hideImg from "../../../../Assets/icons/hide.svg";
import Swtich from "../../../_Common/Switch/Switch";
import Modal from "../../../_Common/Modal/Modal";
import InputField from "../../../_Common/input/input";
import { HTTP_CLIENT } from "../../../../utils/services";
import { LoadingButton2, Wave } from "../../../_Common/loader/Loader";
import { useAlert } from "react-alert";
import Warning from "../../../../Assets/image/warning.png";

import {
  userCleanUp,
  KYCCleanUp,
  userInfo,
} from "../../../../redux/action/userAction";
import {
  currencyLogoutCleanUp,
  getCurrencyDetail,
} from "../../../../redux/action/currencyDetailAction";
import { swapLogoutCleanUp } from "../../../../redux/action/sawapAction";
import { subscriptionCleanUp } from "../../../../redux/action/subscriptionAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import RemittanceAddresslist from "../../../_Common/remittanceAddressList/remittanceAddresslist";
import DirectWireList from "../../../_Common/directWireList/directWireList";
import {
  remittanceCleanUp,
  saveBankDetail,
} from "../../../../redux/action/remittanceAction";
import {
  getBankDetail,
  handleDeleteAccount,
  updateUserName,
} from "../../../../services/paymentServices";
import { BASE_URL } from "../../../../utils/AppConstants";
import { BarWave } from "react-cssfx-loading/lib";
const Settings = ({ visible, onClose, btn }) => {
  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  const { name } = useSelector((state) => state.user);

  const [active, setactive] = useState(false);
  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [passwordshow, setPasswordshow] = useState(false);
  const [passwordshow2, setPasswordshow2] = useState(false);
  const [passwordshow3, setPasswordshow3] = useState(false);
  const [Remittance, setRemittance] = useState(0);
  const [userName, setuserName] = useState(name);
  const [nameLoading, setnameLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [load, setload] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    resetpassword: "",
    olPasswordValidation: "",
    passwordValidation: "",
    // emailValidation: "",
    confirmValidation: "",
  });
  const userSelector = useSelector((state) => state.currency);
  const userKyc = useSelector((state) => state?.user);

  useEffect(() => {
    if (
      !userKyc?.savedFormData?._id &&
      !userKyc?.kyc?._id &&
      !userSelector?.currencyInfo?.gpaData?._id
    ) {
      history.push("/profile");
      alert.show("Please fill your GPA data first", {
        type: "error",
      });
    }
  }, []);
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [name === "newPassword" ? "passwordValidation" : "confirmValidation"]: "",
      [name === "newPassword" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [name === "newPassword" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    if (inputs.newPassword.length > 8 && inputs.newPassword.length < 16) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "",
      }));
    }
    if (inputs?.oldPassword.length > 8 && inputs.oldPassword.length < 16) {
      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "",
      }));
    }
  };

  let isValidated = () => {
    let response = true;
    if (inputs?.oldPassword?.length > 16 || "") {
      response = false;

      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "Password can't be greater than 16.",
      }));
    }
    if (inputs?.oldPassword?.length < 8 || "") {
      response = false;

      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "Password can't be less than 8.",
      }));
    }
    if (!inputs?.oldPassword) {
      response = false;

      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "Password Field can't be empty.",
      }));
    }
    if (inputs?.newPassword?.length > 16 || "") {
      response = false;

      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be greater than 16.",
      }));
    }
    if (inputs?.resetpassword?.length > 16 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be greater than 16.",
      }));
    }
    if (inputs?.newPassword?.length < 8 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be smaller than 8.",
      }));
    }
    if (inputs?.resetpassword?.length < 8 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be smaller than 8.",
      }));
    }
    if (!inputs.newPassword) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password Field can't be empty.",
      }));
    }
    if (!inputs.resetpassword) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat Password can't be empty.",
      }));
    }
    if (
      inputs.newPassword !== "" &&
      inputs.resetpassword !== "" &&
      inputs.newPassword !== inputs.resetpassword
    ) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Password do not match.",
      }));
    }
    return response;
  };

  let handleSubmit = async (e) => {
    // e.preventdefault();
    if (!isValidated()) {
      // setload(false);
      return;
    }
    setload(true);

    let data = {
      oldPassword: inputs.oldPassword,
      newPassword: inputs.newPassword,
    };
    try {
      let res = await HTTP_CLIENT.post(`auth/change-password`, data);
      setload(false);
      alert.show("Password updated successfully", {
        type: "success",
      });
      dispatch(userCleanUp());
      dispatch(KYCCleanUp());
      dispatch(currencyLogoutCleanUp());
      dispatch(swapLogoutCleanUp());
      dispatch(subscriptionCleanUp());
      dispatch(remittanceCleanUp());

      localStorage.clear();
      history.push("/");
      setactive(false);
    } catch (err) {
      if (err?.response?.data?.message) {
        alert.show(err.response.data.message, { type: "error" });
      }
      setload(false);
      // setactive(false);
    }
  };

  const fetchBankDetail = async () => {
    try {
      const response = await getBankDetail();

      dispatch(saveBankDetail(response?.data));
    } catch (error) {
      if (error.response?.status == 401) {
        localStorage.clear();
        history.push("/");
      }
      alert.show(error?.response?.data?.message, { type: "error" });
    }
  };

  useEffect(() => {
    fetchBankDetail();
  }, []);

  const handleNameChange = (e) => {
    setuserName(e.target.value);
  };
  const handleUpdate = async () => {
    if (!userName) {
      alert.show("Name cannot be empty", { type: "error" });
      return;
    }
    let params = {
      name: userName,
      // email: "dd",
    };
    try {
      setnameLoading(true);
      const response = await updateUserName(params);
      let resr = await HTTP_CLIENT.get(`${BASE_URL}user/info`);
      if (resr) {
        dispatch(getCurrencyDetail());
        dispatch(userInfo(resr.data.user));
      }

      setnameLoading(false);
      setShow(false);
      alert.show("User name updated successfully", { type: "success" });
    } catch (error) {
      alert.show(error?.response?.data?.message, { type: "error" });
      setnameLoading(false);
    }
  };

  const handleLogout = () => {
    dispatch(userCleanUp());
    dispatch(KYCCleanUp());
    dispatch(currencyLogoutCleanUp());
    dispatch(swapLogoutCleanUp());
    dispatch(subscriptionCleanUp());
    dispatch(remittanceCleanUp());

    localStorage.clear();
    history.push("/");
  };
  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await handleDeleteAccount();
      alert.show(response.data.message);
      setToggle(false);
      setDeleteLoading(false);
      handleLogout();
    } catch (error) {}
  };

  return (
    <>
      <Modal
        visible={active}
        btn
        onClose={() => {
          setactive(false);
          // onClose();
        }}
      >
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Change Password</p>
          </div>
          <div className={styles.inputfields}>
            <InputField
              onclick={(e) => handleInputChange(e)}
              Name="oldPassword"
              value={inputs.oldPassword}
              inputName="Current Password"
              name="Old Password"
              type={passwordshow ? "text" : "password"}
              img2={passwordshow ? showImg : hideImg}
              changeOne={() => setPasswordshow(!passwordshow)}
            />

            {inputs.olPasswordValidation ? (
              <p style={{ color: "rgb(229, 81, 107)" }}>
                {inputs.olPasswordValidation}
              </p>
            ) : (
              ""
            )}
            <InputField
              onclick={(e) => handleInputChange(e)}
              Name="newPassword"
              inputName="New Password"
              name="New Password"
              type={passwordshow2 ? "text" : "password"}
              img2={passwordshow2 ? showImg : hideImg}
              changeOne={() => setPasswordshow2(!passwordshow2)}
            />
            {inputs.passwordValidation ? (
              <p style={{ color: "rgb(229, 81, 107)" }}>
                {inputs.passwordValidation}
              </p>
            ) : (
              ""
            )}
            <InputField
              onclick={(e) => handleInputChange(e)}
              Name="resetpassword"
              inputName="Confirm New Password"
              name="Confirm password"
              type={passwordshow3 ? "text" : "password"}
              img2={passwordshow3 ? showImg : hideImg}
              changeOne={() => setPasswordshow3(!passwordshow3)}
            />
            {inputs.confirmValidation ? (
              <p style={{ color: "rgb(229, 81, 107)" }}>
                {inputs.confirmValidation}
              </p>
            ) : (
              ""
            )}
          </div>
          {load ? (
            <LoadingButton2 Loading="Confirming" />
          ) : (
            <button onClick={handleSubmit}>Confirm</button>
          )}
        </div>
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <p>
              {Remittance == 0
                ? "Account Settings"
                : Remittance == 1
                ? "Remittance Agreement"
                : Remittance == 2
                ? "Direct Wire Exchange"
                : ""}
            </p>
          </div>
          {Remittance == 0 ? (
            <>
              <div
                className={styles.optionCard}
                onClick={() => setactive(true)}
              >
                <div className={styles.option}>
                  <div className={styles.icon}>
                    <img src={Changepassword} alt="dash-wallet-option" />
                  </div>
                  <div className={styles.text}>Change Password</div>
                </div>
                <div className={styles.arrow}>
                  <img src={optionArrow} alt="arrow" />
                </div>
              </div>
              <div className={styles.optionCard} onClick={() => setShow(true)}>
                <div className={styles.option}>
                  <div className={styles.icon}>
                    <img src={Editprofile} alt="dash-wallet-option" />
                  </div>
                  <div className={styles.text}>Change Profile Name </div>
                </div>
                <div className={styles.arrow}>
                  <img src={optionArrow} alt="arrow" />
                </div>
              </div>
              <div onClick={() => setToggle(true)}>
                <button>Delete my Account</button>
              </div>
            </>
          ) : Remittance == 1 ? (
            <RemittanceAddresslist />
          ) : Remittance == 2 ? (
            <DirectWireList />
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal
        visible={show}
        btn
        onClose={() => {
          setShow(false);
          // onClose();
        }}
      >
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Change Profile Name</p>
          </div>
          <div className={styles.inputfields}>
            <InputField
              onclick={(e) => handleNameChange(e)}
              Name="Name"
              value={userName}
              inputName="Please enter name"
              name="Full name"
              type={"text"}
              // changeOne={() => setPasswordshow(!passwordshow)}
            />
          </div>
          {nameLoading ? (
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Wave />
            </button>
          ) : (
            <button onClick={handleUpdate}>Update</button>
          )}
        </div>
      </Modal>
      <Modal
        visible={toggle}
        btn
        onClose={() => {
          setToggle(false);
          // onClose();
        }}
      >
        <div className={styles.deletemodal}>
          <img src={Warning} alt="warning" />
          <div className={styles.heading}>
            <p>Are you sure you want to delete your account?</p>
            <label>
              Your current vault, accounts and assets will be removed from this
              app permanently. This action cannot be undone. This will remove
              all vault related data from your device. Your accounts exist on
              the blockchain and are not related to CQR Vault.
            </label>
          </div>

          {deleteLoading ? (
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Wave />
            </button>
          ) : (
            <button onClick={handleDelete}>I Understand, Continue</button>
          )}
          <button
            className={styles.cancelbutton}
            onClick={() => setToggle(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Settings;
