import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import noDataGip from "../../../../../Assets/gif/noData.gif";
import Dummy from "../../../../../Assets/icons/dummy.png";
import Logo from "../../../../../Assets/image/logo.svg";
import { getAllMarketplace } from "../../../../../redux/action/marketplace";
import { HTTP_CLIENT } from "../../../../../utils/services";
import { Wave } from "../../../../_Common/loader/Loader";
import Filter from "../Filter";
import Sort from "../Sort";
import styles from "./marketplaceCard.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDebounce } from "use-debounce";
import "react-loading-skeleton/dist/skeleton.css";
import CardSkelton from "../SkeltonCard";
import CQRComponent, {
  CQRAComponent,
} from "../../../../_Common/CQRComponent/CQRComponent";
import {
  MarketplaceAdds,
  ownMarketplaceAdds,
} from "../../../../../services/marketplaceService";

const MarketPlaceCard = () => {
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [marketList, setMarketList] = useState([]);
  const [ownList, setOwnList] = useState([]); // storing list
  const [showOwn, setShowOwn] = useState(false); // storing list
  const [from, setFrom] = useState("");
  const [keyword, setKeyword] = useState("");
  const [debounce] = useDebounce(keyword, 2000);
  const [dataTotal, setDataTotal] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingNum, setLoadingNum] = useState(1);
  const [to, setTo] = useState("");
  const [filterPrice, setFilterPrice] = useState("");
  const [apply, setApply] = useState(0);

  const userSelector = useSelector((state) => state.user?.userInfo);
  const currencySelector = useSelector((state) => state.currency);
  const dispatch = useDispatch();
  const history = useHistory();

  const detailData = (data) => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/marketplace-details",
        state: { detail: data },
      });
    } else {
      history.push("/error");
    }
  };
  const detailOwnData = (data) => {
    history.push({
      pathname: "/own-add-details",
      state: { detail: data },
    });
  };
  const limit = 30;

  //-----------------------------------FETCH MARKETPLACE ADD-----------------------------//

  const fetchData = async () => {
    try {
      let page = Math.ceil(marketList.length / limit) + 1;

      const response = await MarketplaceAdds(
        page,
        limit,
        filterPrice,
        to,
        from,
        debounce
      );

      setDataTotal(response?.data?.data);
      setMarketList([...marketList, ...response?.data?.data?.data]);
      dispatch(getAllMarketplace(response?.data));
      setLoadingNum(loadingNum + 2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response?.status == 401) {
        localStorage.clear();
        history.push("/");
      }
    }
  };

  //--------------------------FETCH FILTERS ADD--------------------------//

  const fetchDataFilters = async () => {
    setLoading(true);
    try {
      let page = Math.ceil(marketList.length / limit) + 1;
      const response = await HTTP_CLIENT.get(
        `marketplace/listing?page=1&limit=30&filter=${
          filterPrice ? filterPrice : ""
        }&price_to=${to ? to : ""}&price_from=${
          from ? from : ""
        }&keyword=${keyword}`
      );
      setLoading(false);
      setMarketList(response?.data?.data?.data);
      setDataTotal(response?.data?.data);
      dispatch(getAllMarketplace(response?.data));
    } catch (error) {
      setLoading(false);
    }
  };

  //-------------------------FETCH OWN ADD------------------------//

  const fetchOwnAdd = async () => {
    try {
      const response = await ownMarketplaceAdds(currPage);

      setOwnList(response?.data?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchOwnAdd();
  }, []);

  useEffect(() => {
    fetchDataFilters();
  }, [filterPrice, apply, debounce]);
  const fetchMoreData = () => {
    fetchData();
  };
  const skeltonNumbers = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div
      id="scrollableDiv"
      style={{
        height: "87vh",
        overflow: "auto",
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.filtersWrapper}>
          <img src={Logo} alt="logo" />
          <input
            type="text"
            className={styles.inputSearch}
            placeholder="Search by Token name /User name"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <div className={styles.btnWrapper}>
            {showOwn ? (
              <button className={styles.btn2} onClick={() => setShowOwn(false)}>
                Switch To Acquire
              </button>
            ) : (
              <button className={styles.btn2} onClick={() => setShowOwn(true)}>
                Switch To Exchange
              </button>
            )}

            <div className={styles.filter}>
              <Filter
                to={to}
                setTo={setTo}
                from={from}
                setFrom={setFrom}
                active={apply}
                setActive={setApply}
              />
            </div>
            <div className={styles.sorting}>
              <Sort active={filterPrice} setActive={setFilterPrice} />
            </div>
          </div>
        </div>
        <div className={styles.pageHeight}>
          {showOwn ? (
            <div className={styles.cardWrapper}>
              {loading ? (
                <div className={styles.loaderPosition}>
                  <Wave />
                </div>
              ) : ownList.length === 0 ? (
                <div className={styles.loaderPosition}>
                  <img src={noDataGip} alt="nodata" />
                </div>
              ) : (
                ownList?.map((Items, index) => {
                  return (
                    <>
                      <div className={styles.card} key={index}>
                        <div className={styles.imgWrapper}>
                          <img
                            src={
                              Items?.nftToken?.image
                                ? Items?.nftToken?.image
                                : Items?.nativeToken?.icon?.url
                            }
                            at="martplace banner"
                          />
                        </div>

                        <div className={styles.userWrapper}>
                          <img
                            src={
                              userSelector.profilePicture
                                ? userSelector.profilePicture
                                : Dummy
                            }
                            alt="profile"
                            className={styles.userimg}
                          />
                          <div>
                            <span>{userSelector.name}</span>
                            <label>{userSelector?.email}</label>
                          </div>
                        </div>

                        {/* <div className={styles.cardHeading}>{Items?.name}</div> */}
                        <div className={styles.paragraphWrapper}>
                          <div className={styles.leftSide}>
                            <span>Value:</span>
                            <p> Remaining Units:</p>
                          </div>
                          <div className={styles.rightSide}>
                            <span>
                              <CQRComponent />
                              &nbsp;
                              {Items?.pricePerShare.toFixed(2)}
                            </span>
                            <p>{Items?.remainingShares?.toFixed(2)}</p>
                          </div>
                        </div>
                        <button
                          className={styles.btn}
                          onClick={() => detailOwnData(Items)}
                        >
                          See Details
                        </button>
                      </div>
                    </>
                  );
                })
              )}
            </div>
          ) : loading ? (
            <div className={styles.cardWrapper}>
              {skeltonNumbers?.map((item) => {
                return (
                  <div className={styles.card}>
                    <CardSkelton />
                  </div>
                );
              })}
            </div>
          ) : marketList.length === 0 ? (
            <div className={styles.noDataPosition}>
              <img src={noDataGip} alt="nodata" />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={marketList.length}
              next={fetchMoreData}
              hasMore={dataTotal.total === marketList.length ? false : true}
              scrollableTarget="scrollableDiv"
              loader={
                <div className={styles.loaderPositionWave}>
                  {" "}
                  <Wave />{" "}
                </div>
              }
            >
              <div className={styles.cardWrapper}>
                {marketList?.map((Items, index) => {
                  return (
                    <>
                      <div className={styles.card} key={index}>
                        <div className={styles.imgWrapper}>
                          <img
                            src={
                              Items?.nfttokens?.image
                                ? Items?.nfttokens?.image
                                : Items?.nativeToken?.icon?.url
                            }
                            at="martplace banner"
                          />
                        </div>
                        <div className={styles.userWrapper}>
                          <img
                            src={
                              Items?.user?.profilePicture
                                ? Items?.user?.profilePicture
                                : Dummy
                            }
                            alt="profile"
                            className={styles.userimg}
                          />
                          <div>
                            <span>{Items?.user?.name}</span>
                            {/* <label>{Items?.user?.email}</label> */}
                          </div>
                        </div>
                        {/* <div className={styles.cardHeading}>{Items?.name}</div> */}
                        <div className={styles.paragraphWrapper}>
                          <div className={styles.leftSide}>
                            <span>Value:</span>
                            <p>Remaining Units:</p>
                          </div>
                          <div className={styles.rightSide}>
                            <span>
                              {" "}
                              <CQRComponent />
                              &nbsp;
                              {Items?.pricePerShare.toFixed(2)}
                            </span>
                            <p>{Items?.remainingShares?.toFixed(2)}</p>
                          </div>
                        </div>
                        <button
                          className={styles.btn}
                          onClick={() => detailData(Items)}
                        >
                          Get Now
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketPlaceCard;
