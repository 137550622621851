import React, { useEffect, useState } from "react";
import styles from "./subscription.module.scss";
import btc from "../../../../Assets/image/btc.svg";
import Modal from "../../../_Common/Modal/Modal";
import Popup from "./popup/popup";
import { HTTP_CLIENT } from "../../../../utils/services";
import { saveSubscriptionData } from "../../../../redux/action/subscriptionAction";
import { useDispatch, useSelector } from "react-redux";
import YourSubscriptions from "./YourSubscriptions/YourSubscriptions";
import { subscriptionStatus } from "../../../../redux/action/subscriptionAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import qsymbol from "../../../../Assets/image/qsymbol.png";
import CQRComponent from "../../../_Common/CQRComponent/CQRComponent";
import white from "../../../../Assets/icons/white.png";

const Subscription = ({ visible, onClose }) => {
  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  const history = useHistory();
  const [yoursubscription, setYoursubscription] = useState(null);
  const [Status, setStatus] = useState(true);
  const [popupvisible, setpopupvisible] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(false);

  const userSelector = useSelector((state) => state.currency);
  const userKyc = useSelector((state) => state?.user);

  useEffect(() => {
    if (
      !userKyc?.savedFormData?._id &&
      !userKyc?.kyc?._id &&
      !userSelector?.currencyInfo?.gpaData?._id
    ) {
      history.push("/profile");
      alert.show("Please fill your GPA data first", {
        type: "error",
      });
    }
  }, []);
  const togglepopup = () => {
    setpopupvisible((preview) => !preview);
    setshow(false);
  };
  const [show, setshow] = useState(false);
  const alert = useAlert();

  const [loading, setloading] = useState(false);
  const [subscriptionObject, setsubscriptionObject] = useState(null);
  const dispatch = useDispatch();
  const { subscriptionData } = useSelector((state) => state.subscription);
  const isSubscriber = useSelector(
    (state) => state?.user?.userInfo?.isSubscriber
  );
  const subStatus = useSelector(
    (state) => state?.subscription?.subscriptionStatus
  );

  const nativeWallet = useSelector(
    (state) => state?.currency?.currencyInfo?.nativeWallets
  );
  const showData = () => {
    setshow((prev) => !prev);
  };

  useEffect(() => {
    fetchData();

    getchSubscriptionData();

    // getchSubscriptionData();
  }, []);
  //---------------------------get Packges---------------------//
  const getchSubscriptionData = async () => {
    setloading(true);
    try {
      const res = await HTTP_CLIENT.get("subscription/user-subscriptions");
      setYoursubscription(res.data);
      dispatch(subscriptionStatus(res.data));

      setloading(false);
    } catch (error) {
      if (error.response?.status == 401) {
        localStorage.clear();
        history.push("/");
      }
      alert.show(error?.response?.data?.message, { type: "error" });
      setloading(false);
    }
  };

  const fetchData = async () => {
    const res = await HTTP_CLIENT.get("/package");
    if (isSubscriber) {
      await getchSubscriptionData();
    }
    await dispatch(saveSubscriptionData(res?.data));
  };

  const handlePurchase = (data) => {
    setsubscriptionObject(data);
    showData();
  };

  const recieveData = async (data) => {
    // dispatch(yourSubscription(data));
    setYoursubscription(data);
    await getchSubscriptionData();
  };

  function numberWithCommas(n) {
    var parts = n?.toString()?.split(".");
    return (
      parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : ".00")
    );
  }

  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <Popup
          data={7}
          subData={subscriptionObject}
          togglepopup={togglepopup}
          recieveData={recieveData}
        />
      </Modal>
      <Modal visible={show} onClose={() => setshow(false)}>
        <div className={styles.modalcontainer}>
          <div className={styles.modalheading}>
            <p
              style={{ fontWeight: "bold", color: "#40a1dc", fontSize: "25px" }}
            >
              {subscriptionObject?.title}
            </p>
            <img src={subscriptionObject?.logo} alt="" />
          </div>
          <div className={styles.modaltextsection}>
            <p>
              <p style={{ fontWeight: "bold" }}>Note:</p>
              Upon subscribing, <CQRComponent />
              {numberWithCommas(
                subscriptionObject?.priceUSD ? subscriptionObject?.priceUSD : ""
              )}{" "}
              will be deducted from your C<CQRComponent />R Vault. You can
              cancel this subscription at any time by going to “Current
              Subscriptions”. If there are not enough tokens in your account
              when your self-directed subscription renews, then your
              subscription will be cancelled automatically.
              {/* Upon subscription, ${subscriptionObject?.priceUSD} will be
              deducted from your account every 1 month. You can cancel this
              subscription any time by going to the "Your Subscriptions"
              sections of the app. If there is not enough money in the account
              at the time of subscription renewal, your subscription will be
              cancelled automatically. */}
            </p>
            <button onClick={togglepopup}>OK</button>
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.btnWrapper}>
            <button
              className={styles.headingBtn}
              onClick={() => history.push("/subscription-history")}
            >
              Subscription History
            </button>
            {!currentSubscription && (
              <button
                className={styles.headingBtn}
                onClick={() => setCurrentSubscription(true)}
              >
                Current Subscriptions
              </button>
            )}
          </div>
          <div className={styles.cards}>
            {!currentSubscription ? (
              subscriptionData?.map((item) => {
                return (
                  <div className={styles.card}>
                    <div className={styles.logo}>
                      <img src={item?.logo} alt="logo" />
                    </div>
                    <div className={styles.text}>
                      <p style={{ fontWeight: "bold", color: "#40a1dc" }}>
                        {item?.title}
                      </p>
                    </div>
                    <div className={styles.price}>
                      <span>
                        Value in C<CQRComponent />R
                      </span>
                      <p>
                        <CQRComponent />
                        &nbsp; {numberWithCommas(item?.priceUSD)}
                      </p>
                    </div>
                    <div className={styles.price}>
                      <span>Value in USD</span>
                      <p>$ {numberWithCommas(item?.priceUSD)}</p>
                    </div>
                    <div className={styles.price}>
                      <span>Duration</span>
                      <p style={{ textTransform: "capitalize" }}>
                        {item?.duration === "month"
                          ? "Monthly"
                          : item?.duration}
                      </p>
                    </div>
                    <div className={styles.purchasebtn}>
                      <button onClick={() => handlePurchase(item)}>
                        {/* Purchase */}
                        Subscribe
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <YourSubscriptions
                Status={Status}
                setCurrentSubscription={setCurrentSubscription}
                setYoursubscription={setYoursubscription}
                data={yoursubscription}
                subLoading={loading}
                setloading={setloading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
