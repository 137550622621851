import React from "react";
import Image from "../../../Assets/icons/CQRVEST.png";
import styles from "./custom.module.scss";
const CustomeAlert = ({ message }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={Image} />
        <div className={styles.content}>
          <div className={styles.heading}>{message.notification.title}</div>
          <div className={styles.Text}>{message.notification.body}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomeAlert;
