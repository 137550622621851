import React from "react";
import styles from "./team.module.scss";

const Team = ({ SData }) => {
  const {
    image,
    image2,
    hectorprofile,
    desigination,
    title,
    subHeading,
    dec,
  } = SData;
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <p>
              Meet Our <span>Team</span>
            </p>
          </div>
          <div className={styles.profile}>
            <div className={styles.rightsection}>
              <img src={image} alt="Profile" />
            </div>
            <div className={styles.leftsection}>
              <div className={styles.title}>
                <label>{hectorprofile}</label>
                <br />
                <span>{subHeading}</span>
                <p>{dec}</p>
              </div>
              <div className={styles.profilearea}>
                <div className={styles.circleprofile}>
                  <div className={styles.profileimage}>
                    <img src={image2} alt="hector" />
                  </div>
                  <span>
                    {title}
                    <label>{desigination}</label>
                  </span>
                </div>
                <div className={styles.circleprofile}>
                  <div className={styles.profileimage}>
                    <img src={image2} alt="hector" />
                  </div>
                  <span>
                    {title}
                    <label>{desigination}</label>
                  </span>
                </div>
                <div className={styles.circleprofile}>
                  <div className={styles.profileimage}>
                    <img src={image2} alt="hector" />
                  </div>
                  <span>
                    {title}
                    <label>{desigination}</label>
                  </span>
                </div>
                <div className={styles.circleprofile}>
                  <div className={styles.profileimage}>
                    <img src={image2} alt="hector" />
                  </div>
                  <span>
                    {title}
                    <label>{desigination}</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
