import React from "react";
import styles from "./openaccount.module.scss";
import Modal from "../../_Common/Modal/Modal";
import Logo from "../../../Assets/image/logo.svg";
import { useHistory } from "react-router-dom";

const OpenAccount = ({ visible, onClose }) => {
  let history = useHistory();

  return (
    <>
      <Modal visible={visible}>
        <div className={styles.container}>
          <div className={styles.heading}>
            <img src={Logo} alt="" />
          </div>
          <div className={styles.textsection}>
            <p>Please select your primary address first</p>

            <button
              className={styles.btn}
              onClick={() => history.push("/remittance")}
            >
              {" "}
              Go to Remittance Address
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OpenAccount;
