import { CREATE_ACCOUNT, OPPERTUNITY_TOKEN } from "../types";

export const CreateAccountCheck = (data) => {
  return {
    type: CREATE_ACCOUNT,
    payload: data,
  };
};

export const oppertunityToken = (data) => {
  return {
    type: OPPERTUNITY_TOKEN,
    payload: data,
  };
};
