import { validatePhoneNumberLength } from "libphonenumber-js/min";
import { useCallback, useContext, useEffect, useState } from "react";
// import alert from 'react-native-toast-message';
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Socket } from "socket.io-client";
import { SocketContext } from "../../../../../Context/Socket";
import { getuserChatRooms } from "../../../../../services/chatService";
import { CHAT_SOCKET_TYPES } from "../../../../../utils/ChatConstants";
// import {SocketContext} from '../../../../context/socket';
// import {getuserChatRooms} from '../../../../services/chat.service';
// import {CHAT_SOCKET_TYPES} from '../../../../shared/models/enums';
// import {
//   CHAT_ROOM,
//   SocketResponses,
//   SOCKET_EVENTS,
// } from '../../../../shared/models/types/chat.types';
import { SOCKET_EVENTS } from "../../../../../utils/ChatConstants";
import { useHistory } from "react-router-dom";

const useInboxSockets = (pageSize) => {
  const [chats, setChats] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshing, setRefreshing] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const history = useHistory();
  const socket = useContext(SocketContext);
  const { userInfo } = useSelector((state) => state.user);
  const alert = useAlert();
  const changeUserStatusInRoom = (roomId, userId, status) => {
    setChats((prevChats) => {
      const chatIndex = prevChats?.findIndex(
        (chat) => chat.chatRoomId === roomId
      );

      if (chatIndex >= 0) {
        const chatRoom = prevChats[chatIndex];
        let members = [...chatRoom.members];
        let memberIndex = members.findIndex((member) => member._id === userId);

        if (memberIndex >= 0) {
          members[memberIndex].onlineStatus = status;
        }
        let updatedChatRoom = { ...chatRoom, members };
        let temp = [...prevChats];
        temp[chatIndex] = updatedChatRoom;

        return temp;
      }
    });
  };

  const updateChatRoom = (chatRoom) => {
    setChats((prevChats) => {
      const chatIndex = prevChats?.findIndex(
        (chat) => chat.chatRoomId === chatRoom.chatRoomId
      );
      let temp = [...prevChats];

      if (chatIndex >= 0) {
        temp[chatIndex] = chatRoom;
      } else {
        temp?.push(chatRoom);
      }

      temp?.sort((a, b) => {
        if (!a.updatedAt || !b.updatedAt) {
          return 0;
        }
        return a.updatedAt > b.updatedAt ? -1 : 1;
      });

      return temp;
    });
  };

  const listeners = useCallback(async () => {
    socket.emit(
      CHAT_SOCKET_TYPES.USER_CONNECT,
      { userId: userInfo?._id },
      (res) => {
        console.debug(":rocket: ~ USER_CONNECT", res);
      }
    );

    socket.on(SOCKET_EVENTS.ROOM_UPDATED, (chatRoom) => {
      updateChatRoom(chatRoom);
    });

    socket.on(SOCKET_EVENTS.USER_OFFLINE, ({ userId, roomId }) => {
      changeUserStatusInRoom(roomId, userId, false);
    });

    socket.on(SOCKET_EVENTS.USER_ONLINE, ({ userId, roomId }) => {
      changeUserStatusInRoom(roomId, userId, true);
    });

    socket.on("connect_error", (err) => {
      socket.connect();
    });
  }, []);

  const getData = async (page = 1) => {
    setLoading(true);
    try {
      //third parameter is for search
      const response = await getuserChatRooms(page, pageSize, "");
      let data = response?.data?.data?.data;
      if (data?.length == 0) {
        setEndReached(true);
        setCurrentPage(response?.data?.data?.page);
      }
      // if (chats?.length > 0) {
      //   setChats((prevAds) => [...prevAds, ...data]);
      // } else {
      //   setChats(data);
      // }
      setChats(data);
      setLoading(false);
    } catch (err) {
      // const error = getNormalizedError(err);
      setLoading(false);
      if (err.response?.status == 401) {
        localStorage.clear();
        history.push("/");
      }
      alert.show("Something Went Wrong", { type: "error" });
    }
  };

  const loadMoreData = () => {
    if (endReached || loading) return;
    getData(currentPage + 1);
  };

  const onRefresh = async () => {
    setChats([]);
    setRefreshing(true);
    setEndReached(false);
    setCurrentPage(1);
    await getData(1);
    setRefreshing(false);
  };

  useEffect(() => {
    listeners();
    getData(1);
    return () => {
      socket.removeAllListeners();
    };
  }, []);
  return [
    chats,
    setChats,
    loading,
    setLoading,
    onRefresh,
    refreshing,
    loadMoreData,
  ];
};

export default useInboxSockets;
