const transformMessageForSending = (
  message,
  userId,
  otheruser,
  chatRoom,
  attachmentUrl
) => {
  return {
    text: message.text,
    user: userId,
    recipient: otheruser,
    chatRoom,
    type: message.type,
    status: "delivered",
    attachment: attachmentUrl,
  };
};

const transformMessageAfterReceiving = (message) => {
  return {
    author: { id: message.user },
    createdAt: message.createdAt,
    id: message._id,
    status: message.status,
    type: message.type,
    updatedAt: message.updatedAt,
    text: message.text,
    uri: message.attachment,
    fileName: message.fileName,
  };
};

const getOtherUser = (chatRoom, userId) => {
  const members = chatRoom.members;
  return members?.find((member) => member._id != userId);
};

export {
  transformMessageForSending,
  transformMessageAfterReceiving,
  getOtherUser,
};
