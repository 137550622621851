import img from "../../../Assets/icons/white.png";
const Data = {
  heading: "Wallet Activation",
  text: "Please select an option below",
  buttons: [
    { name: "Cryptocurrency", link: "/contribute", popup: true },
    { name: "CQR Liquidity Tokens('Q')", link: "/vault-token", img: img },
    {
      name: "CQR Asset Tokens ('Ag/Au')",
      AgAu: true,
      img: img,
      link: "/liquidity-tokens",
    },
    { name: "CQR Vest", cqrlinkchanged: true, img: img, link: "/cqr-vest" },
    // {
    //   name: "Banking",
    //   disabled: true,
    // },
  ],
};
export default Data;
