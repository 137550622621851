import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackTo from "../../../../../../Assets/icons/backk.svg";
import Rcv from "../../../../../../Assets/image/rcv.svg";
import Send from "../../../../../../Assets/image/send.svg";
import Swap from "../../../../../../Assets/image/swap.svg";
import Popup from "../../../../../_Common/DashboardComponents/popup/popup";
import Modal from "../../../../../_Common/Modal/Modal";
import styles from "./CryptoTableGraph.module.scss";
import Graph from "./Graph/Graph";
import GraphBottomTable from "./GraphBottomTable/GraphBottomTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrencyGraph,
  getTransactionDetail,
} from "../../../../../../redux/action/currencyDetailAction";
import CryptoTotal from "./CryptoTotal";

function CryptoTableGraph({ history }) {
  const [coinDetail, setCoinDetail] = useState();
  const [popupvisiblee, setpopupvisiblee] = useState(false);
  const [number, setNumber] = useState(1);

  const location = useLocation();
  const dispatch = useDispatch();
  const currencySelector = useSelector((state) => state.currency);
  // const graphData = useSelector((state) => state.currency);
  const togglepopup = (e, num) => {
    e.preventDefault();
    setNumber(num);
    setpopupvisiblee(true);
  };
  useEffect(() => {
    // result: 'some_value'
    // setCoinDetail(location.state.detail);
    setCoinDetail(currencySelector.graphData);
    if (coinDetail) {
      dispatch(
        getTransactionDetail(coinDetail?.address, coinDetail?.coinSymbol)
      );
      dispatch(getCurrencyGraph(coinDetail?.coinSymbol));
    }
  }, [location, coinDetail]);

  //  const fetchData = async () => {
  //   try {
  //     let res = await HTTP_CLIENT.get(`transaction/${coinDetail?.address}/${coinDetail?.coinSymbol}/txs`);
  //     // alert.show("successfully", { type: "success" });

  //   } catch (err) {
  //     // alert.show(err.response.data.message, { type: "error" });
  //   }
  //  }
  const handleWithdrawal = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push("/withdrawal");
    } else {
      history.push("/error");
    }
  };

  const detailData = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/crypto-swap",
        state: { detail: location },
      });
    } else {
      history.push("/error");
    }
  };
  return (
    <>
      <Modal
        visible={popupvisiblee}
        btn
        onClose={() => setpopupvisiblee(false)}
      >
        <Popup
          data={number}
          popupvisiblee={popupvisiblee}
          location={location}
        />
      </Modal>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <img
              src={BackTo}
              alt=""
              onClick={() => {
                history.push({
                  pathname: "/cryptocurrency",
                  state: {
                    activeState: 1,
                  },
                });
              }}
            />
            {/* <label>{location.state.detail.name}</label> */}
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.leftWrapper}>
              <CryptoTotal location={location} />
              <div className={styles.buttonWrapper}>
                <button onClick={(e) => togglepopup(e, 1)}>
                  {" "}
                  <p>Top-Up</p>
                  <img src={Rcv} alt="recieve" />
                </button>
                <button onClick={(e) => togglepopup(e, 3)}>
                  <p>Send</p>
                  <img src={Send} alt="send" />
                </button>
                {/* <Link
                to={
                  currencySelector.currencyInfo.user.isWalletActivated
                    ? "/crypto-swap"
                    : "/error"
                }
              > */}
                <button onClick={() => detailData()}>
                  <p>Swap</p>
                  <img src={Swap} alt="swap" />
                </button>
              </div>
              {/* </Link> */}

              <div className={styles.headingWrapper}>
                <span>Transaction History</span>
                {/* <p>20 Oct, 2021</p> */}
              </div>
              <GraphBottomTable
                data={currencySelector.currencyDetail}
                coinDetail={coinDetail}
              />
            </div>
            <div className={styles.rightWrapper}>
              <Graph
                currencyGraph={currencySelector?.currencyGraph}
                coinData={location?.state?.detail}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CryptoTableGraph;
