import { useFormik } from "formik";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import {
  addDirectwireAddress,
  getDirectwireAddress,
  updateDirectWireAddress,
} from "../../../../../../services/withdrawService";
import InputField from "../../../../../_Common/input/input";
import { WaveClone } from "../../../../../_Common/loader/Loader";
import styles from "./directwire.module.scss";

const DirectwireAddress = ({ setAllAddress, setShow, addressData }) => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(
    addressData ? addressData?.phoneNumber : ""
  );

  const formikSchema = yup.object({
    name: yup.string().required("Name is required").trim(),
    accountName: yup.string().required("Account Name is required").trim(),
    bankAddress: yup.string().required("Bank Address is required").trim(),

    bankName: yup.string().required("Bank Name is required").trim(),
    // phone: yup.string().required("Phone number is required").trim(),
    zellePayAddress: yup
      .string()
      .required("ZellePay Address is required")
      .trim(),
    streetAddress: yup.string().required("Address is required").trim(),
    iban: yup.string().required("Account no. is required").trim(),
    swift: yup.string().trim(),
    rountingNo: yup.string().trim(),
    aba: yup.string().trim(),
  });
  //------------------formik ------------------------//
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      name: addressData ? addressData?.name : "",
      accountName: addressData ? addressData?.accountTitle : "",

      bankName: addressData ? addressData?.bankName : "",
      bankAddress: addressData ? addressData?.bankAddress : "",
      zellePayAddress: addressData ? addressData?.zellePay : "",
      streetAddress: addressData ? addressData?.address : "",
      iban: addressData ? addressData?.accountNumber : "",
      swift: addressData ? addressData?.swiftBic : "",
      rountingNo: addressData ? addressData?.routingCode : "",
      aba: addressData ? addressData?.aba : "",
    },
    onSubmit: async (values) => {
      handleSubmit(values);
      // reset.resetForm()
    },
  });
  const alert = useAlert();

  //-----------add new address---------------//

  const handleSubmit = async (values) => {
    setLoading(true);
    const params = {
      name: values?.name,
      accountTitle: values?.accountName,
      address: values?.streetAddress,
      phoneNumber: phone?.toString(""),
      accountNumber: values?.iban,
      aba: values?.aba,
      swiftBic: values?.swift,
      routingCode: values?.rountingNo,

      bankName: values?.bankName,
      bankAddress: values?.bankAddress,

      zellePay: values?.zellePayAddress,
    };
    try {
      const res = await addDirectwireAddress(params);
      alert.show(res?.data?.message, { type: "success" });
      const resp = await getDirectwireAddress();
      setAllAddress(resp?.data?.data);
      formik.resetForm();
      setLoading(false);
      setShow(false);
    } catch (error) {
      alert.show(
        error?.response?.data?.message[0]
          ? error?.response?.data?.message[0]
          : "Unable to save address at the moment.",
        { type: "error" }
      );

      setLoading(false);
    }
  };
  //----------------Update Address----------------------//
  const updateAddress = async () => {
    setLoading(true);
    const params = {
      name: formik?.values?.name,
      accountTitle: formik?.values?.accountName,
      address: formik?.values?.streetAddress,
      phoneNumber: phone?.toString(""),
      accountNumber: formik?.values?.iban,
      aba: formik?.values?.aba,
      swiftBic: formik?.values?.swift,
      routingCode: formik?.values?.rountingNo,
      fullName: formik?.values?.name,
      bankName: formik?.values?.bankName,
      bankAddress: formik?.values?.bankAddress,

      zellePay: formik?.values?.zellePayAddress,
    };
    try {
      const res = await updateDirectWireAddress(addressData?._id, params);
      const resp = await getDirectwireAddress();
      setAllAddress(resp?.data?.data);
      setShow(false);
      setLoading(false);
      alert.show(res?.data?.message, { type: "success" });
    } catch (error) {
      alert.show(error?.response?.data?.message, { type: "error" });

      setLoading(false);
    }
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            {addressData ? (
              <p>Update Account Details</p>
            ) : (
              <p>Add Account Details</p>
            )}
          </div>
          <div className={styles.inputfields}>
            <div className={styles.bankDetails}>
              <span>Personal info</span>
            </div>
            <InputField
              Name="Name"
              value={12}
              inputName="Full Name"
              name="Full Name *"
              type="text"
              config={formik.getFieldProps("name")}
            />
            {formik.errors.name && formik.touched.name ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.name}</div>
            ) : null}
            <InputField
              Name="accountName"
              value={12}
              inputName="Account Name"
              name="Name on Bank Account*"
              type="text"
              config={formik.getFieldProps("accountName")}
            />
            {formik.errors.accountName && formik.touched.accountName ? (
              <div style={{ color: "#E5516B" }}>
                {formik.errors.accountName}
              </div>
            ) : null}
            <div className={styles.countrySelects}>
              <label>Number *</label>
              <PhoneInput
                dropdownStyle={{ backgroundColor: "#1b1e2f" }}
                country={"us"}
                buttonClass={styles.buttonClass}
                dropdownClass={styles.dropdownClass}
                inputClass={styles.countrySelect}
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
            <InputField
              Name="street Address"
              value={12}
              inputName=" Address"
              name=" Address *"
              type="text"
              config={formik.getFieldProps("streetAddress")}
            />
            {formik.errors.streetAddress && formik.touched.streetAddress ? (
              <div style={{ color: "#E5516B" }}>
                {formik.errors.streetAddress}
              </div>
            ) : null}
            {/* <InputField
          Name="phone"
          value={12}
          inputName="Phone Number"
          name="Phone Number *"
          type="text"
          config={formik.getFieldProps("phone")}
        />
        {formik.errors.phone && formik.touched.phone ? (
          <div style={{ color: "#E5516B" }}>{formik.errors.phone}</div>
        ) : null} */}
            <div className={styles.bankDetails}>
              <span>Bank Details</span>
            </div>
            <InputField
              Name="Bank Name"
              value={12}
              inputName="Bank Name"
              name="Bank Name *"
              type="text"
              config={formik.getFieldProps("bankName")}
            />
            {formik.errors.bankName && formik.touched.bankName ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.bankName}</div>
            ) : null}
            <InputField
              Name="Bank Address"
              value={12}
              inputName="Bank Address"
              name="Bank Address *"
              type="text"
              config={formik.getFieldProps("bankAddress")}
            />
            {formik.errors.bankAddress && formik.touched.bankAddress ? (
              <div style={{ color: "#E5516B" }}>
                {formik.errors.bankAddress}
              </div>
            ) : null}
            <InputField
              Name="ZellePay Address"
              value={12}
              inputName="ZellePay Address"
              name="ZellePay Address *"
              type="text"
              config={formik.getFieldProps("zellePayAddress")}
            />
            {formik.errors.zellePayAddress && formik.touched.zellePayAddress ? (
              <div style={{ color: "#E5516B" }}>
                {formik.errors.zellePayAddress}
              </div>
            ) : null}
            <InputField
              Name="iban"
              value={12}
              inputName="IBAN/ Account no."
              name="IBAN/ Account no. *"
              type="text"
              config={formik.getFieldProps("iban")}
            />
            {formik.errors.iban && formik.touched.iban ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.iban}</div>
            ) : null}
            <InputField
              Name="swift"
              value={12}
              inputName="Swift"
              name="Swift "
              type="text"
              config={formik.getFieldProps("swift")}
            />
            {formik.errors.swift && formik.touched.swift ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.swift}</div>
            ) : null}
            <InputField
              Name="routingNo"
              value={12}
              inputName="Routing no."
              name="Routing no. "
              type="text"
              config={formik.getFieldProps("rountingNo")}
            />
            {formik.errors.rountingNo && formik.touched.rountingNo ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.rountingNo}</div>
            ) : null}
            <InputField
              Name="aba"
              value={12}
              inputName="ABA"
              name="ABA"
              type="text"
              config={formik.getFieldProps("aba")}
            />
            {formik.errors.aba && formik.touched.aba ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.aba}</div>
            ) : null}
          </div>
          <div className={styles.btn}>
            {loading ? (
              <button type="button">
                <WaveClone />
              </button>
            ) : addressData ? (
              <button onClick={() => updateAddress()} type="button">
                Update
              </button>
            ) : (
              <button type="submit">Confirm</button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DirectwireAddress;
