import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import styles from "./referringbeneficiary.module.scss";
import Input from "../../../../_Common/input/input";
import DropDown from "../../../../_Common/DropDown/dropDown";
import useReferringBeneficiary from "./useReferringBeneficiary";
import { useFormik } from "formik";
import { saveFormData } from "../../../../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import {
  CountryOption,
  sponsoringAssemblyOption,
  option,
  referring,
} from "../../../../../Data/dropDownItems/dropdownData";
const ReferringBeneficiary = ({ handeClickForm }, ref) => {
  const {
    // options,
    // selected,
    // setSelected,
    setBeneficiarySelect,
    beneficiarySelect,
  } = useReferringBeneficiary();
  const referringOptions = referring;

  const options = option;
  const CountryOptions = CountryOption;
  const sponsoringAssemblyOptions = sponsoringAssemblyOption;

  const [benificiaryInput, setBenificiaryInput] = useState();
  const dispatch = useDispatch();
  const formRef = useRef();

  const userSelector = useSelector((state) => state.user);

  useImperativeHandle(ref, () => ({
    onSubmit: () => {
      formik.handleSubmit();
    },
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    refAmbassador,
    sponsor,
    refOrgAddress,
    beneficiary,
  } = userSelector.savedFormData;
  const [PhyCountry, setPhyCountry] = useState({
    name: refOrgAddress?.country || "United States",
  });
  const [error, setError] = useState(false);
  const [sponsoring, setsponsoring] = useState({
    name: sponsor?.name || "Select Sponsoring Assembly",
    value: "SA-02",
  });
  const [referringSelected, setreferringSelected] = useState({
    name: refAmbassador?.ambassador || "Ambassador",
  });
  const [selected, setSelected] = useState({
    name: refAmbassador?.name?.prefix || "Mr",
  });
  const [benSelected, setBenSelected] = useState({
    name: beneficiary?.name?.prefix || "Mr",
  });
  const formik = useFormik({
    initialValues: {
      ambassadorPrefix: { name: refAmbassador?.name?.prefix }
        ? { name: refAmbassador?.name?.prefix }
        : { name: "Mr" },
      ambassadorFirstname: refAmbassador?.name?.firstName
        ? refAmbassador?.name?.firstName
        : "",
      // ambassadormiddleName: refAmbassador?.name?.middleName
      //   ? refAmbassador?.name?.middleName
      //   : "",
      ambassadorlastName: refAmbassador?.name?.lastName
        ? refAmbassador?.name?.lastName
        : "",
      ambassadorsuffix: sponsor?.suffix ? sponsor?.suffix : "",
      ambassadorAreaCode: refAmbassador?.phone?.areaCode
        ? refAmbassador?.phone?.areaCode
        : "",
      ambassadorRemNumber: refAmbassador?.phone?.remNumber
        ? refAmbassador?.phone?.remNumber
        : "",
      ambassadorCountryCode: refAmbassador?.phone?.countryCode
        ? refAmbassador?.phone?.countryCode
        : "",
      ambassadorReferring: { name: refAmbassador?.ambassador }
        ? { name: refAmbassador?.ambassador }
        : {
            name: "Ambassador",
          },
      description: refAmbassador?.description,

      sponerAsembly: { name: sponsor?.name }
        ? { name: sponsor?.name }
        : { name: "" },
      sponerAsemblyAreaCode: sponsor?.phone?.areaCode
        ? sponsor?.phone?.areaCode
        : "",
      sponerAsemblyRemNumber: sponsor?.phone?.remNumber
        ? sponsor?.phone?.remNumber
        : "",

      organizationstreetAddress: refOrgAddress?.streetAddress
        ? refOrgAddress?.streetAddress
        : "",
      organizationstreetAddress2: refOrgAddress?.stAddressLine2
        ? refOrgAddress?.stAddressLine2
        : "",
      organizationCity: refOrgAddress?.city ? refOrgAddress?.city : "",
      organizationstate: refOrgAddress?.state ? refOrgAddress?.state : "",
      organizationpostalCode: refOrgAddress?.postalCode
        ? refOrgAddress?.postalCode
        : "",
      organizationcountry: { name: refOrgAddress?.country }
        ? { name: refOrgAddress?.country }
        : { name: "United States" },

      benificiaryFirstName: beneficiary?.name?.firstName
        ? beneficiary?.name?.firstName
        : "",
      benificiaryMiddleName: beneficiary?.name?.middleName
        ? beneficiary?.name?.middleName
        : "",
      benificiaryLastname: beneficiary?.name?.lastName
        ? beneficiary?.name?.lastName
        : "",
      benificiarySuffix: beneficiary?.name?.suffix
        ? beneficiary?.name?.suffix
        : "",
      benificiaryCountryCode: beneficiary?.countryCode
        ? beneficiary?.countryCode
        : "",
      benificiaryPrefix: { name: beneficiary?.name?.prefix }
        ? { name: beneficiary?.name?.prefix }
        : { name: "Mr" },
      benificiaryAreaCode: beneficiary?.phone?.areaCode
        ? beneficiary?.phone?.areaCode
        : "",
      benificiaryRemNumber: beneficiary?.phone?.remNumber
        ? beneficiary?.phone?.remNumber
        : "",
      benificiaryEmail: beneficiary?.email ? beneficiary?.email : "",
      benificiaryRelationship: beneficiary?.relationship
        ? beneficiary?.relationship
        : "",
    },
    onSubmit: (values) => {
      let formData = {};
      if (!formik?.values?.sponerAsembly?.name) {
        setError(true);
        return;
      }
      formData.refAmbassador = {
        name: {
          prefix: values.ambassadorPrefix.name
            ? values.ambassadorPrefix.name
            : "Mr",
          firstName: values.ambassadorFirstname,
          // middleName: values.ambassadormiddleName,
          lastName: values.ambassadorlastName,
          // suffix: values.ambassadorsuffix,
        },
        phone: {
          countryCode: String(values?.ambassadorCountryCode),
          areaCode: values.ambassadorAreaCode,
          remNumber: values.ambassadorRemNumber,
        },
        ambassador: values.ambassadorReferring.name
          ? values.ambassadorReferring.name
          : "Ambassador",
        description: values.description,
      };
      formData.sponsor = {
        name: values.sponerAsembly.name ? values.sponerAsembly.name : "",
        suffix: values.ambassadorsuffix,
        phone: {
          areaCode: values.sponerAsemblyAreaCode,
          remNumber: values.sponerAsemblyRemNumber,
        },
      };

      formData.refOrgAddress = {
        streetAddress: values.organizationstreetAddress,
        stAddressLine2: values.organizationstreetAddress2,
        city: values.organizationCity,
        state: values.organizationstate,
        postalCode: values.organizationpostalCode,
        country: values.organizationcountry.name
          ? values.organizationcountry.name
          : "United States",
      };

      formData.beneficiary = {
        name: {
          prefix: values.benificiaryPrefix.name
            ? values.benificiaryPrefix.name
            : "Mr",
          firstName: values.benificiaryFirstName,
          middleName: values.benificiaryMiddleName,
          lastName: values.benificiaryLastname,
          suffix: values.benificiarySuffix,
          countryCode: String(values?.ambassadorCountryCode),
        },
        phone: {
          areaCode: values.benificiaryAreaCode,
          remNumber: values.benificiaryRemNumber,
        },
        countryCode: values.benificiaryCountryCode,
        email: values.benificiaryEmail,
        relationship: values.benificiaryRelationship,
      };

      dispatch(saveFormData(formData));
      handeClickForm();
    },
  });
  useEffect(() => {
    if (formik?.values?.sponerAsembly?.name) {
      setError(false);
    }
  }, [formik?.values?.sponerAsembly?.name]);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <form ref={formRef} onSubmit={formik.handleSubmit}>
            <div className={styles.selectionare}>
              <div className={styles.namefield}>
                <div className={styles.heading}>
                  <label>Ambassador or Referral Source (TAF Rewards) </label>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={referringSelected}
                      // setSelected={setSelected}
                      setSelected={(res) => {
                        formik.setFieldValue("ambassadorReferring", res);
                        setreferringSelected(res);
                      }}
                      options={referringOptions}
                      imgShow={true}
                      name="Referring Source"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Describe Referral Source"
                      config={formik.getFieldProps("description")}
                      inputName=" Please describe Digital Ad, Printed Ad, Podcast/VLOG Episode, etc"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={selected}
                      // setSelected={setSelected}
                      setSelected={(res) => {
                        formik.setFieldValue("ambassadorPrefix", res);
                        setSelected(res);
                      }}
                      options={options}
                      imgShow={true}
                      name="Prefix"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="First Name"
                      config={formik.getFieldProps("ambassadorFirstname")}
                      inputName="First Name"
                      type="text"
                    />
                  </div>
                </div>
                <div className={styles.selected}>
                  {/* <div className={styles.innerselected}>
                    <Input
                      name="Middle Name"
                      inputName="Middle Name"
                      config={formik.getFieldProps("ambassadormiddleName")}
                      type="text"
                    />
                  </div> */}
                  <div className={styles.innerselected}>
                    <Input
                      name="Last Name"
                      config={formik.getFieldProps("ambassadorlastName")}
                      inputName="Last Name"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Suffix / Title"
                      config={formik.getFieldProps("ambassadorsuffix")}
                      inputName="Suffix / Title"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Country Code"
                      config={formik.getFieldProps("ambassadorCountryCode")}
                      inputName="Country Code"
                      type="text"
                    />
                  </div>
                </div>

                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Area Code"
                      config={formik.getFieldProps("ambassadorAreaCode")}
                      inputName="Area Code"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Phone Number"
                      config={formik.getFieldProps("ambassadorRemNumber")}
                      inputName="Phone Number"
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.phonefield}>
                <div className={styles.heading}>
                  <label>Sponsoring Assembly</label>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={sponsoring}
                      // setSelected={setsponsoring}
                      options={sponsoringAssemblyOptions}
                      setSelected={(res) => {
                        formik.setFieldValue("sponerAsembly", res);
                        setsponsoring(res);
                      }}
                      imgShow={true}
                      name="Sponsoring Assembly *"
                    />
                    {error ? (
                      <p style={{ color: "red" }}>
                        Sponsoring Assembly Required
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Area Code"
                      inputName="Area Code"
                      config={formik.getFieldProps("sponerAsemblyAreaCode")}
                      type="number"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Phone Number"
                      inputName="Phone Number"
                      config={formik.getFieldProps("sponerAsemblyRemNumber")}
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.addressfield}>
                <div className={styles.heading}>
                  <label>
                    Referring Organization's Physical Address (Optional)
                  </label>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Street Address"
                      config={formik.getFieldProps("organizationstreetAddress")}
                      inputName="Address"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Street Address Line 2"
                      config={formik.getFieldProps(
                        "organizationstreetAddress2"
                      )}
                      inputName="Address"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="City"
                      config={formik.getFieldProps("organizationCity")}
                      inputName="City"
                      type="text"
                    />
                  </div>
                </div>
                <div className={styles.provinceselected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="State"
                      inputName="State / Province"
                      config={formik.getFieldProps("organizationstate")}
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Postal Code"
                      config={formik.getFieldProps("organizationpostalCode")}
                      inputName="Postal / Zip Code"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={PhyCountry}
                      setSelected={(res) => {
                        formik.setFieldValue("organizationcountry", res);
                        setPhyCountry(res);
                      }}
                      options={CountryOptions}
                      imgShow={true}
                      name="Country"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.mailingfield}>
                <div className={styles.heading}>
                  <label>Progenitorial Beneficiary (Optional)</label>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={benSelected}
                      options={options}
                      setSelected={(res) => {
                        formik.setFieldValue("benificiaryPrefix", res);
                        setBenSelected(res);
                      }}
                      imgShow={true}
                      name="Prefix"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="First Name"
                      config={formik.getFieldProps("benificiaryFirstName")}
                      inputName="First Name"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Middle Name"
                      config={formik.getFieldProps("benificiaryMiddleName")}
                      inputName="Middle Name"
                      type="text"
                    />
                  </div>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Last Name"
                      inputName="Last Name"
                      config={formik.getFieldProps("benificiaryLastname")}
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Suffix / Title"
                      config={formik.getFieldProps("benificiarySuffix")}
                      inputName="Suffix / Title"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Country Code"
                      config={formik.getFieldProps("benificiaryCountryCode")}
                      inputName="Country Code"
                      type="text"
                    />
                  </div>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Area Code"
                      config={formik.getFieldProps("benificiaryAreaCode")}
                      inputName="Area Code"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Phone Number"
                      config={formik.getFieldProps("benificiaryRemNumber")}
                      inputName="Phone Number"
                      type="text"
                    />
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Email"
                      config={formik.getFieldProps("benificiaryEmail")}
                      inputName="Email"
                      type="email"
                    />
                  </div>
                </div>
                <div className={styles.displayfield}>
                  <div className={styles.innerselected}>
                    <Input
                      config={formik.getFieldProps("benificiaryRelationship")}
                      name="Relationship"
                      inputName="Spouse, Sibling, Relative, etc."
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default forwardRef(ReferringBeneficiary);
