export const SOCKET_TYPES = {
  WALLET_UPDATE: "walletUpdate",
  CUSTOMER_VERIFIED: "customerVerified",
};
export const CHAT_SOCKET_TYPES = {
  SUBSCRIBE_TO_CHAT: "subscribe-to-chat",
  ENTER_CHAT_ROOM: "enter-chat-room",
  ALL_MESSAGES: "all-messages",
  NEW_MESSAGE: "new-message",
  USER_CONNECT: "user-connect",
  USER_BLOCKED: "userBlocked",
};
export const SINGLE_CHAT_EMIT_EVENTS = {
  ADD_USER_MESSAGE: "add-user-message",
  ENTER_USER_ROOM: "enter-user-room",
};

export const SOCKET_EVENTS = {
  NEW_USER_MESSAGE: "new-user-message",
  USER_OFFLINE: "userOffline",
  USER_ONLINE: "userOnline",
  ROOM_UPDATED: "room-updated",
};

export const SocketResponses = {
  success: "success",
  failure: "failure",
};
