import React from "react";
import styles from "./confirmDirectWire.module.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import CQRComponent, { CQRAComponent } from "../CQRComponent/CQRComponent";
import { DIRECT_WIRE_TYPE } from "../../../utils/AppConstants";
const ConfirmDirectWire = ({
  data,
  activationAmountToken,
  bankDetail,
  activationAmount,
  legacySignature,
  to,
}) => {
  let wireTitle;
  switch (data?.type) {
    case DIRECT_WIRE_TYPE?.TOKEN_PURCHASE:
      wireTitle =
        data?.token?.coinSymbol == "Q"
          ? "Liquidity Token Acquire"
          : `${data?.token.shortName} Acquire`;
      break;
    case "legacyTokenDeposit":
      wireTitle = `${data?.toName} Acquire`;
      break;
    case DIRECT_WIRE_TYPE?.SUBSCRIPTION:
      wireTitle = `${data?.subscription?.title} (subscription) Acquire`;
      break;

    case DIRECT_WIRE_TYPE?.WALLET_ACTIVATION:
      wireTitle = "CQR Wallet Activation";
      break;

    default:
  }
  const directWireFee = useSelector(
    (state) => state?.remittance?.bankDetail?.directWireFee
  );
  const isActivate = useSelector(
    (state) => state?.user?.userInfo?.isWalletActivated
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {data?.status == "pending" && (
            <div className={styles.heading} style={{ color: "yellow" }}>
              Thank You. Your Request has been received. Please continue to
              remit your contribution to the coordinates as provided below
            </div>
          )}
          <div className={styles.orderHeading}>
            <div className={styles.subHeading}>
              <label>Request </label>
              <span>{data?.orderId}</span>
            </div>
            <div className={styles.subHeading}>
              <label>Date</label>
              <span>{moment(data?.updatedAt).format("MMM Do YYYY")}</span>
            </div>
            <div className={styles.subHeading}>
              <label>Email</label>
              <span>{data?.remittanceAddress?.email}</span>
            </div>
            <div className={styles.subHeading}>
              <label>Total</label>
              <span>
                {data?.wireFee
                  ? data?.amount?.toFixed(data?.token?.decimals)
                  : data?.amount}
                {}
              </span>
            </div>
            <div className={styles.subHeading}>
              <label>Remittance Method</label>
              <span>Direct Wire Contribution</span>
            </div>
          </div>

          <div className={styles.middleBox}>
            <div className={styles.rightBox}>
              <div className={styles.boxHeading}>
                CQR Vault Account Details
                <div className={styles.textWrapper}>
                  Please send your contribution to the following account within
                  3 days.
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles.boxHeading}>
                  <p>Zelle Transfer</p>
                </div>
                <div className={styles.boxWrapper}>
                  <div className={styles.boxData}>
                    <span>Account Name</span>
                    <span>{bankDetail?.zellePay?.accountName}</span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Account Type</span>
                    <span>{bankDetail?.zellePay?.accountType}</span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Zelle Address</span>
                    <span>{bankDetail?.zellePay?.bankAddress}</span>
                  </div>
                </div>
                <div className={styles.boxHeading}>
                  <p>Domestic Wires (Originating in the U.S.)</p>
                </div>
                <div className={styles.boxWrapper}>
                  <div className={styles.boxData}>
                    <span>Account Name</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.accountName}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Account Holder Mailing Address</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.mailingAddress}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Account Number</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.accountNo}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>ACH and Wire routing number</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.routingNo}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Account Type</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.accountType}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Bank Name</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.bankName}
                    </span>
                  </div>

                  {/* <div className={styles.boxData}>
                  <span>Account Type</span>
                  <span>{bankDetail?.directWireAccount?.accounttype}</span>
                </div> */}

                  <div className={styles.boxData}>
                    <span>Bank Address</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.bankAddress}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Endorsement/Memo/Attachment</span>
                    <span>
                      {bankDetail?.directWireDomesticAccount?.endorsement}
                    </span>
                  </div>
                </div>
                <div className={styles.boxHeading}>
                  <p>International Wires (Originating outside the U.S.)</p>
                </div>
                <div className={styles.boxWrapper}>
                  <div className={styles.boxData}>
                    <span>Account Name</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.accountName}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Account Holder Mailing Address</span>
                    <span>
                      {
                        bankDetail?.directWireInternationalAccount
                          ?.mailingAddress
                      }
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Account Number</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.accountNo}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Routing number</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.routingNo}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>SWIFT/BIC</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.swiftCode}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Account Type</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.accountType}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Bank Name</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.bankName}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Bank Address</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.bankAddress}
                    </span>
                  </div>
                  <div className={styles.boxData}>
                    <span>Endorsement/Memo/Attachment</span>
                    <span>
                      {bankDetail?.directWireInternationalAccount?.endorsement}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.leftBox}>
              <div className={styles.boxHeading}>Exchange Details </div>
              <div className={styles.box}>
                <div className={styles.boxData}>
                  <span>
                    {/* {data?.token?.shortName
                      ? data?.token?.shortName
                      : to?.to?.shortName
                      ? to?.to?.shortName
                      : "CQR Vault Contribution"} */}
                    {/* {data?.token?.coinSymbol == "Q"
                      ? "Liquidity Token Acquire"
                      : `${data?.token.shortName} Acquire`} */}
                    {wireTitle}
                  </span>
                  <span>
                    {!isActivate
                      ? activationAmountToken?.toFixed(2)
                      : data?.type === "legacyTokenDeposit"
                      ? data?.tokenAmount
                      : data?.amount
                      ? data?.tokenAmount?.toFixed(data?.token?.decimals)
                      : activationAmount.toFixed(data?.token?.decimals)}
                  </span>
                </div>
                <div className={styles.boxData}>
                  <span>Total Amount</span>
                  <span>
                    USD $
                    {data?.type === "legacyTokenDeposit"
                      ? data?.amount
                      : data?.amount
                      ? data?.amount.toFixed(data?.token?.decimals)
                      : activationAmount.toFixed(data?.token?.decimals)}
                  </span>
                </div>
                {/* <div className={styles.boxData}>
                  <span>Fee</span>
                  <span>USD ${data?.wireFee}</span>
                </div> */}
                {/* <div className={styles.boxData}>
                  <span>Total Amount</span>
                  <span>
                    USD $
                    {data?.wireFee
                      ? data?.amount
                        ? (data?.amount + data?.wireFee).toFixed(
                            data?.token?.decimals
                          )
                        : (activationAmount + data?.wireFee).toFixed(
                            data?.token?.decimals
                          )
                      : data?.amount
                      ? (data?.amount).toFixed(data?.token?.decimals)
                      : activationAmount.toFixed(data?.token?.decimals)}
                  </span>
                </div> */}
              </div>
            </div>
          </div>
          <div className={styles.yourSign}> Your Signature</div>
          <div className={styles.signature}>
            <img
              src={
                data?.remittanceAddress?.signatureUrl
                  ? data?.remittanceAddress?.signatureUrl
                  : legacySignature
              }
            />
          </div>
          {/* <div className={styles.button}>
           <button  >Submit</button>
         </div> */}
        </div>
      </div>
    </>
  );
};

export default ConfirmDirectWire;
