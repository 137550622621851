import React from "react";
import style from "./coinheading.module.scss";
import Q from "../../../../Assets/image/qsymbolBlue.png";
const CoinHeading = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.heading}>
          <p>
            ACTIVATE YOUR VAULT WITH
            <br />
            <span>
              C<img src={Q} alt="" />R
            </span>
            &nbsp; DOLLARS!
          </p>
        </div>
      </div>
    </>
  );
};

export default CoinHeading;
