import React, { useEffect, useState, useRef } from "react";
import style from "./profile.module.scss";
import Profileimg from "../../../../Assets/image/profile.svg";
import Correct from "../../../../Assets/image/correct.svg";
import Instructions from "./Instructions/Instructions";
import GPALevel from "./GPALevelInformation/GPALevel";
import ReferringBeneficiary from "./ReferringBeneficiary/ReferringBeneficiary";
import ImportantNote from "./ImportantNote/ImportantNote";
import UseProfile from "./UseProfile";
import Modal from "../../../_Common/Modal/Modal";
import Popup from "../../../_Common/DashboardDone/popup";
import Innerpopup from "../../../_Common/DashboardComponents/popup/popup";
import Logo from "../../../../Assets/image/logo.svg";
import ScrollToTop from "../../../_Common/ScrollToTop";
import { useLocation } from "react-router-dom";
import $ from "jquery";
const Profile = ({ history }) => {
  const [number] = useState(1);
  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  const {
    scrolls,
    pageChange,
    handeClick,
    GPA,
    BeneficiaryBar,
    Importantnote,
    Active,
    gpa,
    referring,
    important,
    instructions,
    popupvisible,
    setpopupvisible,
    accountPopup,
    setAccountPopup,
    introRef,
    gpaRef,
    benificiaryRef,
    signatureRef,
    upperRef,
  } = UseProfile();

  return (
    <>
      <div ref={upperRef}> </div>
      <Modal
        visible={popupvisible || accountPopup}
        onClose={() => {
          setpopupvisible(false);
          history.push("/");
        }}
        btn
      >
        {popupvisible ? (
          <Popup
            Name="Contribute Value Of Q21 Silver Coins To Activate Your Account"
            para="Notice: 1.00 CQR = 1 USD"
            src={Logo}
            btn="Open Account"
            onPressButton={() => {
              setpopupvisible(false);
              setAccountPopup(true);
            }}
          />
        ) : (
          accountPopup && <Innerpopup data={number} />
        )}
      </Modal>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            {/* <div className={style.editprofile}>
              <img src={Profileimg} alt="Profile" />
              <p>Edit Profile</p>
            </div> */}
          </div>
          <div className={style.progressbar}>
            <div className={Active === 0 ? style.active : style.instructions}>
              <div className={style.box}>
                <div className={style.Circlesection}>
                  <div className={style.circle}>
                    <img src={Correct} alt="Correct" />
                  </div>
                </div>
                <div className={style.role}>
                  <label>Instructions</label>
                </div>
              </div>
            </div>
            <div className={GPA ? style.inneractive : style.gpalevel}>
              <div className={style.innerprogressbar}>
                <div className={style.bar}></div>
              </div>
              <div className={style.box}>
                <div className={style.Circlesection}>
                  <div className={style.circle}>
                    <img src={Correct} alt="Correct" />
                  </div>
                </div>
                <div className={style.role}>
                  <label>G.P.A Level 3 Info</label>
                </div>
              </div>
            </div>
            <div
              className={BeneficiaryBar ? style.inneractive : style.beneficiary}
            >
              <div className={style.innerprogressbar}>
                <div className={style.bar}></div>
              </div>
              <div className={style.box}>
                <div className={style.Circlesection}>
                  <div className={style.circle}>
                    <img src={Correct} alt="Correct" />
                  </div>
                </div>
                <div className={style.role}>
                  <label>TAF/Legacy</label>
                </div>
              </div>
            </div>
            <div
              className={
                Importantnote ? style.inneractive : style.importantnote
              }
            >
              <div className={style.innerprogressbar}>
                <div className={style.bar}></div>
              </div>
              <div className={style.box}>
                <div className={style.Circlesection}>
                  <div className={style.circle}>
                    <img src={Correct} alt="Correct" />
                  </div>
                </div>
                <div className={style.role}>
                  <label>Acknowledgement</label>
                </div>
              </div>
            </div>
          </div>
          {instructions ? (
            <>
              <Instructions ref={introRef} handeClickForm={pageChange} />
            </>
          ) : gpa ? (
            <>
              <GPALevel ref={gpaRef} handeClickForm={pageChange} />
            </>
          ) : referring ? (
            <>
              <ReferringBeneficiary
                ref={benificiaryRef}
                handeClickForm={pageChange}
              />
            </>
          ) : important ? (
            <>
              <ImportantNote ref={signatureRef} handeClickForm={pageChange} />
            </>
          ) : (
            <>
              <ImportantNote />
            </>
          )}
          <div className={style.bttnsection}>
            <button className={style.nextbuttons}>Save & Exit</button>

            <button
              className={style.nextbutton}
              type="submit"
              onClick={() => handeClick()}
            >
              {important ? "Done" : "Next"}
            </button>

          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
