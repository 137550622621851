import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import HomeApp from "./Components/WebLayout/HomeApp";
import SideBarWrapper from "./Components/DashBoardLayout/Dashboard/sidebarWrapperDashboard/index";
import Splash from "./Components/_Common/SplashScreen/Splash";
import { Helmet } from "react-helmet";
import {
  transitions,
  positions,
  Provider as AlertProvider,
  useAlert,
} from "react-alert";
import AlertTemplate from "../src/Components/_Common/Alert/AlertTemplate";
import { HTTP_CLIENT, setupAxios } from "./utils/services";
import DirectDebit from "./Components/_Common/DirectDebit/DirectDebit";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import PrivateRoute from "./Components/navigation/PrivateRoute";
import PublicRoute from "./Components/navigation/PublicRoute";
import ChatIcon from "./Components/_Common/Chat/ChatIcon";
import { socket, SocketContext } from "./Context/Socket";
import { io } from "socket.io-client";
import MaintenanceMode from "./Components/_Common/MaintenanceMode";
import { getTokenFirebase, onMessageListener } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomeAlert from "./Components/_Common/customAlet/CustomeAlert";
import { getMessaging } from "@firebase/messaging";
import { saveFcmToken } from "./redux/action/userAction";
import ScrollToTop from "./Components/_Common/ScrollToTop";
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "10px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10000,
  },
};

const App = () => {
  const [resData, setResData] = useState(false);
  const [isTokenFound, setTokenFound] = useState("");
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [show, setShow] = useState(false);
  const handleMaintenanceMode = async () => {
    let res = await HTTP_CLIENT.get(`/settings/get-maintenance`);
    setResData(res?.data);
  };
  ///****** Check cookies functin*/
  function checkCookie() {
    var cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie") != -1;
    }
    return cookieEnabled || showCookieFail();
  }
  function showCookieFail() {
    alert("CQR Vault requires using cookies and they appear to be blocked");
  }

  useEffect(() => {
    getTokenFirebase(setTokenFound);
    setupAxios();
    handleMaintenanceMode();
    checkCookie();
    onMessageListener()
      .then((payload) => {
        setShow(true);
        toast(<CustomeAlert message={payload} />, {
          onClose: () => setShow(false),
        });
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  const [splash, setSplash] = useState(true);
  if (splash) {
    return <Splash setSplash={setSplash} />;
  }

  // for updation of FCM token

  return (
    <>
      <Helmet>
        <title> CQR Vault</title>
      </Helmet>
      <SocketContext.Provider value={socket}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <ScrollToTop />
              {/* <ChatIcon /> */}
              <AlertProvider template={AlertTemplate} {...options}>
                {resData ? (
                  <Switch>
                    <Route exact path="*" component={MaintenanceMode} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/direct-debit" component={DirectDebit} />
                    <PublicRoute
                      exact
                      path={["/", "/about-us", "/contact-us", "/faq"]}
                      component={HomeApp}
                    />

                    <PrivateRoute
                      exact
                      path={[
                        "/:id",
                        "/contribute",
                        "/vault-token",
                        "/redemption",
                        "/fiat-swap",
                        "/currency-graph",
                        "/crypto-swap",
                        "/cqr-vault-token",
                        "/assets-swap",
                        "/error",
                        "/silent-wave",
                        "/my-assets",
                        "CQRVault",
                        "/chat",
                        "/cqr-liquidity-tokens",
                        "/change-password",
                      ]}
                      component={SideBarWrapper}
                    />
                  </Switch>
                )}
              </AlertProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </SocketContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default App;
