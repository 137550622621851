import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import noDataGip from "../../../Assets/gif/noData.gif";
import walletActive from "../../../Assets/icons/walletActive.svg";
import { saveDirectWireList } from "../../../redux/action/remittanceAction";
import { getWire } from "../../../services/paymentServices";
import ConfirmDirectWire from "../confirmDirectWire/confirmDirectWire";
import styles from "./directWireList.module.scss";
import NoData from "../noData";

const DirectWireList = () => {
  const [wireDetail, setWireDetail] = useState(null);
  const [show, setshow] = useState(null);
  const dispatch = useDispatch();

  const { directWireList, bankDetail } = useSelector(
    (state) => state.remittance
  );

  const fetchDirectWire = async () => {
    const response = await getWire();
    dispatch(saveDirectWireList(response.data));
  };

  const handleDetail = (item) => {
    setshow(true);
    setWireDetail(item);
  };

  useEffect(() => {
    fetchDirectWire();
  }, []);
  return (
    <>
      {!show ? (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {!Boolean(directWireList?.length) ? (
              <div className={styles.noDataWrapper}>
                <NoData />
              </div>
            ) : (
              <table cellspacing="0" cellpadding="0">
                <tr>
                  {/* <td>Order Id</td> */}
                  <td></td>
                  <td>Status</td>
                  <td>Amount</td>
                  <td>Type</td>
                </tr>
                {directWireList?.map((item, index) => (
                  <tr onClick={() => handleDetail(item)}>
                    <td>
                      <div className={styles.nameWrapper}>
                        <img
                          src={
                            item?.subscription?.logo
                              ? item?.subscription?.logo
                              : item?.token?.icon?.url
                              ? item?.token?.icon?.url
                              : walletActive
                          }
                          alt=""
                        />
                        <div>
                          <div className={styles.heading}>
                            {item?.type == "tokenPurchase"
                              ? "Exchange Request"
                              : item?.type == "nftPurchase"
                              ? "NFT Purchase"
                              : item?.type == "subscription"
                              ? "subscription Purchase"
                              : item?.type == "walletActivation"
                              ? "wallet Activation"
                              : ""}
                          </div>
                          <div className={styles.description}>
                            {moment(item?.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss A"
                            )}
                          </div>
                          <div className={styles.description}>
                            {item?.streetAddress}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className={styles.nameWrapper}>
                        <div
                          className={`${
                            item?.status == "completed"
                              ? styles.green
                              : item?.status == "expired"
                              ? styles.red
                              : item?.status == "pending"
                              ? styles.purple
                              : item?.status == "rejected"
                              ? styles.red
                              : ""
                          }`}
                        >
                          {item?.status == "completed"
                            ? "Completed"
                            : item?.status == "expired"
                            ? "Expired"
                            : item?.status == "pending"
                            ? "Pending"
                            : item?.status == "rejected"
                            ? "Rejected"
                            : ""}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={styles.nameWrapper}>
                        <div>
                          {item.wireFee
                            ? (item?.amount).toFixed(2)
                            : item?.amount?.toFixed(2)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={styles.nameWrapper}>
                        <div>
                          {item?.type == "tokenPurchase"
                            ? "CQR Contribution"
                            : item?.type == "walletActivation"
                            ? "Wallet Activation"
                            : item?.type == "subscription"
                            ? "Subscription"
                            : item?.type == "nftPurchase"
                            ? "NFT"
                            : ""}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            )}
          </div>
        </div>
      ) : (
        <ConfirmDirectWire data={wireDetail} bankDetail={bankDetail} />
      )}
    </>
  );
};

export default DirectWireList;
