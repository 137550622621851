import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Payment.module.scss";
const PaymentSelection = () => {
  const history = useHistory();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.mainBox}>
            <div className={styles.heading}>Coming Soon</div>
            {/* <div className={styles.buttons}>
                    <button onClick={() => history.push("./liquidity-tokens")}>GO cardless</button>
                    <button onClick={() => history.push("./direct-wire")}>Direct wire</button>
                </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSelection;
