import React, { useEffect } from "react";
import styles from "./About.module.scss";
import Aos from "aos";

function About() {
  useEffect(() => {
    Aos.init();
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.headingWrapper}>
            <div
              className={styles.heading}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="zoom-in"
            >
              About <span>Us</span>
            </div>
            <div
              className={styles.subHeading}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="fade-right"
            >
              <div>
                It's not about the <label>COIN...</label>
              </div>

              <br />
              <div>
                It's about the <span>KOINONIA</span>!
              </div>
            </div>
            <div
              className={styles.textHeading}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="fade-left"
            >
              A classical Greek word used in the New Testament, literally
              translated as <span>“communion by intimate participation”</span>{" "}
              embracing concepts conveyed in the English terms: community,
              communion, joint-participation, sharing and intimacy, implying
              transparency … a real <span>“relationship.”</span>
            </div>
            <div
              className={styles.secHeading}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="fade-right"
            >
              Three elements must be present to satisfy the CQR Vault's
              definition of Koinonia:
            </div>
            <div
              className={styles.text}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="fade-left"
            >
              <ol>
                <li>
                  <span>Sharing</span> : True wealth lay not in what one has,
                  but in what they gave to others. Only participation as a
                  contributive person allows one to share in what others have.
                </li>
                <li>
                  <span>Relationships</span> : Fellowship denotes a common
                  possession of spiritual values and joint-participation in
                  God’s graces. The true reward is never passive, but rather
                  always linked to action, not just being together, but also
                  doing together. With this fellowship comes a close and
                  intimate relationship embracing ideas, communication, and
                  frankness, as in a true, blessed interdependent friendship
                  among multiple group members … creating a mutual bond which
                  overrides each individual’s pride, vanity, and individualism,
                  fulfilling the human yearning with fraternity, belonging, and
                  companionship. The ease by which sharing and generosity flow
                  through our CQR community is a function of association, common
                  effort, or a partnership in common, held and shared jointly
                  with others in Ecclesia, while highlighting a higher purpose
                  or mission that benefits the greater good of the members as a
                  whole.
                </li>
                <li>
                  <span>Community</span> : All ships with no holes rise on an
                  incoming tide. We have a “common unity” of purpose and
                  interests, striving to overcome brokenness, divisiveness, and,
                  ultimately gaining wholeness with each of the members, with
                  their environment, and with God. While there is leadership,
                  the leader’s task is to focus energy, and align interests (not
                  impose control) in order to nurture brethren bonds to build
                  trust and overcome two of humanity’s deepest fears and
                  insecurities: A) being betrayed; and B) being demeaned.
                </li>
              </ol>
            </div>
            <div
              className={styles.secText}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="fade-right"
            >
              <p>
                At the heart of the CQR Community, is the willingness for our
                Members to share the good news of the hope they have today to
                re-kindle an economy that rewards altruism!
              </p>
              <p>
                You see, CQR Vault is designed such that one's willingness to
                give, truly determines the rewards they receive!
              </p>
            </div>
            <div
              className={styles.p2}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="fade-left"
            >
              All CQR Vault Members give from our "T3" as voluntary
              contributions:
              <div
                className={styles.text}
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
                data-aos="fade-right"
              >
                <ol>
                  <li>
                    <span>Time</span> = One's ability to voluntarily work toward
                    the goals of our organic international community.
                  </li>
                  <li>
                    <span>Talent</span> = One's specialized skill set or
                    discipline that they contribute to CoVested causes and
                    projects.
                  </li>
                  <li>
                    <span>Treasure</span> = One's capital resources, property,
                    assets, silver or cash.
                  </li>
                </ol>
                <div
                  className={styles.p2Inner}
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                  data-aos="fade-left"
                >
                  We have developed a "B-Quad" system to embrace a culture of
                  capitally rewarding true altruism. At CQR, the more residual
                  royalties you desire, the more you must give!
                </div>
              </div>
            </div>
            <div
              className={styles.bottomText}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              data-aos="fade-right"
            >
              The CQR Vault system is a revolutionary paradigm that truly allows
              you to <span>"Have your cake...and eat it too!"</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
