import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import FullScreenView from "../../../_Common/Full Screen View/FullScreenView";
import styles from "./privatechat.module.scss";
import Cqrlogo from "../../../../Assets/image/logo.svg";
import Sendicon from "../../../../Assets/image/send-icon.svg";
import AttachIcon from "../../../../Assets/image/attachment-attach.png";
import { CHAT_SOCKET_TYPES } from "../../../../utils/ChatConstants";
import { SocketContext } from "../../../../Context/Socket";
import { generateChatId } from "../../../../utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import { HTTP_CLIENT } from "../../../../utils/services";
import {
  Loader,
  LoaderNew,
  Message,
  Wave,
} from "../../../_Common/loader/Loader";
import chatGif from "../../../../Assets/gif/chatgip.gif";
import moment from "moment";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { useAlert } from "react-alert";
import { FaFileDownload } from "react-icons/fa";

const PrivateChat = () => {
  const socket = useContext(SocketContext);
  // const userInfo = useSelector((state) => state?.currency?.currencyInfo?.user);
  const userInfo = useSelector((state) => state?.user?.userInfo);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [messageLoader, setMessageLoader] = useState(false);
  const chatRef = useRef(null);
  const dispatch = useDispatch();
  const [viewchat, setviewchat] = useState(false);
  const [count, setCount] = useState("");
  const alert = useAlert();
  const history = useHistory();
  const userSelector = useSelector((state) => state.currency);
  const userKyc = useSelector((state) => state?.user);

  useEffect(() => {
    if (
      !userKyc?.savedFormData?._id &&
      !userKyc?.kyc?._id &&
      !userSelector?.currencyInfo?.gpaData?._id
    ) {
      history.push("/profile");
      alert.show("Please fill your GPA data first", {
        type: "error",
      });
    }
  }, []);
  useEffect(() => {
    if (window.location.href.indexOf("/chat") !== -1) {
      $('img[alt="Chat-Icon"]').hide();
    } else {
      $('img[alt="Chat-Icon"]').show();
    }
    if (chatRef.current) {
      setTimeout(() => {
        chatRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    }
  }, [messages.length]);

  var title = document.title;
  function changeTitle() {
    count++;
    var newTitle = "(" + count + ") " + title;
    document.title = newTitle;
  }

  // Img upload
  const handleImg = async (e) => {
    setMessageLoader(true);
    if (
      !(
        e.target.files[0]?.type == "image/jpeg" ||
        e.target.files[0]?.type == "image/jpg" ||
        e.target.files[0]?.type == "image/png" ||
        e.target.files[0]?.type == "application/pdf" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      alert.show("Please select image only", { type: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "chat");
    let data = formData;
    let authToken = localStorage.getItem("authToken");
    let res = await HTTP_CLIENT.post("storage/upload", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    socket.emit("add-message", {
      text: messageText,
      user: userInfo?._id,
      recipient: "ADMIN",
      chatRoom: chatRoomId,
      fileName: e.target.files[0]?.name,

      image: res.data.url,
      type:
        e.target.files[0]?.type === "application/pdf" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "file"
          : "image",
    });
    setMessageLoader(false);
  };

  // chat integration//

  const chatRoomId = generateChatId(userInfo?._id, "ADMIN");

  useEffect(() => {
    socket.emit(CHAT_SOCKET_TYPES.ENTER_CHAT_ROOM, {
      userId: userInfo?._id,
      otherUserId: "ADMIN",
      chatRoomId: chatRoomId,
    });
    socket.on(CHAT_SOCKET_TYPES.ALL_MESSAGES, (data) => {
      setMessages(data.messages);
    });
    socket.on(CHAT_SOCKET_TYPES.NEW_MESSAGE, (msg) => {
      let index = messages.findIndex((data) => data._id == msg._id);
      if (index == -1) {
        setMessages((previousArr) => [...previousArr, msg]);
      }
    });
    socket.on("disconnect", () => {});
    return () => {
      socket.off(CHAT_SOCKET_TYPES.NEW_MESSAGE);
    };
  }, []);

  const onSend = (e) => {
    e.preventDefault();
    if (!messageText.trim()) {
      return;
    }
    socket.emit("add-message", {
      text: messageText.trim(),
      user: userInfo?._id,
      recipient: "ADMIN",
      chatRoom: chatRoomId,
    });
    setMessageText("");
  };
  const URL_REGEX = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  function TextOrLink(value) {
    const words = value?.split(" ");
    return (
      <p>
        {words?.map((word) => {
          return word?.match(URL_REGEX) ? (
            <>
              <a
                href={`http://${
                  word?.split("/")[2] ? word?.split("/")[2] : word
                }`}
                target="_blank"
                style={{
                  color: "rgb(31 187 238)",
                  textDecoration: "underLine",
                }}
              >
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }
  useEffect(() => {
    if (chatRef?.current) {
      setTimeout(() => {
        chatRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 500);
    }
  }, [viewchat]);
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);
    if (!isOnline) {
      alert.show("Please check your internet connection", {
        type: "error",
      });
      setMessageLoader(false);
    }
    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <>
            <div className={styles.chatView}>
              <div className={styles.profile}>
                <div className={styles.left}>
                  <div className={styles.profilepic}>
                    <img src={Cqrlogo} alt="Profile-pic" />
                  </div>
                </div>
                <div className={styles.right}>
                  <label>Support</label>
                  {/* <span>Online</span> */}
                </div>
              </div>
              <div className={styles.messagelist}>
                <div className={styles.messages}>
                  {/* <div className={styles.currentday}>
                    <span>Today</span>
                  </div> */}
                  {!messages.length ? (
                    <div>
                      <img
                        style={{ width: "150px", height: "150px" }}
                        src={chatGif}
                        alt=""
                      />
                      <h4>How may i help you</h4>
                    </div>
                  ) : (
                    messages.map((msg) => {
                      const {
                        chatRoom,
                        recipient,
                        text,
                        createdAt,
                        updatedAt,
                        user,
                        image,
                        type,
                        fileName,
                      } = msg;
                      const isUserMessage = userInfo?._id !== user;
                      return (
                        <>
                          <div className={styles.currentday}>
                            <span> {moment(updatedAt).format("ll")}</span>
                          </div>
                          {isUserMessage ? (
                            <div className={styles.receivermessage}>
                              {
                                <>
                                  {type === "file" && (
                                    <a
                                      href={image}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <div className={styles.fileDownload}>
                                        <span>
                                          {fileName?.length > 28
                                            ? `....${fileName?.slice(-28)}`
                                            : fileName}
                                        </span>
                                        <FaFileDownload
                                          className={styles.icon}
                                        />
                                      </div>
                                    </a>
                                  )}
                                  {msg?.type === "image" && (
                                    <FullScreenView
                                      style={{
                                        width: "150px",
                                        cursor: "pointer",
                                      }}
                                      Imageurl={image}
                                      className={styles.chatMessage}
                                    />
                                  )}

                                  <span>
                                    {TextOrLink(text)}
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        paddingTop: "1rem",
                                        color: "rgb(182, 181, 181)",
                                      }}
                                    >
                                      {moment(updatedAt).format("h:mm a")}{" "}
                                    </span>
                                  </span>
                                </>
                              }
                            </div>
                          ) : (
                            <div className={styles.sendermessage}>
                              {
                                <>
                                  {type === "file" && (
                                    <a
                                      href={image}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <div className={styles.fileDownload}>
                                        <span>
                                          {fileName?.length > 28
                                            ? `....${fileName?.slice(-28)}`
                                            : fileName}
                                        </span>
                                        <FaFileDownload
                                          className={styles.icon}
                                        />
                                      </div>
                                    </a>
                                  )}
                                  {msg?.type === "image" && (
                                    <FullScreenView
                                      style={{
                                        width: "150px",
                                        cursor: "pointer",
                                      }}
                                      Imageurl={image}
                                      className={styles.chatMessage}
                                    />
                                  )}

                                  <span>
                                    {TextOrLink(text)}
                                    {/* <br /> */}
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: "rgb(182, 181, 181)",
                                      }}
                                    >
                                      {moment(updatedAt).format("h:mm a")}{" "}
                                    </span>
                                  </span>
                                </>
                              }
                            </div>
                          )}
                          <div ref={chatRef}> </div>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
              <div className={styles.sendmessage}>
                <form className={styles.typemessage} onSubmit={onSend}>
                  <div className={styles.type}>
                    <input
                      type="text"
                      placeholder="Type Something.."
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                    />
                  </div>
                  <div className={styles.choosefile}>
                    <label for="select">
                      {messageLoader ? (
                        <div className={styles.loader}>
                          <Wave />
                        </div>
                      ) : (
                        <img src={AttachIcon} alt="select-file" />
                      )}
                    </label>
                    <input
                      type="file"
                      multiple
                      accept="image/png, image/gif, image/jpeg, .pdf, .txt, .ppt, .pptx, .doc, .docx, .xls, .xlxs, .xlsx"
                      id="select"
                      onChange={(e) => handleImg(e)}
                    />
                  </div>
                  {messageText ? (
                    <button
                      className={styles.send}
                      type="submit"
                      onClick={onSend}
                    >
                      <img src={Sendicon} alt="send-icon" />
                    </button>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default PrivateChat;
