import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import BackTo from "../../../../../../Assets/icons/backk.svg";
import { saveTokenHistory } from "../../../../../../redux/action/currencyDetailAction";
import { HTTP_CLIENT } from "../../../../../../utils/services";
import DropDown from "../../../../../_Common/DropDown/dropDown";
import Modal from "../../../../../_Common/Modal/Modal";
import SendTokenHistory from "../../../../../_Common/RecentTransactionHistory/sendToken";
import stylesmodal from "../../../../../_Common/checkChangePasswordModal/openaccount.module.scss";
import Input from "../../../../../_Common/input/input";
import { WaveClone } from "../../../../../_Common/loader/Loader";
import styles from "./QTokens.module.scss";
const AssetsTokens = ({ history }) => {
  const alert = useAlert();
  const [getValue, setGetValue] = useState();

  const [filterQData, setfilterQData] = useState("");
  const [Data, setData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const currencySelector = useSelector((state) => state.currency);
  const options = filterQData;
  const [selected, setSelected] = useState(filterQData[0]);
  const [Active, setActive] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let filterLiquidity = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "liquidity"
    );
    let filterAssets = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "asset"
    );
    let filterSdira = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "sdira"
    );
    if (location?.state?.detail?.type === "liquidity") {
      setfilterQData(filterLiquidity);
      setSelected(filterLiquidity[0]);
      formik.setFieldValue("coinSymbol", filterLiquidity[0]);
    } else if (location?.state?.detail?.type === "sdira") {
      setfilterQData(filterSdira);
      setSelected(filterSdira[0]);
      formik.setFieldValue("coinSymbol", filterSdira[0]);
    } else {
      setfilterQData(filterAssets);
      setSelected(filterAssets[0]);
      formik.setFieldValue("coinSymbol", filterAssets[0]);
    }
    // setfilterQData(filterQSymbol);
    // setSelected(filterQSymbol[0]);
    // formik.setFieldValue("coinSymbol", filterQSymbol[0]);
    // setSelected(flterData);
  }, []);
  const formikSchema = yup.object({
    to: yup
      .string()
      .email("Email is not valid")
      .required("Email is required")
      .trim(),
    amount: yup
      .number()
      .required("Amount is required")
      .min(0.00000000000001, "Invalid amount")
      .max(100000000, "max 8 number required")
      .test(
        "maxDigitsAfterDecimal",
        "number field must have 8 digits after decimal or less",
        (number) => /^\d+(\.\d{0,8})?$/.test(number)
      ),
  });

  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      to: "",
      amount: "",
      memo: "",
    },
    onSubmit: async (values, res) => {
      let data = {
        to: String(values?.to)?.trim(),
        amount: String(values.amount),
        coin: selected?.coin ? selected?.coin : "",
        memo: values.memo,
      };

      if (data) {
        setData(data);
        setOpenModal(true);
      }
    },
  });
  const handlePostData = async () => {
    try {
      setLoading(true);
      if (location?.state?.detail?.type === "sdira") {
        let response = await HTTP_CLIENT.post(
          `/request/create-legacy-send-request`,
          Data
        );
        alert.show(response.data.message, { type: "success" });
        if (response) {
          const newData = {
            ...Data,
            tokenName: selected?.displayName
              ? selected?.displayName
              : selected?.name
              ? selected?.name
              : "",
            tokenImage: selected ? selected : "",
            date: Date.now(),
            // price: selected?.balance,
          };
          dispatch(saveTokenHistory(newData));
        }
        formik.resetForm();
        setLoading(false);
        setData("");
        setOpenModal(false);
      } else {
        let response = await HTTP_CLIENT.post(
          `native-wallet/send-tokens`,
          Data
        );
        alert.show(response.data.message, { type: "success" });
        if (response) {
          const newData = {
            ...Data,
            tokenName: selected?.displayName
              ? selected?.displayName
              : selected?.name
              ? selected?.name
              : "",
            tokenImage: selected ? selected : "",
            date: Date.now(),
            // price: selected?.balance,
          };
          dispatch(saveTokenHistory(newData));
        }
        formik.resetForm();
        setLoading(false);
        setData("");
        setOpenModal(false);
      }

      history.push("/cryptocurrency");
    } catch (err) {
      alert.show(err.response.data.message, { type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    setGetValue(
      (Number(formik.values?.amount) * Number(selected?.price)).toFixed(2)
    );
  }, [formik.values?.amount, selected]);

  const HandlerecentHistory = (item) => {
    formik.setFieldValue("to", item?.to);
    formik.setFieldValue("amount", item?.amount);
    formik.setFieldValue("memo", item?.memo);
    setTimeout(() => {
      setGetValue(
        (Number(item?.amount) * Number(item?.tokenImage?.price)).toFixed(2)
      );
    }, 0);
    setSelected({
      name: "Select Token",
      icon: "",
      // price: item?.tokenImage?.price,
      balance: item?.price,
      // coin: selected?.coin,
    });
    setActive(0);
  };
  return (
    <>
      <Modal visible={openModal} onClose={() => setOpenModal(false)} btn>
        <div className={stylesmodal.container}>
          <div className={stylesmodal.textsection}>
            <label>Are you sure you want to send the tokens?</label>
          </div>
          <div className={stylesmodal.btnsection}>
            {loading ? (
              <button className={stylesmodal.Delete} disabled>
                <WaveClone />
              </button>
            ) : (
              <button
                className={stylesmodal.Delete}
                type="submit"
                onClick={handlePostData}
              >
                Confirm
              </button>
            )}
            <button
              disabled={loading ? true : false}
              className={stylesmodal.Delete}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        {/* <div className={styles.btnWrapper}>
          <button
            className={styles.btn}
            onClick={() => history.push("/token-history")}
          >
            Token History
          </button>
        </div> */}

        <div className={styles.wrapper}>
          <div className={styles.cardWrapper}>
            <img
              src={BackTo}
              alt="back"
              className={styles.backTo}
              onClick={() => history.goBack()}
            />
            <div className={styles.heading}>
              <div className={styles.headingWrapper}>
                {" "}
                Send{" "}
                {location?.state?.detail?.shortName
                  ? location?.state?.detail?.shortName
                  : location?.state?.detail?.name}
              </div>
              {/* <div className={styles.history}>
                <MdHistory
                  className={styles.icon}
                  onClick={() => setActive(1)}
                />
              </div> */}
            </div>
            <div className={styles.formWrapper}>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.borderRight}>
                  <div>
                    <Input
                      name="Email *"
                      inputName="Email"
                      type="text"
                      config={formik.getFieldProps("to")}
                    />
                    {formik.errors.to && formik.touched.to ? (
                      <div style={{ color: "#E5516B" }}>{formik.errors.to}</div>
                    ) : null}
                  </div>
                  <div>
                    <DropDown
                      padding="13px 20px"
                      selected={selected}
                      setSelected={setSelected}
                      options={options}
                      imgShow={true}
                      fixed={selected?.decimals}
                      name="Token"
                      img={true}
                      BalanceStatus
                      Balance={selected?.balance}
                    />
                  </div>
                  <div>
                    <Input
                      name="Amount *"
                      inputName="Amount"
                      type="number"
                      imgQ={true}
                      config={formik.getFieldProps("amount")}
                    />
                    {formik.errors.amount && formik.touched.amount ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.amount}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.inputWrapper}>
                    <p>Value in USD</p>
                    <input
                      name="Token"
                      type="number"
                      placeholder="0"
                      value={String(getValue)}
                      disabled
                    />
                  </div>
                  <Input
                    name="Memo / Endorsement"
                    inputName="Memo / Endorsement"
                    type="text"
                    config={formik.getFieldProps("memo")}
                  />
                </div>
                <button type="submit" className={styles.btn}>
                  Send Now
                </button>
              </form>
            </div>
          </div>
          <div className={styles.historyWrapper}>
            <SendTokenHistory HandlerecentHistory={HandlerecentHistory} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetsTokens;
