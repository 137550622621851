import React, { useContext, useEffect, useRef, useState } from "react";
import AttachIcon from "../../../../../Assets/image/attachment-attach.png";
import Sendicon from "../../../../../Assets/image/send-icon.svg";
// import FullScreenView from "../../../_Common/Full Screen View/FullScreenView";
import moment from "moment";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import chatGif from "../../../../../Assets/gif/chatgip.gif";
import { SocketContext } from "../../../../../Context/Socket";
import { getChatRoomMessages } from "../../../../../services/chatService";
import { SINGLE_CHAT_EMIT_EVENTS } from "../../../../../utils/ChatConstants";
import { HTTP_CLIENT } from "../../../../../utils/services";
import FullScreenView from "../../../../_Common/Full Screen View/FullScreenView";
import { transformMessageAfterReceiving } from "../Helpers";
import useChatRoomSockets from "../hooks/useChatRoomSockets";
import styles from "./chatMessage.module.scss";
import Dummy from "../../../../../Assets/icons/dummy.png";
import { BarWave } from "react-cssfx-loading/lib";
import { Wave } from "../../../../_Common/loader/Loader";
import { FaFileDownload } from "react-icons/fa";
const ChatMessage = ({ userData, messages, setMessages }) => {
  const userInfo = useSelector((state) => state?.user?.userInfo);

  const [messageText, setMessageText] = useState("");
  const [page, setPage] = useState(1);
  const [loadingImg, setLoadingimg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [hasEndReached, setHasEndReached] = useState(false);
  const bottomRef = useRef(null);

  const dispatch = useDispatch();
  const alert = useAlert();
  const socket = useContext(SocketContext);

  const handleEndReached = async () => {
    setMessages("");
    setLoading(true);
    const response = await getChatRoomMessages(
      userData?.chatRoomId,
      page,
      1000
    );

    const data = response.data?.data?.data;
    if (data?.length > 0) {
      const m = data.map((e) => transformMessageAfterReceiving(e));
      setMessages(m);
      // setPage(page + 1);
      setLoading(false);
    } else {
      setHasEndReached(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleEndReached();
  }, [userData?.chatRoomId]);

  const otherId = userData?.members?.find((item) => item._id !== userInfo?._id);
  // Img upload

  const handleImg = async (e) => {
    setLoadingimg(true);
    if (
      !(
        e.target.files[0]?.type == "image/jpeg" ||
        e.target.files[0]?.type == "image/jpg" ||
        e.target.files[0]?.type == "image/png" ||
        e.target.files[0]?.type == "application/pdf" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        e.target.files[0]?.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      alert.show("Please select image only", { type: "error" });
      setLoadingimg(false);

      return;
    }
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "chat");
    let data = formData;
    let authToken = localStorage.getItem("authToken");
    let res = await HTTP_CLIENT.post("storage/upload", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    socket.emit(
      SINGLE_CHAT_EMIT_EVENTS.ADD_USER_MESSAGE,
      {
        text: "",
        user: userInfo?._id,
        recipient: otherId?._id,
        chatRoom: userData?.chatRoomId,
        fileName: e.target.files[0]?.name,

        type:
          e.target.files[0]?.type === "application/pdf" ||
          e.target.files[0]?.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          e.target.files[0]?.type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          e.target.files[0]?.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ? "file"
            : "image",
        status: "delivered",
        attachment: res?.data?.url,
      },
      (res) => {}
    );
    setLoadingimg(false);
  };

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);
    if (!isOnline) {
      alert.show("Please check your internet connection", {
        type: "error",
      });
      setLoadingimg(false);
    }
    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);
  // chat integration//

  const emitMessage = (e) => {
    e.preventDefault();
    const data = {
      text: messageText,
      user: userInfo?._id,
      recipient: otherId?._id,
      chatRoom: userData?.chatRoomId,

      type: "text",
      status: "delivered",
    };

    socket.emit(SINGLE_CHAT_EMIT_EVENTS.ADD_USER_MESSAGE, data, (res) => {});

    setMessageText("");
  };

  useEffect(() => {
    if (bottomRef.current) {
      setTimeout(() => {
        bottomRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 500);
    }
  }, [messages?.length, userData?.chatRoomId]);

  const reversed =
    messages.length !== 0 ? [...messages]?.reverse() : [...messages];
  return (
    <>
      {userData?.chatRoomId ? (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.chatView}>
              <div className={styles.profile}>
                <div className={styles.left}>
                  <div className={styles.profilepic}>
                    <img
                      src={
                        otherId?.profilePicture
                          ? otherId?.profilePicture
                          : Dummy
                      }
                      alt="Profile-pic"
                    />
                  </div>
                  <div className={styles.infoWraper}>
                    <label>{otherId?.name}</label>

                    {/* <p>{otherId?.email}</p> */}
                  </div>
                </div>
              </div>
              <div className={styles.messagelist}>
                <div className={styles.messages}>
                  {loading ? (
                    <div className={styles.loader}>
                      <Wave />
                    </div>
                  ) : !messages.length ? (
                    <div>
                      <img
                        style={{ width: "150px", height: "150px" }}
                        src={chatGif}
                        alt=""
                      />
                    </div>
                  ) : (
                    reversed?.map((msg, index) => {
                      const isUserMessage = userInfo?._id !== msg?.author?.id;
                      return (
                        <>
                          <div className={styles.currentday} key={index}>
                            <span> {moment(msg?.updatedAt).format("ll")}</span>
                          </div>
                          {isUserMessage ? (
                            <div className={styles.receivermessage}>
                              {
                                <>
                                  {msg?.type === "file" && (
                                    <>
                                      <a
                                        href={msg?.uri}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <div className={styles.fileDownload}>
                                          <p>
                                            {msg?.fileName?.length > 28
                                              ? `....${msg?.fileName?.slice(
                                                  -28
                                                )}`
                                              : msg?.fileName}
                                          </p>
                                          <FaFileDownload />
                                        </div>
                                      </a>
                                    </>
                                  )}
                                  {msg?.type === "image" && (
                                    <FullScreenView
                                      style={{
                                        width: "150px",
                                        cursor: "pointer",
                                      }}
                                      Imageurl={msg?.uri}
                                      className={styles.chatMessage}
                                    />
                                  )}
                                  <p> {msg?.text}</p>

                                  <span
                                    style={{
                                      fontSize: "10px",
                                      paddingTop: "1rem",
                                      color: "rgb(182, 181, 181)",
                                    }}
                                  >
                                    {moment(msg?.updatedAt).format("h:mm a")}{" "}
                                  </span>
                                </>
                              }
                            </div>
                          ) : (
                            <div className={styles.sendermessage}>
                              {msg?.type === "file" && (
                                <>
                                  <a
                                    href={msg?.uri}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div className={styles.fileDownload}>
                                      <p>
                                        {" "}
                                        {msg?.fileName?.length > 28
                                          ? `....${msg?.fileName?.slice(-28)}`
                                          : msg?.fileName}
                                      </p>
                                      <FaFileDownload className={styles.icon} />
                                    </div>
                                  </a>
                                </>
                              )}
                              {msg?.type === "image" && (
                                <FullScreenView
                                  style={{
                                    width: "150px",
                                    cursor: "pointer",
                                  }}
                                  Imageurl={msg?.uri}
                                  className={styles.chatMessage}
                                />
                              )}
                              <p> {msg?.text}</p>
                              {/* <br /> */}
                              <span
                                style={{
                                  fontSize: "10px",
                                  color: "rgb(182, 181, 181)",
                                }}
                              >
                                {moment(msg?.updatedAt).format("h:mm a")}
                              </span>
                            </div>
                          )}
                        </>
                      );
                    })
                  )}
                  <div ref={bottomRef}> </div>
                </div>
              </div>
              <div className={styles.sendmessage}>
                <form
                  className={styles.typemessage}
                  onSubmit={(e) => emitMessage(e)}
                >
                  <div className={styles.type}>
                    <input
                      type="text"
                      placeholder="Type Your Message Here....."
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                    />
                  </div>
                  {loadingImg ? (
                    <div className={styles.loader}>
                      <Wave />
                    </div>
                  ) : (
                    <div className={styles.choosefile}>
                      <label for="select">
                        <img src={AttachIcon} alt="select-file" />
                      </label>
                      <input
                        type="file"
                        multiple
                        onChange={(e) => handleImg(e)}
                        accept="image/png, image/gif, image/jpeg, .pdf, .txt, .ppt, .pptx, .doc, .docx, .xls, .xlxs, .xlsx"
                        id="select"
                      />
                    </div>
                  )}
                  {messageText ? (
                    <button type="submit" className={styles.send}>
                      <img src={Sendicon} alt="send-icon" />
                    </button>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BarWave />
      )}
    </>
  );
};

export default ChatMessage;
