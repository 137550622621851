import React, { useEffect, useState } from "react";
import styles from "./assetsSwap.module.scss";
import right from "../../../../Assets/icons/Next.svg";
import SwapImg from "../../../../Assets/icons/swapImg.svg";
import Modal from "../../../_Common/Modal/Modal";
import FiatModal from "../SwapModal/ModalStyle";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Wave } from "../../loader/Loader";
import {
  getAssetsToAssetsSawap,
  assetsTokenSwap,
  tokenCleanUp,
} from "../../../../redux/action/sawapAction";
import tokenSwapValidate from "../../../../services/swapServices";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { UserTypes } from "../../../../utils/AppConstants";
import BackTo from "../../../../Assets/icons/backk.svg";
import { useLocation } from "react-router-dom";
import LegacyModalStyle from "../legacySwapModal";
import { CloudLightning } from "react-feather";

function AssetsSwap({
  // topHeading,
  // TopImg,
  // TopP,
  topLabel,
  bottomHeading,
  // BottomImg,
  // bottomP,
  bottomLabel,
  // assetsHistoryUrl,
}) {
  const [popupVisible, setPopupVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [wallet, setWallets] = useState([]);
  const history = useHistory();
  const [nativewallet, setNativeWallets] = useState([]);
  const currencySelector = useSelector((state) => state.currency);
  const sawapSelector = useSelector((state) => state.sawap);
  const checkUser = useSelector((state) => state?.user?.userInfo);
  const dispatch = useDispatch();
  const [from, setFrom] = useState(
    currencySelector?.currencyInfo?.nativeWallets[0]
  );

  const [to, setTo] = useState(
    currencySelector?.currencyInfo?.nativeWallets[1]
  );
  const [amount, setAmount] = useState("");
  const [active, setActive] = useState(0);
  const [recieveAmount, setRecieveAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [swap, setSwap] = useState("swap");

  const alert = useAlert();
  const location = useLocation();
  useEffect(() => {
    setWallets(currencySelector?.currencyInfo?.nativeWallets);
    setNativeWallets(currencySelector?.currencyInfo?.nativeWallets);
  }, []);
  //----------get coin rate from api----------

  useEffect(() => {
    if (Number(amount) < 0) {
      alert.show("Invalid amount", { type: "error" });
      return;
    }

    let data = { fromId: from?._id, from, amount: "1", to };
    dispatch(getAssetsToAssetsSawap(data, swap));
  }, [to, from, amount]);

  //---------set coin amount agaist second coin---------
  useEffect(() => {
    setRecieveAmount((amount * sawapSelector.assetSwaoInfo).toFixed(3));
  }, [sawapSelector.assetSwaoInfo]);

  useEffect(() => {
    let sawap = (Number(amount) * Number(sawapSelector.assetSwaoInfo)).toFixed(
      3
    );
    setRecieveAmount(sawap);
  }, [amount]);

  const handleChangeAmount = (e) => {
    setActive(0);
    let { name, value } = e.target;
    let lenght = value.toString().split(".")[1]?.length;
    if (lenght > 8) {
      return;
    } else {
      setAmount(value);
    }
  };

  const handleHalf = () => {
    setActive(2);
    setAmount(String(from?.balance / 2));
  };
  const handleMax = () => {
    setActive(3);
    setAmount(String(from?.balance));
  };
  const hanldeQuarter = () => {
    setActive(1);
    setAmount(String(from?.balance / 4));
  };

  const handleCoinChange = (data) => {
    if (title === 2) {
      setTo(data);
      setPopupVisible((prev) => !prev);
    }
    if (title === 1) {
      // const filterData = currencySelector?.currencyInfo?.nativeWallets.filter(
      //   (m) => m.coinSymbol != data.coinSymbol
      // );
      // setNativeWallets(filterData);
      setFrom(data);
      setPopupVisible((prev) => !prev);
    }
  };

  const togglePopup = (e) => {
    if (e === "To") {
      setTitle(1);
    } else if (e === "From") {
      setTitle(2);
    }
    setPopupVisible((prev) => !prev);
  };

  //---------handle swap success case-------------
  useEffect(() => {
    if (
      sawapSelector.assetsTokenSuccess === "Swap successfully done" ||
      sawapSelector.assetsTokenSuccess ===
        "Legacy swap request received successfully. Please wait for admin response for this request."
    ) {
      if (checkUser.type != UserTypes.CQR_USER) {
        alert.show("Swap request send to admin", { type: "success" });
      } else {
        alert.show(sawapSelector.assetsTokenSuccess, { type: "success" });
      }
    } else if (sawapSelector.assetsTokenError) {
      alert.show(sawapSelector.assetsTokenError, { type: "error" });
    }
    dispatch(tokenCleanUp());
  }, [sawapSelector.assetsTokenError, sawapSelector.assetsTokenSuccess]);

  //------------handle coin swap---------------
  const handleSubmit = async () => {
    let data = { from, amount, to };

    // return;
    if (Number(from?.balance) === 0 || Number(from?.balance) < 0) {
      alert.show("Insufficient balance ", { type: "error" });
      return;
    }
    if (!amount || Number(amount) < 0 || Number(amount) == 0) {
      alert.show("Please enter valid amount", { type: "error" });
      return;
    }
    // let silverToken = currencySelector?.currencyInfo?.nativeWallets?.find(
    //   (item) => item.shortName === "ICO Ag"
    // );

    // if (
    //   to?.shortName === silverToken?.shortName &&
    //   Number(silverToken?.balance) < 21 &&
    //   Number(recieveAmount) < 21
    // ) {
    //   alert.error(
    //     "Please acquire a minimum value of 21 ICO Ag Tokens or more."
    //   );
    //   return;
    // }
    // if (
    //   to?.shortName !== silverToken?.shortName &&
    //   Number(silverToken?.balance) < 21
    // ) {
    //   alert.error(
    //     "Please acquire a minimum value of 21 ICO Ag Tokens or more."
    //   );
    //   return;
    // }
    setLoading(true);
    try {
      const res = await tokenSwapValidate();
      if (!res?.data?.status) {
        alert.error(res?.data?.message);
        setLoading(false);
        return;
      }
      await dispatch(assetsTokenSwap(data));
      setLoading(false);

      history.push("/cryptocurrency");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal visible={popupVisible} btn onClose={() => setPopupVisible(false)}>
        {title === 2 ? (
          <LegacyModalStyle
            title={title}
            wallet={wallet}
            nativewallet={nativewallet}
            handleCoinChange={handleCoinChange}
          />
        ) : (
          <FiatModal
            title={title}
            wallet={wallet}
            nativewallet={nativewallet}
            handleCoinChange={handleCoinChange}
          />
        )}
      </Modal>

      <div className={styles.container}>
        <div className={styles.topWrapper}>
          <img src={BackTo} alt="" onClick={() => history.goBack()} />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.textWrapper}>
            <div className={styles.heading}> Token Swap</div>
            <p>
              1 {from?.displayName} ≈ {sawapSelector?.assetSwaoInfo?.toFixed(3)}{" "}
              {to?.displayName}
            </p>
          </div>
          {sawapSelector.assetsTokenLoading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.swapSection}>
                <div className={styles.leftSection}>
                  <div className={styles.headings}>
                    <div className={styles.left}>
                      {/* let fromBalance =; */}

                      <div className={styles.imgWraper}>
                        <p>I have</p>
                        <img src={from?.displaySymbol} width={20} />
                        <label>
                          {parseFloat(from?.balance)?.toFixed(from?.decimals)}
                        </label>
                      </div>
                      <div
                        className={styles.coinSection}
                        onClick={() => togglePopup("To")}
                      >
                        <img
                          className={styles.btcImg}
                          src={from?.icon?.url}
                          alt=""
                        />
                        <p>{from?.shortName}</p>
                        <img className={styles.rightImg} src={right} alt="" />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <label>
                        $
                        {parseFloat(
                          from?.price
                            ? from?.balance * from?.price
                            : from?.USD === null
                            ? 0
                            : from?.USD
                        ).toFixed(from?.decimals)}
                      </label>
                      {/* <p>ppp</p> */}
                    </div>
                  </div>
                  <div className={styles.btns}>
                    <button
                      onClick={hanldeQuarter}
                      className={`${
                        active === 1
                          ? styles.Activebutton
                          : styles.notActivebutton
                      }`}
                    >
                      Quarter{" "}
                    </button>
                    <button
                      onClick={handleHalf}
                      className={
                        active === 2
                          ? styles.Activebutton
                          : styles.notActivebutton
                      }
                    >
                      Half
                    </button>
                    <button
                      onClick={handleMax}
                      className={
                        active === 3
                          ? styles.Activebutton
                          : styles.notActivebutton
                      }
                    >
                      Max
                    </button>
                  </div>
                  <div className={styles.inputHeadings}>
                    <div className={styles.inputWrapper}>
                      <input
                        type="number"
                        placeholder="Swap Amount"
                        onChange={(e) => handleChangeAmount(e)}
                        value={amount}
                      />
                      <div className={styles.inputHeadings}>
                        <label>{topLabel}</label>
                        {/* <p>$2,018.95</p> */}
                      </div>
                    </div>
                    {/* <p style={{ marginTop: "5px" }}>
                    Estimated balance {parseFloat(from?.balance).toFixed(3)}
                  </p> */}
                  </div>
                </div>
                <div className={styles.centerSection}>
                  <div className={styles.borderClass}></div>
                  <img style={{ cursor: "pointer" }} src={SwapImg} alt="" />
                  <div className={styles.borderClass}></div>
                </div>
                <div className={styles.rightSection}>
                  <div className={styles.headings}>
                    <div className={styles.left}>
                      <label>{bottomHeading}</label>
                      <div
                        className={styles.coinSection}
                        onClick={() => togglePopup("From")}
                      >
                        <img
                          className={styles.ETHImg}
                          src={to?.icon?.url}
                          alt=""
                        />
                        <p>{to?.shortName}</p>
                        <img className={styles.rightImg} src={right} alt="" />
                      </div>
                    </div>
                    <div className={styles.right}>
                      {/* <label>
                        $
                        {parseFloat(to?.balance * to?.price).toFixed(
                          to?.decimals
                        )}
                      </label> */}
                      {/* <p>18.055153691</p> */}
                    </div>
                  </div>
                  <div className={styles.inputWrapper}>
                    <input
                      type="number"
                      placeholder="Receive Amount"
                      value={recieveAmount}
                      // onChange={(e) => handleChangeRecieveAmount(e)}
                    />
                    <div className={styles.inputHeadings}>
                      <label>{bottomLabel}</label>
                      {/* <p>$2,018.95</p> */}
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <button className={styles.SwapButton}>
                  <Wave />
                </button>
              ) : (
                <button className={styles.SwapButton} onClick={handleSubmit}>
                  Swap
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AssetsSwap;
