import React from "react";
import styles from "./cryptoTotal.module.scss";
import Logo from "../../../../../../../Assets/image/logo dark.svg";

const CryptoTotal = ({ location }) => {
  function numberWithCommas(n) {
    var parts = n ? n?.toString()?.split(".") : "";
    return (
      parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : ".00")
    );
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgWrapper}>
        <img
          src={
            location?.state?.detail?.displaySymbol
              ? location?.state?.detail?.displaySymbol
              : location?.state?.detail?.icon?.url
          }
          alt="logo"
        />
        <span>
          {location?.state?.detail?.displayName
            ? location?.state?.detail?.displayName
            : location?.state?.detail?.name}
        </span>
      </div>
      <div className={styles.imgWraaper}>
        <div>
          <span>
            {location?.state?.detail?.blockchain
              ? numberWithCommas(
                  Number(location?.state?.detail?.balance).toFixed(5)
                )
              : numberWithCommas(
                  Number(location?.state?.detail?.balance).toFixed(
                    location?.state?.detail?.decimals
                  )
                )}
          </span>
          <label>
            ${" "}
            {location?.state?.detail?.blockchain
              ? numberWithCommas(
                  Number(location?.state?.detail?.USD).toFixed(5)
                )
              : numberWithCommas(
                  Number(location?.state?.detail?.USD).toFixed(
                    location?.state?.detail?.decimals
                  )
                )}
          </label>
        </div>
        {location?.state?.detail?.displaySymbol ? (
          <img src={location?.state?.detail?.icon?.url} alt="logo" />
        ) : (
          ""
        )}
        <img src={Logo} className={styles.darklogo} alt="logo" />
      </div>
    </div>
  );
};

export default CryptoTotal;
