import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveFcmToken } from "./redux/action/userAction";
import { store } from "./redux/store";

var firebaseConfig = {
  apiKey: "AIzaSyDU3exbKlPVJau5EV1wqzyAC00OjiDrghI",
  authDomain: "cqr-vault.firebaseapp.com",
  databaseURL: "https://cqr-vault-default-rtdb.firebaseio.com",
  projectId: "cqr-vault",
  storageBucket: "cqr-vault.appspot.com",
  messagingSenderId: "185655577801",
  appId: "1:185655577801:web:245020c55c662f2bb39118",
  measurementId: "G-G0BB1S055C",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getTokenFirebase = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BEp9pJCAY3lTLVWzh9UGEUav3UzXTaF7Hcl0k3LIXLfrSYm53IeBtkMxGL825LHNMEMc2BFjvh_ZcoWwmb4WpMM",
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(currentToken);
        store.dispatch(saveFcmToken(currentToken));
      } else {
        setTokenFound(currentToken);
      }
    })
    .catch((err) => {
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
