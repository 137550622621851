import React from "react";
import MarketPlaceCard from "./MarketplaceCard";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

const Marketplace = () => {
  const history = useHistory();
  const alert = useAlert();
  const userSelector = useSelector((state) => state.currency);
  const userKyc = useSelector((state) => state?.user);

  useEffect(() => {
    if (
      !userKyc?.savedFormData?._id &&
      !userKyc?.kyc?._id &&
      !userSelector?.currencyInfo?.gpaData?._id
    ) {
      history.push("/profile");
      alert.show("Please fill your GPA data first", {
        type: "error",
      });
    }
  }, []);

  return (
    <div>
      <MarketPlaceCard />
    </div>
  );
};

export default Marketplace;
