import React, { useEffect } from "react";
import Form from "./form/form";
import RightSide from "./rightSide/rightSide";
import Styles from "./contact.module.scss";
import Aos from "aos";
import Woman from "../../../../Assets/image/business-woman.png";

function Contact() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <div className={Styles.heading}>
        Contact <span>Us</span>
      </div>

      <div className={Styles.container}>
        <div className={Styles.Wrapper}>
          <div className={Styles.secCard}>
            {/* <img src={Woman}/> */}
            <RightSide />
          </div>
          <div className={Styles.firstCard}>
            <Form />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
