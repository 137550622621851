import {
  SUBSCRIPTION_DATA,
  SUBSCRIPTION_CLEANUP,
  YOUR_SUBSCRIPTION,
  SUBSCRIPTION_STATUS,
} from "../types";

const initialState = {
  subscriptionData: null,
  yourSubscrption: null,
  subscriptionStatus: [],
};

const subscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_DATA:
      return { ...state, subscriptionData: action.payload };
    case YOUR_SUBSCRIPTION:
      return { ...state, yourSubscrption: action.payload };
    case SUBSCRIPTION_STATUS:
      return { ...state, subscriptionStatus: action.payload };
    case SUBSCRIPTION_CLEANUP:
      return {
        ...state,
        yourSubscrption: null,
        SUBSCRIPTION_DATA: null,
        subscriptionStatus: [],
      };
    default:
      return state;
  }
};
export default subscriptionsReducer;
