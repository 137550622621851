import React, { useState, useEffect, useRef } from "react";
import styles from "./dropDown.module.scss";
// import img from "../../../assets/images/SwapDown.svg";
import Down from "../../../Assets/icons/down.svg";
import { BsExclamationCircleFill } from "react-icons/bs";
const DropDown = ({
  selected,
  grow,
  imgCqr,
  fixed,
  setSelected,
  options,
  showRightIcon,
  padding,
  color,
  imgShow,
  img,
  name,
  imgStyle,
  exclamationMark,
  tooltopStatus,
  BalanceStatus,
  Balance,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", onClickOutSide);
    return () => {
      document.removeEventListener("click", onClickOutSide);
    };
  }, []);

  const onClickOutSide = (event) => {
    const isClickInsideElement = dropRef?.current?.contains(event.target);
    if (!isClickInsideElement) {
      setIsActive(false);
    }
  };

  return (
    <div
      className={styles.dropdown}
      ref={dropRef}
      style={{ width: grow && grow }}
    >
      <p>
        {name} &nbsp;{" "}
        {exclamationMark && (
          <span>
            <BsExclamationCircleFill className={styles.exclamationMark} />
            {tooltopStatus && (
              <div className={styles.status}>{tooltopStatus}</div>
            )}
          </span>
        )}
      </p>
      <div
        className={styles.dropdownbtn}
        onClick={(e) => setIsActive(!isActive)}
        style={{
          padding: padding ? `${padding}` : "",
          background: `${color?.length ? color : ""}`,
        }}
      >
        {img && (
          <div className={styles.imgContainer}>
            <img
              className={styles.leftIcon}
              src={
                selected?.icon?.url
                  ? selected?.icon?.url
                  : selected?.imageSrc
                  ? `https://www.coinqvest.com/${selected?.imageSrc}`
                  : selected?.image
                  ? selected?.image
                  : null
              }
              alt=""
            />
          </div>
        )}

        <span>
          {selected?.accountNumber ? (
            selected?.accountNumber
          ) : selected?.displayName ? (
            <span>{selected?.displayName ? selected?.displayName : ""}</span>
          ) : (
            <span>{selected?.name ? selected?.name : ""}</span>
          )}
        </span>
        {imgShow && (
          <img className={styles.withRightIcon} src={Down} alt="SwapDown" />
        )}
      </div>

      {isActive && (
        <div
          className={`${styles.dropdowncontent} ${
            grow && styles.dropdowncontentPaddingZero
          }`}
        >
          {imgCqr
            ? options &&
              options?.map((option) => (
                <div
                  className={styles.dropdownImg}
                  onClick={(e) => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                >
                  <div className={styles.dropdownitems}>
                    {option?.accountNumber
                      ? option?.accountNumber
                      : option?.displayName
                      ? option?.displayName
                      : option?.name
                      ? option?.name
                      : option
                      ? option
                      : ""}
                  </div>
                </div>
              ))
            : options &&
              options?.map((option) => (
                <div
                  className={styles.dropdownImg}
                  onClick={(e) => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                >
                  <img
                    src={
                      option?.icon?.url
                        ? option?.icon?.url
                        : option?.imageSrc
                        ? `https://www.coinqvest.com/${option?.imageSrc}`
                        : option?.image
                        ? option?.image
                        : null
                    }
                    alt=""
                    style={imgStyle}
                  />
                  <div className={styles.dropdownitems}>
                    {option?.accountNumber
                      ? option?.accountNumber
                      : option?.displayName
                      ? option?.displayName
                      : option?.name
                      ? option?.name
                      : option
                      ? option
                      : ""}
                  </div>
                </div>
              ))}
        </div>
      )}
      {BalanceStatus && (
        <div className={styles.lastChild}>
          {Balance ? (
            <div className={styles.text}>
              Available Balance:
              <span>{Balance ? Number(Balance)?.toFixed(fixed) : "0"}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default DropDown;
