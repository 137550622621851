import React, { PureComponent, useMemo } from "react";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
import hidden from "../../../../src/Assets/image/hidden.svg";
import show from "../../../Assets/icons/showEye.svg";
import { Wave } from "../loader/Loader";
import styles from "./DashboardChart.module.scss";

// const noData = [
//   { name: "Group A", value: 400, fill: "#23fccf" },
//   { name: "Group A", value: 200, fill: "#7B8AE9" },
// ];

// export default class DashboardChart extends PureComponent {
//   render() {
//     return (
//       <div className={styles.wrapper}>
//         <ResponsiveContainer width={250} height={250}>
//           <PieChart onMouseEnter={this.onPieEnter}>
//             {noData.length > 0 ? (
//               <Pie
//                 data={noData}
//                 innerRadius={95}
//                 outerRadius={100}
//                 stroke="transparent"
//                 paddingAngle={0}
//                 dataKey="value"
//                 fill="#fff"
//               />
//             ) : (
//               <Pie
//                 data={noData}
//                 innerRadius={85}
//                 outerRadius={100}
//                 stroke="transparent"
//                 paddingAngle={5}
//                 dataKey="value"
//                 fill="#fff"
//               />
//             )}
//           </PieChart>
//         </ResponsiveContainer>
//         <div className={styles.portfolioInfo}>
//           <img src={hidden} alt="" onClick={this.props.toggleBalance} />
//           <div className={styles.heading}>Estimated Balance</div>
//           <div className={styles.totalWorth}>
//             {this.props.hideBalance(JSON.stringify(this.props.num))}
//           </div>
//           <div className={styles.totalAssets}>0.002356981 IC Au</div>
//         </div>
//       </div>
//     );
//   }
// }

function DashboardChart({
  toggleBalance,
  num,
  hideBalance,
  currency,
  isHideBalance,
}) {
  const noData = useMemo(() => {
    let newChartData = currency?.map((currency, index) => {
      let chartObject = {
        name: index,
        value:
          currency.price && currency?.marketData?.rate
            ? Number(currency.balance) *
              Number(
                currency.price ? currency.price : currency.marketData?.rate
              )
            : Number(currency.balance),
        fill: currency?.coinColor
          ? currency?.coinColor
          : currency?.nftToken?.coinColor,
        onPress: () => {},
      };
      return chartObject;
    });

    return newChartData;
  }, [currency]);

  function numberWithCommas(n) {
    var parts = n ? n.toString().split(".") : "";
    if (parts?.length > 0) {
      return (
        parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (parts[1] ? "." + parts[1] : "")
      );
    }
  }
  return (
    <div className={styles.wrapper}>
      <ResponsiveContainer width={250} height={250}>
        <PieChart>
          {noData?.length > 0 ? (
            <Pie
              data={noData}
              innerRadius={95}
              outerRadius={100}
              stroke="transparent"
              paddingAngle={0}
              dataKey="value"
              fill="#fff"
            />
          ) : (
            <Pie
              data={noData}
              innerRadius={85}
              outerRadius={100}
              stroke="transparent"
              paddingAngle={5}
              dataKey="value"
              fill="#fff"
            />
          )}
        </PieChart>
      </ResponsiveContainer>
      <div className={styles.portfolioInfo}>
        <img
          src={isHideBalance ? hidden : show}
          alt=""
          onClick={toggleBalance}
        />
        <div className={styles.heading}>Estimated Balance</div>
        {num ? (
          <div className={styles.totalWorth}>
            {isHideBalance ? "*" : "$"}{" "}
            {numberWithCommas(hideBalance(JSON.stringify(num)))}
          </div>
        ) : (
          numberWithCommas(hideBalance(JSON.stringify(0)))
        )}

        {/* <div className={styles.totalAssets}>0.002356981 IC Au</div> */}
      </div>
    </div>
  );
}

export default DashboardChart;
