import React, { useEffect } from "react";
import styles from "./Testimonial.module.scss";
import Aos from "aos";

function Testimonial() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.headingWrapper}>
          <div
            className={styles.heading}
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            SECURE YOUR WEALTH WITH CQR VAULT
          </div>
          <div
            className={styles.subHeading}
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1800"
          >
            See how CQR Vault is changing the game of private equity as a
            function of cash flow!
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
