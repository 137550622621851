import React from "react";
import { Route } from "react-router-dom";
// import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, ...rest }) => {
  // let auth = useSelector((state) => state.auth);
  let res = localStorage.getItem("authToken");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (false) {
          // return <Redirect to="/cryptocurrency" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PublicRoute;
