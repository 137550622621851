import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils/AppConstants";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import { saveFormData, userInfo } from "../../../redux/action/userAction";
import { sdiraUserCheckChangePassword } from "../../../redux/action/CreateAccountAction";
let useLogin = (onClose, OpenAccount) => {
  let [agree, setAgree] = useState({ isAgree: false, notAgreed: false });
  let [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [humanValidation, setHumanValidation] = useState("");
  const [show, setShow] = useState(false);
  const [changePasswordShow, setChangePasswordShow] = useState(false);
  let [state, setState] = useState({ submitting: false });
  let history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const fcmToken = useSelector((state) => state?.user?.fcmToken);
  let handleInputChange = (e) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));

    if (inputs.email.match(mailformat)) {
      setInputs((prev) => ({ ...prev, emailValidation: "" }));
    }
    if (inputs.password.length > 8 && inputs.password.length < 16) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "",
      }));
    }
    if (/^\S*$/.test(inputs.password)) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "",
      }));
    }
  };

  // --------Submit Login form data----------
  let handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    // if (!isValidated()) {
    //   setButtonLoading(false);

    //   return;
    // }
    let data = {
      email: inputs.email.toLowerCase(),
      password: inputs.password,
      fcmToken: fcmToken,
    };
    try {
      let res = await axios.post(`${BASE_URL}auth/login`, data);
      if (res.data.accessToken) {
        localStorage.setItem("authToken", res.data.accessToken);

        alert.show("User Logged In successfully", { type: "success" });

        let resr = await axios.get(`${BASE_URL}user/info`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${res.data.accessToken}`,
          },
        });
        if (resr?.data?.user?.isImported) {
          // dispatch(saveFormData(resr.data.gpaData));
          // dispatch(userInfo(resr.data.user));
          setChangePasswordShow(true);
          onClose();
          setButtonLoading(false);

          // history.push("/change-password");
          return;
        }
        setButtonLoading(false);

        if (resr.data.gpaData) {
          dispatch(saveFormData(resr.data.gpaData));
          dispatch(userInfo(resr.data.user));
          history.push("/cryptocurrency");
        } else {
          dispatch(userInfo(resr.data.user));
          OpenAccount();
        }
      }
    } catch (err) {
      if (err?.response?.data?.message === "blocked") {
        alert.show(`${err?.response?.data?.error}. Please Contact support`, {
          type: "error",
        });
      } else {
        alert.show(err?.response?.data?.message, {
          type: "error",
        });
      }
      setButtonLoading(false);
    }
  };

  // ----------Validate Login form--------------
  let isValidated = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let response = true;
    if (!inputs.email.match(mailformat)) {
      response = false;
      setInputs((prev) => ({ ...prev, emailValidation: "Email is invalid" }));
    } else {
      setInputs((prev) => ({ ...prev, emailValidation: "" }));
    }
    if (!inputs.email.trim()) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        emailValidation: "Email cannot be empty",
      }));
    }
    if (!inputs.password.trim()) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password Field can't be empty.",
      }));
    }
    if (inputs?.password?.trim().length > 16 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be greater than 16.",
      }));
    }
    if (inputs?.password?.trim().length < 8 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be smaller than 8.",
      }));
    }
    if (!/^\S*$/.test(inputs.password)) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Empty space is not allowed.",
      }));
      // errors.password = "Empty space is not allowed.";
    }
    if (!humanValidation) {
      response = false;
      // setHumanValidation(true);
      setShow(true);
    }
    return response;
  };

  const closeChangePasswordModal = () => {
    setChangePasswordShow(false);
    // OpenAccount();
  };

  // ----------Validate google recaptcha--------
  const googlerecaptcha = (e) => {
    if (e) {
      setHumanValidation(true);
      setShow(false);
    } else {
      setShow(true);
    }
  };

  return {
    ...agree,
    setAgree,
    ...inputs,
    handleInputChange,
    handleSubmit,
    googlerecaptcha,
    humanValidation,
    show,
    ButtonLoading,
    changePasswordShow,
    closeChangePasswordModal,
    ...state,
  };
};

export default useLogin;
