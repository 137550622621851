import img from "../../../Assets/icons/white.png";

const DataTh = {
  heading: "Swap",
  buttons: [
    { name: "Crypto to CQR", linkChanged: true, img: img },
    { name: "Asset to Asset", seclinkchanged: true, img: img },
    // { name: "CQR Liquidity Tokens('Q')", link: "/vault-token", img: img },
    // { name: "CQR Asset Tokens ('Ag/Au')", link: "/cqr-liquidity-tokens" },
    { name: "CQR Vest", cqrlinkchanged: true, img: img, link: "/cqr-vest" },
    // { name: "Banking", disabled: true },
  ],
};
export default DataTh;
