import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { postCustomer } from "../../../../utils/customerService";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { userInfo } from "../../../../redux/action/userAction";
import { useHistory } from "react-router-dom";
const useCoinQvest = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.user?.userInfo);
  const formikSchema = yup.object({
    firstName: yup.string().trim().required("First Name is Required"),
    lastName: yup.string().trim().required("Last Name is Required"),
    // email: yup
    //   .string()
    //   .trim()
    //   .email("email is not valid")
    //   .required("email is Required"),
    Company: yup.string().trim().required("Company is Required"),
    AddressLine1: yup.string().trim().required("Address Line 1 is Required"),
    AddressLine2: yup.string().trim(),
    ZipCode: yup.string().trim().required("ZipCode is Required"),
    City: yup.string().trim().required("City is Required"),
    PhoneNumber: yup.string().trim().required("PhoneNumber is Required"),
    TaxId: yup.string().trim(),
    Country: yup.object().required("Country is Required"),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      // email: "",
      Company: "",
      AddressLine1: "",
      AddressLine2: "",
      ZipCode: "",
      City: "",
      PhoneNumber: "",
      TaxId: "",
      Country: "",
    },
    onSubmit: (values) => {
      handleSubmitData(values);
    },
  });
  const handleSubmitData = async (values) => {
    let data = {
      email: userData?.email,
      firstname: values.firstName,
      lastname: values.lastName,
      company: values.Company,
      adr1: values.AddressLine1,
      adr2: values.AddressLine2,
      zip: String(values.ZipCode),
      city: values.City,
      countrycode: values.Country?.code,
      phonenumber: String(values.PhoneNumber),
      taxid: values.TaxId,
    };
    try {
      setLoading(true);
      const res = await postCustomer(data);
      dispatch(userInfo(res?.data?.user));
      history.go(-2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.error(error?.response?.data?.message || "something error occurred");
    }
  };
  return {
    formik,
    loading,
  };
};

export default useCoinQvest;
