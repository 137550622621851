import React from "react";
import Marquee from "react-fast-marquee";
import User from "../Users/Users";
import style from "./Users.module.scss";
function Slider() {
  return (
    <>
      <div className={style.marqueWrapper}>
        <div className={style.container}>
          <div className={style.heading}>
            <p>Testimonials</p>
          </div>
          <div className={style.subHeading}>
            <p>
              Quidam officiis similique sea ei, vel tollit indoctum efficiendi
              ei, at nihil tantas platonem eos.
            </p>
          </div>
        </div>
        <Marquee pauseOnHover={true} speed={60} gradient={false}>
          <User />
        </Marquee>
      </div>
    </>
  );
}

export default Slider;
