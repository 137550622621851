import React from "react";
import Modal from "../Modal/Modal";
import InputField from "../../_Common/input/input";
import DropDown from "../DropDown/dropDown";
import SignaturePad from "react-signature-canvas";
import styles from "./remittanceAddress.module.scss";

const DetailModal = ({ visible, onClose, detailRemittance }) => {
  return (
    <>
      <Modal visible={visible} btn onClose={() => onClose()}>
        <form>
          <div className={styles.passwordmodal}>
            <div className={styles.heading}>
              <p>Banking Details</p>
            </div>
            <div className={styles.inputfields}>
              <InputField
                Name="name"
                value={detailRemittance?.name}
                inputName="Name"
                name="Name *"
                type="text"
                disable={true}
              />
              <InputField
                Name="email"
                value={detailRemittance?.email}
                inputName="Email"
                name="Email *"
                disable={true}
                type="text"
              />
              <InputField
                Name="street Address"
                value={detailRemittance?.streetAddress}
                inputName="Street Address"
                name="Street Address *"
                disable={true}
                type="text"
              />
              <InputField
                Name="city"
                value={detailRemittance?.city}
                inputName="City"
                disable={true}
                name="City *"
                type="text"
              />
              <InputField
                Name="state"
                value={detailRemittance?.state}
                inputName="State"
                disable={true}
                name="State *"
                type="text"
              />
              <InputField
                Name="zip code"
                value={detailRemittance?.zipCode}
                inputName="Zip code"
                name="Zip code *"
                disable={true}
                type="text"
                // config={formik.getFieldProps("zipCode")}
              />
              <InputField
                Name="Country"
                value={detailRemittance?.country}
                inputName="country"
                name="Country"
                disable={true}
                type="text"
              />
              {/* {formik.errors.zipCode && formik.touched.zipCode ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.zipCode}</div>
              ) : null} */}
              Your Signature *
              <div className={styles.signature}>
                <img
                  src={detailRemittance?.signatureUrl}
                  style={{ width: "300px" }}
                />
              </div>
            </div>
            <div className={styles.btn}></div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default DetailModal;
