import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import NoData from "../../../../../../../Assets/gif/noData.gif";
import trackBottom from "../../../../../../../Assets/icons/trackBottom.svg";
import trackTop from "../../../../../../../Assets/icons/trackTop.svg";
import { BASE_URL } from "../../../../../../../utils/AppConstants";
import { getTransactionData } from "../../../../../../../utils/helpers";
import { HTTP_CLIENT } from "../../../../../../../utils/services";
import { Wave } from "../../../../../../_Common/loader/Loader";
import styles from "./transaction.module.scss";

function Transactions({ opertunityToken, location }) {
  const [dataTotal, setDataTotal] = useState();
  const [transactions, setTransactions] = useState([]);

  const [loading, setLoading] = useState(true);

  const currentUser = useSelector((state) => state.user.userInfo);
  const alert = useAlert();

  //---------api call to get data-------//

  const getUserInfo = async () => {
    setLoading(true);

    const perPage = 30;
    const page = Math.ceil(transactions?.length / perPage) + 1;
    const params = {
      page: page,
      limit: perPage,
      type: "deposit",
      nftTokenId: opertunityToken?.state?.detail?.nftToken,
    };
    try {
      let resr = await HTTP_CLIENT.get(`${BASE_URL}transaction-log/user/logs`, {
        params,
      });

      setTransactions(resr?.data?.data);
      setDataTotal(resr?.data?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getUserInfoNative = async () => {
    setLoading(true);

    const perPage = 30;
    const page = Math.ceil(transactions?.length / perPage) + 1;
    const params = {
      page: page,
      limit: perPage,
      type: "deposit",
      coinId: opertunityToken?.state?.detail?.state?.detail?._id,
    };
    try {
      let resr = await HTTP_CLIENT.get(`${BASE_URL}transaction-log/user/logs`, {
        params,
      });

      setTransactions(resr?.data?.data);
      setDataTotal(resr?.data?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //----------------pagination on data API--------------------//

  const getUserInfoPagination = async () => {
    const perPage = 30;
    const page = Math.ceil(transactions?.length / perPage) + 1;
    const params = {
      page: page,
      limit: perPage,
      type: "deposit",
      nftTokenId: opertunityToken?.state?.detail?.nftToken?._id,
    };
    try {
      let resr = await HTTP_CLIENT.get(`${BASE_URL}transaction-log/user/logs`, {
        params,
      });

      setTransactions([...transactions, ...resr?.data?.data]);
      setDataTotal(resr?.data?.total);
    } catch (error) {}
  };
  const getUserInfoPaginationNativeToken = async () => {
    const perPage = 30;
    const page = Math.ceil(transactions?.length / perPage) + 1;
    const params = {
      page: page,
      limit: perPage,
      type: "deposit",
      coinId: opertunityToken?.state?.detail?.state?.detail?._id,
    };
    try {
      let resr = await HTTP_CLIENT.get(`${BASE_URL}transaction-log/user/logs`, {
        params,
      });

      setTransactions([...transactions, ...resr?.data?.data]);
      setDataTotal(resr?.data?.total);
    } catch (error) {}
  };

  useEffect(() => {
    if (opertunityToken?.state?.detail?.nftToken) {
      getUserInfo();
    } else {
      getUserInfoNative();
    }
  }, []);

  const fetchMoreData = () => {
    if (opertunityToken?.state?.detail?.nftToken) {
      getUserInfoPagination();
    } else {
      getUserInfoPaginationNativeToken();
    }
  };

  return (
    <div className={styles.container}>
      <p>Transaction History</p>
      {loading ? (
        <div className={styles.loaderPosition}>
          <Wave />
        </div>
      ) : (
        <div className={styles.wrapper} id="scrollableDiv">
          <InfiniteScroll
            dataLength={transactions.length}
            next={fetchMoreData}
            scrollableTarget="scrollableDiv"
            hasMore={
              dataTotal === transactions.length || dataTotal === 0
                ? false
                : true
            }
            // scrollableTarget="scrollableDiv"
            loader={<h6>loading.......</h6>}
          >
            {transactions?.length == 0 ? (
              <img className={styles.noData} src={NoData} alt="no data" />
            ) : (
              <table>
                {transactions?.map((item, key) => {
                  const {
                    type,
                    title,
                    message,
                    displayAmount,
                  } = getTransactionData({
                    data: item,
                    userId: currentUser?._id,
                    currentToken: opertunityToken,
                  });

                  const isSend = type === "withdraw";
                  return (
                    <tr key={key}>
                      <td>
                        <div className={styles.tabelWrapper}>
                          {isSend ? (
                            <img src={trackTop} alt="" />
                          ) : (
                            <img src={trackBottom} alt="" />
                          )}
                          <div className={styles.innerWrapper}>
                            <span style={{ fontWeight: "bold" }}> {title}</span>
                            <br />
                            {isSend ? (
                              <span> {message}</span>
                            ) : (
                              <span> {message}</span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={styles.bottomtabelWrapper}>
                          <div className={styles.imgWithName}>
                            {/* <img src={opertunityToken?.icon?.url} /> */}
                            {displayAmount} Units
                          </div>
                          {/* <br /> */}
                          <span>
                            {moment(item?.createdAt).format("MMMM Do YYYY")}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            )}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

export default Transactions;
