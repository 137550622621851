import React from "react";
import styles from "./skeletonCard.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonCard = () => {
  return (
    <div className={styles.skeletonCard}>
      <Skeleton
        height={50}
        className={styles.circleSkelton}
        circle
        width={50}
        baseColor="#20234F"
      />
      <div className={styles.rightSkeleton}>
        <Skeleton height={20} width={150} baseColor="#20234F" />
        <Skeleton
          height={20}
          width={150}
          style={{ marginTop: "10px" }}
          baseColor="#20234F"
        />
      </div>
    </div>
  );
};

export default SkeletonCard;
