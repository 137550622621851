import {
  GET_CURRENCY_DETAIL,
  CURRENCY_CLEANUP,
  GET_TRANSACTION_HISTORY,
  GET_CURRENCY_GRAPH,
  GET_SETTINGS,
  ASSETS_FOR_WALLETS_ACTIVATION,
  Wallets_ACTIVATION,
  CHECK_WALLETS,
  UPDATE_WALLET_BALANCE,
  CURRENCY_LOGOUT_CLEANUP,
  GRAPH_DATA,
  GET_NETWORK,
  SEND_DOLLAR_HISTORY,
  SEND_TOKEN_HISTORY,
} from "../types";

export const getCurrencyDetail = () => {
  return {
    type: GET_CURRENCY_DETAIL,
    payload: {
      request: {
        method: "get",
        url: "user/info",
      },
    },
  };
};

export const getTransactionDetail = (address, coinSymbol) => {
  return {
    type: GET_TRANSACTION_HISTORY,
    payload: {
      request: {
        method: "get",
        url: `transaction/${address}/${coinSymbol}/txs`,
      },
    },
  };
};

export const getCurrencyGraph = (coinSymbol) => {
  return {
    type: GET_CURRENCY_GRAPH,
    payload: {
      request: {
        method: "get",
        url: `coin/sparklines/${coinSymbol}`,
      },
    },
  };
};

export const getSetting = () => {
  return {
    type: GET_SETTINGS,
    payload: {
      request: {
        method: "get",
        url: "settings",
      },
    },
  };
};

export const WalletActivation = (coinSymbol) => {
  return {
    type: Wallets_ACTIVATION,
    payload: {
      request: {
        method: "post",
        url: "native-wallet/activateUserWallet",
        data: {
          coinSymbol: coinSymbol,
        },
      },
    },
  };
};

export const AseetsForWalletActivation = () => {
  return {
    type: ASSETS_FOR_WALLETS_ACTIVATION,
    payload: {
      request: {
        method: "get",
        url: "native-token/assetsForWalletActivation",
      },
    },
  };
};

export const checkWalletsActive = (data) => {
  return {
    type: CHECK_WALLETS,
    payload: data,
  };
};

export const UpdateWalletBalance = (data) => {
  return {
    type: UPDATE_WALLET_BALANCE,
    payload: {
      request: {
        method: "get",
        url: "wallet/updateWalletBalances",
      },
    },
  };
};

export const currencyCleanUp = () => {
  return {
    type: CURRENCY_CLEANUP,
  };
};

export const currencyLogoutCleanUp = () => {
  return {
    type: CURRENCY_LOGOUT_CLEANUP,
  };
};

export const graphData = (data) => {
  return {
    type: GRAPH_DATA,
    payload: data,
  };
};

export const networkData = (data) => {
  return {
    type: GET_NETWORK,
    payload: data,
  };
};

export const saveDollarHistory = (data) => {
  return {
    type: SEND_DOLLAR_HISTORY,
    payload: data,
  };
};

export const saveTokenHistory = (data) => {
  return {
    type: SEND_TOKEN_HISTORY,
    payload: data,
  };
};