import React, { useEffect, useState } from "react";
import styles from "./withdrawal.module.scss";
import DropDown from "../../../../_Common/DropDown/dropDown";
import InputField from "../../../../_Common/input/input";
import { useDispatch, useSelector } from "react-redux";
import {
  getNetwork,
  postWithdrawalData,
} from "../../../../../services/paymentServices";
import { networkData } from "../../../../../redux/action/currencyDetailAction";
import AccountSelection from "./DynamicInput";
import { WITHDRAWAL_NETWORK_TYPES } from "../../../../../utils/withdrawalNetworkTypes";
import { useAlert } from "react-alert";
import { Loader, WaveClone } from "../../../../_Common/loader/Loader";
import { useHistory } from "react-router-dom";
import back from "../../../../../Assets/icons/historyBack.svg";
import WithdrawalHistory from "../WithdrawalHistory";
import stylesmodal from "../../../../_Common/checkChangePasswordModal/openaccount.module.scss";
import Modal from "../../../../_Common/Modal/Modal";
import { getAssetsToAssetsSawap } from "../../../../../redux/action/sawapAction";
import BackTo from "../../../../../Assets/icons/backk.svg";
import { useLocation } from "react-router-dom";
import CQRComponent, {
  CQRAComponent,
} from "../../../../_Common/CQRComponent/CQRComponent";

const Withdrawal = () => {
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const dispatch = useDispatch();
  const [swap, setSwap] = useState("withdraw");
  const sawapSelector = useSelector((state) => state?.sawap);

  const [loading, setloading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [filterQData, setfilterQData] = useState("");
  const options = filterQData;
  const [selected, setSelected] = useState({ name: "Select Token" });
  const [CurrentValue, setCurrentValue] = useState();
  const [network, setNetwork] = useState({ name: "Select Destination" });
  const [networkOpetion, setNetworkOption] = useState("");
  const [valueGet, setValueGet] = useState("");

  const Network = networkOpetion;
  const currencySelector = useSelector((state) => state.currency);
  const { getnetworkData } = useSelector((state) => state.currency);
  const [state, setState] = useState({
    selectedToken: null,
    amount: "",
    selectedNetwork: null,

    address: null,

    //stellar
    account: null,
    memo: null,
    memoType: null,
    asset: null,

    //CBU/CVU (ARS)
    cbu: null,
    cuitPersonal: null,
    cuitCompany: null,

    //PIX(BRL)
    pixKey: null,
    taxId: null,
  });
  const [active, setActive] = useState(0);

  const onChangeState = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
  const isValidated = () => {
    if (!state.selectedToken) {
      alert.show("Please select a token to continue", { type: "error" });
      return false;
    } else if (+state.amount == 0 || !state.amount) {
      alert.show("Please enter a valid amount value", { type: "error" });
      return false;
    } else if (+state.amount >= +state.selectedToken?.balance) {
      alert.show("Insufficient Balance", { type: "error" });
      return false;
    }
    if (!state.selectedNetwork) {
      alert.show("Please select a network to continue", { type: "error" });
      return false;
    }
    const idToCompare = state?.selectedNetwork?.id;
    if (
      idToCompare == WITHDRAWAL_NETWORK_TYPES.BITCOIN ||
      idToCompare == WITHDRAWAL_NETWORK_TYPES.ETHEREUM ||
      idToCompare == WITHDRAWAL_NETWORK_TYPES.LITECOIN
    ) {
      if (!state.address) {
        alert.show("Please enter address to continue", { type: "error" });
        return false;
      }
    } else if (idToCompare == WITHDRAWAL_NETWORK_TYPES.PIX) {
      if (!state.pixKey) {
        alert.show("Please enter a valid pix key value", { type: "error" });
        return false;
      } else if (!state.taxId) {
        alert.show("Please enter a valid tax id value", { type: "error" });
        return false;
      }
    } else if (state?.selectedNetwork?.type == "BANK") {
      if (!state.cbu) {
        alert.show("Please enter a cbu key value", { type: "error" });
        return false;
      } else if (!state.cuitPersonal) {
        alert.show("Please enter a valid cuit personal value", {
          type: "error",
        });
        return false;
      }
    } else if (idToCompare == WITHDRAWAL_NETWORK_TYPES.STELLAR) {
      if (!state.account) {
        alert.show("Please enter a valid account value", { type: "error" });
        return false;
      } else if (!state.asset) {
        alert.show("Please select a stellar asset to continue.", {
          type: "error",
        });
        return false;
      }
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidated()) {
      try {
        setloading(true);
        let targetAccount = {};
        const idToCompare = state?.selectedNetwork?.id;
        if (
          idToCompare == WITHDRAWAL_NETWORK_TYPES.BITCOIN ||
          idToCompare == WITHDRAWAL_NETWORK_TYPES.ETHEREUM ||
          idToCompare == WITHDRAWAL_NETWORK_TYPES.LITECOIN
        ) {
          targetAccount = {
            address: state?.address,
          };
        } else if (idToCompare == WITHDRAWAL_NETWORK_TYPES.PIX) {
          targetAccount = {
            pixKey: state?.pixKey,
            taxId: state?.taxId,
          };
        } else if (state?.selectedNetwork?.type == "BANK") {
          targetAccount = {
            cbu: state?.cbu,
            cuitPersonal: state?.cuitPersonal,
            cuitCompany: state?.cuitCompany,
          };
        } else if (idToCompare == WITHDRAWAL_NETWORK_TYPES.STELLAR) {
          targetAccount = {
            account: String(state?.account).trim(),
            memo: state?.memo,
            memoType: state?.memoType,
          };
        }

        let payload = {
          amount: Number(valueGet),
          tokenId: state?.selectedToken?._id,
          targetNetwork: state?.selectedNetwork?.id,
          targetAccount,
        };

        if (state?.asset) {
          payload["targetAsset"] = state?.asset;
        }
        const response = await postWithdrawalData(payload);
        alert.show(response?.data?.message, { type: "success" });
        setState({});
        setNetwork({ name: "Select Destination" });
        setSelected({});
        setOpenModal(false);
        history.go(-2);
        setloading(false);
      } catch (err) {
        const error = err?.response?.data?.message;
        setloading(false);
        setState({});
        setSelected({ name: "Select Token" });
        setNetwork({ name: "Select Destination" });

        setOpenModal(false);

        alert.show(error ? error : "Something went wrong.Please try again.", {
          type: "error",
        });
      }
    }
  };

  const handleOpenModal = async () => {
    if (isValidated()) {
      setOpenModal(true);
    }
  };
  useEffect(() => {
    if (selected?.shortName == "CQR Dollar") {
      setCurrentValue(Number(state?.amount) * 1);
    } else {
      setCurrentValue(Number(state?.amount) * Number(selected?.price));
    }
    setNetworkOption(getnetworkData);
    let filterLiquidity = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "liquidity"
    );
    let filterAssets = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "asset"
    );
    let filterSdira = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "sdira"
    );
    if (location?.state?.detail?.type === "liquidity") {
      setfilterQData(filterLiquidity);
    } else if (location?.state?.detail?.type === "asset") {
      setfilterQData(filterAssets);
    } else {
      setfilterQData(filterSdira);
    }
  }, [network, state?.amount, selected]);
  const valueChanger = () => {
    let data = {
      selected: selected,
      amount: "1",
    };
    try {
      dispatch(getAssetsToAssetsSawap(data, swap));
    } catch (error) {}
  };
  useEffect(() => {
    valueChanger();
  }, [selected]);
  useEffect(() => {
    setValueGet((1 / sawapSelector?.assetSwaoInfo) * state.amount);
  }, [state.amount]);
  useEffect(() => {
    setValueGet((1 / sawapSelector?.assetSwaoInfo / 1) * state.amount);
  }, [sawapSelector]);
  return (
    <>
      <Modal visible={openModal} onClose={() => setOpenModal(false)} btn>
        <div className={stylesmodal.container}>
          <div className={stylesmodal.textsection}>
            <label>Are you sure you want to redeem this token?</label>
          </div>
          <div className={stylesmodal.btnsection}>
            {loading ? (
              <button className={stylesmodal.Delete} disabled>
                <WaveClone />
              </button>
            ) : (
              <button
                className={stylesmodal.Delete}
                type="submit"
                disabled={loading ? true : false}
                onClick={handleSubmit}
              >
                Confirm
              </button>
            )}
            <button
              disabled={loading ? true : false}
              className={stylesmodal.Delete}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <img
            src={BackTo}
            className={styles.bactoImg}
            alt="back"
            onClick={() => history.goBack()}
            width={40}
          />
          <div className={styles.heading}></div>
          <div className={styles.borderRight}>
            <label>Redeem</label>
            <form>
              <DropDown
                padding="14px 20px"
                selected={selected}
                setSelected={(res) => {
                  onChangeState("selectedToken", res);
                  setSelected(res);
                }}
                name="Select Token"
                options={options}
                fixed={selected?.decimals}
                img
                imgShow
                BalanceStatus
                Balance={selected?.balance}
              />

              <InputField
                value={state?.amount ? state.amount : ""}
                inputName="Enter Amount in USD"
                name="Enter Amount in USD"
                type="number"
                onclick={(e) => onChangeState("amount", e.target.value)}
                BalanceStatus
                InputBalance={valueGet ? valueGet : ""}
              />
              {/* <p>
                Amount You will get USD ≈ $
                {valueGet ? <>{valueGet?.toFixed(selected?.decimals)}</> : "0"}
              </p> */}
              <DropDown
                padding="14px 20px"
                selected={network}
                setSelected={(res) => {
                  onChangeState("selectedNetwork", res);
                  setNetwork(res);
                }}
                name="Select Destination"
                options={Network}
                img
                imgShow
              />
              {state?.selectedNetwork && (
                <AccountSelection
                  network={network}
                  state={state}
                  onChangeState={onChangeState}
                />
              )}
            </form>
            <div className={styles.btnsection}>
              <button className={styles.btn} onClick={handleOpenModal}>
                Get Now
              </button>
              <p>
                This amount will be deducted from your C<CQRComponent />R Vault
                account after the withdrawal process is completed.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.historyWraper}>
          <WithdrawalHistory setActive={setActive} />
        </div>
      </div>
    </>
  );
};

export default Withdrawal;
