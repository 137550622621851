import React from "react";
import styles from "./ChatIcon.module.scss";
import Chaticon from "../../../Assets/icons/chat.svg";
import { Link } from "react-router-dom";

const ChatIcon = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.chatbox}>
            <Link to="/chat">
              <img src={Chaticon} alt="Chat-Icon" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatIcon;
