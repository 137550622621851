import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import back from "../../../../Assets/icons/historyBack.svg";
import Defaultcurrency from "../../../../Assets/image/defaultcurrency.svg";
import optionArrow from "../../../../Assets/image/option-arrow.svg";
import Pushnotifications from "../../../../Assets/icons/HS_White.svg";
import withdrawAddress from "../../../../Assets/icons/directwire-withdraw.svg";
import { saveBankDetail } from "../../../../redux/action/remittanceAction";
import { getBankDetail } from "../../../../services/paymentServices";
import DirectWireList from "../../../_Common/directWireList/directWireList";
import RemittanceAddresslist from "../../../_Common/remittanceAddressList/remittanceAddresslist";
import { useSelector } from "react-redux";
import styles from "./Ramittance.module.scss";
import WithdrawAddresslist from "./withdrawAddress";

const Ramittance = ({ visible, onClose, btn }) => {
  const history = useHistory();

  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  const [Remittance, setRemittance] = useState(0);

  const alert = useAlert();
  const dispatch = useDispatch();

  const fetchBankDetail = async () => {
    try {
      const response = await getBankDetail();
      dispatch(saveBankDetail(response?.data));
    } catch (error) {
      if (error.response?.status == 401) {
        localStorage.clear();
        history.push("/");
      }
      let modifiyError = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Something went wrong";

      alert.show(modifiyError, { type: "error" });
    }
  };

  useEffect(() => {
    fetchBankDetail();
  }, []);
  const userSelector = useSelector((state) => state.currency);
  const userKyc = useSelector((state) => state?.user);

  useEffect(() => {
    if (
      !userKyc?.savedFormData?._id &&
      !userKyc?.kyc?._id &&
      !userSelector?.currencyInfo?.gpaData?._id
    ) {
      history.push("/profile");
      alert.show("Please fill your GPA data first", {
        type: "error",
      });
    }
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            {(Remittance == 1 || Remittance == 2) && (
              <img onClick={() => setRemittance(0)} src={back} />
            )}
            <p>
              {Remittance == 0
                ? "Remittance"
                : Remittance == 1
                ? "Remittance Agreement"
                : Remittance == 2
                ? "Direct Wire Exchange"
                : ""}
            </p>
          </div>
          {Remittance == 0 ? (
            <>
              <div
                className={styles.optionCard}
                onClick={() => setRemittance(1)}
              >
                <div className={styles.option}>
                  <div className={styles.icon}>
                    <img src={Pushnotifications} alt="dash-wallet-option" />
                  </div>
                  <div className={styles.text}>Remittance Agreement</div>
                </div>
                <div className={styles.arrow}>
                  <img src={optionArrow} alt="arrow" />
                </div>
              </div>

              <div
                className={styles.optionCard}
                onClick={() => setRemittance(2)}
              >
                <div className={styles.option}>
                  <div className={styles.icon}>
                    <img src={Defaultcurrency} alt="dash-wallet-option" />
                  </div>
                  <div className={styles.text}>Direct Wire Exchange</div>
                </div>
                <div className={styles.arrow}>
                  {/* <div className={styles.value}>USD</div> */}
                  <img src={optionArrow} alt="arrow" />
                </div>
              </div>
              <div
                className={styles.optionCard}
                onClick={() => setRemittance(3)}
              >
                <div className={styles.option}>
                  <div className={styles.icon}>
                    <img src={withdrawAddress} alt="dash-wallet-option" />
                  </div>
                  <div className={styles.text}>Direct Wire Redeem Address</div>
                </div>
                <div className={styles.arrow}>
                  {/* <div className={styles.value}>USD</div> */}
                  <img src={optionArrow} alt="arrow" />
                </div>
              </div>
            </>
          ) : Remittance === 1 ? (
            <RemittanceAddresslist />
          ) : Remittance === 2 ? (
            <DirectWireList />
          ) : Remittance === 3 ? (
            <WithdrawAddresslist setRemittance={setRemittance} />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Ramittance;
