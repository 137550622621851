import {
  // GET_USER_INFO,
  // GET_USER_INFO_FAIL,
  // GET_USER_INFO_SUCCESS,
  SAVE_FORM_DATA,
  SAVE_SIGN,
  USER_KYC,
  USER_KYC_SUCCESS,
  USER_KYC_FAIL,
  USER_CLEANUP,
  IMG_UPLOAD,
  IMG_UPLOAD_SUCCESS,
  IMG_UPLOAD_FAIL,
  IMG_UPLOAD_USER_INFO,
  IMG_UPLOAD_USER_INFO_SUCCESS,
  IMG_UPLOAD_USER_INFO_FAIL,
  KYC_CLEANUP_UP,
  // USER_CLEANUP,
  USER_info,
  CREATE_ACCOUNT,
  SET_CHECKED,
  SEND_DOLLAR_HISTORY,
  SEND_TOKEN_HISTORY,
  USER_FCM_TOKEN,
} from "../types";

const initialState = {
  savedFormData: null,
  sign: null,
  kyc: null,
  kycLoading: null,
  kycError: null,
  img: null,
  imgSuccess: null,
  imgError: null,
  imgLoader: false,
  //
  imgUpdateUserInfo: null,
  // imgUpdateLoader: null,
  imgUpdateError: null,
  imgUpdateSuccess: null,
  userInfo: null,
  createAccountCheck: false,
  isChecked: false,
  fcmToken: "",

  // loading: false,
  // error: null,
  // currencyInfo: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FORM_DATA:
      const data = { ...state.savedFormData, ...action.payload };
      return { ...state, savedFormData: data };
    case SAVE_SIGN:
      return { ...state, sign: action.payload };
    case USER_CLEANUP:
      return {
        ...state,
        // savedFormData: null,
        sign: null,
        kyc: null,
        kycLoading: null,
        kycError: null,
        imgUpdateSuccess: null,
        imgUpdateError: null,
        imgSuccess: null,
        imgError: null,

        //img

        img: null,
        imgError: null,
        imgSuccess: null,
      };

    case KYC_CLEANUP_UP:
      return {
        ...state,
        savedFormData: null,
        userInfo: null,
      };

    case USER_KYC:
      return { ...state, kycError: null, kycLoading: true };
    case SET_CHECKED:
      return { ...state, isChecked: action.payload };
    case USER_KYC_SUCCESS:
      return { ...state, kyc: action?.payload?.data, kycLoading: false };
    case USER_KYC_FAIL:
      return {
        ...state,
        kyc: null,
        kycError: action.error?.response?.data?.message,
        kycLoading: false,
      };

    case IMG_UPLOAD:
      return { ...state, imgError: null, imgLoader: true };
    case IMG_UPLOAD_SUCCESS:
      return {
        ...state,
        img: action?.payload?.data,
        imgSuccess: "retrived successfully",
        imgLoader: false,
      };
    case IMG_UPLOAD_FAIL:
      return {
        ...state,
        img: null,
        imgError: action.error?.response?.data?.message,
        imgSuccess: "",
        imgLoader: false,
      };

    // user info-----
    case USER_info:
      return {
        ...state,
        userInfo: action?.payload,
      };
    // store fcm token
    case USER_FCM_TOKEN:
      return {
        ...state,
        fcmToken: action?.payload,
      };

    case CREATE_ACCOUNT:
      return {
        ...state,
        createAccountCheck: action?.payload,
      };

    //img uploade update user info

    case IMG_UPLOAD_USER_INFO:
      return { ...state, imgUpdateError: null, imgLoader: true };
    case IMG_UPLOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        imgUpdateUserInfo: action?.payload?.data,
        imgUpdateSuccess: "successfully",
        imgLoader: false,
      };
    case IMG_UPLOAD_USER_INFO_FAIL:
      return {
        ...state,
        imgUpdateUserInfo: null,
        imgUpdateError: action.error?.response?.data?.message,
        imgSuccess: "",
        imgLoader: false,
      };

    default:
      return state;
  }
};
export default userReducer;
