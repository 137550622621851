const data = [
  {
    price: 1,
    timestemp: 2000,
  },
  {
    price: 2,
    timestemp: 1000,
  },
  {
    price: 3,
    timestemp: 2100,
  },
  {
    price: 4,
    timestemp: 300,
  },
  {
    price: 5,
    timestemp: 3080,
  },
  {
    price: 6,
    timestemp: 480,
  },
  {
    price: 7,
    timestemp: 50000,
  },
  {
    price: 8,
    timestemp: 1080,
  },
  {
    price: 9,
    timestemp: 3080,
  },
  {
    price: 10,
    timestemp: 2900,
  },
  {
    price: 11,
    timestemp: 8900,
  },
  {
    price: 12,
    timestemp: 9000,
  },
];

export default data;
