import React from "react";
import style from "./legacyModal.module.scss";
// import Data from "../../../../Data/Dashboard/SwapModal/data";
// import Data2 from "../../../../Data/Dashboard/SwapModal/data2";

function LegacyModalStyle({ title, wallet, handleCoinChange, nativewallet }) {
  const DataObj = {
    1: wallet,
    2: nativewallet,
  };
  const data = DataObj[title];
  const dataToDispaly = data?.filter((item) => item.type !== "sdira");
  return (
    <>
      <div className={style.container}>
        <div className={style.Wrapper}>
          {dataToDispaly?.map((data) => {
            return (
              <div
                className={style.CardWrapper}
                key={data._id}
                onClick={() => handleCoinChange(data)}
              >
                <div className={style.Card}>
                  <img src={data?.icon?.url} alt="" />
                  <div className={style.text}>
                    {data?.shortName?.toUpperCase()
                      ? data?.shortName?.toUpperCase()
                      : data?.coinSymbol?.toUpperCase()}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default LegacyModalStyle;
