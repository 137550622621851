import React, { useEffect, useState } from "react";
import styles from "./GraphBottomTable.module.scss";
import moment from "moment";
import NoData from "../../../../../../../Assets/gif/noData.gif";
// import data from "../../../../../../../Data/Dashboard/CryptoCurrency/SecTabel/data";

const SecTabel = ({ data, coinDetail }) => {
  const [tableArray, setTableArray] = useState();

  useEffect(() => {
    data?.reverse();
  }, [data]);
  const getStatus = (trx) => {
    return trx?.from == coinDetail?.address ? "Send" : "Receive";
  };

  const getAmountInUed = (amount) => {
    return parseFloat(amount?.value * coinDetail?.marketData?.rate).toFixed(4);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {data?.length == 0 ? (
          <img className={styles.NoData} src={NoData} alt="no data" />
        ) : (
          <table>
            {data?.map((tabelData) => {
              return (
                <tr key={tabelData._id}>
                  <td>
                    <div className={styles.tabelWrapper}>
                      {/* <img src={img} alt="" /> */}
                      <div className={styles.innerWrapper}>
                        <span> {getStatus(tabelData)}</span>
                        {moment(tabelData?.updatedAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabelWrapper}>
                      {parseFloat(tabelData?.value).toFixed(4)}
                      <br />$ {getAmountInUed(tabelData)}
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        )}
      </div>
    </div>
  );
};

export default SecTabel;
