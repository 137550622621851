import React, { useEffect, useState } from "react";

import Styles from "./form.module.scss";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { formikSchema } from "../../../../DashBoardLayout/Dashboard/Profile/GPALevelInformation/GPAFormikSchema";
import { HTTP_CLIENT } from "../../../../../utils/services";
import { useAlert } from "react-alert";
import { LoadingButton, WaveClone } from "../../../../_Common/loader/Loader";
import DropDown from "../../../../_Common/DropDown/dropDown";
import { useSelector } from "react-redux";
import { sponsoringAssemblyOption } from "../../../../../Data/dropDownItems/dropdownData";
function Form() {
  const userSelector = useSelector((state) => state.user);

  // const {
  //   name,
  //   refAmbassador,
  //   sponsor,
  //   refOrgAddress,
  //   beneficiary,
  // } = userSelector.savedFormData;
  const alert = useAlert();
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [sponsoring, setsponsoring] = useState({
    name: "SA-01 (I Don't Know)",
    value: "SA-02",
  });
  const sponsoringAssemblyOptions = sponsoringAssemblyOption;

  const formikSchema = yup.object({
    firstName: yup
      .string()
      .required("Name is Required")
      .max(50, "First name Too long!")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .min(2, "First name Too Short!"),
    lastName: yup
      .string()
      .max(50, "First name Too long!")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .min(2, "First name Too Short!"),
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is Required"),
    phone: yup.string().required("Phone is Required"),
    // .min(11, "Phone is Too Short!"),
    message: yup.string().required("Message is Required").lowercase(),
    // sponerAsembly: yup.string().required("Sponer Asembly is Required"),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sponerAsembly: "",
      message: "",
    },
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });

  const handleSubmit = async (values) => {
    setbuttonLoading(true);
    try {
      const res = await HTTP_CLIENT.post("/user/contact-us", values);
      alert.show(res.data.message, { type: "success" });
      setbuttonLoading(false);
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
      setbuttonLoading(false);
    }
  };

  return (
    <div>
      <div className={Styles.container}>
        <div className={Styles.wrapper}>
          <div className={Styles.headingWrapper}>
            <div className={Styles.headingg}>
              <br />
              How can we help?
            </div>
            <div className={Styles.subHeading}>
              We're here to help and answer any question you might have.
              <br /> We look forward to hearing from you.
            </div>
          </div>
          <div className={Styles.textField}>
            <form onSubmit={formik.handleSubmit}>
              <input
                type="text"
                placeholder="First Name *"
                {...formik.getFieldProps("firstName")}
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <div style={{ color: "#E5516B" }}>
                  {formik.errors.firstName}
                </div>
              ) : null}
              <input
                type="text"
                placeholder="Last Name"
                {...formik.getFieldProps("lastName")}
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.lastName}</div>
              ) : null}
              <input
                type="email"
                placeholder="Email *"
                {...formik.getFieldProps("email")}
              />
              {formik.errors.email && formik.touched.email ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.email}</div>
              ) : null}
              <input
                type="text"
                placeholder="Phone number"
                {...formik.getFieldProps("phone")}
              />
              {formik.errors.phone && formik.touched.phone ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.phone}</div>
              ) : null}
              <input
                type="text"
                placeholder="Message *"
                {...formik.getFieldProps("message")}
              />
              {formik.errors.message && formik.touched.message ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.message}</div>
              ) : null}
              <DropDown
                padding="18px 0px"
                selected={sponsoring}
                // setSelected={setsponsoring}
                options={sponsoringAssemblyOptions}
                setSelected={(res) => {
                  formik.setFieldValue("sponerAsembly", res);
                  setsponsoring(res);
                }}
                imgShow={true}
                name="Sponsoring Assembly"
              />
              {/* {formik.errors.sponerAsembly && formik.touched.sponerAsembly ? (
                <div style={{ color: "#E5516B" }}>
                  {formik.errors.sponerAsembly}
                </div>
              ) : null} */}
              {buttonLoading ? (
                <WaveClone />
              ) : (
                <button type="submit">SEND</button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
