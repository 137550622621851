// import {
//     SEND_ERC20_TRX,
//   ,
//   } from '../shared/models/types/network.types';
import { HTTP_CLIENT } from "./services";

const sendEthTransaction = async (params) => {
  return HTTP_CLIENT.post("transaction/eth", params);
};

const sendBNBTransaction = async (params) => {
  return HTTP_CLIENT.post("transaction/bnb", params);
};

const sendERC20Transaction = async (params) => {
  return HTTP_CLIENT.post("transaction/erc20", params);
};

const sendBep20Transaction = async (params) => {
  return HTTP_CLIENT.post("transaction/bep20", params);
};

const sendBtcTransaction = async (params) => {
  return HTTP_CLIENT.post("transaction/btc", params);
};

export {
  sendEthTransaction,
  sendERC20Transaction,
  sendBNBTransaction,
  sendBep20Transaction,
  sendBtcTransaction,
};
