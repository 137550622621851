import React, { useState } from "react";
import IosImage from "../../../../Assets/image/App-store.svg";
import Mobileicon from "../../../../Assets/image/appstoreimageNew.png";
import Andiordimage from "../../../../Assets/image/play-store.svg";
import Modal from "../../../_Common/Modal/Modal";
import styles from "./appstore.module.scss";
import ComingSoon from "./ComingSoon";
const AppStore = () => {
  const [comingSoon, setComingSoon] = useState(false);
  return (
    <>
      <Modal visible={comingSoon} onClose={() => setComingSoon(false)}>
        <ComingSoon
          para="Coming Soon..."
          // onPressButton={() => history.push("/")}
          onPressinnerButton={() => setComingSoon(false)}
        />
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            className={styles.leftsection}
            data-aos="fade-up-right"
            data-aos-easing="ease-in-sine"
            data-aos-duration="3000"
          >
            <label>
              Also Available on <span>Andriod</span>
            </label>
            <p>
              Sync your data between your desktop and mobile app and keep track
              of your crypto assets no matter where you are.
            </p>
            <div className={styles.btndiv}>
              {/* <a
                onClick={() => {
                  setComingSoon(true);
                }}
              >
                <img src={IosImage} alt="Iso" />
              </a> */}
              <a
                href="https://play.google.com/store/apps/details?id=com.cqrvault"
                target="_blank"
              >
                <img src={Andiordimage} alt="Iso" />
              </a>
            </div>
          </div>
          <div
            className={styles.rightsection}
            data-aos="fade-up-left"
            data-aos-easing="ease-in-sine"
            data-aos-duration="3000"
          >
            <img src={Mobileicon} alt="Mobile" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppStore;
