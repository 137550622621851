import React, { useState } from "react";
import styles from "./appfeatures.module.scss";
import Iphoneicon from "../../../../Assets/image/iPhone.png";
import Modal from "../../../_Common/Modal/Modal";
import CqrvestWhitepaper from "../../../_Common/Footer/dataFile/cqrVestWhitepaper";

const AppFeatures = () => {
  const [popupvisible, setpopupvisible] = useState(false);
  const togglepopup = () => {
    setpopupvisible((preview) => !preview);
  };
  return (
    <>
      <Modal
        visible={popupvisible}
        onClose={() => setpopupvisible(false)}
        outerStyle={{
          background: "white",
          maxWidth: "900px",
        }}
        showModal2={true}
      >
        <CqrvestWhitepaper />
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <p>
              MOBILE APP&nbsp;
              <span>FEATURES</span>
            </p>
          </div>
          <div className={styles.featuresection}>
            <div className={styles.leftfeature}>
              <div
                className={styles.anaylize}
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
                data-aos="fade-right"
              >
                <div className={styles.left}>
                  <p>1</p>
                </div>
                <div className={styles.right}>
                  <label>Freedom</label>
                  <p>
                    True financial independence is one’s ability to live off
                    residual cash flows generated from leveraged assets, while
                    safely traveling on a “Toll-Free Road” with “Mandator
                    Tex-Excepted” Jurisdictional Benefits.
                  </p>
                </div>
              </div>
              <div
                className={styles.anaylize}
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
                data-aos="fade-right"
              >
                <div className={styles.left}>
                  <p>2</p>
                </div>
                <div className={styles.right}>
                  <label>Innovation</label>
                  <p>
                    CQR Vault Members have Fungibility & Liquidity coupled with
                    Physical & Jurisdictional Security over their store of
                    wealth. CQR Vest AP Opportunity Tokens are digital
                    “Smart-Covenants” that represent real Appreciative Assets &
                    Royalties generated from “Asset Pool” syndicated cash flow
                    vehicles.
                  </p>
                </div>
              </div>
              <div
                className={styles.anaylize}
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
                data-aos="fade-right"
              >
                <div className={styles.left}>
                  <p>3</p>
                </div>
                <div className={styles.right}>
                  <label>Redeem</label>
                  <p>
                    Anonymously send and receive value Peer-to-Peer, exchange to
                    your favorite Charity, Small Business, and Philanthropic
                    Causes who also have a CQR Vault digital vault.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.mobilefeature}
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-duration="4000"
            >
              <img src={Iphoneicon} alt="Mobile Pic" />
              <a
                href="https://transfer.box.com/s/wdr8soglqhf23hvjl6ru8fg082m44fdf"
                target="_blank"
                rel="noreferrer"
              >
                <button className={styles.btn}>Download Whitepaper</button>
              </a>
            </div>
            <div className={styles.rightfeature}>
              <div
                className={styles.anaylize}
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
                data-aos="fade-left"
              >
                <div className={styles.left}>
                  <p>4</p>
                </div>
                <div className={styles.right}>
                  <label>Instant Exchange</label>
                  <p>
                    Securely exchange Digital Certificates of Authenticity that
                    represent USD, Ag, Au and Royalties redeemable on demand for
                    real-world listings provided by community SBMs with
                    real-time transparent ledger accountancy right in your hand!
                  </p>
                </div>
              </div>
              <div
                className={styles.anaylize}
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
                data-aos="fade-left"
              >
                <div className={styles.left}>
                  <p>5</p>
                </div>
                <div className={styles.right}>
                  <label>Live Exchanges</label>
                  <p>
                    Monitor all global transactions and your CQR Vault asset
                    holdings in real time. Privately see your Community Impact
                    Rating.
                  </p>
                </div>
              </div>
              <div
                className={styles.anaylize}
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
                data-aos="fade-left"
                style={{ paddingTop: "20px" }}
              >
                <div className={styles.left}>
                  <p>6</p>
                </div>
                <div className={styles.right}>
                  <label>Build Platform</label>
                  <p>
                    Easy to use graphic interface! Easy to send real assets with
                    real value! Easy to SECURE your legacy with CQR Vault!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppFeatures;
