import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./instructions.module.scss";
import Input from "../../../../_Common/input/input";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { saveFormData } from "../../../../../redux/action/userAction";
import CQRComponent from "../../../../_Common/CQRComponent/CQRComponent";

const Instructions = ({ handeClickForm }, ref) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const formRef = useRef();
  const userSelector = useSelector((state) => state.user);
  const [selectDate, setSelectDate] = useState(
    userSelector?.savedFormData?.registrationDate
      ? new Date(userSelector?.savedFormData?.registrationDate)
      : new Date()
  );
  useImperativeHandle(ref, () => ({
    onSubmit: () => {
      formik.handleSubmit();
    },
  }));

  const formikSchema = yup.object({
    date: yup.date("date").required("date is required"),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      date: userSelector?.savedFormData?.registrationDate
        ? moment(userSelector?.savedFormData?.registrationDate).format(
            "YYYY-MM-DD"
          )
        : new Date(),
    },
    onSubmit: (values) => {
      let registrationDate = {
        registrationDate: selectDate ? selectDate : values.date,
      };
      dispatch(saveFormData(registrationDate));
      handeClickForm();
    },
  });
  const silverRate = useSelector(
    (state) => state?.currency?.currencyInfo?.nativeWallets
  );

  const handleDateChange = (date) => {
    setSelectDate(date);
  };

  // useEffect(() => {
  //   setSelectDate(userSelector?.savedFormData?.registrationDate
  //     ? moment(userSelector?.savedFormData?.registrationDate).format(
  //       "YYYY-MM-DD"
  //     )
  //     : new Date())
  // }, [])
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <p>INSTRUCTIONS:</p>
          </div>
          <div className={styles.text}>
            <p>
              Please complete the required data entry fields in as many slides
              as you are able, the further you go, the higher your Gideon
              Protocol Access (G.P.A.) clearance level issuance will be in
              accordance with the G.P.A. Mapping Matrix. To determine your
              G.P.A. clearance level, and for qualification of any Member
              Rewards, one must first complete ALL REQUIRED data fields and
              submit for vetting and granted access privilege(s). Pursuant to
              your Relationship & Royalty Sharing Covenant with C
              <CQRComponent />R Vault and other Members within our C
              <CQRComponent />R Professional Alliance.
            </p>
            <br />
            <p>
              Any data field with an (*) to the right of its header is
              information that is required before proceeding forward to
              submission. You may choose to upgrade your G.P.A. Level at any
              time -- this is based on your G.P.A. relationship level, more than
              any formal commercial background analysis. Pursuant with the
              volume of business we do together, you may be required to increase
              your G.P.A. Level in accordance with the amount of C
              <CQRComponent />R Asset Tokens allocated and assigned to the
              benefit of your C
              <CQRComponent />R Vault Digital Vault.
            </p>
          </div>
          <div className={styles.selectionarea}>
            <p>Effective Date of Agreement and Submission</p>
            <form ref={formRef} onSubmit={formik.handleSubmit}>
              <Input
                InfoDatePicker={true}
                config={handleDateChange}
                value={selectDate}
              />
              {!selectDate ? (
                <div style={{ color: "#E5516B" }}>Date is required</div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Instructions);
