import { HTTP_CLIENT } from "../utils/services";

export const ENDPOINTS = {
  CHAT: {
    createChatRoom: "/chat/room/create",
    roomMessages: "/chat/room/messages",
    chatRooms: "/chat/room",
  },
};

const getuserChatRooms = async (page, perPage = 1000) => {
  return await HTTP_CLIENT.get(
    `${ENDPOINTS.CHAT.chatRooms}?page=${page}&limit=${perPage}`
  );
};

const getChatRoomMessages = async (roomId, page, perPage = 1000) => {
  return await HTTP_CLIENT.get(
    `${ENDPOINTS.CHAT.roomMessages}?chatRoom=${roomId}&page=${page}&limit=${perPage}`
  );
};

const generateNewChatRoom = async (otherUserId) => {
  let payload = {
    otherUserId,
  };

  return await HTTP_CLIENT.post(ENDPOINTS.CHAT.createChatRoom, payload);
};
const deleteChatRooms = async (roomId) => {
  return await HTTP_CLIENT.get(`/chat/user-delete/${roomId}`);
};

export {
  getuserChatRooms,
  getChatRoomMessages,
  generateNewChatRoom,
  deleteChatRooms,
};
