import React, { useEffect, useState } from "react";
import styles from "./home.module.scss";
import Revelution from "./Revelution/Revelution";
import AppFeatures from "./AppFeatures/AppFeatures";
import Users from "./Users/Slider";
import AppStore from "./AppStore/AppStore";
import Team from "./Team/Team";
import FavouriteCoin from "./FavouriteCoin/FavouriteCoin";
import Slider from "../../_Common/Slider/slider";
import TeamData from "../../../Data/Slider/home/team/Data";
import CoinHeading from "./CoinHeading/CoinHeading";
import AdvantEDGE from "./AdvantEDGE/AdvantEDGE";
import Aos from "aos";
import "aos/dist/aos.css";
import VideoSection from "./VideoSection/videoSection";
import { useDispatch } from "react-redux";
import { KYCCleanUp } from "../../../redux/action/userAction";

const Home = () => {
  const [AccesToken, setAccesToken] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    Aos.init();
  }, []);
  useEffect(() => {
    let res = localStorage.getItem("authToken");
    setAccesToken(res);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <Revelution />
        <VideoSection />
        <CoinHeading />

        <FavouriteCoin />
        <AppFeatures />
        <AdvantEDGE />
        {/* <Users /> */}
        <AppStore />
        {/* <Slider team={2}>
          {TeamData.map((SData) => {
            return <Team SData={SData} key={SData.id} />;
          })}
        </Slider> */}
      </div>
    </>
  );
};

export default Home;
