import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import styles from "./gpalevel.module.scss";
import Input from "../../../../_Common/input/input";
import DropDown from "../../../../_Common/DropDown/dropDown";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { formikSchema } from "./GPAFormikSchema";
import { useDispatch } from "react-redux";
import {
  saveFormData,
  setChecked,
} from "../../../../../redux/action/userAction";
import {
  CountryOption,
  option,
} from "../../../../../Data/dropDownItems/dropdownData";
import CQRComponent from "../../../../_Common/CQRComponent/CQRComponent";

const GPALevel = ({ handeClickForm }, ref, pageChange) => {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const formRef = useRef();
  const checkboxRef = useRef();
  const options = option;
  const {
    displayName,
    name,
    primaryEmail,
    secondaryEmail,
    primaryPhone,
    physicalAddress,
    mailingAddress,
  } = userSelector.savedFormData;
  // const {  checkboxRef?.current?.checked } = userSelector;

  const [selected, setSelected] = useState(
    name?.prefix ? { name: name?.prefix } : { name: "Mr" }
  );

  const CountryOptions = CountryOption;
  const [PhyCountry, setPhyCountry] = useState({
    name: physicalAddress?.country || "United States",
  });

  const [MailingCountry, setMailingCountry] = useState({
    name:
      mailingAddress?.country || physicalAddress?.country || "United States",
  });

  useEffect(() => {
    if (pageChange) {
    }
  }, [pageChange]);

  useImperativeHandle(ref, () => ({
    onSubmit: () => {
      formik.handleSubmit();
    },
  }));

  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      gpaDisplayname: displayName ? displayName : "",
      gpaFirstname: name?.firstName ? name?.firstName : "",
      gpamiddleName: name?.middleName ? name?.middleName : "",
      gpalastName: name?.lastName ? name?.lastName : "",
      gpasuffix: name?.suffix ? name?.suffix : "",
      gpaprimaryEmail: primaryEmail ? primaryEmail : "",
      gpasecondaryEmail: secondaryEmail ? secondaryEmail : "",
      primaryareaCode: primaryPhone?.areaCode ? primaryPhone?.areaCode : "",
      primaryphoneNumber: primaryPhone?.remNumber
        ? primaryPhone?.remNumber
        : "",
      countryCode: primaryPhone?.countryCode ? primaryPhone?.countryCode : "",
      PhysicalstreetAddress: physicalAddress?.streetAddress
        ? physicalAddress?.streetAddress
        : "",
      PhysicalstreetAddress2: physicalAddress?.stAddressLine2
        ? physicalAddress?.stAddressLine2
        : "",
      PhysicalCity: physicalAddress?.city ? physicalAddress?.city : "",
      Physicalstate: physicalAddress?.state ? physicalAddress?.state : "",
      PhysicalpostalCode: physicalAddress?.postalCode
        ? physicalAddress?.postalCode
        : "",
      MailingstreetAddress: mailingAddress?.streetAddress
        ? mailingAddress?.streetAddress
        : "",
      MailingstreetAddress2: mailingAddress?.stAddressLine2
        ? mailingAddress?.stAddressLine2
        : "",
      MailingCity: mailingAddress?.city ? mailingAddress?.city : "",
      MailingState: mailingAddress?.state ? mailingAddress?.state : "",
      MailingpostalCode: mailingAddress?.postalCode
        ? mailingAddress?.postalCode
        : "",
      prefix: { name: name?.prefix } ? { name: name?.prefix } : { name: "Mr" },
      Mailingcountrys: { name: mailingAddress?.country }
        ? { name: mailingAddress?.country }
        : { name: "United States" },
      PhysicalCountry: { name: physicalAddress?.country }
        ? { name: physicalAddress?.country }
        : { name: "United States" },
    },
    onSubmit: (values) => {
      let formData = {};
      formData.name = {
        prefix: values.prefix.name ? values.prefix.name : "Mr",
        firstName: values.gpaFirstname,
        middleName: values.gpamiddleName,
        lastName: values.gpalastName,
        suffix: values.gpasuffix,
      };

      formData.displayName = values.gpaDisplayname;
      formData.primaryEmail = values.gpaprimaryEmail;
      formData.secondaryEmail = values.gpasecondaryEmail;

      formData.primaryPhone = {
        countryCode: String(values.countryCode),
        areaCode: values.primaryareaCode,
        remNumber: values.primaryphoneNumber,
      };

      formData.physicalAddress = {
        streetAddress: values.PhysicalstreetAddress,
        stAddressLine2: values.PhysicalstreetAddress2,
        city: values.PhysicalCity,
        state: values.Physicalstate,
        postalCode: values.PhysicalpostalCode,
        country: values.PhysicalCountry?.name
          ? values.PhysicalCountry?.name
          : "United States",
      };
      formData.mailingAddress = {
        streetAddress: values.MailingstreetAddress,
        stAddressLine2: values.MailingstreetAddress2,
        city: values.MailingCity,
        state: values.MailingState,
        postalCode: values.MailingpostalCode,
        country: values.Mailingcountrys?.name
          ? values.Mailingcountrys?.name
          : "United States",
      };
      dispatch(saveFormData(formData));
      handeClickForm();
    },
  });

  const setValues = () => {
    const values = formik.values;
    formik.setFieldValue("MailingstreetAddress", values.PhysicalstreetAddress);
    formik.setFieldValue(
      "MailingstreetAddress2",
      values.PhysicalstreetAddress2
    );
    formik.setFieldValue("MailingCity", values.PhysicalCity);
    formik.setFieldValue("MailingState", values.Physicalstate);
    formik.setFieldValue("MailingpostalCode", values.PhysicalpostalCode);
    formik.setFieldValue("Mailingcountrys", values.Mailingcountrys?.name);
    formik.setFieldValue("PhysicalCountry", values.PhysicalCountry?.name);
  };

  const clearValues = () => {
    const values = formik.values;
    formik.setFieldValue("MailingstreetAddress", values.MailingstreetAddress);
    formik.setFieldValue("MailingstreetAddress2", values.MailingstreetAddress2);
    formik.setFieldValue("MailingCity", values.MailingCity);
    formik.setFieldValue("MailingState", values.MailingState);
    formik.setFieldValue("MailingpostalCode", values.MailingpostalCode);
    formik.setFieldValue("Mailingcountrys", values.Mailingcountrys?.name);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <label>G.P.A. Level 3 Required Information</label>
            <p>
              The following registration information is required by all C
              <CQRComponent />R Vault Members to complete for access to the C
              <CQRComponent />R Vault Digital Vault, and to qualify for any
              Member Rewards and Royalties as part of our "KYN" (Know Your
              Neighbor) Policy. Submission of the following information
              increases your G.P.A. to Level 3 , thereby qualifying you for
              additional access to the C<CQRComponent />R Vault online digital
              vault platform and other available programs.
            </p>
          </div>
          <form ref={formRef} onSubmit={formik.handleSubmit}>
            <div className={styles.selectionare}>
              {/* <div className={styles.displayfield}>
                <div className={styles.innerselected}>
                  <Input
                    name="Display Name *"
                    inputName="John Doe"
                    type="text"
                    config={formik.getFieldProps("gpaDisplayname")}
                  />
                  {formik.errors.gpaDisplayname &&
                  formik.touched.gpaDisplayname ? (
                    <div style={{ color: "#E5516B" }}>
                      {formik.errors.gpaDisplayname}
                    </div>
                  ) : null}
                </div>
              </div> */}
              <div className={styles.namefield}>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={selected}
                      setSelected={(res) => {
                        formik.setFieldValue("prefix", res);
                        setSelected(res);
                      }}
                      options={options}
                      imgShow={true}
                      name="Prefix *"
                    />
                  </div>

                  <div className={styles.innerselected}>
                    <Input
                      name="First Name *"
                      inputName="First Name"
                      type="text"
                      config={formik.getFieldProps("gpaFirstname")}
                    />
                    {formik.errors.gpaFirstname &&
                    formik.touched.gpaFirstname ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.gpaFirstname}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Middle Name"
                      inputName="Middle Name"
                      type="text"
                      config={formik.getFieldProps("gpamiddleName")}
                    />
                    {formik.errors.gpamiddleName &&
                    formik.touched.gpamiddleName ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.gpamiddleName}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Last Name *"
                      inputName="Last Name"
                      type="text"
                      config={formik.getFieldProps("gpalastName")}
                    />
                    {formik.errors.gpalastName && formik.touched.gpalastName ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.gpalastName}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Suffix / Title"
                      inputName="Suffix / Title"
                      type="text"
                      config={formik.getFieldProps("gpasuffix")}
                    />
                    {/* {formik.errors.gpasuffix && formik.touched.gpasuffix ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.gpasuffix}
                      </div>
                    ) : null} */}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Primary Email *"
                      inputName="Primary Email"
                      type="text"
                      config={formik.getFieldProps("gpaprimaryEmail")}
                    />
                    {formik.errors.gpaprimaryEmail &&
                    formik.touched.gpaprimaryEmail ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.gpaprimaryEmail}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Secondary Email"
                      inputName="Secondry Email"
                      type="text"
                      config={formik.getFieldProps("gpasecondaryEmail")}
                    />
                    {formik.errors.gpasecondaryEmail &&
                    formik.touched.gpasecondaryEmail ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.gpasecondaryEmail}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={styles.phonefield}>
                <div className={styles.heading}>
                  <label>Primary Phone Number</label>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Country Code"
                      inputName="Country Code"
                      type="number"
                      config={formik.getFieldProps("countryCode")}
                    />
                    {formik.errors.countryCode && formik.touched.countryCode ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.countryCode}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Area Code"
                      inputName="Area Code"
                      type="number"
                      config={formik.getFieldProps("primaryareaCode")}
                    />
                    {/* {formik.errors.primaryareaCode &&
                    formik.touched.primaryareaCode ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.primaryareaCode}
                      </div>
                    ) : null} */}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Phone Number *"
                      inputName="Phone number"
                      type="number"
                      config={formik.getFieldProps("primaryphoneNumber")}
                    />
                    {formik.errors.primaryphoneNumber &&
                    formik.touched.primaryphoneNumber ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.primaryphoneNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={styles.addressfield}>
                <div className={styles.heading}>
                  <label>Physical Address </label>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Street Address *"
                      inputName="Address"
                      type="text"
                      config={formik.getFieldProps("PhysicalstreetAddress")}
                    />
                    {formik.errors.PhysicalstreetAddress &&
                    formik.touched.PhysicalstreetAddress ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.PhysicalstreetAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Address Line 2"
                      inputName="Address"
                      type="text"
                      config={formik.getFieldProps("PhysicalstreetAddress2")}
                    />
                    {formik.errors.PhysicalstreetAddress2 &&
                    formik.touched.PhysicalstreetAddress2 ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.PhysicalstreetAddress2}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="City *"
                      inputName="City"
                      type="text"
                      config={formik.getFieldProps("PhysicalCity")}
                    />
                    {formik.errors.PhysicalCity &&
                    formik.touched.PhysicalCity ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.PhysicalCity}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.provinceselected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="State"
                      inputName="State / Province"
                      type="text"
                      config={formik.getFieldProps("Physicalstate")}
                    />
                    {/* {formik.errors.Physicalstate &&
                      formik.touched.Physicalstate ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.Physicalstate}
                      </div>
                    ) : null} */}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Postal Code *"
                      inputName="Postal / Zip Code"
                      type="text"
                      config={formik.getFieldProps("PhysicalpostalCode")}
                    />
                    {formik.errors.PhysicalpostalCode &&
                    formik.touched.PhysicalpostalCode ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.PhysicalpostalCode}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={PhyCountry}
                      setSelected={(val) => {
                        formik.setFieldValue("PhysicalCountry", val);
                        setPhyCountry(val);
                      }}
                      options={CountryOptions}
                      imgShow={true}
                      name="Country *"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  columnGap: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <input
                  type="checkbox"
                  ref={checkboxRef}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValues();
                    } else {
                      clearValues();
                    }
                  }}
                />
                Fill Mailing Address same as Physical address
              </div>

              <div className={styles.mailingfield}>
                <div className={styles.heading}>
                  <label>Mailing Address </label>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="Street Address *"
                      inputName="Address"
                      type="text"
                      config={formik.getFieldProps(
                        // `${
                        //    checkboxRef?.current?.checked
                        //     ? "PhysicalstreetAddress"
                        //     : "MailingstreetAddress"
                        // }`
                        "MailingstreetAddress"
                      )}
                    />
                    {checkboxRef?.current?.checked ? null : formik.errors
                        .MailingstreetAddress &&
                      formik.touched.MailingstreetAddress ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.MailingstreetAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Address Line 2"
                      inputName="Address"
                      type="text"
                      config={formik.getFieldProps(
                        // `${
                        //    checkboxRef?.current?.checked
                        //     ? "PhysicalstreetAddress2"
                        //     : "MailingstreetAddress2"
                        // }`
                        "MailingstreetAddress2"
                      )}
                    />
                    {checkboxRef?.current?.checked ? null : formik.errors
                        .MailingstreetAddress2 &&
                      formik.touched.MailingstreetAddress2 ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.MailingstreetAddress2}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="City *"
                      inputName="City"
                      type="text"
                      config={formik.getFieldProps(
                        // `${ checkboxRef?.current?.checked ? "PhysicalCity" : "MailingCity"}`
                        "MailingCity"
                      )}
                    />
                    {checkboxRef?.current?.checked ? null : formik.errors
                        .MailingCity && formik.touched.MailingCity ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.MailingCity}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.selected}>
                  <div className={styles.innerselected}>
                    <Input
                      name="State"
                      inputName="State / Province"
                      type="text"
                      config={formik.getFieldProps(
                        // `${ checkboxRef?.current?.checked ? "Physicalstate" : "MailingState"}`
                        "MailingState"
                      )}
                    />
                    {checkboxRef?.current?.checked ? null : formik.errors
                        .MailingState && formik.touched.MailingState ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.MailingState}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <Input
                      name="Postal Code *"
                      inputName="Postal / Zip Code"
                      type="text"
                      config={formik.getFieldProps(
                        // `${
                        //    checkboxRef?.current?.checked ? "PhysicalpostalCode" : "MailingpostalCode"
                        // }`
                        "MailingpostalCode"
                      )}
                    />
                    {checkboxRef?.current?.checked ? null : formik.errors
                        .MailingpostalCode &&
                      formik.touched.MailingpostalCode ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.MailingpostalCode}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.innerselected}>
                    <DropDown
                      padding="13px 5px"
                      selected={
                        checkboxRef?.current?.checked
                          ? PhyCountry
                          : MailingCountry
                      }
                      setSelected={(val) => {
                        formik.setFieldValue(
                          checkboxRef?.current?.checked
                            ? "PhysicalCountry"
                            : "Mailingcountrys",
                          val
                        );
                        {
                          checkboxRef?.current?.checked
                            ? setPhyCountry(val)
                            : setMailingCountry(val);
                        }
                      }}
                      options={CountryOptions}
                      imgShow={true}
                      name="Country *"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default forwardRef(GPALevel);
