import React, { useEffect, useState } from "react";
// import QrImg from "../../../../Assets/icons/QrImg.svg";
import styles from "./Send.module.scss";
import InputFields from "../../input/input";
import Done from "../../DashboardDone/popup";
import Successful from "../../../../Assets/image/TransactionSuccessful.svg";
import DropDown from "../../DropDown/dropDown";
// import BitCoin from "../../../../Assets/icons/Btc.svg";
// import litecoin from "../../../../Assets/icons/ETH.svg";
import Doge from "../../../../Assets/icons/dogg.svg";
import { useDispatch, useSelector } from "react-redux";
import { Wave } from "../../loader/Loader";
import {
  WalletActivation,
  currencyCleanUp,
  getCurrencyDetail,
} from "../../../../redux/action/currencyDetailAction";
import { useAlert } from "react-alert";
import {
  sendEthTransaction,
  sendBNBTransaction,
  sendERC20Transaction,
  sendBep20Transaction,
  sendBtcTransaction,
} from "../../../../utils/sendCoinService";
import { UpdateWalletBalance } from "../../../../redux/action/currencyDetailAction";

export default function Send({ location }) {
  // const [currencySelecter, setcurrencySelecter] = useState("");

  const currencySelector = useSelector((state) => state?.currency);
  const userData = useSelector((state) => state?.user?.userInfo);

  const [filterSymbol, setFilterSymbol] = useState("");
  const [filterassets, setFilterassets] = useState(null);
  const [popupvisible, setpopupvisible] = useState(false);
  const [load, setLoad] = useState(false);
  const [selected, setSelected] = useState(
    location
      ? location?.state?.detail
      : currencySelector.currencyInfo.wallets[1]
  );
  const [masterAdress, setMasterAdress] = useState("");
  const [amount, setAmount] = useState("");

  const [estimateAmount, setestimateAmount] = useState("");
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    filterData();
  }, []);

  //------------Check wallet activate success case-----------------
  useEffect(() => {
    if (currencySelector.walletactivationSuccess) {
      alert.show("Wallet activate successfully", { type: "success" });
      setpopupvisible((preview) => {
        return !preview;
      });
      dispatch(currencyCleanUp());
    }
    dispatch(UpdateWalletBalance());
    dispatch(getCurrencyDetail());
    setLoad(false);
  }, [currencySelector.walletactivationSuccess]);

  //---------case when wallets is not activae----------
  useEffect(() => {
    if (!currencySelector?.currencyInfo?.user?.isWalletActivated) {
      setAmount(selected?.total);
      setestimateAmount(selected?.marketData?.rate * selected?.total);
      if (selected?.coinSymbol === "doge" || selected?.coinSymbol === "btc") {
        setMasterAdress(currencySelector?.settings?.masterWalletBTC);
      } else {
        setMasterAdress(currencySelector?.settings?.masterWalletETH);
      }
    }
  }, [selected]);

  //-------------check the wallets is activate or not ----------------
  const filterData = () => {
    if (currencySelector?.currencyInfo?.user?.isWalletActivated) {
      setFilterSymbol(currencySelector?.currencyInfo?.wallets);
      // setSelected(currencySelector?.currencyInfo?.wallets[0]);
    } else {
      let array = [];
      let ass = [];
      currencySelector?.currencyInfo?.wallets?.map((wallet) => {
        let a = currencySelector?.assetsForWalletsActivation?.filter(
          (asset) => {
            if (asset.coinSymbol === wallet.coinSymbol) {
              array.push({ ...wallet, ...asset });
              ass.push(asset);
            }
          }
        );
      });
      setFilterSymbol(array);
      setFilterassets(ass);
      setSelected(array[0]);
    }
  };

  const handleAmountChange = (e) => {
    let lenght = e.target.value.toString().split(".")[1]?.length;

    if (lenght > 8) {
      return;
    } else {
      setAmount(e.target.value);
    }
  };
  const handleMasterAdress = (e) => {
    let lenght = e.target.value.toString().split(".")[1]?.length;

    if (lenght > 8) {
      return;
    } else {
      setMasterAdress(e.target.value);
    }
  };

  //-------------Handle wallet activation error case------------
  useEffect(() => {
    if (!currencySelector.walletactivationError) {
      dispatch(currencyCleanUp());
    }
    dispatch(UpdateWalletBalance());
    setLoad(false);
  }, [currencySelector.walletactivationError]);

  //-------------hanlde wallet active case and send transaction------------
  const togglepopup = async () => {
    setLoad(true);
    if (!currencySelector?.currencyInfo?.user?.isWalletActivated) {
      if (Number(selected?.balance) < Number(amount)) {
        alert.show("insufficient Balance", { type: "error" });
        setLoad(false);
        return;
      }
      dispatch(WalletActivation(selected?.coinSymbol));
    } else {
      setLoad(true);
      const coin = selected;

      if (!amount) {
        alert.show("Please enter amount", { type: "error" });
        setLoad(false);
        return;
      }
      if (!masterAdress) {
        alert.show("Please enter address", { type: "error" });
        setLoad(false);
        return;
      }
      if (Number(coin?.balance) < Number(amount)) {
        alert.show("insufficient Balance", { type: "error" });
        setLoad(false);

        return;
      }
      const params = {
        to: String(masterAdress)?.trim(),
        value: amount,
        coinSymbol: selected?.coinSymbol,
      };

      let trxRes;

      if (coin?.coinSymbol == "btc") {
        try {
          trxRes = await sendBtcTransaction(params);
          setLoad(false);
        } catch (error) {
          setLoad(false);
        }

        if (trxRes.status == "201" || trxRes.status == "200") {
          alert.show(`${coin?.coinSymbol?.toUpperCase()} sent Successfully`, {
            type: "success",
          });
        }
      } else if (!coin.isErc20 && !coin.isBep20) {
        if (coin.coinSymbol == "eth") {
          trxRes = await sendEthTransaction(params);
          if (trxRes.status == "201" || trxRes.status == "200") {
            alert.show(`${coin?.coinSymbol?.toUpperCase()} sent Successfully`, {
              type: "success",
            });
          }
        } else if (coin.coinSymbol == "bnb") {
          trxRes = await sendBNBTransaction(params);
          if (trxRes.status == "201" || trxRes.status == "200") {
            alert.show(`${coin?.coinSymbol?.toUpperCase()} sent Successfully`, {
              type: "success",
            });
          }
        }
      } else if (coin?.isErc20) {
        trxRes = await sendERC20Transaction(params);
      } else if (coin?.isBep20) {
        trxRes = await sendBep20Transaction(params);
      }
      setLoad(false);
      dispatch(UpdateWalletBalance());
      dispatch(getCurrencyDetail());
      setpopupvisible((preview) => {
        return !preview;
      });
    }
  };

  if (popupvisible) {
    return <Done src={Successful} Name="Transaction Successful" />;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cardwrapper}>
            <div className={styles.iconImg}>
              {!currencySelector?.currencyInfo?.user?.isWalletActivated ? (
                <p>
                  Please Deposit the Given Funds to your {selected?.name} Wallet
                  address and then Activate your Vault{" "}
                </p>
              ) : (
                <label>Send</label>
              )}
            </div>
            <div className={styles.labelWrapper}>
              <span>Select Token</span>
              <div className={styles.Selection}>
                <DropDown
                  padding="14px 20px"
                  selected={selected}
                  setSelected={setSelected}
                  options={location ? "" : filterSymbol}
                  imgShow
                  img={Doge}
                />
              </div>
              <div className={styles.topLabel}>
                {/* <span>Your BTC iiiiAddress</span> */}
                <div>
                  {!currencySelector?.currencyInfo?.user?.isWalletActivated ? (
                    // <InputFields
                    //   name="Your coin Address"
                    //   type="text"
                    //   inputName="Address"
                    //   disable={true}
                    //   // onchange={(e) => handleAmountChange(e)}
                    //   value={masterAdress}
                    // />
                    ""
                  ) : (
                    <InputFields
                      name="Please enter Address"
                      type="text"
                      inputName="Address"
                      onclick={(e) => handleMasterAdress(e)}
                      value={masterAdress}
                    />
                  )}
                </div>
                {/* <div className={styles.inputWrapper}>
                  <div className={styles.textWrapper}>
                  

                    {currencySelector?.currencyInfo?.user?.isWalletActivated
                      ? ""
                      : masterAdress}
                  </div>
                  <img src={QrImg} alt="" />
                </div> */}
              </div>
              <div>
                {!currencySelector?.currencyInfo?.user?.isWalletActivated ? (
                  <InputFields
                    name="Your amount is"
                    type="number"
                    inputName="Amount"
                    disable={true}
                    // onchange={(e) => handleAmountChange(e)}
                    value={amount}
                  />
                ) : (
                  <>
                    <InputFields
                      name="Please enter amount"
                      type="number"
                      inputName="Amount"
                      onclick={(e) => handleAmountChange(e)}
                      value={amount}
                    />
                    <div className={styles.usdwrapper}>
                      <p>Value in USD</p>
                      <label>
                        {selected != null &&
                          selected?.marketData?.rate * amount}
                      </label>
                    </div>
                  </>
                )}
              </div>
              <div>
                {currencySelector?.currencyInfo?.user?.isWalletActivated ? (
                  // <InputFields
                  //   Name="Your amount is"
                  //   type="number"
                  //   inputName="Amount"
                  //   disable={true}
                  //   // onchange={(e) => handleAmountChange(e)}
                  //   value={amount}
                  // />
                  ""
                ) : (
                  <InputFields
                    name="Estimate Amount in USD"
                    type="number"
                    inputName="Estimate amount"
                    onclick={(e) => handleAmountChange(e)}
                    value={estimateAmount}
                  />
                )}
              </div>
              {/* <div className={styles.btn2}>
                <div className={styles.btn}>25%</div>
                <div className={styles.btn}>50%</div>
                <div className={styles.btn}>75%</div>
                <div className={styles.btn}>100%</div>
              </div>
              <div className={styles.bottomLabel}>
                <InputFields
                  style={{ background: " #161824" }}
                  Name="Estimated Amount in USD"
                  type="number"
                  inputName="Amount"
                />
                <div className={styles.p3}>Estimated Balance 0.00BTC</div>
              </div> */}

              {load ? (
                <button className={styles.btn3}>
                  <Wave />
                </button>
              ) : userData.isWalletActivated ? (
                <button className={styles.btn3} onClick={togglepopup}>
                  Send Now
                </button>
              ) : (
                <button className={styles.btn3} onClick={togglepopup}>
                  Activate Now
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
