import React, { useEffect, useState } from "react";
import styles from "./AdvantEDGE.module.scss";
import Data from "../../../../Data/home/AdvantedgeData";
import Aos from "aos";
import "aos/dist/aos.css";

const AdvantEDGE = () => {
  useEffect(() => {
    Aos.init();
  });

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.textSection}>
          <label
            data-aos="fade-down"
            data-aos-duration="2000"
            data-aos="fade-down"
          >
            Who Are <span>We?</span>
          </label>
          <p
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-easing="ease-in-sine"
          >
            Get the CQR Vault Advant<span>EDGE!</span>
          </p>
          <div className={styles.dec}>
            We’re redefining private equity as a function of cash flow one
            family at a time! Now you can obtain financial freedom with a true
            wealth preservation vehicle generally afforded to the Elite right
            here on Main Street! CQR Vault is a social exchange platform where
            you can create turn-key cash flows established by digital
            “Stablekoinonia!” CQR Vault bears your “Legacy Preservation
            Covenant” domiciled in F.B.O. Private Member Association that bears
            mandatory exception via exclusive social exchange honorariums
            declared in Relationship and Royalty Sharing Covenant. Open your CQR
            Vault today!
          </div>
        </div>
        <div className={styles.cardWrapper}>
          {Data.map((dataValue) => {
            const { title, dec, img, id } = dataValue;
            return (
              <div
                className={styles.card}
                key={id}
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
                data-aos="flip-right"
              >
                <div className={styles.textWrapper}>
                  <img src={img} alt="" />
                  <label>{title}</label>
                  <p>{dec}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdvantEDGE;
