import React, { useEffect, useState } from "react";
import styles from "./SubscriptionTable.module.scss";
import { HistoryData } from "./SubscriptionData";
import moment from "moment";
import { HTTP_CLIENT } from "../../../../../../utils/services";
import { Loader } from "../../../../../_Common/loader/Loader";
import emptyImg from "../../../../../../../src/Assets/gif/empty.svg";

const SubscriptionTable = () => {
  const [assetsData, setAssetsData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    AssetsHistory();
  }, []);
  const AssetsHistory = async () => {
    setLoading(true);
    let response = await HTTP_CLIENT.get("/subscription-log");
    setAssetsData(response?.data?.reverse());
    setLoading(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {loading ? (
            <div className={styles.loaderPosition}>
              <Loader />
            </div>
          ) : (
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>Remittance Method</td>
                <td>Units Amount</td>
                <td>Subscription On</td>
                {/* <td>Created At</td> */}
              </tr>

              {assetsData?.length == 0 ? (
                <div className={styles.emptyImg}>
                  <img src={emptyImg} alt="" />
                </div>
              ) : (
                assetsData?.map((item) => {
                  return (
                    <tr key={item?._id}>
                      <td>
                        <div
                          className={styles.estApyWrapper}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.paymentMethod === "nativeToken"
                            ? "CQR Dollar"
                            : item?.paymentMethod}
                        </div>
                      </td>
                      <td>
                        <div className={styles.amountWrapper}>
                          {item?.paymentAmount}
                        </div>
                      </td>
                      <td>
                        <div className={styles.estApyWrapper}>
                          {moment(item?.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </div>
                      </td>
                      {/* <td>
                        <div className={styles.estApyWrapper}>
                          {moment(item?.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </div>
                      </td> */}
                    </tr>
                  );
                })
              )}
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionTable;
