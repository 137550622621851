import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { customerCleanup } from "../../../../../../../redux/action/customerRegistrationAction";
import { getAssetsToAssetsSawap } from "../../../../../../../redux/action/sawapAction";
import { activationWalletToken } from "../../../../../../../services/paymentServices";
import { DIRECT_WIRE_TYPE } from "../../../../../../../utils/AppConstants";
import {
  handlePayPalCheckout,
  handleStartCheckout,
} from "../../../../../../../utils/customerService";
import CQRComponent from "../../../../../../_Common/CQRComponent/CQRComponent";
import InputField from "../../../../../../_Common/input/input";
import {
  Loader,
  LoadingButton,
  WaveClone,
} from "../../../../../../_Common/loader/Loader";
import Modal from "../../../../../../_Common/Modal/Modal";
import Popup from "../../../../../../_Common/paymentSelection/popup";
import styles from "./send.module.scss";

const Send = () => {
  const alert = useAlert();
  const currencySelector = useSelector((state) => state?.currency);

  const userData = useSelector((state) => state?.user?.userInfo);

  const customerSelector = useSelector((state) => state?.customer);
  const silverRate = useSelector(
    (state) => state?.currency?.currencyInfo?.nativeWallets
  );
  const isWalletCheck = useSelector(
    (state) => state?.user?.userInfo?.isWalletActivated
  );

  const isWalletCheckd = useSelector((state) => state?.user);

  const options = currencySelector?.currencyInfo?.nativeWallets;

  const [selected, setSelected] = useState(options[0]);
  const sawapSelector = useSelector((state) => state?.sawap);
  const [swap, setSwap] = useState("deposit");
  const [valueGet, setValueGet] = useState("");

  const [activationAmount, setActivationAmount] = useState("");
  const [filterQSymbol, setfilterQSymbol] = useState(null);
  const [loading, setLoading] = useState(false);

  const [getValue, setgetValue] = useState();
  const [params, setParams] = useState(null);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (customerSelector?.walletFiatSuccess === "Token Swap successfully") {
      if (currencySelector?.currencyInfo?.user?.sdira) {
        alert.show("Token request sent to admin please wait for approval", {
          type: "success",
        });
      } else {
        alert.show(
          // `You have successfully purchase ${selected.coinSymbol} USD`,
          `You have successfully acquired CQR Dollars`,

          {
            type: "success",
          }
        );
      }
    } else if (customerSelector?.walletFiatError) {
      alert.show(customerSelector?.walletFiatError, { type: "error" });
    }
  }, [customerSelector]);

  useEffect(() => {
    dispatch(customerCleanup());
    setSelected(options[0]);
    // setActivationAmount(flterData?.price * 21 * flterData?.multiplier);

    const filterQ = currencySelector?.currencyInfo?.nativeWallets?.find(
      (m) => m.coinSymbol === "Q"
    );
    setfilterQSymbol(filterQ);
  }, []);

  useEffect(() => {
    if (customerSelector?.walletFiatSuccess === "Token Swap successfully") {
      dispatch(customerCleanup());
      // history.push("./cryptocurrency");
      history.goBack();
    }
  }, [customerSelector?.walletFiatSuccess]);

  const formikScheme = yup.object({
    // to: yup.string().required("Please select coin"),
    amount: yup
      .number()
      .positive("Invalid amount")
      .max(99999999, "Amount less then 8")
      .required("Amount is required")
      .test(
        "maxDigitsAfterDecimal",
        "number field must have 8 digits after decimal or less",
        (number) => /^\d+(\.\d{0,8})?$/.test(number)
      ),
  });
  const formik = useFormik({
    validationSchema: formikScheme,
    initialValues: {
      amount: "",
      // to: selected.coinSymbol ? selected.coinSymbol : "",
    },

    onSubmit: async (values, reset) => {
      let amount = Number(values?.amount).toFixed(2);
      let to = { to: filterQSymbol };
      let data = {
        // to: values?.to,
        to: to,
        amount: Number(amount),
      };
      setParams(data);
      setShow(true);

      // dispatch(walletActiveViaFiat(data));
    },
  });

  const handlePaymentViaGoCardless = () => {
    history.push("/payment-selection");

    // if (params) {
    //   dispatch(walletActiveViaFiat(params));

    // }
  };
  const handleSend = async () => {
    setShow(true);
    return;
    // setLoading(true);
    // try {
    //   const res = await HTTP_CLIENT.post(
    //     "/native-wallet/activateUserWalletByFiat"
    //   );
    //   alert.show("Wallet has been activated successfully", { type: "success" });
    //   await dispatch(userInfo(res.data.user));
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    // }
  };

  const handlePayPalActivation = async () => {
    let data = {
      paymentType: !userData?.isWalletActivated
        ? DIRECT_WIRE_TYPE?.WALLET_ACTIVATION
        : DIRECT_WIRE_TYPE?.TOKEN_PURCHASE,
      tokenId: selected?._id,
      amount: params?.amount,
    };

    try {
      setLoading(true);
      const res = await handlePayPalCheckout(data);
      let response = res?.data?.links;
      let urlresp = response?.find((curr) => curr?.method == "REDIRECT");
      window.open(urlresp?.href, "_blank", "noopener,noreferrer");
      setShow(false);
      history.goBack();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.error(error?.response?.data?.message || "something error occurred");
    }
  };

  const handleWalletActivation = async () => {
    let data = {
      paymentType: !userData?.isWalletActivated
        ? DIRECT_WIRE_TYPE?.WALLET_ACTIVATION
        : DIRECT_WIRE_TYPE?.TOKEN_PURCHASE,
      tokenId: params?.to?.to?.coin,
      amount: params?.amount,
    };
    try {
      setLoading(true);
      const res = await handleStartCheckout(data);
      window.open(res?.data?.url, "_blank", "noopener,noreferrer");
      setShow(false);
      history.goBack();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.error(error?.response?.data?.message || "something error occurred");
    }
  };

  const handleCoinQvest = async () => {
    if (!userData?.isCoinqvestCustomer) {
      history.push("/register-qvest");
    } else {
      setLoading(true);
      await handleWalletActivation();
      setLoading(false);
    }
  };
  const handleModalOk = () => {
    setLoading(true);
    // if (activationAmount > 5000) {
    //   alert.show("Amount cannot be greater then 5000", { type: "error" });
    //   setShow(false);
    //   return;
    // }
    const filterData = options?.find((m) => m.coinSymbol === "XAG");

    history.push({
      pathname: "/direct-wire",
      state: {
        detail: {
          ...params,
          amount: isWalletCheck ? params?.amount : activationAmount,
          tokenAmount: activationAmount / filterData?.price,
          tokenRate: Number(filterData?.price),
        },
        activationAmount: activationAmount,
        type: isWalletCheck
          ? DIRECT_WIRE_TYPE?.TOKEN_PURCHASE
          : DIRECT_WIRE_TYPE?.WALLET_ACTIVATION,
      },
    });
    setLoading(false);
  };
  const walletActiveData = async () => {
    const data = {
      activationType: "fiat",
      type: "required",
    };
    try {
      const res = await activationWalletToken(data);
      setActivationAmount(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    walletActiveData();
  }, []);

  const valueChanger = () => {
    let data = {
      selected: selected,
      amount: "1",
    };
    try {
      dispatch(getAssetsToAssetsSawap(data, swap));
    } catch (error) {}
  };

  useEffect(() => {
    valueChanger();
  }, [selected]);
  useEffect(() => {
    setValueGet(formik.values.amount * sawapSelector?.assetSwaoInfo);
  }, [formik.values.amount]);
  useEffect(() => {
    setValueGet(formik.values.amount * sawapSelector?.assetSwaoInfo);
  }, [sawapSelector]);
  return (
    <div className={styles.container}>
      {customerSelector.walletFiatLoading ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <>
            {!isWalletCheck ? (
              <>
                <label>Member Activation</label>
                <span className={styles.text}>
                  To open your C<CQRComponent />R Vault, you must add & maintain
                  a C<CQRComponent />R Dollar (“ C<CQRComponent />R ”)
                  contribution ≥ the value of 21 Ag Asset Tokens (21oz 0.999
                  Fine Silver).
                  <br />
                  <br />
                  Please remit the amount below or more to open your C
                  <CQRComponent />R Vault account:
                </span>
                <InputField
                  Name="Redeem Amount"
                  type="number"
                  inputName="amount"
                  value={Number(activationAmount).toFixed(2)}
                  img3
                />
                {loading ? (
                  <LoadingButton Loading="Loading" />
                ) : (
                  <button className={styles.btnBtn} onClick={handleSend}>
                    Open
                  </button>
                )}
              </>
            ) : (
              <>
                <label>
                  Get C
                  <CQRComponent
                    spanStyle={{ fontSize: "38px", width: "32px" }}
                  />
                  R Dollars
                </label>
                <span className={styles.text}>
                  Each <CQRComponent />
                  1.00 C<CQRComponent />R bears the beneficial interest value of
                  $1.00 USD domiciled in the C<CQRComponent />R Vault for the
                  self-directed value exchange(s) made by and between Bona Fide
                  Participant Members of C<CQRComponent />R Vault.
                  <br />
                  <br />
                  Simply enter the amount of C<CQRComponent />R Dollars you want
                  to hold in your C<CQRComponent />R Vault, and put C
                  <CQRComponent />R Dollars (a.k.a. C<CQRComponent />R or “
                  <CQRComponent />
                  ”) in your digital vault today!
                </span>
                <form onSubmit={formik.handleSubmit}>
                  <div className={styles.inputWrapper}>
                    <p>Select Token</p>
                    <div className={styles.divWrapper}>
                      C
                      <CQRComponent spanStyle={{ width: "16px" }} />
                      R Dollar (" <CQRComponent
                        spanStyle={{ width: "16px" }}
                      />{" "}
                      ")
                    </div>
                  </div>
                  {/* <DropDown
                    padding="14px 20px"
                    selected={selected}
                    setSelected={(res) => {
                      formik.setFieldValue("to", res.coinSymbol);
                      setSelected(res);
                    }}
                    options={options}
                    img={BitCoin}
                    imgShow
                  />
                  {formik.errors.to && formik.touched.to ? (
                    <div style={{ color: "#E5516B" }}>{formik.errors.to}</div>
                  ) : null} */}
                  <InputField
                    Name="Redeem Amount"
                    type="number"
                    // inputName="Max Exchange in 24hrs is 25,000.00 CQR"
                    config={formik.getFieldProps("amount")}
                    img3
                  />
                  {formik.errors.amount && formik.touched.amount ? (
                    <div style={{ color: "#E5516B" }}>
                      {formik.errors.amount}
                    </div>
                  ) : null}
                  <p className={styles.QSymbolImg}>
                    Max Online Exchange is <CQRComponent />
                    5,000.00 / Direct Wire (No Limit)
                  </p>
                  <InputField
                    name="Quantity of Tokens you will Receive"
                    type="number"
                    value={valueGet}
                    disable
                  />

                  <button className={styles.btnBtn} type="submit">
                    Get Now
                  </button>
                </form>
              </>
            )}
          </>
        </div>
      )}
      <Modal visible={show} onClose={() => setShow(false)}>
        <Popup
          // src={logo}
          para="Please select your method for settlement"
          btn="Direct wire"
          btn1="Go cardless"
          QVestbtn="COINQVEST"
          Paypalbtn="PayPal"
          loading={loading}
          BtnShow
          tokenData={params}
          activationAmount={activationAmount}
          handleCoinQvest={() => handleCoinQvest()}
          handleOk={handleModalOk}
          handlePayPalRes={() => handlePayPalActivation()}
          // onPressButton={() => history.push("/")}
          onPressinnerButton={() => {
            setShow(false);
            // formik.handleSubmit()
            handlePaymentViaGoCardless();
          }}
        />
      </Modal>
    </div>
  );
};

export default Send;
