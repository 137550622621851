import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import SignaturePad from "react-signature-canvas";
import * as yup from "yup";
import noDataGif from "../../../Assets/gif/noData.gif";
import Remmitanceplus from "../../../Assets/icons/remmitanceplus.svg";
import star from "../../../Assets/icons/whiteStar.svg";
import Cross from "../../../Assets/image/stop.svg";
import InputField from "../../../Components/_Common/input/input";
import { CountryOption } from "../../../Data/dropDownItems/dropdownData";
import { HTTP_CLIENT } from "../../../utils/services";
import DropDown from "../../_Common/DropDown/dropDown";
import Modal from "../Modal/Modal";
import DetailModal from "./detailModal";
import styles from "./remittanceAddress.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  saveRemittanceAddress,
  saveSignleRemittanceAddress,
} from "../../../redux/action/remittanceAction";
import {
  getAddress,
  submitAddress,
  uploadImage,
} from "../../../services/paymentServices";
import { Loader, WaveClone } from "../loader/Loader";
import NoData from "../noData";

const RemittanceAddresslist = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listloading, setlistLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [saveIndex, setSaveIndex] = useState(null);
  const [detailShow, setdetailShow] = useState(false);
  const [detailRemittance, setdetailRemittance] = useState(null);
  const [todoId, setTodoId] = useState("");

  let [agree, setAgree] = useState({ isAgree: false, notAgreed: false });
  const dispatch = useDispatch();
  const CountryOptions = CountryOption;
  const [selected, setSelected] = useState({ name: "United States" });
  const [deleteShow, setDeleteShow] = useState(false);
  const { remittanceAddressList, signleRemittanceAddress } = useSelector(
    (state) => state.remittance
  );

  let sigPad = useRef({});
  const alert = useAlert();
  const formData = new FormData();

  const Clear = () => {
    sigPad.current.clear();
  };

  const formikSchema = yup.object({
    name: yup.string().required("Name is required").trim(),
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required")
      .trim(),
    city: yup.string().required("City is required").trim(),
    // phone: yup.string().required("Phone number is required").trim(),
    state: yup.string().required("state is required").trim(),
    streetAddress: yup.string().required("streetAddress is required").trim(),
    zipCode: yup.string().required("zip Code is required").trim(),
  });

  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      name: "",
      email: "",
      city: "",
      state: "",
      streetAddress: "",
      zipCode: "",
      country: { name: "United States" },
    },
    onSubmit: async (values) => {
      handleSubmit(values);
      // reset.resetForm()
    },
  });
  const handleSubmit = async (values) => {
    let sign = sigPad.current.toDataURL();
    if (sigPad.current.isEmpty()) {
      alert.show("Please upload signature first", { type: "error" });
      return;
    }
    if (!agree.isAgree) {
      setAgree((prev) => ({ ...prev, notAgreed: true }));
      return;
    }
    setLoading(true);
    formData.append("type", "directWire");
    formData.append("file", sign);

    try {
      let res = await uploadImage(formData);

      if (res.data.url) {
        let param = {
          name: values.name,
          country: values.country.name,
          streetAddress: values.streetAddress,
          city: values.city,
          state: values?.state,
          zipCode: values.zipCode,
          email: values.email,
          signatureUrl: res.data.url,
          // phoneNumber: values?.phone,
        };
        const response = await submitAddress(param);
        alert.show(response?.data?.message, { type: "success" });
        setLoading(false);
        setShow(false);
        setAgree((prev) => ({
          ...prev,
          isAgree: false,
          notAgreed: false,
        }));
        formik.resetForm();
      }
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setLoading(false);
      setAgree((prev) => ({
        ...prev,
        isAgree: false,
        notAgreed: false,
      }));
    }
  };

  const fetchAddress = async () => {
    try {
      setlistLoading(true);
      const response = await getAddress();
      dispatch(saveRemittanceAddress(response.data));
      setlistLoading(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setlistLoading(false);
    }
  };

  const handleprimaryAddress = async (item, index) => {
    dispatch(saveSignleRemittanceAddress(item));
    setSaveIndex(item._id);
  };

  useEffect(() => {
    if (!show) {
      fetchAddress();
    }
    if (signleRemittanceAddress) {
      setSaveIndex(signleRemittanceAddress._id);
    }
  }, [show, reload]);

  const handleOpen = (data) => {
    setdetailRemittance(data);
    setdetailShow(true);
  };

  const DeleteTodo = async (id) => {
    try {
      setLoading(true);
      const response = await HTTP_CLIENT.delete(`/remittance-addresses/${id}`);
      setDeleteShow(false);
      setReload(!reload);
      setLoading(false);
    } catch (error) {
      alert.show(error?.response?.data?.message, { type: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <DetailModal
        detailRemittance={detailRemittance}
        visible={detailShow}
        btn
        onClose={() => {
          setdetailShow(false);
          // onClose();
        }}
      />
      <Modal
        visible={show}
        btn
        onClose={() => {
          setShow(false);
          // onClose();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.passwordmodal}>
            <div className={styles.heading}>
              <p>Add Banking Details</p>
            </div>
            <div className={styles.inputfields}>
              <InputField
                Name="Name"
                value={12}
                inputName="Name"
                name="Name *"
                type="text"
                config={formik.getFieldProps("name")}
              />
              {formik.errors.name && formik.touched.name ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.name}</div>
              ) : null}
              <InputField
                Name="Email"
                value={12}
                inputName="Email"
                name="Email *"
                type="text"
                config={formik.getFieldProps("email")}
              />
              {formik.errors.email && formik.touched.email ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.email}</div>
              ) : null}
              <InputField
                Name="street Address"
                value={12}
                inputName="Street Address"
                name="Street Address *"
                type="text"
                config={formik.getFieldProps("streetAddress")}
              />
              {formik.errors.streetAddress && formik.touched.streetAddress ? (
                <div style={{ color: "#E5516B" }}>
                  {formik.errors.streetAddress}
                </div>
              ) : null}
              {/* <InputField
                Name="phone"
                value={12}
                inputName="Phone Number"
                name="Phone Number *"
                type="text"
                config={formik.getFieldProps("phone")}
              />
              {formik.errors.phone && formik.touched.phone ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.phone}</div>
              ) : null} */}
              <InputField
                Name="City"
                value={12}
                inputName="City"
                name="City *"
                type="text"
                config={formik.getFieldProps("city")}
              />
              {formik.errors.city && formik.touched.city ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.city}</div>
              ) : null}
              <InputField
                Name="state"
                value={12}
                inputName="State"
                name="State *"
                type="text"
                config={formik.getFieldProps("state")}
              />
              {formik.errors.state && formik.touched.state ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.state}</div>
              ) : null}
              <InputField
                Name="zip code"
                value={12}
                inputName="Zip code"
                name="Zip code *"
                type="text"
                config={formik.getFieldProps("zipCode")}
              />
              {formik.errors.zipCode && formik.touched.zipCode ? (
                <div style={{ color: "#E5516B" }}>{formik.errors.zipCode}</div>
              ) : null}
              <DropDown
                padding="13px 5px"
                selected={selected}
                setSelected={(res) => {
                  formik.setFieldValue("country", res);
                  setSelected(res);
                }}
                options={CountryOptions}
                imgShow={true}
                name="Country *"
              />
              Your Signature *
              <div className={styles.signature}>
                <SignaturePad
                  ref={sigPad}
                  penColor="#40a1dc"
                  canvasProps={{
                    width: 350,
                    height: 100,
                    className: "sigCanvas",
                  }}
                />
              </div>
              <div
                style={{ width: "100%", textAlign: "right", color: "#03b2cb" }}
                onClick={Clear}
              >
                Clear
              </div>
              <div className={styles.termAndCondition}>
                By affixing my e-signature above and checking the box below, I
                confirm that I have read, understand and agree to all of CQR
                Vault’s terms and conditions pursuant with my Relationship &
                Royalty Sharing Covenant(s). I authorize my personal data to be
                used to process my remittance request, and to support my
                experience throughout CQR Vault, and for other purposes
                described in CQR Vaults's privacy policy.
              </div>
              <div
                className={styles.check}
                onClick={() =>
                  setAgree((prev) => ({
                    ...prev,
                    isAgree: !prev.isAgree,
                    notAgreed: false,
                  }))
                }
              >
                <input type="checkbox" /> I have read and agree to the website{" "}
                <span>terms & conditions </span> and understand that I cannot
                edit or modify, but only add to my information upon submission.
                *
              </div>
              {agree.notAgreed && (
                <small style={{ color: "#E5516B", textAlign: "left" }}>
                  You need to agree to our terms and conditions.
                </small>
              )}
            </div>
            <div className={styles.btn}>
              {loading ? (
                <button type="button">
                  <WaveClone />
                </button>
              ) : (
                <button type="submit">Confirm</button>
              )}
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        visible={deleteShow}
        btn
        onClose={() => {
          setDeleteShow(false);
          // onClose();
        }}
      >
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Are you sure you want to delete this Remittance?</p>
          </div>
          <div className={styles.buttonset}>
            {loading ? (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => {
                  // DeleteData();
                }}
              >
                <WaveClone />
              </button>
            ) : (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => {
                  // DeleteData();
                  DeleteTodo(todoId);
                }}
              >
                Delete
              </button>
            )}

            <button
              className={styles.Delete}
              style={{ marginLeft: "3rem" }}
              onClick={() => setDeleteShow(false)}
            >
              {" "}
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {listloading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.addbutton}>
              <div style={{ width: "100%", color: "gray" }}>
                Select Member account address recorded with financial
                institution by clicking star, or add account information by
                clicking "+".
              </div>
              <img
                onClick={() => setShow(true)}
                src={Remmitanceplus}
                alt="plus"
              />
            </div>

            <div className={styles.tableWrapper}>
              <table
                cellspacing="0"
                cellpadding="0"
                style={{ textAlign: "center" }}
              >
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                {remittanceAddressList === 0 ? (
                  <NoData />
                ) : (
                  remittanceAddressList?.map((item, index) => (
                    <tr>
                      <img
                        onClick={() => {
                          setDeleteShow(true);
                          setTodoId(item._id);
                        }}
                        className={styles.cross}
                        src={Cross}
                      />

                      <td onClick={() => handleOpen(item)}>
                        <div className={styles.nameWrapper}>
                          <img src={item?.signatureUrl} alt="" />
                          <div>
                            <div className={styles.heading}>{item?.name}</div>
                            <div className={styles.description}>
                              {item?.email}
                            </div>
                            <div className={styles.description}>
                              {item?.streetAddress}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className={styles.estApyWrapper}>
                          <img
                            src={star}
                            className={`${
                              saveIndex == item._id ? styles.img2 : styles.img1
                            }`}
                            onClick={() => handleprimaryAddress(item, index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RemittanceAddresslist;
