import { combineReducers } from "redux";
import userReducer from "../reducer/userReducer";
import currencyDetailReducer from "./currencyDetailReducer";
import sawapReducer from "./sawapReducer";
import customerReducer from "./customerRegistrationReducer";
import subscriptionsReducer from "./subscriptionReducer";
import createAccountReducer from "./createAccount";
import remittanceReducer from "./remittanceAddressReducer";
import marketplaceReducer from "./marketplace";

export default combineReducers({
  user: userReducer,
  currency: currencyDetailReducer,
  sawap: sawapReducer,
  customer: customerReducer,
  subscription: subscriptionsReducer,
  account: createAccountReducer,
  remittance: remittanceReducer,
  marketplace: marketplaceReducer,
});
