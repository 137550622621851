import Jurisdictional from "../../Assets/icons/Jurisdictional.svg";
import Wealth from "../../Assets/icons/Wealth.svg";
import Cash from "../../Assets/icons/Cash.svg";
import Confidential from "../../Assets/icons/Confidential.svg";
import Fraud from "../../Assets/icons/Fraud.svg";
import Send from "../../Assets/icons/Send.svg";
import Save from "../../Assets/icons/Save.svg";
import Exchange from "../../Assets/icons/Exchange.svg";
import Global from "../../Assets/icons/Global.svg";
import Rewards from "../../Assets/icons/Rewards.svg";

const AdvantedgeData = [
  {
    id: "1",
    img: Jurisdictional,
    title: "Jurisdictional Superiority ",
    dec:
      "All Bona Fide Participant Member exchanges are performed Mandatory Tax-Excepted under 508c(1)A",
  },
  {
    id: "2",
    img: Wealth,
    title: "Wealth Preservation Supremacy",
    dec:
      "Securely store wealth w/real-time digital accountancy provided via our robust social exchange platform​",
  },
  {
    id: "3",
    img: Cash,
    title: "Cash Flow",
    dec:
      "Generate cash flow by deploying private equity positions within a private member association via CQR Vest",
  },
  {
    id: "4",
    img: Confidential,
    title: "Confidential Records",
    dec:
      "Stop identity theft & protect your privacy by communicating and settling exchanges inside your CQR Vault",
  },
  {
    id: "5",
    img: Fraud,
    title: "Fraud Prevention",
    dec:
      "Safeguard your info because no card, checks or banks are required for value exchanges between members in CQR Vault",
  },
  {
    id: "6",
    img: Send,
    title: "Real Money",
    dec:
      "Privately exchange the beneficial value of Real Money (Gold/Silver) with other members in real time",
  },
  {
    id: "7",
    img: Save,
    title: "Save on Fees",
    dec:
      "Avoid unnecessary banking fees, windfall taxes, high exchange rates, fiat currency inflation, etc",
  },
  {
    id: "8",
    img: Exchange,
    title: "Exchange Anywhere",
    dec:
      "Finalize exchanges via Mobile App with any other member, and also everywhere CQR Vault is honored",
  },
  {
    id: "9",
    img: Global,
    title: "Global Settlements",
    dec:
      "It's easy for Small Business Members (SBM) to start accepting global settlements with zero chargeback",
  },
  {
    id: "10",
    img: Rewards,
    title: "Royalties & Rewards",
    dec:
      "Enjoy promotional savings from your favorite SBMs with geo targeted CQR Rewards & Asset Pool Royalties ",
  },
];
export default AdvantedgeData;
