export const CountryOption = [
  { name: "United States" },
  { name: "Afghanistan" },
  { name: "land Islands" },
  { name: "Albania" },
  { name: "Algeria" },
  { name: "American Samoa" },
  { name: "AndorrA" },
  { name: "Angola" },
  { name: "Anguilla" },
  { name: "Antarctica" },
  { name: "Antigua and Barbuda" },
  { name: "Argentina" },
  { name: "Armenia" },
  { name: "Aruba" },
  { name: "Australia" },
  { name: "Austria" },
  { name: "Azerbaijan" },
  { name: "Bahamas" },
  { name: "Bahrain" },
  { name: "Bangladesh" },
  { name: "Barbados" },
  { name: "Belarus" },
  { name: "Belgium" },
  { name: "Belize" },
  { name: "Benin" },
  { name: "Bermuda" },
  { name: "Bhutan" },
  { name: "Bolivia" },
  { name: "Bosnia and Herzegovina" },
  { name: "Botswana" },
  { name: "Bouvet Island" },
  { name: "Brazil" },
  { name: "British Indian Ocean Territory" },
  { name: "Brunei Darussalam" },
  { name: "Bulgaria" },
  { name: "Burkina Faso" },
  { name: "Burundi" },
  { name: "Cambodia" },
  { name: "Cameroon" },
  { name: "Canada" },
  { name: "Cape Verde" },
  { name: "Cayman Islands" },
  { name: "Central African Republic" },
  { name: "Chad" },
  { name: "Chile" },
  { name: "China" },
  { name: "Christmas Island" },
  { name: "Cocos (Keeling) Islands" },
  { name: "Colombia" },
  { name: "Comoros" },
  { name: "Congo" },
  { name: "Congo, The Democratic Republic of the" },
  { name: "Cook Islands" },
  { name: "Costa Rica" },
  { name: "Cote" },
  { name: "Croatia" },
  { name: "Cuba" },
  { name: "Cyprus" },
  { name: "Czech Republic" },
  { name: "Denmark" },
  { name: "Djibouti" },
  { name: "Dominica" },
  { name: "Dominican Republic" },
  { name: "Ecuador" },
  { name: "Egypt" },
  { name: "El Salvador" },
  { name: "Equatorial Guinea" },
  { name: "Eritrea" },
  { name: "Estonia" },
  { name: "Ethiopia" },
  { name: "Falkland Islands (Malvinas)" },
  { name: "Faroe Islands" },
  { name: "Fiji" },
  { name: "Finland" },
  { name: "France" },
  { name: "French Guiana" },
  { name: "French Polynesia" },
  { name: "French Southern Territories" },
  { name: "Gabon" },
  { name: "Gambia" },
  { name: "Georgia" },
  { name: "Germany" },
  { name: "Ghana" },
  { name: "Gibraltar" },
  { name: "Greece" },
  { name: "Greenland" },
  { name: "Grenada" },
  { name: "Guadeloupe" },
  { name: "Guam" },
  { name: "Guatemala" },
  { name: "Guernsey" },
  { name: "Guinea" },
  { name: "Guinea-Bissau" },
  { name: "Guyana" },
  { name: "Haiti" },
  { name: "Heard Island and Mcdonald Islands" },
  { name: "Holy See (Vatican City State)" },
  { name: "Honduras" },
  { name: "Hong Kong" },
  { name: "Hungary" },
  { name: "Iceland" },
  { name: "India" },
  { name: "Indonesia" },
  { name: "Iran, Islamic Republic Of" },
  { name: "Iraq" },
  { name: "Ireland" },
  { name: "Isle of Man" },
  { name: "Israel" },
  { name: "Italy" },
  { name: "Jamaica" },
  { name: "Japan" },
  { name: "Jersey" },
  { name: "Jordan" },
  { name: "Kazakhstan" },
  { name: "Kenya" },
  { name: "Kiribati" },
  { name: "Korea" },
  { name: "Korea, Republic of" },
  { name: "Kuwait" },
  { name: "Kyrgyzstan" },
  { name: "Lao" },
  { name: "Latvia" },
  { name: "Lebanon" },
  { name: "Lesotho" },
  { name: "Liberia" },
  { name: "Libyan Arab Jamahiriya" },
  { name: "Liechtenstein" },
  { name: "Lithuania" },
  { name: "Luxembourg" },
  { name: "Macao" },
  { name: "Macedonia, The Former Yugoslav Republic of" },
  { name: "Madagascar" },
  { name: "Malawi" },
  { name: "Malaysia" },
  { name: "Maldives" },
  { name: "Mali" },
  { name: "Malta" },
  { name: "Marshall Islands" },
  { name: "Martinique" },
  { name: "Mauritania" },
  { name: "Mauritius" },
  { name: "Mayotte" },
  { name: "Mexico" },
  { name: "Micronesia, Federated States of" },
  { name: "Moldova, Republic of" },
  { name: "Monaco" },
  { name: "Mongolia" },
  { name: "Montenegro" },
  { name: "Montserrat" },
  { name: "Morocco" },
  { name: "Mozambique" },
  { name: "Myanmar" },
  { name: "Namibia" },
  { name: "Nauru" },
  { name: "Nepal" },
  { name: "Netherlands" },
  { name: "Netherlands Antilles" },
  { name: "New Caledonia" },
  { name: "New Zealand" },
  { name: "Nicaragua" },
  { name: "Niger" },
  { name: "Nigeria" },
  { name: "Niue" },
  { name: "Norfolk Island" },
  { name: "Northern Mariana Islands" },
  { name: "Norway" },
  { name: "Oman" },
  { name: "Pakistan" },
  { name: "Palau" },
  { name: "Palestinian Territory, Occupied" },
  { name: "Panama" },
  { name: "Papua New Guinea" },
  { name: "Paraguay" },
  { name: "Peru" },
  { name: "Philippines" },
  { name: "Pitcairn" },
  { name: "Poland" },
  { name: "Portugal" },
  { name: "Puerto Rico" },
  { name: "Qatar" },
  { name: "Reunion" },
  { name: "Romania" },
  { name: "Russian Federation" },
  { name: "RWANDA" },
  { name: "Saint Helena" },
  { name: "Saint Kitts and Nevis" },
  { name: "Saint Lucia" },
  { name: "Saint Pierre and Miquelon" },
  { name: "Saint Vincent and the Grenadines" },
  { name: "Samoa" },
  { name: "San Marino" },
  { name: "Sao Tome and Principe" },
  { name: "Saudi Arabia" },
  { name: "Senegal" },
  { name: "Serbia" },
  { name: "Seychelles" },
  { name: "Sierra Leone" },
  { name: "Singapore" },
  { name: "Slovakia" },
  { name: "Slovenia" },
  { name: "Solomon Islands" },
  { name: "Somalia" },
  { name: "South Africa" },
  { name: "South Georgia and the South Sandwich Islands" },
  { name: "Spain" },
  { name: "Sri Lanka" },
  { name: "Sudan" },
  { name: "Suriname" },
  { name: "Svalbard and Jan Mayen" },
  { name: "Swaziland" },
  { name: "Sweden" },
  { name: "Switzerland" },
  { name: "Syrian Arab Republic" },
  { name: "Taiwan, Province of China" },
  { name: "Tajikistan" },
  { name: "Tanzania, United Republic of" },
  { name: "Thailand" },
  { name: "Timor-Leste" },
  { name: "Togo" },
  { name: "Tokelau" },
  { name: "Tonga" },
  { name: "Trinidad and Tobago" },
  { name: "Tunisia" },
  { name: "Turkey" },
  { name: "Turkmenistan" },
  { name: "Turks and Caicos Islands" },
  { name: "Tuvalu" },
  { name: "Uganda" },
  { name: "Ukraine" },
  { name: "United Arab Emirates" },
  { name: "United Kingdom" },
  { name: "United States" },
  { name: "United States Minor Outlying Islands" },
  { name: "Uruguay" },
  { name: "Uzbekistan" },
  { name: "Vanuatu" },
  { name: "Venezuela" },
  { name: "Viet Nam" },
  { name: "Virgin Islands, British" },
  { name: "Virgin Islands, U.S." },
  { name: "Wallis and Futuna" },
  { name: "Western Sahara" },
  { name: "Yemen" },
  { name: "Zambia" },
  { name: "Zimbabwe" },
];

export const option = [
  { name: "Mr" },
  { name: "Ms" },
  { name: "Mrs" },
  { name: "Dr" },
  { name: "Prof" },
  { name: "Rev" },
  { name: "Bishop" },
  { name: "Pastor" },
  { name: "Esq" },
  { name: "Other" },
];
export const referring = [
  { name: "Ambassador" },
  { name: "Advertisement" },
  { name: "Radio" },
  { name: "Television" },
  { name: "Facebook" },
  { name: "Instagram" },
  { name: "LinkedIn" },
  { name: "Twitter" },
  { name: "YouTube" },
  { name: "Other Social Media" },
];
export const sponsoringAssemblyOption = [
  { name: "SA-01 (I Don't Know)", value: "SA-02" },
  { name: "SA-02", value: "SA-02" },
  { name: "SA-03", value: "SA-03" },
  { name: "SA-04", value: "SA-04" },
  { name: "SA-05", value: "SA-05" },
  { name: "SA-06", value: "SA-06" },
  { name: "SA-07", value: "SA-07" },
  { name: "SA-08", value: "SA-08" },
  { name: "SA-09", value: "SA-09" },
  { name: "SA-10", value: "SA-10" },
  { name: "SA-11", value: "SA-11" },
  { name: "SA-12", value: "SA-12" },
  { name: "SA-13", value: "SA-13" },
  { name: "SA-14", value: "SA-14" },
  { name: "SA-15", value: "SA-15" },
  { name: "SA-16", value: "SA-16" },
  { name: "SA-17", value: "SA-17" },
  { name: "SA-18", value: "SA-18" },
  { name: "SA-19", value: "SA-19" },
  { name: "SA-20", value: "SA-20" },
  { name: "SA-21", value: "SA-21" },
  { name: "SA-22", value: "SA-22" },
  { name: "SA-23", value: "SA-23" },
  { name: "SA-24", value: "SA-24" },
  { name: "SA-25", value: "SA-25" },
  { name: "SA-26", value: "SA-26" },
  { name: "SA-27", value: "SA-27" },
  { name: "SA-28", value: "SA-28" },
  { name: "SA-29", value: "SA-29" },
  { name: "SA-30", value: "SA-30" },
  { name: "SA-31", value: "SA-31" },
  { name: "SA-32", value: "SA-32" },
  { name: "SA-33", value: "SA-33" },
  { name: "SA-34", value: "SA-34" },
  { name: "SA-35", value: "SA-35" },
  { name: "SA-36", value: "SA-36" },
  { name: "SA-37", value: "SA-37" },
  { name: "SA-38", value: "SA-38" },
  { name: "SA-39", value: "SA-39" },
  { name: "SA-40", value: "SA-40" },
  { name: "SA-41", value: "SA-41" },
  { name: "SA-42", value: "SA-42" },
  { name: "SA-43", value: "SA-43" },
  { name: "SA-44", value: "SA-44" },
  { name: "SA-45", value: "SA-45" },
  { name: "SA-46", value: "SA-46" },
  { name: "SA-47", value: "SA-47" },
  { name: "SA-48", value: "SA-48" },
  { name: "SA-49", value: "SA-49" },
  { name: "SA-50", value: "SA-50" },
];

// export const options = [
//     { name: "Mr" },
//     { name: "Ms" },
//     { name: "Mrs" },
//     { name: "Dr" },
//     { name: "Prof" },
//     { name: "Rev" },
//     { name: "Bishop" },
//     { name: "Pastor" },
//     { name: "Esq" },
//     { name: "Other" },
//   ];
