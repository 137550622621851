import React, { useMemo, useState } from "react";
import styles from "./HistoryTable.module.scss";
import { Loader } from "../../../../_Common/loader/Loader";
import { HistoryData } from "./HistoryData";
import Modal from "../../../../_Common/Modal/Modal";
import Popup from "../../../../_Common/DashboardDone/popup";
import HistoryPopup from "../HistoryPopup/HistoryPopup";
const HistoryTable = () => {
  const [popupvisible, setpopupvisible] = useState(false);

  const togglepopup = () => {
    setpopupvisible((preview) => !preview);
  };
  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <HistoryPopup Name="Thank You Your Deposit is Confirmed" />
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {HistoryData?.map((item) => {
            const { id, heading, description, date, amount, estApy } = item;
            return (
              <table cellspacing="0" cellpadding="0" key={id}>
                <tr>
                  <td>Name</td>
                  <td>Date</td>
                  <td>Amount</td>
                  <td>Est.APY</td>
                </tr>

                <tr onClick={togglepopup}>
                  <td>
                    <div className={styles.nameWrapper}>
                      <div className={styles.heading}>{heading}</div>
                      <div className={styles.description}>{description}</div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.dateWrapper}>{date}</div>
                  </td>
                  <td>
                    <div className={styles.amountWrapper}>{amount}</div>
                  </td>
                  <td>
                    <div className={styles.estApyWrapper}>{estApy}</div>
                  </td>
                </tr>
              </table>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HistoryTable;
