import React, { useState } from "react";
import styles from "./CoinQvest.module.scss";
import BackTo from "../../../../Assets/icons/backTo.svg";
import { useHistory } from "react-router-dom";
import Input from "../../../_Common/input/input";
import DropDown from "../../DropDown/dropDown";
import { countries } from "./dropdownData";
import useCoinQvest from "./useCoinQvest";
import { useSelector } from "react-redux";
import { WaveClone } from "../../loader/Loader";
const CoinQvest = () => {
  const { formik, loading } = useCoinQvest();
  const history = useHistory();
  const options = countries;
  const [selected, setSelected] = useState({ name: "Choose your country" });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <img src={BackTo} alt="" onClick={() => history.goBack()} />
            <label>Member Registration</label>
          </div>
          <div className={styles.pera}>
            You currently do not have a registered COINQVEST account. Please
            register your Banking details before adding tokens to your CQR Vault
          </div>
          <form className={styles.itemWrapper} onSubmit={formik.handleSubmit}>
            <Input
              name="First Name *"
              inputName="First Name"
              type="text"
              config={formik.getFieldProps("firstName")}
            />
            {formik.errors.firstName && formik.touched.firstName ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.firstName}</div>
            ) : null}
            <Input
              name="Last Name *"
              inputName="Last Name"
              type="text"
              config={formik.getFieldProps("lastName")}
            />
            {formik.errors.lastName && formik.touched.lastName ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.lastName}</div>
            ) : null}
            {/* <Input
              name="Email *"
              inputName="Email"
              type="email"
              config={formik.getFieldProps("email")}
            />
            {formik.errors.email && formik.touched.email ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.email}</div>
            ) : null} */}
            <Input
              name="Company *"
              inputName="Company"
              type="text"
              config={formik.getFieldProps("Company")}
            />
            {formik.errors.Company && formik.touched.Company ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.Company}</div>
            ) : null}
            <Input
              name="Address Line 1*"
              inputName="Address"
              type="text"
              config={formik.getFieldProps("AddressLine1")}
            />
            {formik.errors.AddressLine1 && formik.touched.AddressLine1 ? (
              <div style={{ color: "#E5516B" }}>
                {formik.errors.AddressLine1}
              </div>
            ) : null}
            <Input
              name="Address Line 2 "
              inputName="Address"
              type="text"
              config={formik.getFieldProps("AddressLine2")}
            />
            <Input
              name="Zip Code *"
              inputName="Zip Code"
              type="number"
              config={formik.getFieldProps("ZipCode")}
            />
            {formik.errors.ZipCode && formik.touched.ZipCode ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.ZipCode}</div>
            ) : null}
            <Input
              name="City *"
              inputName="City"
              type="text"
              config={formik.getFieldProps("City")}
            />
            {formik.errors.City && formik.touched.City ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.City}</div>
            ) : null}
            <DropDown
              padding="13px 5px"
              selected={selected}
              setSelected={(res) => {
                formik.setFieldValue("Country", res);
                setSelected(res);
              }}
              options={options}
              imgShow={true}
              name="Your Country *"
            />
            {formik.errors.Country && formik.touched.Country ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.Country}</div>
            ) : null}
            <Input
              name="Phone Number *"
              inputName="Phone"
              type="number"
              config={formik.getFieldProps("PhoneNumber")}
            />
            {formik.errors.PhoneNumber && formik.touched.PhoneNumber ? (
              <div style={{ color: "#E5516B" }}>
                {formik.errors.PhoneNumber}
              </div>
            ) : null}
            <Input
              name="Tax Id"
              inputName="Tax Id"
              type="text"
              config={formik.getFieldProps("TaxId")}
            />

            {!loading ? (
              <button type="submit">Submit</button>
            ) : (
              <button>
                <WaveClone />
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default CoinQvest;
