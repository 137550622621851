import { useState } from "react";

let useReferringBeneficiary = () => {
  const options = [
    { name: "Mr" },
    { name: "Ms" },
    { name: "Dr" },
    { name: "Mrs" },
    { name: "Prof" },
    { name: "Rev" },
    { name: "Bishop" },
    { name: "Pastor" },
    { name: "Esq" },
    { name: "Other" },
  ];
  const [selected, setSelected] = useState({ name: "Mr" });
  const [beneficiarySelect, setBeneficiarySelect] = useState({ name: "Mr" });

  const CountryOptions = [
    { name: "United States", code: "AF" },
    { name: "Afghanistan", code: "AF" },
    { name: "land Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
  const [Country, setCountry] = useState({ name: "United States", code: "AF" });
  const sponsoringAssemblyOptions = [
    { name: "SA-01 (I Don't Know)", value: "SA-02" },
    { name: "SA-02", value: "SA-02" },
    { name: "SA-03", value: "SA-03" },
    { name: "SA-04", value: "SA-04" },
    { name: "SA-05", value: "SA-05" },
    { name: "SA-06", value: "SA-06" },
    { name: "SA-07", value: "SA-07" },
    { name: "SA-08", value: "SA-08" },
    { name: "SA-09", value: "SA-09" },
    { name: "SA-10", value: "SA-10" },
    { name: "SA-11", value: "SA-11" },
    { name: "SA-12", value: "SA-12" },
    { name: "SA-13", value: "SA-13" },
    { name: "SA-14", value: "SA-14" },
    { name: "SA-15", value: "SA-15" },
    { name: "SA-16", value: "SA-16" },
    { name: "SA-17", value: "SA-17" },
    { name: "SA-18", value: "SA-18" },
    { name: "SA-19", value: "SA-19" },
    { name: "SA-20", value: "SA-20" },
    { name: "SA-21", value: "SA-21" },
    { name: "SA-22", value: "SA-22" },
    { name: "SA-23", value: "SA-23" },
    { name: "SA-24", value: "SA-24" },
    { name: "SA-25", value: "SA-25" },
    { name: "SA-26", value: "SA-26" },
    { name: "SA-27", value: "SA-27" },
    { name: "SA-28", value: "SA-28" },
    { name: "SA-29", value: "SA-29" },
    { name: "SA-30", value: "SA-30" },
    { name: "SA-31", value: "SA-31" },
    { name: "SA-32", value: "SA-32" },
    { name: "SA-33", value: "SA-33" },
    { name: "SA-34", value: "SA-34" },
    { name: "SA-35", value: "SA-35" },
    { name: "SA-36", value: "SA-36" },
    { name: "SA-37", value: "SA-37" },
    { name: "SA-38", value: "SA-38" },
    { name: "SA-39", value: "SA-39" },
    { name: "SA-40", value: "SA-40" },
    { name: "SA-41", value: "SA-41" },
    { name: "SA-42", value: "SA-42" },
    { name: "SA-43", value: "SA-43" },
    { name: "SA-44", value: "SA-44" },
    { name: "SA-45", value: "SA-45" },
    { name: "SA-46", value: "SA-46" },
    { name: "SA-47", value: "SA-47" },
    { name: "SA-48", value: "SA-48" },
    { name: "SA-49", value: "SA-49" },
    { name: "SA-50", value: "SA-50" },
  ];
  const [sponsoring, setsponsoring] = useState({
    name: "SA-01 (I Don't Know)",
    value: "SA-02",
  });
  const [referringBeneficiaryName, setReferringBeneficiaryName] = useState({
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
  });
  const [referringphone, setReferringphone] = useState({
    areaCode: "",
    remNumber: "",
  });

  const [sponsorPhone, setSponsorPhone] = useState({
    areaCode: "",
    remNumber: "",
  });

  const [refOrgAdress, setRefOrgAdress] = useState({
    streetAddress: "",
    stAddressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });
  const [sponsorName, setSponsorName] = useState("");

  const [beneficiary, setBeneficiary] = useState({
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
  });

  const [beneficiaryPhone, setBeneficiaryPhone] = useState({
    areaCode: "",
    remNumber: "",
  });

  const [beneficiaryInfo, setBeneficiaryInfo] = useState({
    countryCode: "",
    email: "",
    relationship: "",
  });
  // const [beneficiaryEmail, setBeneficiaryEmail] = useState({})
  // const [beneficiaryRelationship, setBeneficiaryRelationship] = useState({})

  //   const [email, setEmail] = useState({
  //     displayName:"",
  //     primaryEmail:"",
  //     secondryEmail:""
  //   })

  // useEffect(() => {
  //   // setReferringBeneficiaryName((prev) => ({ ...prev, prefix: selected.name }));
  //   RecieveDatefunction(referringBeneficiaryName, "referringName");
  // }, [selected]);

  //   useEffect(() => {
  //     // setSponsorName((prev) => ({ ...prev, sponsor: sponsoring.name }));
  //     RecieveDatefunction(sponsorName, "SponsorName");
  //   }, [sponsoring,beneficiary]);

  //   useEffect(() => {
  //     // setRefOrgAdress((prev) => ({ ...prev, country: Country.name }));
  //     RecieveDatefunction(refOrgAdress, "refOrgAdress");
  //   }, [Country]);

  //   useEffect(() => {
  //     // setBeneficiary((prev) => ({ ...prev, prefix: selected.name }));
  //     RecieveDatefunction(beneficiary, "beneficiaryName");
  //   }, [selected]);

  // const isData = (val) => Object.values(val).filter((m) => m !== "").length > 1;

  // useEffect(() => {
  //   setReferringBeneficiaryName((prev) => ({ ...prev, prefix: selected.name }));
  //   setRefOrgAdress((prev) => ({ ...prev, country: Country.name }));
  //   setBeneficiary((prev) => ({ ...prev, prefix: selected.name }));

  // RecieveDatefunction(referringBeneficiaryName, "referringName");
  // RecieveDatefunction(refOrgAdress, "refOrgAdress");
  // RecieveDatefunction(beneficiary, "beneficiaryName");
  // }, [selected, sponsoring, Country]);

  // useEffect(() => {
  //   setSponsorName((prev) => ({ ...prev, sponsor: sponsoring.name }));
  //   RecieveDatefunction(sponsorName, "SponsorName");
  // }, [sponsoring, beneficiary]);

  // useEffect(() => {
  //   let cloneData = { ...data };
  //   _.set(cloneData, "refAmbassador.name.prefix", selected?.name);
  //   dispatch(saveFormData(cloneData));
  // }, [selected]);

  // useEffect(() => {
  //   let cloneData = { ...data };
  //   _.set(cloneData, "Beneficiary.name.prefix", selected?.name);
  //   dispatch(saveFormData(cloneData));
  // }, [beneficiarySelect]);

  // useEffect(() => {
  //   let cloneData = { ...data };
  //   _.set(cloneData, "sponsor.name", sponsoring?.name);
  //   dispatch(saveFormData(cloneData));
  // }, [sponsoring]);
  // useEffect(() => {
  //   let cloneData = { ...data };
  //   _.set(cloneData, "refOrgAddress.country", Country?.name);
  //   dispatch(saveFormData(cloneData));
  // }, [Country]);

  // useEffect(() => {
  //   setReferringBeneficiaryName(data?.refAmbassador?.name);
  //   setReferringphone(data?.refAmbassador?.phone);
  //   setSponsorPhone(data?.sponsor?.phone);
  //   setRefOrgAdress(data?.refOrgAddress);
  //   setBeneficiary(data?.Beneficiary?.name);
  //   setBeneficiaryPhone(data?.Beneficiary?.phone);
  //   setBeneficiaryInfo((prev) => ({
  //     ...prev,
  //     countryCode: data?.Beneficiary?.countryCode,
  //     email: data?.Beneficiary?.email,
  //     relationship: data?.Beneficiary?.relationship,
  //   }));
  // }, []);

  // const handleReferringBeneficiaryName = (e) => {
  //   let { name, value } = e.target;
  //   setReferringBeneficiaryName((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleReferringPhone = (e) => {
  //   let { name, value } = e.target;
  //   setReferringphone((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleSponsorPhone = (e) => {
  //   let { name, value } = e.target;
  //   setSponsorPhone((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleRefOrgAdress = (e) => {
  //   let { name, value } = e.target;
  //   setRefOrgAdress((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleBeneficiaryName = (e) => {
  //   let { name, value } = e.target;
  //   setBeneficiary((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleBeneficiaryPhone = (e) => {
  //   let { name, value } = e.target;
  //   setBeneficiaryPhone((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // const handleBeneficiaryInfo = (e) => {
  //   let { name, value } = e.target;
  //   setBeneficiaryInfo((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  // useEffect(() => {
  //   RecieveDatefunction(referringBeneficiaryName, "referringName");

  //   RecieveDatefunction(referringphone, "referringPhone");
  //   RecieveDatefunction(sponsorPhone, "SponsorPhone");
  //   RecieveDatefunction(refOrgAdress, "refOrgAdress");
  //   RecieveDatefunction(beneficiary, "beneficiaryName");

  //   RecieveDatefunction(beneficiaryPhone, "beneficiaryPhone");

  //   RecieveDatefunction(beneficiaryInfo, "beneficiaryInfo");
  // }, [
  //   referringBeneficiaryName,
  //   referringphone,
  //   sponsorPhone,
  //   refOrgAdress,
  //   beneficiary,
  //   beneficiaryPhone,
  //   beneficiaryInfo,
  //   selected,
  //   sponsoring,
  //   Country,
  // ]);
  // const handleBeneficiaryEmail = (e) => {
  //   let { name, value } = e.target;
  //   setBeneficiaryEmail((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  //   RecieveDatefunction(beneficiaryEmail, "beneficiaryEmail");
  // }

  // const handleBeneficiaryRelationship = (e) => {
  //   let { name, value } = e.target;
  //   setBeneficiaryRelationship((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  //   RecieveDatefunction(beneficiaryRelationship, "beneficiaryrelationship");
  // }

  return {
    options,
    selected,
    setSelected,
    CountryOptions,
    Country,
    setCountry,
    sponsoringAssemblyOptions,
    sponsoring,
    setsponsoring,
    setBeneficiarySelect,
    beneficiarySelect,
  };
};

export default useReferringBeneficiary;
