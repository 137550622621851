import React from "react";
import About from "./About/About";
import Achievements from "./Achievements/Achievements";
import Data from "../../../Data/Slider/Aboutus/Data";
// import Images from "./Images/Images";
import Testimonial from "./Testimonial/Testimonial";
import Slider from "../../_Common/Slider/slider";
import SliderData from "./AboutSlider/sliderData";
import styles from "./aboutus.module.scss";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About - CQR Vault</title>
        <meta
          name="description"
          content="See how CQR Vault is changing the game of private equity as a function of cash flow!"
        />
        <link rel="canonical" href="https://cqrvault.org/about-us" />
      </Helmet>
      <About />
      {/* <Images /> */}
      <Achievements />
      <Testimonial />
      <div className={styles.sliderWrapper}>
        <Slider team={1}>
          {Data.map((SData, index) => {
            return <SliderData SData={SData} key={SData.id} />;
          })}
        </Slider>
      </div>
    </>
  );
};

export default AboutUs;
