import { useCallback, useContext, useEffect, useState } from "react";

import { SocketContext } from "../../../../../Context/Socket";
import {
  SINGLE_CHAT_EMIT_EVENTS,
  SOCKET_EVENTS,
} from "../../../../../utils/ChatConstants";
import { transformMessageAfterReceiving } from "../Helpers";
import { useDebounce } from "use-debounce";

const useChatRoomSockets = (chatRoomId) => {
  const [messages, setMessages] = useState([]);
  const [debounce] = useDebounce(chatRoomId, 2000);
  const socket = useContext(SocketContext);

  const listeners = useCallback(async () => {
    socket.emit(
      SINGLE_CHAT_EMIT_EVENTS.ENTER_USER_ROOM,
      {
        chatRoomId: chatRoomId,
      },
      (res) => {}
    );

    socket.on(SOCKET_EVENTS.NEW_USER_MESSAGE, (message) => {
      const transformed = transformMessageAfterReceiving(message);
      if (chatRoomId === message?.chatRoom) {
        setMessages((previous) => {
          return previous?.concat(transformed)?.sort(function (a, b) {
            return Date.parse(b?.createdAt) - Date.parse(a?.createdAt);
          });
        });
      }
    });

    socket.on("connect_error", (err) => {
      socket.connect();
    });
  }, [chatRoomId]);
  const listenersNew = useCallback(async () => {
    socket.emit(
      SINGLE_CHAT_EMIT_EVENTS.ENTER_USER_ROOM,
      {
        chatRoomId: chatRoomId,
      },
      (res) => {}
    );

    socket.on(SOCKET_EVENTS.NEW_USER_MESSAGE, (message) => {
      const transformed = transformMessageAfterReceiving(message);
      setMessages([transformed]);
    });

    socket.on("connect_error", (err) => {
      socket.connect();
    });
  }, [chatRoomId]);

  useEffect(() => {
    if (messages?.length == 0) {
      listenersNew();
    } else {
      listeners();
    }
    return () => {
      // setMessages("");
      socket.removeListener(SOCKET_EVENTS.NEW_USER_MESSAGE);
    };
  }, [debounce, messages]);

  return [messages, setMessages];
};

export default useChatRoomSockets;
