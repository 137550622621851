import {
  CUSTOMER_REGISTRATION,
  CUSTOMER_REGISTRATION_SUCCESS,
  CUSTOMER_REGISTRATION_FAIL,
  WALLET_ACTIVE_VIA_FIAT,
  WALLET_ACTIVE_VIA_FIAT_SUCCESS,
  WALLET_ACTIVE_VIA_FIAT_FAIL,
  CUSTOMER_CLEANUP,
} from "../types";

const initialState = {
  // crypto to asstes-------------------
  customerData: null,
  customerLoading: false,
  customerDataError: null,
  customerDataSuccess: null,

  //wallet active via fiat

  walletFiatError: null,
  walletFiatSuccess: null,
  walletFiatLoading: null,
  walletFiat: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    //native token swap-------------------------

    case CUSTOMER_REGISTRATION:
      return { ...state, customerDataError: null, customerLoading: true };
    case CUSTOMER_REGISTRATION_SUCCESS:
      return {
        ...state,
        customerData: action?.payload?.data,
        customerDataSuccess: "Customer Registered successfully",
        customerLoading: false,
      };
    case CUSTOMER_REGISTRATION_FAIL:
      return {
        ...state,
        customerData: null,
        customerDataError: action.error?.response?.data?.message,
        customerLoading: false,
      };

    //wallet activation via fiat

    case WALLET_ACTIVE_VIA_FIAT:
      return { ...state, walletFiatError: null, walletFiatLoading: true };
    case WALLET_ACTIVE_VIA_FIAT_SUCCESS:
      return {
        ...state,
        walletFiat: action?.payload,
        walletFiatSuccess: "Token Swap successfully",
        walletFiatLoading: false,
      };
    case WALLET_ACTIVE_VIA_FIAT_FAIL:
      return {
        ...state,
        walletFiat: null,
        walletFiatError: action.error?.response?.data?.message,
        walletFiatLoading: false,
      };

    case CUSTOMER_CLEANUP:
      return {
        ...state,
        error: null,
        walletFiatError: null,
        walletFiatSuccess: null,
        customerDataError: null,
        customerDataSuccess: null,
      };

    default:
      return state;
  }
};

export default customerReducer;
