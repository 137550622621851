import {
  FillingBottle,
  BouncingBalls,
  BarWave,
  Messaging,
} from "react-cssfx-loading";
import styles from "./loader.module.scss";
import { RotatingLines, ThreeCircles } from "react-loader-spinner";

export const Loader = ({ width, height }) => {
  return (
    <div className={styles.loader}>
      <BarWave color="aqua" duration="1s" width={width} height={height} />
    </div>
  );
};
export const LoaderNew = () => {
  return (
    <div className={styles.loaders}>
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        width="20"
        visible={true}
      />
    </div>
  );
};

export const Wave = ({ width, height }) => {
  return (
    <div className={styles.barwave}>
      <BarWave color="aqua" duration="1s" width={width} height={height} />
    </div>
  );
};
export const WaveNew = ({ width, height }) => {
  return <BarWave color="aqua" duration="1s" width={width} height={height} />;
};

export const WaveClone = () => {
  return (
    <div className={styles.barwave}>
      <BarWave color="white" duration="1s" />
    </div>
  );
};
export const Message = () => {
  return (
    <div className={styles.barwave}>
      <Messaging color="aqua" duration="0.5s" />
    </div>
  );
};

export const LoadingButton = ({ Loading }) => {
  return (
    <div className={styles.buttonload}>
      <div className={styles.loader}>
        <BouncingBalls color="white" duration="0.5s" />
      </div>
      <p>{Loading}</p>
    </div>
  );
};

export const LoadingButton2 = ({ Loading }) => {
  return (
    <div className={styles.buttonload1}>
      <div className={styles.loader1}>
        <BouncingBalls color="white" duration="0.5s" />
      </div>
      <p>{Loading}</p>
    </div>
  );
};
