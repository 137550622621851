import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import right from "../../../../Assets/icons/Next.svg";
import BackTo from "../../../../Assets/icons/backk.svg";
import SwapImg from "../../../../Assets/icons/swapImg.svg";
import { UpdateWalletBalance } from "../../../../redux/action/currencyDetailAction";
import {
  getCryptoToAssetsSawap,
  nativeTokenSwap,
  tokenCleanUp,
} from "../../../../redux/action/sawapAction";
import { UserTypes } from "../../../../utils/AppConstants";
import CQRComponent from "../../../_Common/CQRComponent/CQRComponent";
import Modal from "../../Modal/Modal";
import { Loader, Wave } from "../../loader/Loader";
import FiatModal from "../SwapModal/ModalStyle";
import styles from "./swap.module.scss";

function Swap({
  topHeading,
  TopImg,
  TopP,
  topLabel,
  bottomHeading,
  BottomImg,
  bottomP,
  bottomLabel,
  swapHistoryUrl,
}) {
  const [popupVisible, setPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [wallet, setWallets] = useState([]);
  const [nativewallet, setNativeWallets] = useState([]);
  const currencySelector = useSelector((state) => state?.currency);
  const sawapSelector = useSelector((state) => state?.sawap);
  // const isSdira = useSelector((state) => state?.user?.userInfo?.sdira);
  const checkUser = useSelector((state) => state?.user?.userInfo);

  const dispatch = useDispatch();
  const history = useHistory();

  const [from, setFrom] = useState(
    history?.location?.state?.detail?.state?.detail
  );

  const [to, setTo] = useState(
    currencySelector?.currencyInfo?.nativeWallets[0]
  );
  const [active, setActive] = useState(0);
  const [amount, setAmount] = useState("");
  const [getRate, setGetRate] = useState("1");
  const [recieveAmount, setRecieveAmount] = useState("");
  const alert = useAlert();

  //---------Get coin rates from Api------------
  useEffect(() => {
    if (Number(amount) < 0) {
      alert.show("Invalid amount", { type: "error" });
      return;
    }
    setWallets(currencySelector?.currencyInfo?.wallets);
    setNativeWallets(currencySelector?.currencyInfo?.nativeWallets);
    let data = { from, amount: "1", to };
    dispatch(getCryptoToAssetsSawap(data));
  }, [from, to]);

  useEffect(() => {
    setRecieveAmount(amount * sawapSelector.sawapInfo);
  }, [sawapSelector]);

  let fromUSD = from?.balance * from?.marketData?.rate;

  //-------show amount against select coin----------
  useEffect(() => {
    let sawap = amount * sawapSelector.sawapInfo;
    setRecieveAmount(sawap);
  }, [amount]);

  const handleChangeAmount = (e) => {
    setActive(0);
    let { name, value } = e.target;
    let lenght = value.toString().split(".")[1]?.length;
    if (lenght > 8) {
      return;
    } else {
      setAmount(value);
    }
  };

  //---------Set coin when select from modal---------
  const handleCoinChange = (data) => {
    if (title === 2) {
      setTo(data);
      setPopupVisible((prev) => !prev);
    }
    if (title === 1) {
      setFrom(data);
      setPopupVisible((prev) => !prev);
    }
  };

  const togglePopup = (e) => {
    if (e === "To") {
      setTitle(1);
    } else if (e === "From") {
      setTitle(2);
    }
    setPopupVisible((prev) => !prev);
  };

  //-----------Check Success and Error case when coin is swaped-------------
  useEffect(() => {
    if (sawapSelector.nativeTokenSuccess === "Token Swap successfully") {
      if (checkUser.type != UserTypes.CQR_USER) {
        alert.show("Swap request send to admin", { type: "success" });
      } else {
        alert.show("Assets swap successfully", { type: "success" });
      }
      history.push("/cryptocurrency");
    } else if (sawapSelector.nativeTokenError) {
      alert.show(sawapSelector.nativeTokenError, { type: "error" });
      dispatch(UpdateWalletBalance());
    }

    dispatch(tokenCleanUp());
  }, [sawapSelector.nativeTokenError, sawapSelector.nativeTokenSuccess]);

  const handleMin = () => {
    setActive(1);
    setAmount(from?.balance / 4);
  };
  const handleHalf = () => {
    setActive(2);
    setAmount(from?.balance / 2);
  };
  // const handleMax = () => {
  //   setAmount(from?.balance);
  // };

  //-----------Coin swap-----------------
  const handleSubmit = async () => {
    if (Number(from?.balance) === 0 || Number(from?.balance) < 0) {
      alert.show("Insufficient balance ", { type: "error" });
      return;
    }
    if (amount == 0) {
      alert.show("Amount cannot be zero ", { type: "error" });
      return;
    }

    if (!amount) {
      alert.show("Please enter amount ", { type: "error" });
      return;
    }
    if (Number(amount) < 0) {
      alert.show("Invalid amount ", { type: "error" });
      return;
    }
    String(amount);
    let data = { from, amount, to };
    setLoading(true);
    await dispatch(nativeTokenSwap(data));
    setLoading(false);
  };

  return (
    <>
      <Modal visible={popupVisible} btn onClose={() => setPopupVisible(false)}>
        <FiatModal
          title={title}
          wallet={wallet}
          nativewallet={nativewallet}
          handleCoinChange={handleCoinChange}
        />
      </Modal>
      <div className={styles.container}>
        <div className={styles.topWrapper}>
          <img src={BackTo} alt="" onClick={() => history.goBack()} />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.textWrapper}>
            <div className={styles.heading}>
              Crypto To C<CQRComponent />R Vault
            </div>
            <p>
              1 {from?.name} ≈ {sawapSelector?.sawapInfo} {to?.displayName}
            </p>
          </div>
          {sawapSelector.nativeTokenLoading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.swapSection}>
                <div className={styles.leftSection}>
                  <div className={styles.headings}>
                    <div className={styles.left}>
                      <label>
                        I have{" "}
                        {parseFloat(from?.balance)?.toFixed(from?.decimals)}
                      </label>
                      <div
                        className={styles.coinSection}
                        onClick={() => togglePopup("To")}
                      >
                        <img
                          className={styles.btcImg}
                          src={from?.icon?.url}
                          alt=""
                        />
                        <p>{from?.coinSymbol?.toUpperCase()}</p>
                        <img className={styles.rightImg} src={right} alt="" />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <label>
                        Balance in USD {fromUSD?.toFixed(from?.decimals)}
                      </label>
                      {/* <p>ppp</p> */}
                    </div>
                  </div>
                  <div className={styles.btns}>
                    <button
                      onClick={handleMin}
                      className={
                        active === 1
                          ? styles.Activebutton
                          : styles.notActivebutton
                      }
                    >
                      Min
                    </button>
                    <button
                      onClick={handleHalf}
                      className={
                        active === 2
                          ? styles.Activebutton
                          : styles.notActivebutton
                      }
                    >
                      Half
                    </button>
                    {/* <button onClick={handleMax}>Max</button> */}
                  </div>
                  <div className={styles.inputHeadings}>
                    <div className={styles.inputWrapper}>
                      <input
                        type="number"
                        placeholder="Swap Amount"
                        onChange={(e) => handleChangeAmount(e)}
                        value={amount}
                      />
                      {/* <div className={styles.inputHeadings}> */}
                      {/* <label>{topLabel}</label> */}
                      {/* <p>$2,018.95</p> */}
                      {/* </div> */}
                    </div>
                    <p>
                      {/* Estimated Balance {parseFloat(from?.balance)?.toFixed(4)} */}
                      {/* Estimated Balance {amount} */}
                    </p>
                  </div>
                </div>
                <div className={styles.centerSection}>
                  <div className={styles.borderClass}></div>
                  {/* <img style={{ width: "49px" }} src={right} alt="" /> */}
                  <img style={{ cursor: "pointer" }} src={SwapImg} alt="" />
                  <div className={styles.borderClass}></div>
                </div>
                <div className={styles.rightSection}>
                  <div className={styles.headings}>
                    <div className={styles.left}>
                      <label>I want {to?.shortName?.toUpperCase()}</label>
                      <div
                        className={styles.coinSection}
                        onClick={() => togglePopup("From")}
                      >
                        <img
                          className={styles.ETHImg}
                          src={to?.icon?.url}
                          alt=""
                        />
                        <p>{to?.shortName}</p>
                        <img className={styles.rightImg} src={right} alt="" />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <label>
                        ${parseFloat(to?.balance)?.toFixed(to?.decimals)}
                      </label>
                      {/* <p>18.055153691</p> */}
                    </div>
                  </div>
                  <div className={styles.inputWrapper}>
                    <input
                      type="number"
                      placeholder="Receive Amount"
                      value={recieveAmount}
                      // onChange={(e) => handleChangeRecieveAmount(e)}
                    />
                    <div className={styles.inputHeadings}>
                      {/* <label>{bottomLabel}</label>
                    <p>$2,018.95</p> */}
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <button className={styles.SwapButton}>
                  <Wave />
                </button>
              ) : (
                <button className={styles.SwapButton} onClick={handleSubmit}>
                  Swap
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Swap;
