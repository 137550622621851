import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Navigation from "../../Components/_Common/navigation/navigation";
import Footer from "../../Components/_Common/Footer/Footer";
import Home from "../../Components/WebLayout/Home/Home";
import AboutUs from "../../Components/WebLayout/AboutUs/AboutUs";
import ContactUs from "../../Components/WebLayout/ContactUs/ContactUs";
import SignIn from "../../Components/WebLayout/Signin/Signin";
import SignUp from "../../Components/WebLayout/Signup/Signup";
import ScrollToTop from "../../Components/_Common/ScrollToTop";
import PulicChat from "../_Common/Chat/PublicChat";
import { useSelector } from "react-redux";
import Faq from "./Faq";

const HomeApp = () => {
  return (
    <>
      <ScrollToTop />
      <Navigation show={true} />
      {/* <PulicChat /> */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/faq" component={Faq} />

        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  );
};

export default HomeApp;
