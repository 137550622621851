import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Modal from "../Modal/Modal";
import Popup from "../DashboardDone/popup";
import styles from "./navigation.module.scss";
import { slide as Menu } from "react-burger-menu";
import MobileIcon from "../../../Assets/image/navicon.svg";
import Navlogo from "../../../Assets/image/logo.svg";
import Loginsignup from "../loginSignup/Loginsignup";
import Notification from "../Notification/Notification";
import Search from "../../../Assets/image/search.svg";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useSelector } from "react-redux";
import Dummy from "../../../Assets/icons/dummy.png";
import logo from "../../../Assets/image/logo.svg";
import { imgUpload, userCleanUp } from "../../../redux/action/userAction";
import { useDispatch } from "react-redux";
import Teamprofile from "../../../Assets/image/teamprofile.png";
import editprofile from "../../../Assets/image/editprofile.svg";
import loaderImg from "../../../Assets/icons/loadingblue.svg";
// import Dummy from "../../../Assets/icons/dummy user.png";
const Navigation = ({ show }) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [logout, setlogout] = useState(false);
  const dispatch = useDispatch();
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);
  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  const userSelector = useSelector((state) => state.user);

  const scrollRef = useRef(null);
  useEffect(() => {
    menuOpenState ? disableBodyScroll(scrollRef) : enableBodyScroll(scrollRef);
  }, [menuOpenState]);
  const currencySelector = useSelector((state) => state.currency);
  const handleImg = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("type", "profilePicture");
    dispatch(imgUpload(formData));
    dispatch(userCleanUp());
  };
  return (
    <>
      {show ? (
        <>
          <Menu
            right
            disableAutoFocus
            customBurgerIcon={false}
            isOpen={menuOpenState}
            onStateChange={(state) => stateChangeHandler(state)}
            itemListElement="div"
          >
            <div className="bm-item">
              <div className="headerLogo">
                <img src={Navlogo} alt="logo" />
              </div>
              <p>{userSelector?.userInfo?.name}</p>
              <div className="bm-list">
                <ul>
                  <li className="bm-item-list" onClick={handleMenu}>
                    <div className={styles.listitem}>
                      <Link to="/">Home</Link>
                      <Link to="/about-us">About Us</Link>
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="/faq">FAQs</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Menu>
          <div className={styles.mainmobileNav}>
            <Link to="/">
              <img className={styles.logo} src={Navlogo} alt="logo" />
            </Link>

            <div className={styles.RightWrapper}>
              <div className={styles.menuItem}>
                <Loginsignup />
              </div>
              <div
                onClick={() => setMenuOpenState(!menuOpenState)}
                className={styles.navbtn}
              >
                <img src={MobileIcon} alt="mobileNav" />
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <div className={styles.leftsection}>
                <Link to="/">
                  <img className={styles.logo} src={Navlogo} alt="logo" />
                </Link>
              </div>
              <div className={styles.rightsection}>
                <div className={styles.menuItem}>
                  <Link to="/about-us">
                    <p>About Us</p>
                  </Link>
                </div>
                <div className={styles.menuItem}>
                  <Link to="/faq">
                    <p>FAQs</p>
                  </Link>
                </div>
                <div className={styles.menuItem}>
                  <Link to="/contact-us">
                    <p>Contact Us</p>
                  </Link>
                </div>
                <div className={styles.menuItem}>
                  <Loginsignup />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.shows}>
          <div>
            <p>Welcome, {userSelector?.userInfo?.name}!</p>
            <label className={styles.email}>
              {userSelector?.userInfo?.email}
            </label>
          </div>
          <div className={styles.RightWrapper}>
            <Notification />
            {/* <img src={Search} alt="search" className={styles.search} /> */}
            <div className={styles.profilediv}>
              <label For="inputimage">
                <div className={styles.profile}>
                  {userSelector.imgLoader ? (
                    <img className={styles.img1} src={loaderImg} alt="" />
                  ) : (
                    <img
                      className={styles.img2}
                      src={
                        userSelector?.userInfo?.profilePicture
                          ? userSelector?.userInfo?.profilePicture +
                            "?" +
                            new Date()
                          : Teamprofile
                      }
                      alt="profile"
                    />
                  )}

                  <div className={styles.editprofile}>
                    <div className={styles.selectimage}>
                      <img src={editprofile} />
                    </div>
                    <input
                      type="file"
                      multiple
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => handleImg(e)}
                      id="inputimage"
                    />
                  </div>
                </div>
              </label>
            </div>
            <span
              onClick={() => {
                setlogout(true);
              }}
            >
              {userSelector?.userInfo?.name}
            </span>
          </div>
        </div>
      )}
      <Modal visible={logout} onClose={() => setlogout(false)}>
        <Popup
          src={logo}
          para="Are you sure you want to logout?"
          btn="Yes"
          btn1="Cancel"
          BtnShow
          // onPressButton={() => history.push("/")}
          onPressinnerButton={() => setlogout(false)}
        />
      </Modal>
    </>
  );
};

export default Navigation;
