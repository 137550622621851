import React, { useEffect, useState } from "react";
import style from "./modalStyle.module.scss";
// import Data from "../../../../Data/Dashboard/SwapModal/data";
// import Data2 from "../../../../Data/Dashboard/SwapModal/data2";

function ModalStyle({ title, wallet, handleCoinChange, nativewallet }) {
  const [tokens, setToken] = useState([]);
  const DataObj = {
    1: wallet,
    2: nativewallet,
  };
  const data = DataObj[title];
  useEffect(() => {
    if (title === 2) {
      const dataToDisplay = data?.filter((item) => item.type !== "sdira");
      setToken(dataToDisplay);
    } else {
      setToken(data);
    }
  }, []);
  return (
    <>
      <div className={style.container}>
        <div className={style.Wrapper}>
          {data?.map((data) => {
            return (
              <div
                className={style.CardWrapper}
                key={data._id}
                onClick={() => handleCoinChange(data)}
              >
                <div className={style.Card}>
                  <img src={data?.icon?.url} alt="" />
                  <div className={style.text}>
                    {data?.shortName?.toUpperCase()
                      ? data?.shortName?.toUpperCase()
                      : data?.coinSymbol?.toUpperCase()}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ModalStyle;
