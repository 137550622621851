import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import BitCoin from "../../../../../../../Assets/icons/Btc.svg";
import BackTo from "../../../../../../../Assets/icons/backk.svg";
import { customerCleanup } from "../../../../../../../redux/action/customerRegistrationAction";
import { getAssetsToAssetsSawap } from "../../../../../../../redux/action/sawapAction";
import { activationWalletToken } from "../../../../../../../services/paymentServices";
import { DIRECT_WIRE_TYPE } from "../../../../../../../utils/AppConstants";
import {
  handlePayPalCheckout,
  handleStartCheckout,
} from "../../../../../../../utils/customerService";
import CQRComponent, {
  CQRAComponent,
  CQRBComponent,
} from "../../../../../../_Common/CQRComponent/CQRComponent";
import DropDown from "../../../../../../_Common/DropDown/dropDown";
import Modal from "../../../../../../_Common/Modal/Modal";
import InputField from "../../../../../../_Common/input/input";
import {
  Loader,
  LoadingButton,
  Wave,
} from "../../../../../../_Common/loader/Loader";
import Popup from "../../../../../../_Common/paymentSelection/popup";
import styles from "./send.module.scss";
import { despositGetRates } from "../../../../../../../services/depositService";

const CqrLiquidityTokens = ({ location }) => {
  const alert = useAlert();
  const currencySelector = useSelector((state) => state.currency);
  const customerSelector = useSelector((state) => state.customer);

  const silverRate = useSelector(
    (state) => state?.currency?.currencyInfo?.nativeWallets
  );
  const isActivate = useSelector(
    (state) => state?.user?.userInfo?.isWalletActivated
  );
  const userData = useSelector((state) => state?.user?.userInfo);
  const sawapSelector = useSelector((state) => state?.sawap);

  //  currencySelector?.currencyInfo?.nativeWallets;
  const [activationAmount, setActivationAmount] = useState("");
  const [filterQData, setfilterQData] = useState("");
  const options = filterQData;
  const [selected, setSelected] = useState(filterQData[0]);
  const [valueGet, setValueGet] = useState("");

  const [loading, setLoading] = useState(false);
  const [legacyLoading, setLegacyLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [params, setParams] = useState(null);
  const [tokenRate, setTokenRate] = useState("");

  const [swap, setSwap] = useState("deposit");
  // const [isActivate, setIsActivate] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerSelector?.walletFiatSuccess === "Token Swap successfully") {
      if (currencySelector?.currencyInfo?.user?.sdira) {
        alert.show("Token request sent to admin please wait for approval", {
          type: "success",
        });
      } else {
        alert.show(
          `You have successfully acquired  ${formik.values.amount}  ${selected.coinSymbol} USD`,
          {
            type: "success",
          }
        );
      }
    } else if (customerSelector?.walletFiatError) {
      alert.show(customerSelector?.walletFiatError, { type: "error" });
    }
  }, [customerSelector]);

  useEffect(() => {
    dispatch(customerCleanup());
    const flterData = silverRate.find((m) => m.shortName === "ICO Ag");
    // setActivationAmount(flterData?.price * 21 * flterData?.multiplier);

    // filter q symbol

    let filterLiquidity = currencySelector?.currencyInfo?.nativeWallets?.filter(
      (item) => item?.type === "liquidity"
    );
    let filterAssets = currencySelector?.currencyInfo?.nativeWallets?.filter(
      (item) => item?.type === "asset"
    );
    let filterSdira = currencySelector?.currencyInfo?.nativeWallets?.filter(
      (item) => item?.type === "sdira"
    );
    let sdiraTokens = filterSdira?.filter(
      (item) => item.displayName !== "APi (Real Biz) Legacy Token"
    );
    let silverToken = currencySelector?.currencyInfo?.nativeWallets?.filter(
      (item) => item.shortName === "ICO Ag"
    );
    if (!isActivate) {
      setfilterQData(silverToken);
      setSelected(silverToken[0]);
    } else if (location?.state?.detail?.type === "liquidity") {
      setfilterQData(filterLiquidity);
      setSelected(filterLiquidity[0]);
      formik.setFieldValue("to", filterLiquidity[0]);
    } else if (location?.state?.detail?.type === "sdira") {
      setfilterQData(sdiraTokens);
      setSelected(sdiraTokens[0]);
      formik.setFieldValue("to", filterSdira[0]);
    } else {
      setfilterQData(filterAssets);
      setSelected(filterAssets[0]);
      formik.setFieldValue("to", filterAssets[0]);
    }
    // setSelected(flterData);
  }, []);
  useEffect(() => {
    if (customerSelector?.walletFiatSuccess === "Token Swap successfully") {
      dispatch(customerCleanup());
      history.push("./cryptocurrency");
    }
  }, [customerSelector?.walletFiatSuccess]);

  const formikScheme = yup.object({
    // to: yup.string().required("Please select coin"),
    amount: yup
      .number()
      .positive("Invalid amount")
      // .max(5000.1, "Amount less the 5000")
      .required("Amount is required")

      .test(
        "maxDigitsAfterDecimal",
        "number field must have 8 digits after decimal or less",
        (number) => /^\d+(\.\d{0,8})?$/.test(number)
      ),
  });
  const formik = useFormik({
    validationSchema: formikScheme,
    initialValues: {
      amount: "",
      to: selected ? selected : filterQData[0],
    },

    onSubmit: async (values, reset) => {
      let silverToken = currencySelector?.currencyInfo?.nativeWallets?.find(
        (item) => item.shortName === "ICO Ag"
      );
      const amountDiffrence = 21 - silverToken?.balance;
      if (
        location?.state?.detail?.type === "asset" &&
        selected?.shortName === silverToken?.shortName &&
        valueGet < amountDiffrence
      ) {
        alert.error(
          `Please acquire a minimum value of ${amountDiffrence?.toFixed(
            3
          )} ICO Ag Tokens or more. See description for details`
        );
        return;
      }
      if (
        location?.state?.detail?.type === "asset" &&
        selected?.shortName !== silverToken?.shortName &&
        Number(silverToken?.balance) < 21
      ) {
        alert.error(
          `Please acquire a minimum value of ${amountDiffrence?.toFixed(
            3
          )} ICO Ag Tokens or more. See description for details`
        );
        return;
      }
      let amount = Number(values.amount).toFixed(2);
      let data = {
        to: values,
        // to: "Q",
        amount: Number(amount),
      };
      setShow(true);
      setParams(data);

      // setShow(true)
      // return
      // reset.resetForm();
    },
  });

  const handlePaymentViaGoCardless = () => {
    setLoading(true);
    history.push("/payment-selection");
    // if (params) {
    //   dispatch(walletActiveViaFiat(params));
    // }
    setLoading(false);
  };

  const handleSend = async () => {
    setShow(true);
    return;
    // setLoading(true);
    // try {
    //   const res = await HTTP_CLIENT.post(
    //     "/native-wallet/activateUserWalletByFiat"
    //   );
    //   alert.show("Wallet has been activated successfully", { type: "success" });
    //   await dispatch(userInfo(res.data.user));
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    // }
  };
  const handleWalletActivation = async () => {
    let data = {
      paymentType: !userData?.isWalletActivated
        ? DIRECT_WIRE_TYPE?.WALLET_ACTIVATION
        : DIRECT_WIRE_TYPE?.TOKEN_PURCHASE,
      tokenId: params?.to?.to?.coin,
      amount: params?.amount,
    };
    try {
      setLoading(true);
      const res = await handleStartCheckout(data);
      window.open(res?.data?.url, "_blank", "noopener,noreferrer");
      setShow(false);
      history.goBack();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.error(error?.response?.data?.message || "something error occurred");
    }
  };

  const handlePayPalActivation = async () => {
    let data = {
      paymentType: !userData?.isWalletActivated
        ? DIRECT_WIRE_TYPE?.WALLET_ACTIVATION
        : DIRECT_WIRE_TYPE?.TOKEN_PURCHASE,
      tokenId: params?.to?.to?.coin,
      amount: params?.amount,
    };
    try {
      setLoading(true);
      const res = await handlePayPalCheckout(data);
      let response = res?.data?.links;
      let urlresp = response?.find((curr) => curr?.method == "REDIRECT");
      window.open(urlresp?.href, "_blank", "noopener,noreferrer");
      setShow(false);
      history.goBack();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.error(error?.response?.data?.message || "something error occurred");
    }
  };

  const handleCoinQvest = async () => {
    if (!userData?.isCoinqvestCustomer) {
      history.push("/register-qvest");
    } else {
      setLoading(true);
      await handleWalletActivation();
      setLoading(false);
    }
  };

  const handleModalOk = () => {
    // if (activationAmount > 5000) {
    //   alert.show("Amount cannot be greater then 5000", { type: "error" });
    //   setShow(false);
    //   return;
    // }
    setLoading(true);
    history.push({
      pathname: "/direct-wire",
      state: {
        detail: {
          ...params,
          amount: isActivate ? params?.amount : activationAmount,
          tokenAmount: valueGet,
          tokenRate: tokenRate,
        },

        activationAmount: activationAmount,
        type: isActivate
          ? DIRECT_WIRE_TYPE?.TOKEN_PURCHASE
          : DIRECT_WIRE_TYPE?.WALLET_ACTIVATION,
      },
    });
    setLoading(false);
  };
  const walletActiveData = async () => {
    const data = {
      selected: "",
      activationType: "fiat",
      type: "required",
    };
    try {
      const res = await activationWalletToken(data);
      setActivationAmount(res?.data);
    } catch (error) {}
  };
  useEffect(() => {
    walletActiveData();
  }, []);

  const valueChanger = () => {
    let data = {
      selected: selected,
      amount: "1",
    };
    try {
      dispatch(getAssetsToAssetsSawap(data, swap));
    } catch (error) {}
  };

  useEffect(() => {
    valueChanger();
  }, [selected]);
  useEffect(() => {
    if (isActivate) {
      setValueGet(formik.values.amount * sawapSelector?.assetSwaoInfo);
    }
  }, [formik.values.amount, selected]);

  useEffect(() => {
    if (isActivate) {
      setValueGet(formik.values.amount * sawapSelector?.assetSwaoInfo);
    }
  }, [sawapSelector]);
  //------------get token Rates--------------------//
  const getDepositRates = async () => {
    try {
      const res = await despositGetRates(selected?.coinSymbol);
      if (res?.data?.price) {
        setTokenRate(res?.data?.price);
      } else {
        setTokenRate(1);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getDepositRates();
  }, [selected]);
  useEffect(() => {
    if (!isActivate) {
      setValueGet(activationAmount / tokenRate);
    }
  }, [activationAmount, tokenRate]);
  return (
    <div className={styles.container}>
      {customerSelector.walletFiatLoading ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <>
            {!isActivate ? (
              <div className={styles.cardWraper}>
                <div className={styles.card}>
                  <label>Member Activation</label>
                  <span className={styles.text}>
                    To open your C<CQRComponent />R Vault, you must add &
                    maintain a C<CQRComponent />R Dollar (“ C<CQRComponent />R
                    ”) contribution ≥ the value of 21 Ag Asset Tokens (21oz
                    0.999 Fine Silver).
                    <br />
                    <br />
                    Please remit the amount below or more to open your C
                    <CQRComponent />R Vault account:
                  </span>
                </div>
                <div className={styles.card}>
                  <InputField
                    Name="Redeem Amount"
                    type="number"
                    inputName="amount"
                    value={Number(activationAmount).toFixed(2)}
                  />
                  {loading ? (
                    <LoadingButton Loading="Loading" />
                  ) : (
                    <button className={styles.btnBtn} onClick={handleSend}>
                      Open
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.cardWraper}>
                <div className={styles.cardImg}>
                  <img
                    src={BackTo}
                    alt="back"
                    className={styles.bactTo}
                    onClick={() => history.goBack()}
                  />
                  {location?.state?.detail?.type === "asset" ? (
                    <label>
                      {" "}
                      Get C<CQRComponent spanStyle={{ fontSize: "38px" }} />R
                      Assets
                    </label>
                  ) : (
                    <label>
                      Get C
                      <CQRComponent
                        spanStyle={{ fontSize: "38px", width: "32px" }}
                      />
                      R Dollars
                    </label>
                  )}
                  {location?.state?.detail?.type === "asset" ? (
                    <span className={styles.text}>
                      Each 1.000 Ag/Au Asset bears the beneficial interest value
                      of 1.000oz Government Minted 0.999 Fine Silver/Gold legal
                      tender coin domiciled in the C<CQRComponent />R Vault for
                      the self-directed value exchange(amount) made by and
                      between bona fide participant members of C<CQRComponent />
                      R Vault. All Asset Token covestments must be maintained in
                      your account for a minimum of 1 Year and 1 Day before they
                      can qualify for redeem. However, you are still able to
                      send or list these tokens on C<CQRComponent />R Vest
                      whenever you want.
                      <br />
                      <br />
                      For any initial acquisition of ICO Ag/Au recorded to your
                      vault ledger, you must make a minimum swap and/or deposit
                      equal to or greater than the value of 21 ICO Ag Tokens.
                      This value of Asset Tokens must be maintained at all
                      times, or pursuant to the terms and policy of your
                      Relationship and Royalty Sharing Covenant, the Asset
                      Tokens may be automatically redeemed to CQRUS. To covest
                      Simply enter the amount of C<CQRComponent />R Asset Tokens
                      you want to hold in your C<CQRComponent />R Vault, and put
                      C
                      <CQRComponent />R Silver & Gold (a.k.a. “<CQRBComponent />{" "}
                      / <CQRAComponent /> / Å”) in your digital vault today!
                    </span>
                  ) : (
                    <span className={styles.text}>
                      Each <CQRComponent />
                      1.00 C<CQRComponent />R bears the beneficial interest
                      value of $1.00 USD domiciled in the C<CQRComponent />R
                      Vault for the self-directed value exchange(s) made by and
                      between Bona Fide Participant Members of C<CQRComponent />
                      R Vault.
                      <br />
                      <br />
                      Simply enter the amount of C<CQRComponent />R Dollars you
                      want to hold in your C<CQRComponent />R Vault, and put C
                      <CQRComponent />R Dollars (a.k.a. C<CQRComponent />R or “
                      <CQRComponent />
                      ”) in your digital vault today!
                    </span>
                  )}
                </div>
                <div className={styles.card}>
                  <form onSubmit={formik.handleSubmit}>
                    <label> Token</label>
                    <DropDown
                      padding="14px 20px"
                      selected={selected}
                      setSelected={(res) => {
                        formik.setFieldValue("to", res);
                        setSelected(res);
                      }}
                      options={options}
                      img={BitCoin}
                      imgShow
                    />
                    {formik.errors.to && formik.touched.to ? (
                      <div style={{ color: "#E5516B" }}>{formik.errors.to}</div>
                    ) : null}
                    <InputField
                      Name="Redeem Amount"
                      type="number"
                      // inputName="Max Exchange in 24hrs is 5,000.00 CQR"
                      config={formik.getFieldProps("amount")}
                    />
                    {formik.errors.amount && formik.touched.amount ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.amount}
                      </div>
                    ) : null}
                    <p className={styles.QSymbolImg}>
                      Max Online Exchange is <CQRComponent />
                      5,000.00 / Direct Wire (No Limit)
                    </p>
                    <InputField
                      name={"Quantity of Tokens you will Receive"}
                      type="number"
                      value={valueGet}
                      disable
                    />
                    {legacyLoading ? (
                      <button className={styles.btnBtn} type="button">
                        <Wave />
                      </button>
                    ) : (
                      <button className={styles.btnBtn} type="submit">
                        Get Now
                      </button>
                    )}
                  </form>
                </div>
              </div>
            )}
          </>
        </div>
      )}
      <Modal visible={show} onClose={() => setShow(false)}>
        {location?.state?.detail?.type === "sdira" ? (
          <Popup
            // src={logo}
            para="Please select your method for settlement"
            btn="Direct wire"
            loading={loading}
            // BtnShow
            tokenData={params}
            activationAmount={activationAmount}
            handleOk={handleModalOk}
            handleCoinQvest={() => handleCoinQvest()}
            handlePayPalRes={() => handlePayPalActivation()}
            // onPressButton={() => history.push("/")}
            onPressinnerButton={() => {
              setShow(false);
              // formik.handleSubmit()
              handlePaymentViaGoCardless();
            }}
          />
        ) : (
          <Popup
            // src={logo}
            para="Please select your method for settlement"
            btn="Direct wire"
            btn1="Go cardless"
            QVestbtn="COINQVEST"
            Paypalbtn="PayPal"
            loading={loading}
            BtnShow
            tokenData={params}
            activationAmount={activationAmount}
            handleOk={handleModalOk}
            handleCoinQvest={() => handleCoinQvest()}
            handlePayPalRes={() => handlePayPalActivation()}
            // onPressButton={() => history.push("/")}
            onPressinnerButton={() => {
              setShow(false);
              // formik.handleSubmit()
              handlePaymentViaGoCardless();
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default CqrLiquidityTokens;
