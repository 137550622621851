import React, { useEffect, useState } from "react";
import Input from "../../../../../../_Common/input/input";
import styles from "./FNFTpopup.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { HTTP_CLIENT } from "../../../../../../../utils/services";
import { NFT_PURCHASE_METHODS } from "../../../../../../../utils/AppConstants";
import btc from "../../../../../../../Assets/icons/Btc.svg";
import {
  LoadingButton,
  LoadingButton2,
  WaveClone,
} from "../../../../../../_Common/loader/Loader";
import { CloudLightning } from "react-feather";

const FNFTpopup = ({ data }) => {
  const history = useHistory();
  const [FNFTData, setFNFTData] = useState(null);
  const [loanCheck, setLoanCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const nativeWallet = useSelector(
    (state) => state?.currency?.currencyInfo?.nativeWallets
  );
  const isSdira = useSelector((state) => state.user.userInfo.sdira);

  useEffect(() => {
    setFNFTData(data);
  }, []);
  const handlechange = (e) => {
    const { value } = e.target;
    if (value.match(/^(0|[1-9]\d*)$/) && value > 0) {
      setFNFTData((prev) => ({
        ...prev,
        amount: value,
      }));
    } else if (value == "") {
      setFNFTData((prev) => ({
        ...prev,
        amount: "",
      }));
    }
  };

  const handleSubmit = async () => {
    // return;
    if (Number(FNFTData?.remainingSupply) < Number(FNFTData?.amount)) {
      alert.show("Enter number of share not available", { type: "error" });
      return;
    }
    if (Number(FNFTData?.remainingSupply) < Number(FNFTData?.amount)) {
      alert.show("Enter number of share not available", { type: "error" });
      return;
    }
    if (!FNFTData.amount) {
      alert.show("Please enter amount first", { type: "error" });
      return;
    }
    if (FNFTData.amount < 0) {
      alert.show("Amount cannot be negative", { type: "error" });
      return;
    }
    // if (FNFTData.amount > 5000) {
    //   alert.show("Number of units should not be more than 5000", {
    //     type: "error",
    //   });
    //   return;
    // }
    if (FNFTData.paymentMetod == "CQR") {
      const qWallet = nativeWallet.find((wallet) => wallet.coinSymbol == "Q");
      if (
        Number(qWallet.balance) <
        Number(FNFTData.amount * FNFTData?.pricePerShare)
      ) {
        alert.show("insufficient balance", { type: "error" });
        setLoanCheck(true);
        return;
      } else {
        try {
          setLoading(true);
          let data = {
            assetPool: FNFTData.nftToken,
            amount: String(FNFTData.amount),
            price: String(FNFTData?.pricePerShare),
            paymentMethod: NFT_PURCHASE_METHODS.TOKEN,
          };
          const res = await HTTP_CLIENT.post(
            "nft-purchase-request/create-new-request",
            data
          );
          if (res) {
            // alert.show(
            //   "Request submitted successfully please wait for admin approval",
            //   { type: "success" }
            // );
            // if (isSdira) {
            alert.show(
              "Request submitted successfully please wait for admin approval",
              {
                type: "success",
              }
            );
            // } else {
            //   alert.show("Assest buy successfully", {
            //     type: "success",
            //   });
            // }
          }
          history.push("/cryptocurrency");
          setLoading(false);
        } catch (err) {
          if (err.response.data.message == "Please subscribe plan first") {
            history.push("/subscription");
          }

          alert.show(err.response.data.message, { type: "error" });
          setLoading(false);
        }
      }
    } else {
      try {
        let data = {
          assetPool: FNFTData._id,
          amount: String(FNFTData.amount),
          price: String(FNFTData?.pricePerShare),

          paymentMethod: NFT_PURCHASE_METHODS.FIAT,
        };
        const res = await HTTP_CLIENT.post(
          "nft-purchase-request/create-new-request",
          data
        );
        if (res) {
          // alert.show(
          //   "Request submitted successfully please wait for admin approval",
          //   { type: "success" }
          // );
          // if (isSdira) {
          alert.show(
            "Request submitted successfully please wait for admin approval",
            {
              type: "success",
            }
          );
          // } else {
          //   alert.show("Assest buy successfully", {
          //     type: "success",
          //   });
          // }
        }
        history.push("/cryptocurrency");
      } catch (err) {
        alert.show(err.response.data.message, { type: "error" });
      }
    }
  };
  const handleLoan = async () => {
    try {
      setLoading(true);
      let data = {
        assetPool: FNFTData?.nftToken,
        amount: String(FNFTData.amount),
        price: String(
          FNFTData.amount * FNFTData?.pricePerShare
            ? FNFTData?.pricePerShare
            : FNFTData?.pricePerShare
        ),
        paymentMethod: NFT_PURCHASE_METHODS.TOKEN,
        isLoan: true,
        isPaid: false,
      };
      const res = await HTTP_CLIENT.post(
        "nft-purchase-request/create-new-request",
        data
      );
      if (res) {
        // alert.show(
        //   "Request submitted successfully please wait for admin approval",
        //   { type: "success" }
        // );
        // if (isSdira) {
        alert.show(
          "Request submitted successfully please wait for admin approval",
          {
            type: "success",
          }
        );
        // } else {
        //   alert.show("Assest buy successfully", {
        //     type: "success",
        //   });
        // }
      }
      history.push("/cqr-vest");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert.show(err.response.data.message, { type: "error" });
    }
  };
  return (
    <>
      <div className={styles.container}>
        {/* {loanCheck ? (
          <div className={styles.wrapper}>
            <div className={styles.heading}>Loan Request</div>
            <div style={{ textAlign: "center" }}>
              <img
                src={data.image}
                alt="hhh"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div style={{ textAlign: "center" }}>{data.name}</div>
            <div className={styles.textWrapper}>
              <label style={{ textAlign: "center" }}>Term & condition:</label>
              <p>
                Nft tokens will be transferred to your wallet when admin
                approves your loan request. Loan duration is 30 days.
                {FNFTData?.amount} Q tokens will be deducted from your account
                any time you deposit money in your account. If there is not
                enough Q Tokens in your account at the end of 30 days period,
                your nft token units will be reverted back to where it was
                before purchase.
              </p>
            </div>
            {loading ? (
              <button className={styles.btn}>Submit Request</button>
            ) : (
              <button className={styles.btn} onClick={handleLoan}>
                Submit Request
              </button>
            )}
          </div>
        ) : ( */}
        <div className={styles.wrapper}>
          <div className={styles.heading}>Get Units Now</div>
          <div>
            <Input
              pStyle={{ color: "white", opacity: "1" }}
              style2={{
                backgroundColor: "#2d3143",
                border: " 1px solid  rgba(71, 71, 71, 0.6)",
              }}
              name="Number of Units"
              type="text"
              inputName="Enter number of Units"
              value={FNFTData?.amount}
              onclick={(e) => handlechange(e)}
            />
          </div>
          <div className={styles.textWrapper}>
            <label>Note:</label>
            <p>
              Please enter the amount of units you want to get. Once you click
              submit, your request will be sent to the CQR Vault Treasury for
              due diligence and review. Units will be transferred to your
              account in 3-4 working days once the admin has approved your
              request. You can use the “Support” feature of the CQR Vault to
              inquire or ask for assistance.
            </p>
          </div>
          {loading ? (
            <button className={styles.loadingBtn}>
              <WaveClone />
            </button>
          ) : (
            <button className={styles.btn} onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default FNFTpopup;
// () => history.push("/cqr-Vest")
