import React, { useState } from "react";
import styles from "./marketplaceDetail.module.scss";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Cqrvest from "../../../../../Assets/icons/CQRVEST.png";
import Chat from "../../../../../Assets/icons/inbox.svg";
import white from "../../../../../Assets/icons/white.png";
// import { useHistory } from "react-router-dom";
import { Loader, Wave } from "../../../../_Common/loader/Loader";
import BackTo from "../../../../../Assets/icons/backk.svg";
import Dummy from "../../../../../Assets/icons/dummy.png";
import Modal from "../../../../_Common/Modal/Modal";
import FNFTpopup from "../../DashboardInnerComponent/CQR Vest/SilentWave/FNFTpopup/FNFTpopup";
import BuyMarketplace from "./BuyMarketplace";
import { HTTP_CLIENT } from "../../../../../utils/services";
import CQRComponent from "../../../../_Common/CQRComponent/CQRComponent";

const MarketplaceDetail = ({ history }) => {
  const [popupvisible, setpopupvisible] = useState(false);
  const [FNFTData, setFNFTData] = useState(null);
  const [adId, setAdId] = useState("");
  const [loading, setLoading] = useState(false);

  // const history = useHistory();
  const location = useLocation();
  const userSelector = useSelector((state) => state.user);

  const options = [{ image: white, name: "CQR" }];
  const [selected, setSelected] = useState({ name: "CQR" });

  const togglepopup = (id) => {
    setAdId(id);
    setFNFTData(history?.location?.state?.detail);
    setpopupvisible((preview) => !preview);
  };

  //-----------cretae user chat room-----------//
  const userId = history?.location?.state?.detail?.user?._id;
  const generateNewChatRoom = async () => {
    setLoading(true);
    let payload = {
      otherUserId: userId,
    };

    try {
      const res = await HTTP_CLIENT.post("chat/room/create", payload);
      history.push({
        pathname: "/inbox",
        state: { detail: res.data },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const URL_REGEX = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:academy|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  function Text() {
    const words = location?.state?.detail?.nfttokens?.description
      ? location?.state?.detail?.nfttokens?.description?.split(" ")
      : location?.state?.detail?.nativeToken?.description?.split(" ");
    return (
      <p>
        {words?.map((word) => {
          return word?.match(URL_REGEX) ? (
            <>
              <a
                href={`http://${
                  word?.split("/")[2] ? word?.split("/")[2] : word
                }`}
                target="_blank"
              >
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }
  function TextComments() {
    const words = location?.state?.detail?.description?.split(" ");
    return (
      <p>
        {words?.map((word) => {
          return word?.match(URL_REGEX) ? (
            <>
              <a
                href={`http://${
                  word?.split("/")[2] ? word?.split("/")[2] : word
                }`}
                target="_blank"
              >
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }
  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <BuyMarketplace
          data={FNFTData}
          id={adId}
          setpopupvisible={setpopupvisible}
          priceOfShare={location?.state?.detail?.pricePerShare.toFixed(2)}
        />
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <img
              src={BackTo}
              alt="back"
              className={styles.back}
              onClick={() => history.goBack()}
            />
            <div className={styles.imgLogo}>
              <img src={Cqrvest} alt="logo" className={styles.logo} />
            </div>
          </div>

          <div className={styles.CardWrapper}>
            <div className={styles.leftCard}>
              <img
                src={
                  location?.state?.detail?.nfttokens?.image
                    ? location?.state?.detail?.nfttokens?.image
                    : location?.state?.detail?.nativeToken?.icon?.url
                }
                alt="oppertunity token"
              />
            </div>

            <div className={styles.rightCard}>
              <div>
                <div className={styles.userWrapper}>
                  <div className={styles.userInfo}>
                    <img
                      src={
                        history?.location?.state?.detail?.user?.profilePicture
                          ? history?.location?.state?.detail?.user
                              ?.profilePicture
                          : Dummy
                      }
                      alt="user"
                    />
                    <div>
                      <span>
                        {history?.location?.state?.detail?.user?.name}
                      </span>
                      {/* <p>{history?.location?.state?.detail?.user?.email}</p> */}
                    </div>
                  </div>
                  {loading ? (
                    <div className={styles.loaderWidth}>
                      <Wave />
                    </div>
                  ) : (
                    <img
                      src={Chat}
                      alt="chat"
                      onClick={() => generateNewChatRoom()}
                    />
                  )}
                </div>
                <div className={styles.Dec}>
                  <label>
                    {location?.state?.detail?.nfttokens?.name
                      ? location?.state?.detail?.nfttokens?.name
                      : location?.state?.detail?.nativeToken?.displayName}
                  </label>
                  <p>
                    <Text />
                  </p>
                </div>
                <div className={styles.description}>
                  <label>Member Comments</label>
                  <p>
                    <TextComments />
                  </p>
                </div>
                <div className={styles.cardHeading}>{FNFTData?.name}</div>
              </div>{" "}
              <div>
                <div className={styles.paragraphWrapper}>
                  <div>
                    <label>
                      Value: <CQRComponent />
                      &nbsp;
                      {location?.state?.detail?.pricePerShare.toFixed(2)}
                    </label>
                    {/* <label>Min Unit You can Acquire: 2 </label> */}
                    <label>
                      Number of Exchanges: {location?.state?.detail?.soldShares}{" "}
                    </label>
                  </div>
                  <div>
                    <p>
                      Units Available:{" "}
                      {location?.state?.detail?.remainingShares?.toFixed(2)}
                    </p>
                    {/* <p>Max Unit You Can Acquire: 5</p> */}
                  </div>
                </div>

                <div className={styles.buttonWrapper}>
                  <button
                    className={styles.btn1}
                    onClick={() => history.push("/marketplace")}
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    className={styles.btn2}
                    onClick={() => togglepopup(location?.state?.detail?._id)}
                  >
                    <span> Acquire</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketplaceDetail;
