import { MARKETPLACE_LISTING } from "../types";
const initialState = {
  allMarketplace: [],
};
const marketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case MARKETPLACE_LISTING:
      return {
        ...state,
        allMarketplace: action?.payload,
      };

    default:
      return state;
  }
};

export default marketplaceReducer;
