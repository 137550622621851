import React from "react";
import styles from "./openaccount.module.scss";
import Modal from "../../_Common/Modal/Modal";
import Logo from "../../../Assets/image/logo.svg";
import { useHistory } from "react-router-dom";

const OpenAccount = ({ visible, onClose }) => {
  let history = useHistory();

  return (
    <>
      <Modal visible={visible}>
        <div className={styles.container}>
          <div className={styles.heading}>
            <img src={Logo} alt="" />
          </div>
          <div className={styles.textsection}>
            <p>Submit your GPA Level 3 Data to Continue</p>
            <button onClick={() => history.push("/profile")}>Apply Now</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OpenAccount;
