import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Dummy from "../../../../../Assets/icons/dummy.png";
import Edit from "../../../../../Assets/icons/editchat.svg";
import SearchIcon from "../../../../../Assets/icons/search.svg";
import InputField from "../../../../_Common/input/input";
import { Wave } from "../../../../_Common/loader/Loader";
import Modal from "../../../../_Common/Modal/Modal";
import { getOtherUser } from "../Helpers";
import useInboxSockets from "../hooks/useInboxSockets";
import styles from "./chatContact.module.scss";
import NewUserModal from "./NewContact";
import { useAlert } from "react-alert";

import SkeletonCard from "../SkeletonCard";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import {
  deleteChatRooms,
  getuserChatRooms,
} from "../../../../../services/chatService";
import { setupAxios } from "../../../../../utils/services";

const ChatContact = ({ userMessages, setMessages }) => {
  const [search, setSearch] = useState("");
  const [chatDelte, setChatDelete] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [popupVisibleDelete, setPopupVisibleDelete] = useState(false);
  const [popupvisiblee, setpopupvisiblee] = useState(false);

  const [
    chats,
    setChats,
    loading,
    onRefresh,
    refreshing,

    setLoading,
  ] = useInboxSockets();
  const { userInfo } = useSelector((state) => state.user);
  const alert = useAlert();
  const handleInputChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };
  const userMessage = (data) => {
    userMessages(data);
  };

  const togglePopupDelete = (id) => {
    setPopupVisibleDelete((preview) => !preview);
  };
  const filteredData = chats?.filter((el) => {
    //if no input then return the original array
    if (search === "") {
      return el;
    }
    //return the item which contains the user input
    else {
      return (
        el?.members[0]?.name?.toLowerCase()?.includes(search) ||
        el?.members[1]?.name?.toLowerCase()?.includes(search)
      );
    }
  });
  const skeletonCard = [1, 2, 3, 4, 5, 6, 7, 8];
  const togglePopup = (item) => {
    setChatDelete(item);
    setpopupvisiblee(true);
  };

  //------------------------DELETE CHAT----------------------------//
  const deleteChat = async () => {
    setLoadingDelete(true);
    setupAxios();
    try {
      const res = await deleteChatRooms(chatDelte?.chatRoomId);
      const response = await getuserChatRooms(1, 1000, "");
      setChats(response?.data?.data?.data);
      setMessages("");
      alert.show(res?.data?.message, { type: "success" });
      setpopupvisiblee(false);
      setLoadingDelete(false);
    } catch (error) {
      setpopupvisiblee(false);
      setLoadingDelete(false);
    }
  };
  //----------Filter Chats on Delete Basis----------//
  const deleteChatFIlter = filteredData?.filter(
    (item) => item?.deletedBy?.includes(userInfo?._id) !== true
  );

  return (
    <div>
      <Modal
        visible={popupvisiblee}
        // btn
        onClose={() => setpopupvisiblee(false)}
      >
        <div className={styles.modalWrapper}>
          <label>Are you sure you want to delete this chat?</label>
          <div className={styles.btnsection}>
            {loadingDelete ? (
              <button className={styles.Delete} disabled>
                <Wave />
              </button>
            ) : (
              <button
                className={styles.Delete}
                type="submit"
                onClick={deleteChat}
              >
                Confirm
              </button>
            )}
            <button
              className={styles.Delete}
              onClick={() => setpopupvisiblee(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={popupVisibleDelete}
        btn
        onClose={() => setPopupVisibleDelete(false)}
      >
        <NewUserModal
          setpopupvisible={setPopupVisibleDelete}
          setPopupVisibleDelete={setPopupVisibleDelete}
          userMessages={userMessages}
        />
      </Modal>
      <div className={styles.wrapper}>
        <div className={styles.inboxWrapper}>
          <p>Inbox</p>
          <img src={Edit} alt="edit chat" onClick={() => togglePopupDelete()} />
        </div>
        <div className={styles.inputWrapper}>
          <InputField
            inputName="Search Users"
            style2={{
              backgroundColor: "#111422",
              height: "40px",
              borderRadius: "20px",
            }}
            onclick={(e) => handleInputChange(e)}
            value={search}
            type="text"
            img={SearchIcon}
          />
        </div>
        {loading
          ? skeletonCard?.map((item, index) => {
              return <SkeletonCard key={index} />;
            })
          : deleteChatFIlter?.map((item, index) => {
              const filter = item?.deletedBy?.length > 0;
              const chat = getOtherUser(item, userInfo?._id);
              return (
                <div
                  className={styles.messageWraper}
                  key={index}
                  onClick={() => userMessage(item)}
                >
                  <div className={styles.userWrapper}>
                    <div className={styles.imgwrapper}>
                      <img
                        src={
                          chat?.profilePicture ? chat?.profilePicture : Dummy
                        }
                        alt="user"
                      />
                      {chat?.onlineStatus ? (
                        <div className={styles.isOnline}></div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <label>{chat?.name}</label>
                      <span>
                        {item?.recentMessage?.text == ""
                          ? "Image Attachment"
                          : item?.recentMessage?.text.length > 10
                          ? `${item?.recentMessage?.text.substring(0, 25)}...`
                          : item?.recentMessage?.text}
                      </span>
                    </div>
                  </div>
                  <div className={styles.delteWrapper}>
                    <p>{moment(chat?.updatedAt).fromNow(true)}</p>
                    <BsThreeDotsVertical
                      className={styles.icon}
                      onClick={() => togglePopup(item)}
                    />
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default ChatContact;
