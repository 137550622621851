import React, { useEffect } from "react";
import styles from "./splashscreen.module.scss";
import "../../../index.css";
import Logo from "../../../Assets/image/logo.svg";
export default function Splash({ setSplash }) {
  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 1000);
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <img src={Logo} alt="log" />
          </div>
          {/* <div className="pulse"></div> */}
          <div className="CQRloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
