import React, { useEffect } from "react";
import Navigation from "./navigationSideBar/index";
import SideBar from "./sidebar";
import styles from "./sidebarWrapper.module.scss";
import LandingNavigation from "../../../_Common/navigation/navigation";
import DashRoutes from "../../DashBoardLayout";
import { useParams } from "react-router-dom";
import { HTTP_CLIENT } from "../../../../utils/services";
import { useDispatch } from "react-redux";
import { userCleanUp, KYCCleanUp } from "../../../../redux/action/userAction";
import { currencyLogoutCleanUp } from "../../../../redux/action/currencyDetailAction";
import { swapLogoutCleanUp } from "../../../../redux/action/sawapAction";
import { subscriptionCleanUp } from "../../../../redux/action/subscriptionAction";
import { remittanceCleanUp } from "../../../../redux/action/remittanceAction";
// import { useHistory } from "react-router-dom";

const SideBarWrapper = ({ history }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      await HTTP_CLIENT.get(`user/info`);
    } catch (error) {
      dispatch(userCleanUp());
      dispatch(KYCCleanUp());
      dispatch(currencyLogoutCleanUp());
      dispatch(swapLogoutCleanUp());
      dispatch(subscriptionCleanUp());
      dispatch(remittanceCleanUp());

      localStorage.clear();
      history.push("/");
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        <Navigation id={id} history={history} />
        <div className={styles.left}>
          <SideBar id={id} history={history} />
        </div>
        <div className={styles.right}>
          <LandingNavigation show={false} />
          <DashRoutes />
        </div>
      </div>
    </>
  );
};

export default SideBarWrapper;
