import * as yup from "yup";
export const formikSchema = yup.object({
  CompanyName: yup.string(),
  Email: yup.string().email("Must be a valid email").required("Email required"),
  FamilyName: yup.string().required("Family Name required"),
  GivenName: yup.string().required("Given Name required"),
  // Language: yup.string().required("Language is Required"),
  PhoneNumber: yup
    .string()
    .min(6, "min 8 number")
    // .matches(
    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //   "Phone number is not valid"
    // )
    .required("Phone Number required"),
  Address: yup.string().required("Address is required"),
  City: yup.string().required("City is required"),
  PostalCode: yup.string().required("Postal code is required"),
});
