import React, { useEffect, useState } from "react";
import styles from "./SilentWave.module.scss";
import qsymbol from "../../../../../../Assets/image/qsymbol.png";
import { useHistory } from "react-router-dom";
import Modal from "../../../../../_Common/Modal/Modal";
import FNFTpopup from "./FNFTpopup/FNFTpopup";
import DropDown from "../../../../../_Common/DropDown/dropDown";
import { useLocation } from "react-router-dom";
import Cqrvest from "../../../../../../Assets/icons/CQRVEST.png";
import white from "../../../../../../Assets/icons/white.png";
import Rcv from "../../../../../../Assets/image/rcv.svg";
import Send from "../../../../../../Assets/image/send.svg";
import { useSelector } from "react-redux";
import Chat from "../../../../../../Assets/icons/inbox.svg";
import Dummy from "../../../../../../Assets/icons/dummy.png";
import { BASE_URL } from "../../../../../../utils/AppConstants";
import axios from "axios";
// import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import BackTo from "../../../../../../Assets/icons/backk.svg";
import { Loader, WaveClone, Wave } from "../../../../../_Common/loader/Loader";
import { useAlert } from "react-alert";
import Transactions from "./Transactions";
import { toast } from "react-toastify";
import OpportunityHeader from "./opportunityHeader";
import ScrollToTop from "../../../../../_Common/ScrollToTop";
const SilentWave = ({ history }) => {
  const location = useLocation();

  const [popupvisible, setpopupvisible] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [quantityValidation, setQuantityValidation] = useState(false);

  const [FNFTData, setFNFTData] = useState(null);
  const [active, setActive] = useState(
    location?.state?.detail?.state?.detail?.type ? 2 : 1
  );
  const [amountCount, setAmountCount] = useState("");

  const [loading, setLoading] = useState(false);

  // const history = useHistory();
  const userSelector = useSelector((state) => state.user);
  const alert = useAlert();
  const togglepopup = () => {
    setpopupvisible((preview) => !preview);
  };
  const options = [{ image: white, name: "CQR" }];
  const [selected, setSelected] = useState({ name: "CQR" });

  useEffect(() => {
    setFNFTData(location?.state?.detail);
  }, []);

  useEffect(() => {
    setFNFTData((prev) => ({
      ...prev,
      paymentMetod: selected.name,
    }));
  }, [selected]);

  function numberWithCommas(n) {
    var parts = n ? n?.toString()?.split(".") : "";
    return (
      parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : ".00")
    );
  }
  const URL_REGEX = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:academy|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|(?:life|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  // const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  function Text() {
    const words = location?.state?.detail?.description?.split(" ");
    return (
      <p>
        {words?.map((word) => {
          return word?.match(URL_REGEX) ? (
            <>
              <a
                href={`http://${
                  word?.split("/")[2] ? word?.split("/")[2] : word
                }`}
                target="_blank"
              >
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }
  const formikSchema = yup.object({
    value: yup.string().required("Value is Required"),

    description: yup.string(),

    amount: yup.string(),
    // .min(11, "Phone is Too Short!"),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      value: "",

      description: "",
      amount: amountCount,
    },
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
      setQuantity("");
    },
  });
  useEffect(() => {
    setAmountCount(formik?.values?.value * quantity);
  }, [formik?.values?.value, quantity]);

  //--------adding to Marketplace--------//
  const handleSubmit = async (values) => {
    if (!quantity) {
      setQuantityValidation(true);
      return;
    }
    setLoading(true);
    const params = {
      type: "sell",
      coinType: location?.state?.detail?.state?.detail?.type ? "native" : "nft",
      numShares: +quantity,
      pricePerShare: +values?.value,
      walletId: location?.state?.detail?.state?.detail?.type
        ? location?.state?.detail?.state?.detail?.walletId
        : location?.state?.detail?._id,
      description: values?.description,
    };
    let authToken = localStorage.getItem("authToken");
    try {
      const res = await axios.post(`${BASE_URL}marketplace/ad`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res?.data?.message === "ad created successfully") {
        alert.show("Your listing has been posted successfully", {
          type: "success",
        });
      }

      history.push("/marketplace");
      setLoading(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setLoading(false);
    }
  };
  //--------------user go back to details screen----------//
  const goBack = () => {
    if (location?.state?.detail?.state?.detail?.type) {
      history.push({
        pathname: "/cryptocurrency",
        state: {
          activeState: location?.state?.detail?.state?.detail?.activeButton
            ? location?.state?.detail?.state?.detail?.activeButton
            : location?.state?.detail?.state?.detail?.type === "liquidity"
            ? 2
            : 4,
        },
      });
    } else {
      setActive(1);
    }
  };
  const handleChangeAmount = (e) => {
    let { name, value } = e.target;
    let lenght = value.toString().split(".")[1]?.length;
    if (lenght > 8) {
      return;
    } else {
      setQuantity(value);
    }
  };

  useEffect(() => {
    if (quantity?.length > 1) {
      setQuantityValidation(false);
    }
  }, [quantity]);

  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <FNFTpopup data={FNFTData} />
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <img
              src={BackTo}
              alt="back"
              className={styles.back}
              onClick={() => {
                history.push({
                  pathname: "/cryptocurrency",
                  state: {
                    activeState: location?.state?.detail?.state?.detail
                      ?.activeButton
                      ? location?.state?.detail?.state?.detail?.activeButton
                      : location?.state?.detail?.state?.detail?.type ===
                        "liquidity"
                      ? 2
                      : 4,
                  },
                });
              }}
            />
            <div className={styles.imgLogo}>
              <img src={Cqrvest} alt="logo" className={styles.logo} />
            </div>
          </div>

          <div className={styles.CardWrapper}>
            {active === 1 ? (
              <div className={styles.leftCard}>
                <OpportunityHeader location={location} />
                <div className={styles.buttonWrapper}>
                  <button
                    className={styles.btn1}
                    onClick={() => togglepopup()}
                    // onClick={() => history.push("/cqr-vest")}
                  >
                    <span>Acquire</span>
                    <img src={Rcv} alt="recieve" />
                  </button>
                  <button className={styles.btn2} onClick={() => setActive(2)}>
                    <span> Exchange</span> <img src={Send} alt="send" />
                  </button>
                </div>
                <Transactions opertunityToken={location} />
              </div>
            ) : (
              <div className={styles.exchangeWrapper}>
                <div className={styles.userWrapper}>
                  <div className={styles.userInfo}>
                    <img
                      src={
                        userSelector?.userInfo?.profilePicture
                          ? userSelector?.userInfo?.profilePicture
                          : Dummy
                      }
                      alt="user"
                    />
                    <div>
                      <span>{userSelector?.userInfo?.name}</span>
                      <p>{userSelector?.userInfo?.email}</p>
                    </div>
                  </div>
                  {/* <img src={Chat} alt="chat" /> */}
                </div>
                <div className={styles.textField}>
                  <form onSubmit={formik.handleSubmit}>
                    <label>Value Per Unit (CQRUS)</label>
                    <input
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      min="0"
                      type="text"
                      placeholder="Value"
                      {...formik.getFieldProps("value")}
                    />
                    {formik.errors.value && formik.touched.value ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.value}
                      </div>
                    ) : null}
                    <label>Quantity(Tokens)</label>
                    <input
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      onChange={(e) => handleChangeAmount(e)}
                      value={quantity}
                      min="0"
                      step="any"
                      type="number"
                      placeholder="Quantity"
                    />
                    {quantityValidation ? (
                      <div style={{ color: "#E5516B" }}>
                        Quantity is Required
                      </div>
                    ) : null}

                    <label>Description</label>
                    <textarea
                      rows="4"
                      type="text"
                      placeholder="Description"
                      {...formik.getFieldProps("description")}
                    />
                    {formik.errors.description && formik.touched.description ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.description}
                      </div>
                    ) : null}

                    <label>Total Amount</label>
                    <input
                      type="text"
                      placeholder="Amount"
                      value={amountCount}
                    />
                    {formik.errors.amount && formik.touched.amount ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.amount}
                      </div>
                    ) : null}
                    <div className={styles.buttonWrapper}>
                      <button
                        type="button"
                        className={styles.btn1}
                        onClick={() => goBack()}
                      >
                        Cancel
                      </button>
                      {loading ? (
                        <button type="button" className={styles.btn2}>
                          <Wave />
                        </button>
                      ) : (
                        <button type="submit" className={styles.btn2}>
                          Post
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
            {location?.state?.detail?.state?.detail?.type === "asset" ||
            location?.state?.detail?.state?.detail?.type === "liquidity" ? (
              <div className={styles.leftCard}>
                <Transactions opertunityToken={location} />
              </div>
            ) : (
              <div className={styles.rightCard}>
                <div className={styles.Dec}>
                  <div>
                    {/* <img
                    src={location?.state?.detail?.image}
                    alt="oppertunity token"
                  /> */}
                    <label>{location?.state?.detail?.name}</label>

                    <p>
                      <Text />
                    </p>

                    {/* <Text /> */}
                  </div>
                </div>
                {/* <div className={styles.cardHeading}>{FNFTData?.name}</div> */}
                <div className={styles.paragraphWrapper}>
                  <div className={styles.leftSide}>
                    <label>Value:</label>
                    {/* <p>Owned By</p> */}
                  </div>
                  <div className={styles.rightSide}>
                    <label>
                      <img src={qsymbol} alt="" />
                      &nbsp;&nbsp;
                      {FNFTData?.pricePerShare}
                    </label>
                    {/* <p style={{ color: "#40a1dc" }}>{FNFTData?.name}</p> */}
                  </div>
                </div>
                <div className={styles.secparagraphWrapper}>
                  <div className={styles.leftSide}>
                    <label>Remaining Units:</label>
                    <p>Total Units:</p>
                  </div>
                  <div className={styles.rightSide}>
                    <label>
                      {numberWithCommas(
                        location?.state?.detail?.remainingSupply?.toFixed(2)
                      )}
                    </label>
                    <p>
                      {numberWithCommas(
                        location?.state?.detail?.totalSupply?.toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SilentWave;
