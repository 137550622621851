import { CREATE_ACCOUNT, OPPERTUNITY_TOKEN } from "../types";
const initialState = {
  createAccountCheck: false,
  oppertunityTokenList: [],
};
const createAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return {
        ...state,
        createAccountCheck: action?.payload,
      };
    case OPPERTUNITY_TOKEN:
      return {
        ...state,
        oppertunityTokenList: action?.payload,
      };
    default:
      return state;
  }
};

export default createAccountReducer;
