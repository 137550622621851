import React, { useState } from "react";
import styles from "./ownDetail.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Cqrvest from "../../../../../Assets/icons/CQRVEST.png";
import BackTo from "../../../../../Assets/icons/backk.svg";
import Modal from "../../../../_Common/Modal/Modal";
import ActiveOwnAd from "./ActivateAd";
import DeleteModal from "./DeleteAd";
import CQRComponent from "../../../../_Common/CQRComponent/CQRComponent";

const OwnDetail = ({ history }) => {
  const [popupvisible, setpopupvisible] = useState(false);
  const [popupVisibleDelete, setPopupVisibleDelete] = useState(false);
  const [FNFTData, setFNFTData] = useState(null);
  const [adId, setAdId] = useState("");

  const location = useLocation();

  const togglepopup = (id) => {
    setAdId(id);
    setpopupvisible((preview) => !preview);
  };

  const togglePopupDelete = (id) => {
    setAdId(id);
    setPopupVisibleDelete((preview) => !preview);
  };

  const MARKETPLACE_AD_STATUS = {
    ACTIVE: "active",
    SOLD_OUT: "soldout",
    DEACTIVATED: "deactivated",
  };
  const URL_REGEX = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:academy|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  // const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  function Text() {
    const words = location?.state?.detail?.description?.split(" ");
    return (
      <p>
        {words?.map((word) => {
          return word?.match(URL_REGEX) ? (
            <>
              <a
                href={`http://${
                  word?.split("/")[2] ? word?.split("/")[2] : word
                }`}
                target="_blank"
              >
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }
  function Description() {
    const words = location?.state?.detail?.nftToken?.description
      ? location?.state?.detail?.nftToken?.description?.split(" ")
      : location?.state?.detail?.nativeToken?.description?.split(" ");
    return (
      <p>
        {words?.map((word) => {
          return word?.match(URL_REGEX) ? (
            <>
              <a
                href={`http://${
                  word?.split("/")[2] ? word?.split("/")[2] : word
                }`}
                target="_blank"
              >
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }
  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <ActiveOwnAd
          data={location?.state?.detail?.status}
          id={adId}
          setpopupvisible={setpopupvisible}
        />
      </Modal>
      <Modal
        visible={popupVisibleDelete}
        btn
        onClose={() => setPopupVisibleDelete(false)}
      >
        <DeleteModal
          data={location?.state?.detail?.status}
          id={adId}
          setpopupvisible={setPopupVisibleDelete}
        />
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <img
              src={BackTo}
              alt="back"
              className={styles.back}
              onClick={() => history.goBack()}
            />
            <div className={styles.imgLogo}>
              <img src={Cqrvest} alt="logo" className={styles.logo} />
            </div>
          </div>

          <div className={styles.CardWrapper}>
            <div className={styles.leftCard}>
              <img
                src={
                  history?.location?.state?.detail?.nfttokens?.image
                    ? history?.location?.state?.detail?.nfttokens?.image
                    : history?.location?.state?.detail?.nftToken?.image
                    ? history?.location?.state?.detail?.nftToken?.image
                    : history?.location?.state?.detail?.nativeToken?.icon?.url
                }
                alt=" token"
              />
            </div>

            <div className={styles.rightCard}>
              {/* <div className={styles.userWrapper}>
                <div className={styles.userInfo}>
                  <img
                    src={
                      history?.location?.state?.detail?.user?.profilePicture
                        ? history?.location?.state?.detail?.user?.profilePicture
                        : Dummy
                    }
                    alt="user"
                  />
                  <div>
                    <span>{history?.location?.state?.detail?.user?.name}</span>
                    <p>{history?.location?.state?.detail?.user?.email}</p>
                  </div>
                </div>
                <img src={Chat} alt="chat" />
              </div> */}
              <div>
                <div className={styles.Dec}>
                  <label>
                    {location?.state?.detail?.nftToken?.name
                      ? location?.state?.detail?.nftToken?.name
                      : location?.state?.detail?.nativeToken?.displayName}
                  </label>
                  <Description />
                  <span>Member Comments</span>
                  <p>
                    <Text />
                  </p>
                  {/* <Text /> */}
                </div>
                <div className={styles.cardHeading}>{FNFTData?.name}</div>
              </div>
              <div>
                <div className={styles.paragraphWrapper}>
                  <div>
                    <label>
                      Value: <CQRComponent />
                      &nbsp;
                      {location?.state?.detail?.pricePerShare.toFixed(2)}
                    </label>
                    {/* <label>Min Unit You can Acquire: 2 </label> */}
                    <label>
                      Number of Exchanges: {location?.state?.detail?.soldShares}{" "}
                    </label>
                  </div>
                  <div>
                    <p>
                      Units Available:{" "}
                      {location?.state?.detail?.remainingShares}
                    </p>
                    {/* <p>Max Unit You Can Acquire: 5</p> */}
                  </div>
                </div>

                <div className={styles.buttonWrapper}>
                  <button
                    className={styles.btn1}
                    onClick={() =>
                      togglePopupDelete(location?.state?.detail?._id)
                    }
                  >
                    <span>Delete</span>
                  </button>
                  {location?.state?.detail?.status ===
                  MARKETPLACE_AD_STATUS.ACTIVE ? (
                    <button
                      className={styles.btn2}
                      onClick={() => togglepopup(location?.state?.detail?._id)}
                    >
                      <span> Deactivate</span>
                    </button>
                  ) : location?.state?.detail?.status ==
                    MARKETPLACE_AD_STATUS.DEACTIVATED ? (
                    <button
                      className={styles.btn2}
                      onClick={() => togglepopup(location?.state?.detail?._id)}
                    >
                      <span> Activate</span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnDetail;
