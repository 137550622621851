export const HistoryData = [
  {
    id: 0,
    heading: "Silent Wave",
    description: "You Will Buy FNFT Agaisnt Silent Color",
    date: "09-01-2022 16:04:00",
    amount: "0.00252 ETH",
    estApy: "24.3 %",
  },
  {
    id: 1,
    heading: "Silent Wave",
    description: "You Will Buy FNFT Agaisnt Silent Color",
    date: "09-01-2022 16:04:00",
    amount: "0.00252 ETH",
    estApy: "24.3 %",
  },
  {
    id: 2,
    heading: "Silent Wave",
    description: "You Will Buy FNFT Agaisnt Silent Color",
    date: "09-01-2022 16:04:00",
    amount: "0.00252 ETH",
    estApy: "24.3 %",
  },
  {
    id: 3,
    heading: "Silent Wave",
    description: "You Will Buy FNFT Agaisnt Silent Color",
    date: "09-01-2022 16:04:00",
    amount: "0.00252 ETH",
    estApy: "24.3 %",
  },
];
