import React from "react";
import styles from "./Error.module.scss";
import infoicon from "../../../../../../../Assets/icons/infoicon.svg";
import CQRComponent from "../../../../../../_Common/CQRComponent/CQRComponent";

export const Error = ({ Click }) => {
  return (
    <>
      <div className={styles.Card}>
        <div className={styles.cardWrapper}>
          <div className={styles.imgWrapper}>
            <img src={infoicon} alt="" />
          </div>
          <span>
            You currently do not have a registered GoCardless Direct Debit
            account. Please register your banking details before adding tokens
            to your C<CQRComponent />R Vault.
          </span>
          <div className={styles.btn}>
            <button onClick={Click}>Register</button>
          </div>
        </div>
      </div>
    </>
  );
};
