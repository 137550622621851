import React, { useState, useContext } from "react";
import styles from "./publicchat.module.scss";
import QuestionIcon from "../../../Assets/image/questionicon.svg";
import { useFormik } from "formik";
import * as yup from "yup";

const PublicChat = () => {
  const [viewchat, setviewchat] = useState(false);
  const formikSchema = yup.object({
    name: yup.string().required("Name is Required"),
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is Required"),
    subject: yup.string().required("Sunject is Required"),
    dec: yup.string().required("Description is Required"),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      name: "",
      email: "",
      subject: "",
      dec: "",
    },
    onSubmit: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {viewchat ? (
            <>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.chatView}>
                  <div className={styles.heading}>
                    <span>Let us know about your problem in details.</span>
                  </div>
                  <div className={styles.formbody}>
                    <div className={styles.inputfield}>
                      <span>
                        Your Name<span style={{ color: "red" }}> *</span>
                      </span>
                      <div className={styles.field}>
                        <input
                          className={
                            formik.errors.name ? styles.input1 : styles.input2
                          }
                          type="text"
                          name="name"
                          placeholder={
                            formik.errors.name
                              ? formik.errors.name
                              : "Full Name"
                          }
                          {...formik.getFieldProps("name")}
                        />
                      </div>
                    </div>

                    <div className={styles.inputfield}>
                      <span>
                        Your Email<span style={{ color: "red" }}> *</span>
                      </span>
                      <div className={styles.field}>
                        <input
                          className={
                            formik.errors.email ? styles.input1 : styles.input2
                          }
                          type="email"
                          name="email"
                          placeholder={
                            formik.errors.email ? formik.errors.email : "Email"
                          }
                          {...formik.getFieldProps("email")}
                        />
                      </div>
                    </div>
                    <div className={styles.inputfield}>
                      <span>
                        Subject<span style={{ color: "red" }}> *</span>
                      </span>
                      <div className={styles.field}>
                        <input
                          className={
                            formik.errors.subject
                              ? styles.input1
                              : styles.input2
                          }
                          type="text"
                          name="Subject"
                          placeholder={
                            formik.errors.subject
                              ? formik.errors.subject
                              : "Subject"
                          }
                          {...formik.getFieldProps("subject")}
                        />
                      </div>
                    </div>
                    <div className={styles.inputfield}>
                      <span>
                        Description<span style={{ color: "red" }}> *</span>
                      </span>
                      <div className={styles.field}>
                        <textarea
                          className={
                            formik.errors.dec
                              ? styles.textarea1
                              : styles.textarea2
                          }
                          placeholder={
                            formik.errors.dec
                              ? formik.errors.dec
                              : "Write Anything..."
                          }
                          rows="5"
                          cols="70"
                          {...formik.getFieldProps("dec")}
                        />
                      </div>
                    </div>
                    <div className={styles.sendbtn}>
                      <button type="submit">Send</button>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <></>
          )}

          <div
            className={styles.chatbox}
            onClick={() => setviewchat(!viewchat)}
          >
            <img src={QuestionIcon} alt="Chat-Icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicChat;
