import React from "react";
import styles from "./Error.module.scss";
import infoicon from "../../../Assets/icons/infoicon.svg";

const Error = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.Card}>
          <div className={styles.cardWrapper}>
            <div className={styles.imgWrapper}>
              <img src={infoicon} alt="" />
            </div>
            <span>
              Your Vault has not been activated yet. Please activate your vault
              and try again.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
