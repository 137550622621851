import React, { useEffect, useState } from "react";
import styles from "./Fiat.module.scss";
import InputField from "../../../../../_Common/input/input";
import email from "../../../../../../Assets/icons/email.svg";
import Modal from "../../../../../_Common/Modal/Modal";
import Done from "../../../../../_Common/DashboardDone/popup";
import DoneImg from "../../../../../../Assets/icons/done.svg";
import { formikSchema } from "./FiatFormikSchema";
import { useFormik } from "formik";
import Econtact from "../../../../../../Assets/icons/Econtact.svg";
import dial from "../../../../../../Assets/icons/dial.svg";
import { Error } from "./Error/Error";
import DropDown from "../../../../../_Common/DropDown/dropDown";
import Send from "./send/send";
import { useDispatch, useSelector } from "react-redux";
import { customerRegistration } from "../../../../../../redux/action/customerRegistrationAction";

function Fiat() {
  const [show, setShow] = useState(false);
  const [registered, setRegistered] = useState(false);
  const currencySelector = useSelector((state) => state.currency);
  const customerSelector = useSelector((state) => state.customer);
  const options = [
    { name: "English", value: "en" },
    { name: "French", value: "fr" },
    { name: "Spanish", value: "es" },
    { name: "Deutsch", value: "de" },
    { name: "Portuguese", value: "pt" },
    { name: "Italian", value: "it" },
    { name: "Dutch", value: "nl" },
    { name: "Danish", value: "da" },
    { name: "Bokmål", value: "nb" },
    { name: "Slovenian", value: "sl" },
    { name: "Swedish", value: "sv" },
  ];
  const [selected, setSelected] = useState(options[0]);
  const dispatch = useDispatch();

  const [popupvisible, setpopupvisible] = useState(false);

  const togglepopup = () => {
    setpopupvisible((preview) => !preview);
  };

  // useEffect(() => {
  //   setShow(currencySelector.currencyInfo.user.isCustomer);
  // }, []);

  useEffect(() => {
    if (
      customerSelector.customerDataSuccess ===
      "Customer Registered successfully"
    ) {
      window?.open(customerSelector?.customerData.redirectUrl, "_blank");
    }
  }, [customerSelector?.customerData]);

  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      CompanyName: "",
      Email: "",
      FamilyName: "",
      Language: { name: "English", value: "en" },
      GivenName: "",
      PhoneNumber: "",
      Address: "",
      City: "",
      PostalCode: "",
    },

    onSubmit: (values, reset) => {
      dispatch(customerRegistration(values));
      // togglepopup();
      reset.resetForm();
    },
  });

  return (
    <>
      <Modal visible={popupvisible} onClose={() => setpopupvisible(false)}>
        <Done Name="Thank You Your Top-Up is Confirmed" src={DoneImg} />
      </Modal>
      <div className={styles.container}>
        <div className={styles.card}>
          <Send />
        </div>
        {/* line no 88 below */}
      </div>
    </>
  );
}

export default Fiat;

// {currencySelector?.currencyInfo?.user?.isCustomer ? (

//   ) : (
//     <>
//       {show === false ? (
//         <div className={styles.cardWrapper}>
//           <Error Click={() => setShow(true)} />
//         </div>
//       ) : (
//         ""
//       )}
//       {show === true ? (
//         <div className={styles.wrapper}>
//           <div className={styles.heading}>Member Registration</div>

//           <form onSubmit={formik.handleSubmit}>
//             <div className={styles.inputWrapper}>
//               <div className={styles.secInput}>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="Company Name"
//                     inputName="Name"
//                     type="text"
//                     config={formik.getFieldProps("CompanyName")}
//                   />

//                   {/* {formik.errors.CompanyName &&
//                   formik.touched.CompanyName ? (
//                     <div className={styles.error}>
//                       {formik.errors.CompanyName}
//                     </div>
//                   ) : null} */}
//                 </div>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="Email *"
//                     img2={email}
//                     type="email"
//                     inputName="Email"
//                     config={formik.getFieldProps("Email")}
//                   />

//                   {formik.errors.Email && formik.touched.Email ? (
//                     <div className={styles.error}>
//                       {formik.errors.Email}
//                     </div>
//                   ) : null}
//                 </div>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="Family Name *"
//                     type="text"
//                     img2={Econtact}
//                     inputName="Family Name"
//                     config={formik.getFieldProps("FamilyName")}
//                   />
//                   {formik.errors.FamilyName &&
//                   formik.touched.FamilyName ? (
//                     <div className={styles.error}>
//                       {formik.errors.FamilyName}
//                     </div>
//                   ) : null}
//                 </div>
//               </div>
//               <div className={styles.secInput}>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="Given Name *"
//                     inputName="Given Name"
//                     type="text"
//                     img2={Econtact}
//                     config={formik.getFieldProps("GivenName")}
//                   />

//                   {formik.errors.GivenName && formik.touched.GivenName ? (
//                     <div className={styles.error}>
//                       {formik.errors.GivenName}
//                     </div>
//                   ) : null}
//                 </div>
//                 <div style={{ width: "100%" }}>
//                   <DropDown
//                     name="Language"
//                     padding="14px 20px"
//                     selected={selected}
//                     setSelected={(res) => {
//                       formik.setFieldValue("Language", res);
//                       setSelected(res);
//                     }}
//                     options={options}
//                     // img={BitCoin}
//                     imgShow
//                   />
//                   {/* <InputField
//                     Name="Language"
//                     type="text"
//                     inputName="Language"
//                     config={formik.getFieldProps("Language")}
//                   />

//                   {formik.errors.Language && formik.touched.Language ? (
//                     <div className={styles.error}>
//                       {formik.errors.Language}
//                     </div>
//                   ) : null} */}
//                 </div>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="Phone Number *"
//                     type="text"
//                     img2={dial}
//                     inputName="Phone"
//                     config={formik.getFieldProps("PhoneNumber")}
//                   />
//                   {formik.errors.PhoneNumber &&
//                   formik.touched.PhoneNumber ? (
//                     <div className={styles.error}>
//                       {formik.errors.PhoneNumber}
//                     </div>
//                   ) : null}
//                 </div>
//               </div>
//               <div className={styles.secInput}>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="Address *"
//                     inputName="Address"
//                     type="text"
//                     config={formik.getFieldProps("Address")}
//                   />

//                   {formik.errors.Address && formik.touched.Address ? (
//                     <div className={styles.error}>
//                       {formik.errors.Address}
//                     </div>
//                   ) : null}
//                 </div>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="City *"
//                     type="text"
//                     inputName="City"
//                     config={formik.getFieldProps("City")}
//                   />

//                   {formik.errors.City && formik.touched.City ? (
//                     <div className={styles.error}>
//                       {formik.errors.City}
//                     </div>
//                   ) : null}
//                 </div>
//                 <div style={{ width: "100%" }}>
//                   <InputField
//                     name="Postal Code *"
//                     type="text"
//                     inputName="Postal code"
//                     config={formik.getFieldProps("PostalCode")}
//                   />
//                   {formik.errors.PostalCode &&
//                   formik.touched.PostalCode ? (
//                     <div className={styles.error}>
//                       {formik.errors.PostalCode}
//                     </div>
//                   ) : null}
//                 </div>
//               </div>
//             </div>
//             <button className={styles.btnBtn} type="submit">
//               Send
//             </button>
//           </form>
//         </div>
//       ) : (
//         ""
//       )}
//     </>
//   )}
