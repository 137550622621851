import { useEffect, useRef, useState, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

let UseProfile = () => {
  //Active State By Default
  const [Active] = useState(0);

  //Prograss Bar States Starts here //
  const [GPA, setGPA] = useState(false);
  const [BeneficiaryBar, setBeneficiaryBar] = useState(false);
  const [Importantnote, setImportantnote] = useState(false);
  //Prograss Bar States Ends here //
  const chatRef = useRef(null);
  //Render Components State Starts here //
  const [instructions, setinstructions] = useState(true);
  const [gpa, setgpa] = useState(false);
  const [referring, setreferring] = useState(false);
  const [important, setimportant] = useState(false);
  //Render Components State Ends here //
  const [popupvisible, setpopupvisible] = useState(false);
  const [accountPopup, setAccountPopup] = useState(false);
  const userSelector = useSelector((state) => state.user);
  const history = useHistory();
  const upperRef = useRef(null);
  const dispatch = useDispatch();

  const introRef = useRef();
  const gpaRef = useRef();
  const benificiaryRef = useRef();
  const signatureRef = useRef();

  // const togglepopup = (e) => {
  //   setpopupvisible((preview) => !preview);
  // };

  const pageChange = () => {
    setTimeout(() => {
      upperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    });
    if (Active === 0) {
      setGPA(true);
      setgpa(true);
      setinstructions(false);
    }
    if (GPA) {
      setBeneficiaryBar(true);
      setreferring(true);
      setgpa(false);
      setinstructions(false);
    }

    if (BeneficiaryBar) {
      setImportantnote(true);
      setimportant(true);
      setreferring(false);
      setgpa(false);
      setinstructions(false);
    }

    if (GPA && BeneficiaryBar && Importantnote) {
      setGPA(true);
      setBeneficiaryBar(true);
      setImportantnote(true);
      setgpa(false);
      setreferring(false);
      setimportant(false);

      setinstructions(false);
    }
  };

  const handeClick = () => {
    introRef?.current?.onSubmit();

    gpaRef?.current?.onSubmit();

    benificiaryRef?.current?.onSubmit();

    signatureRef?.current?.onSubmit();
  };

  return {
    pageChange,
    GPA,
    BeneficiaryBar,
    Importantnote,
    Active,
    gpa,
    referring,
    important,
    instructions,
    popupvisible,
    setpopupvisible,
    accountPopup,
    setAccountPopup,
    introRef,
    gpaRef,
    benificiaryRef,
    signatureRef,
    handeClick,
    upperRef,
  };
};

export default UseProfile;
