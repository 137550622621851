import React, { useState } from "react";
import Styles from "./sliderData.module.scss";
import CQRIMG from "../../../../Assets/image/ThumbnailEdit.jpg";

function SliderData({ SData }) {
  const [imgShow, setImgShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { video, heading, subHeading, subHeading2 } = SData;
  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.wrapper}>
          {!imgShow ? (
            <img
              src={CQRIMG}
              alt=""
              style={{ width: "55%", cursor: "pointer" }}
              onClick={() => setImgShow(true)}
            />
          ) : (
            <>
              {loading && (
                <img
                  src={CQRIMG}
                  alt=""
                  style={{ width: "55%", cursor: "pointer" }}
                />
              )}
              <video
                style={{ display: `${loading ? "none" : "block"}` }}
                autoPlay={true}
                muted={false}
                controls={true}
                width={10000}
                onLoadStart={() => setLoading(true)}
                onLoad={() => setLoading(false)}
                onLoadedData={() => setLoading(false)}
              >
                <source src={video} type="video/mp4" />
              </video>
            </>
          )}
          <div className={Styles.textWrapper}>
            <div className={Styles.heading}>{heading}</div>
            <div className={Styles.subHeading}>
              {subHeading}
              <br />
              {subHeading2}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SliderData;
