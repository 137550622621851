import React, { useState } from "react";
import ChatContact from "./ChatContact";
import ChatMessage from "./ChatMessage";
import styles from "./inbox.module.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import useChatRoomSockets from "./hooks/useChatRoomSockets";
const Inbox = ({ history }) => {
  const [userData, setUserData] = useState(
    history ? history?.location?.state?.detail : ""
  );
  const [messages, setMessages] = useChatRoomSockets(userData?.chatRoomId);

  const userMessage = (data) => {
    setUserData(data ? data : history?.location?.state?.detail);
  };

  const alert = useAlert();
  const userSelector = useSelector((state) => state.currency);
  const userKyc = useSelector((state) => state?.user);

  useEffect(() => {
    if (
      !userKyc?.savedFormData?._id &&
      !userKyc?.kyc?._id &&
      !userSelector?.currencyInfo?.gpaData?._id
    ) {
      history.push("/profile");
      alert.show("Please fill your GPA data first", {
        type: "error",
      });
    }
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.chatWidth}>
        <ChatContact userMessages={userMessage} setMessages={setMessages} />
      </div>
      <div className={styles.messageWrapper}>
        {userData ? (
          <ChatMessage
            userData={userData}
            messages={messages}
            setMessages={setMessages}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Inbox;
