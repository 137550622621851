import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import BackTo from "../../../../../../Assets/icons/backk.svg";
import Rcv from "../../../../../../Assets/image/rcv.svg";
import Send from "../../../../../../Assets/image/send.svg";
import Swap from "../../../../../../Assets/image/swap.svg";
import {
  getCurrencyGraph,
  getTransactionDetail,
} from "../../../../../../redux/action/currencyDetailAction";
import CQRComponent from "../../../../../_Common/CQRComponent/CQRComponent";
import Transactions from "../../../../../_Common/DashboardComponents/Transactions/Transactions";
import Modal from "../../../../../_Common/Modal/Modal";
import CryptoTotal from "../../CryptoCurrency/CryptoTableGraph/CryptoTotal";
import styles from "./liquidityGraph.module.scss";

function LiquidityTableGraph({ history }) {
  const [coinDetail, setCoinDetail] = useState();
  const [popupvisiblee, setpopupvisiblee] = useState(false);
  const [number, setNumber] = useState(1);

  const location = useLocation();

  const orignalSymbol = location?.state?.detail;
  const sysmbol = location?.state?.detail?.graphKey;

  const dispatch = useDispatch();
  const currencySelector = useSelector((state) => state.currency);
  // const graphData = useSelector((state) => state.currency);
  const togglepopup = (e, num) => {
    setpopupvisiblee(true);
  };
  useEffect(() => {
    // result: 'some_value'
    // setCoinDetail(location.state.detail);
    setCoinDetail(currencySelector.graphData);
    if (coinDetail) {
      dispatch(
        getTransactionDetail(coinDetail?.address, coinDetail?.coinSymbol)
      );
      dispatch(getCurrencyGraph(coinDetail?.coinSymbol));
    }
  }, [location, coinDetail]);

  const swapAssets = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/assets-swap",
        state: { detail: location },
      });
    } else {
      history.push("/error");
    }
  };

  const send = () => {
    history.push({
      pathname: "/cqr-asset-tokens",
      state: { detail: location?.state?.detail },
    });
  };
  const detailDataa = () => {
    history.push({
      pathname: "/liquidity-tokens",
      state: { detail: location?.state?.detail },
    });
  };

  const exchangeWallet = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/silent-wave",
        state: { detail: location },
      });
    } else {
      history.push("/error");
    }
  };
  ///=---------data format---------//
  const withdrawAssest = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/withdrawal",
        state: { detail: location?.state?.detail },
      });
    } else {
      history.push("/error");
    }
  };
  const withdrawAssestDirectWire = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/withdrawal-directwire",
        state: { detail: location?.state?.detail },
      });
    } else {
      history.push("/error");
    }
  };
  return (
    <>
      <Modal
        visible={popupvisiblee}
        // btn
        onClose={() => setpopupvisiblee(false)}
      >
        <div className={styles.modalWrapper}>
          <label>Please Select Redeem Method</label>
          <div className={styles.buttonWrapper}>
            <button onClick={() => withdrawAssest()}>CoinQvest</button>
            <button onClick={() => withdrawAssestDirectWire()}>
              Direct wire
            </button>
          </div>
        </div>
      </Modal>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <img
              src={BackTo}
              alt="back"
              onClick={() => {
                history.push({
                  pathname: "/cryptocurrency",
                  state: { activeState: 2 },
                });
              }}
            />
            {/* <label>{location.state.detail.name}</label> */}
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.leftWrapper}>
              <CryptoTotal location={location} />
              <div className={styles.buttonWrapper}>
                <button onClick={() => detailDataa()}>
                  {" "}
                  <p> Top-Up</p>
                  <img src={Rcv} alt="recieve" />
                </button>
                <button onClick={() => send()}>
                  <p>Send</p>
                  <img src={Send} alt="send" />
                </button>
                <button onClick={() => togglepopup()}>
                  <p>Redeem</p>
                  <img src={Send} alt="send" />
                </button>

                <button onClick={() => swapAssets()}>
                  <p>Swap</p>
                  <img src={Swap} alt="swap" />
                </button>
                {location?.state?.detail?.displayName ===
                "CQR Dollar (1 USD) Liquidity Token" ? (
                  ""
                ) : (
                  <button onClick={() => exchangeWallet()}>
                    <p>Exchange</p>
                    <img src={Send} alt="send" />
                  </button>
                )}
              </div>
              {/* </Link> */}

              <div className={styles.headingWrapper}>
                <span>Transaction History</span>
                {/* <p>20 Oct, 2021</p> */}
              </div>
              <Transactions location={location} orignalSymbol={orignalSymbol} />
            </div>
            <div className={styles.rightWrapper}>
              <div className={styles.graph}>
                <TradingViewWidget
                  symbol={sysmbol}
                  theme={Themes.DARK}
                  locale="en"
                  width="100%"
                  height="400"
                />
              </div>
              <span>
                Each <CQRComponent />
                1.00 of C<CQRComponent />R Liquidity Token bears the value of
                1.00 beneficial interest unit of its corresponding currency
                domiciled in the C
                <CQRComponent />R Vault. C<CQRComponent />R Liquidity Tokens are
                for self-directed value exchanges or redeem made by and between
                Bona Fide Participant Members of C<CQRComponent />R Vault.
                {/* Each <CQRComponent />
                1.00 C<CQRComponent />R bears the beneficial interest value of
                $1.00 USD domiciled in the C<CQRComponent />R Vault for the
                self-directed value exchange(s) made by and between Bona Fide
                Participant Members of C<CQRComponent />
                R Vault. */}
                <br />
                {/* <br />
                Simply enter the amount of C<CQRComponent />R Dollars you want
                to hold in your C<CQRComponent />R Vault, and put C
                <CQRComponent />R Dollars (a.k.a. C<CQRComponent />R or “
                <CQRComponent />
                ”) in your digital wallet today! */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiquidityTableGraph;
