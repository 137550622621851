import React from "react";
import noDataGip from "../../../Assets/gif/noData.gif";
import styles from "./nodata.module.scss";

const NoData = () => {
  return (
    <div className={styles.noDataWrapper}>
      <img src={noDataGip} alt="no data" />
    </div>
  );
};

export default NoData;
