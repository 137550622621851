import React, { useState } from "react";
import Slider from "./menu";
import styles from "./navigation.module.scss";
import { Link } from "react-router-dom";
import logo from "../../../../../Assets/image/logo.svg";
import logoMenu from "../../../../../Assets/image/navicon.svg";
import NotificationIcon from "../../../../../Assets/image/notification.svg";
import NotificationImag from "../../../../../Assets/image/notification-image.svg";
import NotificationCompo from "../../../../_Common/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { imgUpload, userCleanUp } from "../../../../../redux/action/userAction";
// import Teamprofile from "../../../../../../Assets/image/teamprofile.png";

const Navigation = ({ id, history }) => {
  const [notification, setnotification] = useState(false);
  const [menuOpenState, setMenuOpenState] = useState(false);
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);
  const userSelector = useSelector((state) => state.user);

  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftWrapper}>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="" />
          </Link>
          <p>{userSelector?.userInfo?.name}</p>
        </div>

        <div className={styles.middleWrapper}>
          {/* <p>{userSelector?.userInfo?.name}</p> */}

          <NotificationCompo />
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <img src={logoMenu} alt="" />
          </div>
        </div>
      </div>
      <Slider
        handleMenu={handleMenu}
        menuOpenState={menuOpenState}
        setMenuOpenState={setMenuOpenState}
        stateChangeHandler={stateChangeHandler}
        id={id}
        history={history}
      />
    </>
  );
};

export default Navigation;
