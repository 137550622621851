import moment from "moment";
import React from "react";
import styles from "./RecentHistory.module.scss";
import noDataGip from "../../../Assets/gif/noData.gif";
import { useSelector } from "react-redux";
const SendDollarHistory = ({ HandlerecentHistory }) => {
  const { sendDollarHistory } = useSelector((state) => state.currency);
  return (
    <>
      <div className={styles.cardWrapper}>
        <div className={styles.heading}>
          <div className={styles.headingWrapper}>
            Recent Transaction History
          </div>
          <div className={styles.history}></div>
        </div>
        <div className={styles.details}>
          <table cellspacing="0" cellpadding="0">
            <tr>
              <td></td>
              <td></td>
            </tr>
            {!Boolean(sendDollarHistory?.length) ? (
              <div className={styles.empty}>
                <img src={noDataGip} className={styles.noData} />
              </div>
            ) : (
              sendDollarHistory?.map((item, index) => (
                <tr key={index} onClick={() => HandlerecentHistory(item)}>
                  <td>
                    <div className={styles.nameWrapper}>
                      <div>
                        <div className={styles.heading}>{item.to}</div>
                        <div className={styles.description}>
                          {moment(item.date).format("lll")}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.nameWrapper}>
                      {item.amount} {item.tokenName}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SendDollarHistory;
