import React, { useEffect } from "react";
import Aos from "aos";
import styles from "./video.module.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAlert } from "react-alert";
import { WaveClone } from "../../../_Common/loader/Loader";
import { useState } from "react";
import { HTTP_CLIENT } from "../../../../utils/services";

const VideoSection = () => {
  const alert = useAlert();
  const [buttonLoading, setbuttonLoading] = useState(false);

  useEffect(() => {
    Aos.init();
  });
  const formikSchema = yup.object({
    firstName: yup
      .string()
      .required("Name is Required")
      .max(50, "First name Too long!")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .min(2, "First name Too Short!"),
    lastName: yup
      .string()
      .max(50, "First name Too long!")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .min(2, "First name Too Short!"),
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is Required"),
    phone: yup.string().required("Phone is Required"),
    // .min(11, "Phone is Too Short!"),
    message: yup.string().required("Message is Required").lowercase(),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });
  const handleSubmit = async (values) => {
    setbuttonLoading(true);
    try {
      const res = await HTTP_CLIENT.post("/user/contact-us", values);
      alert.show(res.data.message, { type: "success" });
      setbuttonLoading(false);
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
      setbuttonLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p
        // data-aos-easing="ease-in-sine"
        // data-aos-duration="2000"
        // data-aos="fade-right"
        >
          FAQ's
        </p>
        <label
        // data-aos-easing="ease-out-sine"
        // data-aos-duration="2000"
        // data-aos="fade-left"
        >
          An awesome new cash flow community for innovative people is coming
          very soon. We're nearly there, enter your email and any inquiry you
          may have, we'll inform you when this section is complete!
        </label>
        <div className={styles.playerwrapper}>
          <video
            controls
            poster="/thumb1.jpg"
            data-aos-easing="ease-in-sine"
            data-aos-duration="2000"
            data-aos="fade-left"
          >
            <source
              src="https://d1brp5cmiud168.cloudfront.net/videos/Wallet+Opening+Process+v2+20222212.mp4"
              type="video/mp4"
            />
          </video>
          <video
            controls
            poster="/thumb2.jpg"
            data-aos-easing="ease-in-sine"
            data-aos-duration="2000"
            data-aos="fade-right"
          >
            <source
              src="https://d1brp5cmiud168.cloudfront.net/videos/Account+Funding+(Direct+Wire)+v2+20222212.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div
          className={styles.textField}
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="2000"
          // data-aos="fade-right"
        >
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              placeholder="First Name *"
              {...formik.getFieldProps("firstName")}
            />
            {formik.errors.firstName && formik.touched.firstName ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.firstName}</div>
            ) : null}
            <input
              type="text"
              placeholder="Last Name"
              {...formik.getFieldProps("lastName")}
            />
            {formik.errors.lastName && formik.touched.lastName ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.lastName}</div>
            ) : null}
            <input
              type="email"
              placeholder="Email *"
              {...formik.getFieldProps("email")}
            />
            {formik.errors.email && formik.touched.email ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.email}</div>
            ) : null}
            <input
              type="text"
              placeholder="Phone number"
              {...formik.getFieldProps("phone")}
            />
            {formik.errors.phone && formik.touched.phone ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.phone}</div>
            ) : null}
            <input
              type="text"
              placeholder="Message *"
              {...formik.getFieldProps("message")}
            />
            {formik.errors.message && formik.touched.message ? (
              <div style={{ color: "#E5516B" }}>{formik.errors.message}</div>
            ) : null}
            {buttonLoading ? (
              <WaveClone />
            ) : (
              <button type="submit">SEND</button>
            )}{" "}
          </form>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
