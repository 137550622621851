import React, { useEffect, useState } from "react";
import styles from "./AssetsTokens.module.scss";
import Input from "../../../../../_Common/input/input";
import DropDown from "../../../../../_Common/DropDown/dropDown";
import Modal from "../../../../../_Common/Modal/Modal";
import Logo from "../../../../../../Assets/image/logo.svg";
import stylesmodal from "../../../../../_Common/checkChangePasswordModal/openaccount.module.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { HTTP_CLIENT } from "../../../../../../utils/services";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { WaveClone } from "../../../../../_Common/loader/Loader";
import CQRComponent from "../../../../../_Common/CQRComponent/CQRComponent";
import white from "../../../../../../Assets/icons/white.png";
import { MdHistory } from "react-icons/md";
import moment from "moment";
import { saveDollarHistory } from "../../../../../../redux/action/currencyDetailAction";
import SendDollarHistory from "../../../../../_Common/RecentTransactionHistory/sendDollar";
const AssetsTokens = ({ history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [getValue, setGetValue] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [Active, setActive] = useState(0);
  const [Data, setData] = useState();
  const [filterQSymbol, setfilterQSymbol] = useState(false);

  const currencySelector = useSelector((state) => state.currency);
  const options = currencySelector?.currencyInfo?.nativeWallets;
  // const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    const filterQ = currencySelector?.currencyInfo?.nativeWallets?.find(
      (m) => m.coinSymbol === "Q"
    );

    setfilterQSymbol(filterQ);
  }, []);
  const formikSchema = yup.object({
    to: yup
      .string()
      .email("Email is not valid")
      .required("Email is required")
      .trim(),
    amount: yup
      .number()
      .required("Amount is required")
      .min(0.00000000000001, "Invalid amount")
      .max(100000000, "max 8 number required")
      .test(
        "maxDigitsAfterDecimal",
        "number field must have 8 digits after decimal or less",
        (number) => /^\d+(\.\d{0,8})?$/.test(number)
      ),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      to: "",
      amount: "",
    },
    onSubmit: async (values, res) => {
      let data = {
        to: String(values?.to)?.trim(),
        amount: String(values.amount),
        coin: filterQSymbol?.coin,
        memo: values?.memo,
      };

      if (data) {
        setData(data);
        setOpenModal(true);
      }
    },
  });
  const handlePostData = async () => {
    try {
      setLoading(true);
      let response = await HTTP_CLIENT.post(`native-wallet/send-tokens`, Data);
      alert.show(response.data.message, { type: "success" });
      if (response) {
        const newData = {
          ...Data,
          tokenName: "CQR Dollar Q",
          date: Date.now(),
        };
        dispatch(saveDollarHistory(newData));
      }
      formik.resetForm();
      setLoading(false);
      setData("");
      history.goBack();
    } catch (err) {
      alert.show(err.response.data.message, { type: "error" });
      setLoading(false);
    }
  };
  const HandlerecentHistory = (item) => {
    formik.setFieldValue("to", item?.to);
    formik.setFieldValue("amount", item?.amount);
    formik.setFieldValue("memo", item?.memo);
    setActive(0);
  };

  useEffect(() => {
    setGetValue(Number(formik.values?.amount) * Number(1));
  }, [formik.values?.amount]);
  return (
    <>
      <Modal visible={openModal} onClose={() => setOpenModal(false)} btn>
        <div className={stylesmodal.container}>
          <div className={stylesmodal.textsection}>
            <label>Are you sure you want to send the tokens?</label>
          </div>
          <div className={stylesmodal.btnsection}>
            {loading ? (
              <button className={stylesmodal.Delete} disabled>
                <WaveClone />
              </button>
            ) : (
              <button
                className={stylesmodal.Delete}
                type="submit"
                onClick={handlePostData}
              >
                Confirm
              </button>
            )}
            <button
              disabled={loading ? true : false}
              className={stylesmodal.Delete}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {Active == 0 ? (
            <div className={styles.cardWrapper}>
              <div className={styles.heading}>
                <div className={styles.headingWrapper}>
                  Send C
                  <CQRComponent spanStyle={{ width: "32px" }} />R Dollars
                </div>
                <div className={styles.history}>
                  <MdHistory
                    className={styles.icon}
                    onClick={() => setActive(1)}
                  />
                </div>
              </div>
              <div className={styles.formWrapper}>
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <Input
                      name="Email *"
                      inputName="Email"
                      type="text"
                      config={formik.getFieldProps("to")}
                    />
                    {formik.errors.to && formik.touched.to ? (
                      <div style={{ color: "#E5516B" }}>{formik.errors.to}</div>
                    ) : null}
                  </div>
                  <div className={styles.inputWrapper}>
                    <p>Token</p>
                    <div className={styles.divWrapper}>
                      C<CQRComponent />R Dollar ("
                      <CQRComponent />
                      ")
                    </div>
                    <div className={styles.avbalance}>
                      Available Balance: &nbsp;
                      {Number(filterQSymbol?.balance).toFixed(
                        filterQSymbol.decimals
                      )}
                    </div>
                  </div>
                  <div>
                    <Input
                      // imgQ={true}
                      name="Amount *"
                      inputName="Amount"
                      type="number"
                      config={formik.getFieldProps("amount")}
                      img3
                    />
                    {formik.errors.amount && formik.touched.amount ? (
                      <div style={{ color: "#E5516B" }}>
                        {formik.errors.amount}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.inputWrapper}>
                    <p>Value in USD</p>
                    <input
                      name="Token"
                      type="number"
                      placeholder="0"
                      value={String(getValue)}
                      disabled
                    />
                  </div>
                  <div>
                    <Input
                      name="Memo / Endorsement"
                      inputName="Memo / Endorsement"
                      type="text"
                      config={formik.getFieldProps("memo")}
                    />
                    {/* {formik.errors.memo && formik.touched.memo ? (
                    <div style={{ color: "#E5516B" }}>{formik.errors.memo}</div>
                  ) : null} */}
                  </div>
                  <button type="submit" className={styles.btn}>
                    Send Now
                  </button>
                </form>
              </div>
            </div>
          ) : Active == 1 ? (
            <SendDollarHistory HandlerecentHistory={HandlerecentHistory} />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default AssetsTokens;
