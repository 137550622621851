import React, { useEffect, useRef, useState } from "react";
import styles from "./filter.module.scss";
// import NotificationImag from "../../../Assets/image/notification-image.svg";

const Filter = (props) => {
  const ref = useRef(null);
  const [notification, setnotification] = useState(false);

  const [selected, setSelected] = useState("Quantity");

  const [colneNotification, setCloneNotification] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState();
  const [notificationData, setNotificationData] = useState([]);

  const { onClickOutside } = props;
  const options = [{ name: "Quantity" }, { name: "Level" }];
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setnotification(notification);
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);
  //----------reset Filters------------------//
  const resetFilter = () => {
    props.setTo("");
    props.setFrom("");
    props.setActive(props.active + 1);
    setnotification(false);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper} ref={ref}>
          <div
            className={styles.notification}
            onClick={() => setnotification(!notification)}
          >
            <button>Filter</button>
            {notificationNumber ? (
              <div className={styles.notificationbadge}>
                <span>6</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {notification ? (
            <div className={styles.bellmessage}>
              <div className={styles.heading}>
                <span>Filter</span>
              </div>

              <div className={styles.card}>
                {/* <div className={styles.left}>
                        <img src={NotificationImag} alt="Sort" />
                      </div> */}
                <p>Value</p>
                <div className={styles.inputWrapper}>
                  <input
                    type="text"
                    placeholder="Min"
                    value={props.from}
                    onChange={(e) => props.setFrom(e.target.value)}
                  />
                  <p>to</p>
                  <input
                    type="text"
                    placeholder="Max"
                    value={props.to}
                    onChange={(e) => props.setTo(e.target.value)}
                  />
                </div>
                <div className={styles.buttonWrapper}>
                  <button
                    className={styles.resetButton}
                    onClick={() => resetFilter()}
                  >
                    Reset Filter
                  </button>
                  <button
                    onClick={() => {
                      props.setActive(props.active + 1);
                      setnotification(false);
                    }}
                  >
                    Apply
                  </button>
                </div>
                {/* <div className={styles.dropdownWrapper}>
                  <DropDown
                    padding="16px 5px"
                    selected={selected}
                    setSelected={setSelected}
                    options={options}
                    imgCqr={true}
                    imgShow={true}
                  />
                </div>
                <div className={styles.buttonWrapper}>
                  <button className={styles.clear}>Clear all</button>
                  <button className={styles.done}>Done</button>
                </div> */}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Filter;
