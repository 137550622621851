import React from "react";
import styles from "./alert.module.scss";
import infoicon from "../../../../Assets/icons/infoicon.svg";
import { Wave } from "../../../_Common/loader/Loader";
// import { useSelector } from "react-redux";
import CQRComponent from "../../../_Common/CQRComponent/CQRComponent";
// import white from "../../../../Assets/icons/white.png";

// import { FillingBottle, BouncingBalls, BarWave } from "react-cssfx-loading";

const alert = ({ handleClick, Click, AlertShow, loading, isSidra }) => {
  return (
    <>
      {loading ? (
        <Wave color="white" duration="1s" />
      ) : isSidra ? (
        ""
      ) : !AlertShow ? (
        <div className={styles.Card}>
          <div className={styles.leftSide}>
            <img src={infoicon} alt="" />
          </div>
          <div className={styles.rightSide}>
            <span>
              <label>
                {" "}
                Your C<CQRComponent />R Vault Account has not been opened yet.
              </label>{" "}
              <br />
              To open your C<CQRComponent />R Vault, you must add & maintain a{" "}
              <CQRComponent /> Dollar "
              <CQRComponent />" contribution ≥ the value of 21 Ag Asset Tokens
              (21oz 0.999 Fine Silver).
            </span>
            <div className={styles.btn}>
              <button onClick={handleClick}>Activate Now</button>
              {/* <button onClick={Click}>
                Open w/
                <CQRComponent />
              </button> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default alert;
