import { HTTP_CLIENT } from "../utils/services";

const ownMarketplaceAdds = async (currPage) => {
  return await HTTP_CLIENT.get(`marketplace/ad?page=${currPage}&limit=130`);
};
const MarketplaceAdds = async (
  page,
  limit,
  filterPrice,
  to,
  from,
  debounce
) => {
  return await HTTP_CLIENT.get(
    `marketplace/listing?page=${page}&limit=${limit}&filter=${
      filterPrice ? filterPrice : ""
    }&price_to=${to ? to : ""}&price_from=${
      from ? from : ""
    }&keyword=${debounce}`
  );
};
export { ownMarketplaceAdds, MarketplaceAdds };
