import { useState, useEffect } from "react";
import { BASE_URL } from "../../../utils/AppConstants";
import axios from "axios";
import { useAlert } from "react-alert";

let useSignup = (onClose, signuphandle) => {
  let [agree, setAgree] = useState({ isAgree: false, notAgreed: false });
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [humanValidation, setHumanValidation] = useState("");

  let [inputs, setInputs] = useState({
    name: "",
    email: "",
    password: "",
    resetpassword: "",
    passwordValidation: "",
    emailValidation: "",
    confirmValidation: "",
  });

  let [state, setState] = useState({ submitting: false });
  const alert = useAlert();

  let handleInputChange = (e) => {
    e.preventDefault();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [name === "password" ? "passwordValidation" : "confirmValidation"]: "",
      [name === "password" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [name === "password" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    if (inputs.name.trim()?.length > 2) {
      setInputs((prev) => ({
        ...prev,
        nameValidation: "",
      }));
    }
    if (inputs.email.match(mailformat)) {
      setInputs((prev) => ({ ...prev, emailValidation: "" }));
    }

    if (inputs.password.length > 8 && inputs.password.length < 16) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "",
      }));
    }
    if (/^\S*$/.test(inputs.password)) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "",
      }));
    }
  };

  let handleCheckbox = () => {
    setAgree(!agree);
  };

  //----------Submit signup data---------
  let handleSubmit = async (e) => {
    setButtonLoading(true);
    if (!isValidated()) {
      setButtonLoading(false);

      return;
    }
    let data = {
      name: inputs.name,
      email: inputs.email,
      password: inputs.password,
      fcmToken: "",
    };
    try {
      let res = await axios.post(`${BASE_URL}auth/register`, data);
      setButtonLoading(false);
      setAgree({});
      onClose();
      signuphandle();

      alert.show(
        "Account created successfully & verification email has been sent to your email address",
        {
          type: "success",
        }
      );
    } catch (err) {
      if (err?.response?.data?.message) {
        alert.show(err.response.data.message, { type: "error" });
      }
      setButtonLoading(false);
    }
  };

  // ---------Check form validation------
  let isValidated = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    let response = true;

    if (inputs.name.trim()?.length < 2) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        nameValidation: "Name must be greater than 2 characters",
      }));
    }

    if (!inputs.email.match(mailformat)) {
      response = false;
      setInputs((prev) => ({ ...prev, emailValidation: "Email is invalid" }));
    } else {
      setInputs((prev) => ({ ...prev, emailValidation: "" }));
    }
    if (!inputs.email.trim()) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        emailValidation: "Email cannot be empty",
      }));
    }

    if (inputs?.password?.trim().length > 16 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be greater than 16.",
      }));
    }

    if (!/^\S*$/.test(inputs.password)) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Empty space is not allowed.",
      }));
    }
    if (inputs?.resetpassword?.trim().length > 16 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be greater than 16.",
      }));
    }

    if (!/^\S*$/.test(inputs.resetpassword)) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Empty space is not allowed.",
      }));
      // errors.password = "Empty space is not allowed.";
    }
    if (inputs?.password?.trim().length < 8 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be less than 8 characters.",
      }));
    }
    if (inputs?.resetpassword?.trim().length < 8 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be less than 8 characters.",
      }));
    }
    if (!inputs.password.trim()) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password Field can't be empty.",
      }));
    }
    if (!inputs.resetpassword.trim()) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be empty.",
      }));
    }
    if (
      inputs.password !== "" &&
      inputs.resetpassword !== "" &&
      inputs.password !== inputs.resetpassword
    ) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Passwords do not match.",
      }));
    }
    if (!humanValidation) {
      response = false;
      // setHumanValidation(true);
      setShow(true);
    }
    if (!agree.isAgree) {
      response = false;
      setAgree((prev) => ({ ...prev, notAgreed: true }));
    }
    return response;
  };
  const googlerecaptcha = (e) => {
    if (e) {
      setHumanValidation(true);
      setShow(false);
    } else {
      setShow(true);
    }
  };

  return {
    ...inputs,
    ...agree,
    setAgree,

    handleInputChange,
    handleCheckbox,
    handleSubmit,
    ButtonLoading,
    googlerecaptcha,
    show,
    humanValidation,
    ...state,
  };
};

export default useSignup;
