import React from "react";
// import white from "../../../Assets/icons/white.png";
import styles from "./CQRComponent.module.scss";
import "../../../Assets/fonts/TokenSymbols.ttf";

const CQRComponent = ({ style, spanStyle }) => {
  return (
    <span className={styles.spanContainter} style={spanStyle}>
      2
    </span>
  );
};

export default CQRComponent;


export const CQRAComponent =({spanStyle})=>{
  return (
    <span className={styles.spanContainter} style={spanStyle}>
      3
    </span>
  );
}

export const CQRBComponent =({spanStyle})=>{
  return (
    <span className={styles.spanContainter} style={spanStyle}>
      1
    </span>
  );
}