import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducer/index";
import { persistStore, persistReducer } from "redux-persist";
import axiosMiddleware from "redux-axios-middleware";
import storage from "redux-persist/lib/storage";
import { BASE_URL } from "../utils/AppConstants";
import axios from "axios";

const client = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
});

client.interceptors.request.use(async (config) => {
  let authToken = localStorage.getItem("authToken");

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user", "currency", "sawap", "subscription", "remittance"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(
  persistedReducer,
  compose(applyMiddleware(axiosMiddleware(client)))
);
const persistor = persistStore(store);
export { store, persistor };
