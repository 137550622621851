import React, { useEffect, useState } from "react";
import styles from "./AssetsTable.module.scss";
import moment from "moment";
import { HTTP_CLIENT } from "../../../../../../../../utils/services";
import { Loader } from "../../../../../../../_Common/loader/Loader";
import emptyImg from "../../../../../../../../Assets/gif/empty.svg";
import { useAlert } from "react-alert";

const HistoryTable = () => {
  const alert = useAlert();
  const [assetsData, setAssetsData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    AssetsHistory();
  }, []);
  const AssetsHistory = async () => {
    try {
      setLoading(true);
      let response = await HTTP_CLIENT.get(
        "/transaction-log/native-token-send"
      );
      setAssetsData(response.data.reverse());
      setLoading(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setLoading(false);
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {loading ? (
            <div className={styles.loaderPosition}>
              <Loader />
            </div>
          ) : (
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>Email</td>
                <td>Type</td>
                <td>Amount</td>
                <td>Created At</td>
              </tr>
              {assetsData?.length == 0 ? (
                <div className={styles.emptyImg}>
                  <img src={emptyImg} alt="" />
                </div>
              ) : (
                assetsData?.map((item) => {
                  return (
                    <tr key={item?._id}>
                      <td>{item?.to}</td>
                      <td>{item?.type}</td>
                      <td>{item?.amount}</td>
                      <td>{moment(item?.updatedAt).format("MMMM Do YYYY")}</td>
                    </tr>
                  );
                })
              )}
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default HistoryTable;
