import {
  GET_CURRENCY_DETAIL,
  GET_CURRENCY_DETAIL_FAIL,
  GET_CURRENCY_DETAIL_SUCCESS,
  CURRENCY_CLEANUP,
  GET_TRANSACTION_HISTORY,
  GET_TRANSACTION_HISTORY_SUCCESS,
  GET_TRANSACTION_HISTORY_FAIL,
  GET_CURRENCY_GRAPH,
  GET_CURRENCY_GRAPH_SUCCESS,
  GET_CURRENCY_GRAPH_FAIL,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  CHECK_WALLETS,
  Wallets_ACTIVATION,
  Wallets_ACTIVATION_SUCCESS,
  Wallets_ACTIVATION_FAIL,
  ASSETS_FOR_WALLETS_ACTIVATION,
  ASSETS_FOR_WALLETS_ACTIVATION_SUCCESS,
  ASSETS_FOR_WALLETS_ACTIVATION_FAIL,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE_SUCCESS,
  UPDATE_WALLET_BALANCE_FAIL,
  CURRENCY_LOGOUT_CLEANUP,
  GRAPH_DATA,
  GET_NETWORK,
  SEND_DOLLAR_HISTORY,
  SEND_TOKEN_HISTORY,
} from "../types";

const initialState = {
  loading: false,
  error: null,
  currencyInfo: null,
  currencyDetail: null,
  currencyDetailLoader: false,
  currencyGraph: null,
  settings: null,
  checkWallets: null,
  assetsForWalletsActivation: null,
  walletsActivate: null,
  walletactivationError: null,
  walletactivationSuccess: false,
  walletActivateLoader: false,
  walletUpdateBalance: null,
  walletUpdateBalanceError: null,
  graphData: null,
  getnetworkData: [],
  sendDollarHistory: [],
  sendTokenHistory: []
};

const currencyDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_WALLETS:
      return { ...state, checkWallets: action.payload };
    case GET_CURRENCY_DETAIL:
      return {
        ...state,
        error: null,
        loading: true,
        currencyDetailLoader: true,
      };
    case GET_CURRENCY_DETAIL_SUCCESS:
      return {
        ...state,
        currencyInfo: action.payload.data,
        currencyDetailLoader: false,
        loading: false,
      };
    case GET_CURRENCY_DETAIL_FAIL:
      return {
        ...state,
        currencyInfo: null,
        error: action.error?.response?.data?.message,
        loading: false,
        currencyDetailLoader: false,
      };
    case CURRENCY_CLEANUP:
      return {
        ...state,
        error: null,
        walletactivationError: null,
        sendDollarHistory: [],
        sendTokenHistory: []
        // loading: false,
      };

    case CURRENCY_LOGOUT_CLEANUP:
      return {
        ...state,
        loading: false,
        error: null,
        currencyInfo: null,
        currencyDetail: null,
        currencyGraph: null,
        settings: null,
        checkWallets: null,
        assetsForWalletsActivation: null,
        walletsActivate: null,
        walletactivationError: null,
        walletactivationSuccess: false,
        walletActivateLoader: false,
        walletUpdateBalance: null,
        walletUpdateBalanceError: null,
        sendDollarHistory: [],
        sendTokenHistory: []
      };

    //transaction history
    case GET_TRANSACTION_HISTORY:
      return { ...state, error: null, loading: true };
    case GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        currencyDetail: action?.payload?.data,
        loading: false,
      };
    case GET_TRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        currencyDetail: null,
        error: action.error?.response?.data?.message,
        loading: false,
      };

    //currency graph

    case GET_CURRENCY_GRAPH:
      return { ...state, error: null, loading: true };
    case GET_CURRENCY_GRAPH_SUCCESS:
      return { ...state, currencyGraph: action.payload.data, loading: false };
    case GET_CURRENCY_GRAPH_FAIL:
      return {
        ...state,
        currencyGraph: null,
        error: action.error?.response?.data?.message,
        loading: false,
      };

    //setting

    case GET_SETTINGS:
      return { ...state, error: null, loading: true };
    case GET_SETTINGS_SUCCESS:
      return { ...state, settings: action.payload.data, loading: false };
    case GET_SETTINGS_FAIL:
      return {
        ...state,
        settings: null,
        error: action.error?.response?.data?.message,
        loading: false,
      };

    //assets for wallets activation

    case ASSETS_FOR_WALLETS_ACTIVATION:
      return { ...state, error: null, loading: true };
    case ASSETS_FOR_WALLETS_ACTIVATION_SUCCESS:
      return {
        ...state,
        assetsForWalletsActivation: action.payload.data,
        loading: false,
      };
    case ASSETS_FOR_WALLETS_ACTIVATION_FAIL:
      return {
        ...state,
        assetsForWalletsActivation: null,
        error: action.error?.response?.data?.message,
        loading: false,
      };

    //wallets activation

    case Wallets_ACTIVATION:
      return {
        ...state,
        walletactivationError: null,
        walletActivateLoader: true,
        walletactivationSuccess: false,
      };
    case Wallets_ACTIVATION_SUCCESS:
      return {
        ...state,
        walletsActivate: action.payload.data,
        walletactivationSuccess: true,
        walletActivateLoader: false,
      };
    case Wallets_ACTIVATION_FAIL:
      return {
        ...state,
        walletsActivate: null,
        walletactivationError: action.error?.response?.data?.message,
        walletActivateLoader: false,
      };

    //wallet update balance

    case UPDATE_WALLET_BALANCE:
      return {
        ...state,
        walletUpdateBalanceError: null,
        loading: true,
        walletactivationSuccess: false,
      };
    case UPDATE_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        walletUpdateBalance: action.payload.data,
        loading: false,
      };
    case UPDATE_WALLET_BALANCE_FAIL:
      return {
        ...state,
        walletUpdateBalance: null,
        walletUpdateBalanceError: action.error?.response?.data?.message,
        loading: false,
      };

    // graph data
    case GRAPH_DATA:
      return { ...state, graphData: action.payload };

    case GET_NETWORK:
      return { ...state, getnetworkData: action.payload }

    case SEND_DOLLAR_HISTORY:
      return {
        ...state,
        sendDollarHistory: state.sendDollarHistory ? [...state.sendDollarHistory, action.payload] : [action.payload]
      };

    case SEND_TOKEN_HISTORY:
      return {
        ...state,
        sendTokenHistory: state.sendTokenHistory ? [...state.sendTokenHistory, action.payload] : [action.payload]
      };
    default:
      return state;
  }
};



export default currencyDetailReducer;
