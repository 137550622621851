import * as yup from "yup";
import { string } from "yup/lib/locale";
export const formikSchema = yup.object({
  gpaDisplayname: yup.string(),
  gpaFirstname: yup.string().required("First Name is required"),
  gpamiddleName: yup.string(),
  gpalastName: yup.string().required("Last Name is required"),
  gpasuffix: yup.string(),
  gpaprimaryEmail: yup
    .string()
    .email("Must be a valid email")
    .required("Primary email required"),
  gpasecondaryEmail: yup.string().email("Must be a valid email"),
  // MailingState: yup.string().required("State Code required"),
  // primaryareaCode: yup
  //   .string()
  //   .max(4, "Area Code is greater than 4")
  //   .required("Area Code required"),
  // countryCode: yup
  //   .string()
  //   .max(4, "Country Code is greater than 4")
  //   .required("Country Code required"),
  primaryphoneNumber: yup
    .number()
    .transform((value) => (value === "" ? undefined : value))
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(600000, "Must be greater than or equal to 6")
    .max(10000000000000000, "Must be less than or equal to 16")
    .required("A phone number is required"),
  PhysicalstreetAddress: yup.string().required("Street Address required"),
  PhysicalstreetAddress2: yup.string(),
  PhysicalCity: yup.string().required("City is required"),
  // Physicalstate: yup.string().required("State is required"),
  PhysicalpostalCode: yup.string().required("Postal code is required"),
  MailingstreetAddress: yup.string().required("Street Address required"),
  MailingstreetAddress2: yup.string(),
  MailingCity: yup.string().required("City is required"),
  // MailingState: yup.string().required("State is required"),
  MailingpostalCode: yup.string().required("Postal code is required"),
});
