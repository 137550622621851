import React from "react";
import Swap from "../../../../../_Common/DashboardComponents/CryptoToAssets/swap";
import IGsilver from "../../../../../../Assets/icons/IGsilver.svg";
import IGgold from "../../../../../../Assets/icons/IGgold.svg";

function CryptoSwap() {
  return (
    <div>
      <Swap
        topHeading="I have 1.8924 AG"
        TopImg={IGsilver}
        TopP="AC Ag"
        topLabel="0.65 AG"
        bottomHeading="I want Au"
        BottomImg={IGgold}
        bottomP="IG Au"
        bottomLabel="0.65 Au"
        assetsHistoryUrl={false}
      />
    </div>
  );
}

export default CryptoSwap;
