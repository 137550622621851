import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./skelton.module.scss";
const SkeltonTable = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton
        height={24}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        width={130}
        baseColor="#20233F"
      />
      <Skeleton
        style={{ marginTop: "10px", marginBottom: "10px" }}
        height={20}
        width={130}
        baseColor="#20233F"
      />
      <Skeleton
        style={{ marginTop: "10px", marginBottom: "10px" }}
        height={20}
        width={130}
        baseColor="#20233F"
      />
    </div>
  );
};

export default SkeltonTable;
