import React, { useEffect, useState } from "react";
import styles from "./CQRvest.module.scss";
import FullScreenView from "../../../../_Common/Full Screen View/FullScreenView";
import HistoryIcon from "../../../../../Assets/icons/history.svg";
import { useHistory } from "react-router-dom";
// import Pagination from "react-js-pagination";
import { HTTP_CLIENT } from "../../../../../utils/services";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Loader } from "../../../../_Common/loader/Loader";
import { Error } from "./Error/Error";
import moment from "moment";
import $ from "jquery";
import { saveFormData, userInfo } from "../../../../../redux/action/userAction";
import { useDispatch } from "react-redux";
import qsymbol from "../../../../../Assets/image/qsymbol.png";
import CQRComponent from "../../../../_Common/CQRComponent/CQRComponent";
import white from "../../../../../Assets/icons/white.png";
import cqrvest from "../../../../../Assets/icons/CQRVEST.png";

const CQRvest = () => {
  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  const [popupvisible, setpopupvisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const isSubscriber = useSelector(
    (state) => state?.user?.userInfo?.isSubscriber
  );
  const subStatus = useSelector(
    (state) => state?.subscription?.subscriptionStatus
  );
  const history = useHistory();

  const Info = useSelector((state) => state?.currency?.currencyInfo?.user);
  const togglepopup = () => {
    setpopupvisible((preview) => !preview);
  };
  const [count, setCount] = useState(30);
  const alert = useAlert();

  const [cqrVestData, setCqrVestData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const handlePageChange = async (pageNumber) => {
    setPagination((prev) => ({ ...prev, page: pageNumber }));
  };
  useEffect(() => {
    FatchImages();
  }, []);
  const FatchImages = async () => {
    setLoading(true);
    let response = await HTTP_CLIENT.get("/nft-token");
    let userResponse = await HTTP_CLIENT.get("/user/info");
    dispatch(userInfo(userResponse.data.user));
    setCqrVestData(response.data);
    setLoading(false);
  };

  const handleClick = (Items) => {
    if (!isSubscriber) {
      alert.show("You are not subscribed. Please subscribe first.", {
        type: "error",
      });
      history.push("/subscription");
      return;
    }
    history.push({
      pathname: "/silent-wave",
      state: { detail: Items },
    });
  };

  function numberWithCommas(n) {
    var parts = n ? n.toString().split(".") : "";
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : ".00")
    );
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.headingWrapper}>
          {/* Left Side Button */}

          <button
            className={styles.headingBtn}
            onClick={() => history.push("/my-assets")}
          >
            My Opportunities
          </button>

          {/* Left Side Button */}
          <div className={styles.heading}>
            <img src={cqrvest} />
          </div>
          {/* tooltip implementation */}
          {/* <div className={styles.tooltip}>
            <div
              className={styles.imgWrapper}
              onClick={() => history.push("/history")}
            >
              <img src={HistoryIcon} alt="" />
            </div>
            <span className={styles.tooltiptext}>History</span>
          </div> */}
          {/* tooltip implementation */}
        </div>
        {/* <p>{`${Info?.isCustomer}`}</p> */}
        {/* {!(Info?.isWalletActivated && Info?.isCustomer) ? (
          <Error />
        ) : ( */}
        <div className={styles.wrapper}>
          <div className={styles.pageHeight}>
            <div className={styles.cardWrapper}>
              {loading ? (
                <div className={styles.loaderPosition}>
                  <Loader />
                </div>
              ) : (
                cqrVestData?.map((Items) => {
                  return (
                    <>
                      <div className={styles.card}>
                        <div className={styles.imgWrapper}>
                          <FullScreenView
                            Imageurl={Items.image}
                            style={{
                              height: "200px",
                              cursor: "pointer",
                              borderRadius: "20px",
                            }}
                          />
                        </div>
                        <div className={styles.cardHeading}>{Items?.title}</div>
                        <div className={styles.paragraphWrapper}>
                          <div className={styles.leftSide}>
                            <span>{Items?.name}</span>
                            <p>
                              {numberWithCommas(Items.remainingSupply)}&nbsp;
                              Remaining Units
                            </p>
                          </div>
                          <div className={styles.rightSide}>
                            <span>
                              <CQRComponent /> {Items?.pricePerShare}
                            </span>
                            <p>
                              {moment(Items?.updatedAt).format("MMMM Do YYYY")}
                            </p>
                          </div>
                        </div>
                        <button
                          className={styles.btn}
                          onClick={() => handleClick(Items)}
                        >
                          Get
                        </button>
                      </div>
                    </>
                  );
                })
              )}
            </div>
          </div>
          {/* <Pagination
              activePage={pagination.page}
              itemsCountPerPage={pagination.limit}
              totalItemsCount={count}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
              activeClass={styles.activeClass}
              innerClass={styles.innerClass}
              itemClass={styles.itemClass}
              hideFirstLastPages={true}
            /> */}
        </div>
        {/* // )} */}
      </div>
    </>
  );
};

export default CQRvest;
