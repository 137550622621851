const CQRvestData = {
  heading: "PURCHASE",
  buttons: [
    { name: "CQR" },
    { name: "Fiat" },
    // { name: "Crypto", disabled: true },
    // { name: "CQR Asset Tokens (Ac/Ag)", disabled: true },
    // { name: "CQR Vest ('FNFT')", disabled: true },
    // { name: "CQR Vest", disabled: true },
  ],
};
export default CQRvestData;
