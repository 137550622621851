import {
  SAWAP_CRYPTO_TO_ASSETS,
  NATIVE_TOKEN_SWAP,
  TOKEN_CLEAN_UP,
  ASSETS_TOKEN_SWAP,
  GET_ASSETS_TO_ASSETS,
  SWAP_LOGOUT_CLEANUP,
} from "../types";

export const getCryptoToAssetsSawap = (data) => {
  return {
    type: SAWAP_CRYPTO_TO_ASSETS,
    payload: {
      request: {
        method: "get",
        url: `native-token/rates/crypto/swap?from=${
          data?.from?.coinSymbol
        }&to=${data ? data?.to?._id : ""}&amount=${data ? data?.amount : ""}`,
      },
    },
  };
};

export const getAssetsToAssetsSawap = (data, swap) => {
  return {
    type: GET_ASSETS_TO_ASSETS,
    payload: {
      request: {
        method: "get",
        url: `native-token/rates/${swap}?from=${
          data?.from?._id ? data?.from?._id : data?.selected?._id
        }&to=${data?.to?._id ? data?.to?._id : ""}&amount=${
          data ? data?.amount : ""
        }&tokenType=${
          data?.from?.type === "sdira" || data?.selected?.type === "sdira"
            ? "legacy"
            : ""
        }&swapType=${
          data?.from?._id === "64f815cc108867b167460837" ? "apiSwap" : ""
        }`,
      },
    },
  };
};

export const nativeTokenSwap = (data) => {
  return {
    type: NATIVE_TOKEN_SWAP,
    payload: {
      request: {
        method: "post",
        url: "native-wallet/swap",
        data: {
          coinSymbol: data?.from?.coinSymbol,
          amount: String(data?.amount),
          to: data?.to?.coin,
        },
      },
    },
  };
};

export const assetsTokenSwap = (data) => {
  return {
    type: ASSETS_TOKEN_SWAP,
    payload: {
      request: {
        method: "post",
        url: "native-token/native-token-swap",
        data: {
          from: data?.from?.coin,
          amount: Number(data?.amount),
          to: data?.to?.coin,
          requestType: data?.from?.type === "sdira" ? "legacy" : "",
        },
      },
    },
  };
};

export const tokenCleanUp = () => {
  return {
    type: TOKEN_CLEAN_UP,
  };
};

export const swapLogoutCleanUp = () => {
  return {
    type: SWAP_LOGOUT_CLEANUP,
  };
};
