import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import styles from "./importantnote.module.scss";
import Captacha from "../../../../../Assets/image/captacha.svg";
import SignaturePad from "react-signature-canvas";
import { BASE_URL, FILE_TYPE } from "../../../../../utils/AppConstants";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAlert } from "react-alert";
import { HTTP_CLIENT } from "../../../../../utils/services";
import { useDispatch, useSelector } from "react-redux";
import {
  saveSign,
  saveFormData,
  userKyc,
} from "../../../../../redux/action/userAction";
import { Loader } from "../../../../_Common/loader/Loader";
import CQRComponent from "../../../../_Common/CQRComponent/CQRComponent";
import { CQRAComponent } from "../../../../_Common/CQRComponent/CQRComponent";
import { CQRBComponent } from "../../../../_Common/CQRComponent/CQRComponent";

import { CQRCComponent } from "../../../../_Common/CQRComponent/CQRComponent";
import { getCurrencyDetail } from "../../../../../redux/action/currencyDetailAction";
// import tokenTerm from "../../../../../Assets/pdf/CQR Token Terms _ Conditions of Use v.20220424.pdf"
// import privacyTerm from "../../../../../Assets/pdf/CQR Vault Privacy Policy v.20220424.pdf"

const ImportantNote = ({ handeClickForm }, ref) => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userSelector = useSelector((state) => state.user);

  let sigPad = useRef({});
  const formData = new FormData();

  const [signature, setSignature] = useState("");
  const [kycRes, setKycRes] = useState("");

  const [uploadLoader, setUploadLoader] = useState(false);
  const [error, setError] = useState("");
  const alert = useAlert();
  const dispatch = useDispatch();
  const formRef = useRef();

  const tokenTerm =
    "https://transfer.app.box.com/s/6h3pbxtud4wc73uuyrmksk9of2cizuhc";
  const relationShip =
    "https://transfer.box.com/s/hqhv1wyhqza41wrokzc6b75jyfh3esvw";

  const privacyTerm =
    "https://transfer.app.box.com/s/itb74fi26xdks6bic9ebky3ym2txqywe";
  const privacyTerm2 =
    "https://transfer.app.box.com/s/xm2jsd5di6w8e82oj3z88jv3rwy9gv2u";

  useImperativeHandle(ref, () => ({
    onSubmit: () => {
      postInfo();
    },
  }));

  const Clear = () => {
    sigPad.current.clear();
    setSignature("");
  };

  let handleSubmit = async () => {
    let sign = sigPad.current.toDataURL();
    if (sigPad.current.isEmpty()) {
      alert.show("Please upload signature first", { type: "success" });
      return;
    }
    setUploadLoader(true);
    formData.append("type", FILE_TYPE.GPA_LEVEL);
    formData.append("file", sign);
    try {
      let res = await HTTP_CLIENT.post(`storage/upload/base64`, formData);
      setError("");
      setUploadLoader(false);
      alert.show("Signature uploaded successfully", { type: "success" });

      let signatureUrl = { signatureUrl: res.data.url };

      dispatch(saveFormData(signatureUrl));
      dispatch(saveSign(sigPad.current.toDataURL()));
      setSignature(res.data.url);
      setError("");
      setUploadLoader(false);
    } catch (err) {
      setUploadLoader(false);
      // alert.show(err?.response?.data?.message, { type: "error" });
    }
  };

  useEffect(() => {
    if (userSelector?.savedFormData?.signatureUrl) {
      setSignature(userSelector?.savedFormData?.signatureUrl);
    }
  }, [userSelector?.savedFormData?.signatureUrl]);
  const userKycs = useSelector((state) => state);

  // const userKycData = useSelector(
  //   (state) => state?.currency?.currencyInfo?.gpaData
  // );
  const postInfo = async () => {
    try {
      if (signature === "") {
        setError("Upload the signature");
      } else {
        setError("");
        let data = userSelector.savedFormData;
        const res = await dispatch(userKyc(data));
        dispatch(getCurrencyDetail());
        handeClickForm();
        // const params = { redirect: true };
        // dispatch(saveFormData(params));

        // if (userKycData) {
        if (res?.payload?.data) {
          alert.show("Form submitted successfully", { type: "success" });
          history.push("/cryptocurrency");
        }
        // }
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   RecieveDatefunction(signature, "signature");
  // }, [signature]);

  useEffect(() => {
    if (userSelector?.sign) {
      sigPad.current.fromDataURL(userSelector?.sign);
    } else if (userSelector?.savedFormData?.signatureUrl) {
      setSignature(userSelector.savedFormData.signatureUrl);
    } else {
      setSignature("");
    }
    // sigPad.current.fromDataURL(userSelector?.sign);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            <p>IMPORTANT NOTE</p>
          </div>
          <div className={styles.textsection}>
            <p>
              Whereas, the <span>Member</span> (hereinafter, <span>“you”</span>{" "}
              or <span>“your”</span> as the case may be), submit this
              application with clear understanding and cognitive self-direction
              by your willingness to freely provide information through this C
              <CQRComponent />R<span>
                {" "}
                Vault FBO Website, Web Application
              </span>{" "}
              and/or <span>Mobile Application</span> (hereinafter the,{" "}
              <span>“Service”</span> or <span>“Services”</span> as the case may
              be) as required by C<CQRComponent />R Vault’s “
              <span>Know Your Neighbor Policy</span>”, you hereby personally
              agree and determine your <span>Gideon Protocol Access </span>
              (hereinafter, "<span>GPA</span>") Services registration level.
              Your GPA Level determines your ability to access certain private
              subscription based features available through the Services of your{" "}
              C<CQRComponent />R <span>Vault Account </span>
              (hereinafter, "<span>Account</span>"). By submitting your personal
              information through this Services “
              <span>Application & Registration</span>” process, you hereby
              effectively agree to all <span>Covenants, Policies</span>, C
              <CQRComponent />R{" "}
              <span>Vault Terms & Conditions, Token Terms & Conditions</span> in
              accordance with your Relationship &{" "}
              <span>Royalty Sharing Covenant</span>
              (collectively hereinafter, "<span>RRSC</span>") and declare your
              Account GPA at a bona fide participant entry Level 3 clearance.
              (You may determine to increase your GPA Level at any time pursuant
              with your RRSC eligibility status.)
              <br />
              <br />
              Any NEW cryptocurrency or fiat capital contribution made to
              activate or increase the amount of your Account’s C
              <CQRComponent />R Dollar Liquidity Token balance shall be
              administratively held and domiciled within the C<CQRComponent />R
              Vault Community Stabilization Fund until your contribution has
              been evaluated, approved, and allocated for Beneficial Interest
              Unit assignment to your Account. This automated process is
              overseen daily in real time by an authorized Fiduciary Manager of
              the receiving Interdependent Auxiliary Trust for and on behalf of{" "}
              C<CQRComponent />R Vault’s Ministry of Finance.
              <br />
              <br />
              Notice: Once allocated to your Account, pursuant to your RRSC, the
              vesting/due diligence period begins. No crypto or fiat value
              redeem of any C<CQRComponent />R Dollar (“
              <CQRComponent />
              ”), Silver or Gold Asset Token (“
              <CQRAComponent />
              ”or “<CQRBComponent />” or “Å”) or Opportunity Token (“Å”) may be
              redeemed (withdrawn) during each acquired token’s vesting period,
              but only added to one’s Account balance during the current
              calendar year as it pertains to various relationship categories
              and respective thresholds therein. The vesting period extends in
              duration for minimally one (1) year and one (1) day annually in
              arrears. Vesting effectively begins the January 2 of next calendar
              year. In other words, the last day to add value in the next
              vesting period is December 31 of the current year. Your right of
              rescission to revoke your voluntary contribution for full recourse
              of any or all cryptocurrency and/or fiat capital contributions
              made prior to administrative hold obligations expires within 5
              Business Days (M-F, 2400 GMT) from the effective date of your
              transaction. Your information provided today is time stamped with
              the "Effective Date & Time" ascribed in blockchain
              “Smart-Covenant” upon submission.
              <div className={styles.dontAgree}>
                If you do not agree, then please terminate your Services
                application submission, and any capital contribution to your
                Account right here.
              </div>
              Please sign and submit your Account Application & Registration if
              you <span>AGREE</span> to all of our Covenants ,&nbsp;
              <a
                style={{ color: "#40a1dc" }}
                href={privacyTerm2}
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                Policies
              </a>{" "}
              ,&nbsp;
              <a
                style={{ color: "#40a1dc" }}
                href={privacyTerm}
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                CQR Vault Terms & Conditions{" "}
              </a>
              &nbsp;and&nbsp;
              <a
                style={{ color: "#40a1dc" }}
                href={tokenTerm}
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                Token Terms & Conditions{" "}
              </a>
              &nbsp;,{" "}
              <a href={relationShip}>
                <label style={{ color: "#40a1dc" }}>
                  {" "}
                  Relationship & Royalty Sharing Covenant
                </label>{" "}
              </a>
              &nbsp;and&nbsp;
              <a href="https://transfer.box.com/s/cs58xjsegmqsumjglmflmfi275zcmaig">
                <label style={{ color: "#40a1dc" }}>
                  {" "}
                  Disclaimer & Indemnification Agreement
                </label>
              </a>
              &nbsp;, as well as the{" "}
              <a href="https://transfer.box.com/s/01uag7rtcf6ujmkqnwlqu6bnupreanv3">
                <label style={{ color: "#40a1dc" }}>
                  {" "}
                  Shared Expense Schedule{" "}
                </label>
              </a>
              of your Member Account. <br /> <br />
              By <span>Signing Below </span>, I Hereby Acknowledge, Understand
              and Agree to all:
            </p>
          </div>
          {uploadLoader ? (
            <Loader />
          ) : (
            <div className={styles.signaturearea}>
              <p>
                C<CQRComponent />R Member Applicant Signature
              </p>
              {userSelector?.savedFormData?.signatureUrl ? (
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    marginTop: "1.5rem",
                    height: "200px",
                    background: "white",
                  }}
                  src={userSelector?.savedFormData?.signatureUrl}
                />
              ) : (
                <div className={styles.signature}>
                  <SignaturePad
                    ref={sigPad}
                    penColor="#40a1dc"
                    canvasProps={{
                      width: 1285,
                      height: 100,
                      className: "sigCanvas",
                    }}
                  />
                </div>
              )}
              <label>
                USE YOUR MOUSE/FINGER TO SIGN YOUR NAME IN THE SPACE ABOVE, THEN
                CLICK/TAP "UPLOAD"
              </label>

              <div className={styles.clear}>
                {userSelector?.savedFormData?.signatureUrl ? (
                  ""
                ) : (
                  <p onClick={Clear}>Clear</p>
                )}

                {userSelector?.savedFormData?.signatureUrl ? (
                  " "
                ) : (
                  <p onClick={handleSubmit}>Upload</p>
                )}
              </div>
              {error ? <label style={{ color: "#E5516B" }}>{error}</label> : ""}
            </div>
          )}

          {/* <div className={styles.inputfield}>
            <span>
              Please Verify That You Are Not an Orwellian Cyber Bot A.I. *
            </span>
            <div className={styles.captacha}>
              <img src={Captacha} alt="Password" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default forwardRef(ImportantNote);
