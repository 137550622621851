import React, { useEffect, useState } from "react";
import styles from "./directdebit.module.scss";
import CheckedImage from "../../../Assets/icons/done.svg";
import Button from "../DashboardButton/Button";
import { useAlert } from "react-alert";
import { HTTP_CLIENT } from "../../../utils/services";
import CQRComponent from "../../_Common/CQRComponent/CQRComponent";

const DirectDebit = () => {
  const alert = useAlert();
  const [redirectFlowId, setRedirectFlowId] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let redirect_flow_id = params.get("redirect_flow_id");
    setRedirectFlowId(redirect_flow_id);
    handleSubmit(redirect_flow_id);
  }, []);

  const handleSubmit = async (redirect_flow_id) => {
    let data = {
      redirectId: redirect_flow_id,
    };

    try {
      let res = await HTTP_CLIENT.post(
        `go-cardless/complete-customer-creation`,
        data
      );

      alert.show("successfully", { type: "success" });
      // history.push(res?.data?.confirmationUrl)
      setUrl(res?.data?.confirmationUrl);
    } catch (err) {
      alert.show(err.response.data.message, { type: "error" });
    }
  };

  const handleUrl = () => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.debit}>
            <div className={styles.heading}>
              <img src={CheckedImage} alt="" />
            </div>
            <div className={styles.text}>
              <p>Direct Debit Set Up Successfully</p>
            </div>
            <div className={styles.text}>
              <span>
                C<CQRComponent />R Vault FBO will appear on your bank statement
                when payments are taken against this Direct Debit
              </span>
            </div>
            <div className={styles.btnsection}>
              <Button clickHandler={handleUrl} name="Finalize Flow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectDebit;
