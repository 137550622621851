import React from "react";

import styles from "./comingSoon.module.scss";

const ComingSoon = ({ Name, src, para }) => {
  return (
    <div className={styles.container}>
      <img src={src} alt="" />
      <label>{Name}</label>
      {para && (
        <>
          <p>{para}</p>
          <div className={styles.btndiv}></div>
        </>
      )}
    </div>
  );
};

export default ComingSoon;
