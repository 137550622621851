export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SAVE_FORM_DATA = "SAVE_FORM_DATA";
export const SAVE_SIGN = "SAVE_SIGN";
export const GET_CURRENCY_DETAIL = "GET_CURRENCY_DETAIL";
export const GET_CURRENCY_DETAIL_SUCCESS = "GET_CURRENCY_DETAIL_SUCCESS";
export const GET_CURRENCY_DETAIL_FAIL = "GET_CURRENCY_DETAIL_FAIL";
export const CURRENCY_CLEANUP = "CURRENCY_CLEANUP";
export const USER_CLEANUP = "USER_CLEANUP";
export const SET_CHECKED = "SET_CHECKED";
export const GRAPH_DATA = "GRAPH_DATA";
export const SEND_DOLLAR_HISTORY = "SEND_DOLLAR_HISTORY";
export const SEND_TOKEN_HISTORY = "SEND_TOKEN_HISTORY";
// currency logout cleanup
export const CURRENCY_LOGOUT_CLEANUP = "CURRENCY_LOGOUT_CLEANUP";

// Swap logout cleanup
export const SWAP_LOGOUT_CLEANUP = "SWAP_LOGOUT_CLEANUP";

//kyc cleanup

export const KYC_CLEANUP_UP = "KYC_CLEANUP_UP";

// FCM Token
export const USER_FCM_TOKEN = "USER_FCM_TOKEN";

//user KYC

export const USER_KYC = "USER_KYC";
export const USER_KYC_SUCCESS = "USER_KYC_SUCCESS";
export const USER_KYC_FAIL = "USER_KYC_FAIL";

//img upload

export const IMG_UPLOAD = "IMG_UPLOAD";
export const IMG_UPLOAD_SUCCESS = "IMG_UPLOAD_SUCCESS";
export const IMG_UPLOAD_FAIL = "IMG_UPLOAD_FAIL";

//img upload in userInfo

export const IMG_UPLOAD_USER_INFO = "IMG_UPLOAD_USER_INFO";
export const IMG_UPLOAD_USER_INFO_SUCCESS = "IMG_UPLOAD_USER_INFO_SUCCESS";
export const IMG_UPLOAD_USER_INFO_FAIL = "IMG_UPLOAD_USER_INFO_FAIL";

//transaction history
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_HISTORY_SUCCESS =
  "GET_TRANSACTION_HISTORY_SUCCESS";
export const GET_TRANSACTION_HISTORY_FAIL = "GET_TRANSACTION_HISTORY_FAIL";

// currency graph

export const GET_CURRENCY_GRAPH = "GET_CURRENCY_GRAPH";
export const GET_CURRENCY_GRAPH_SUCCESS = "GET_CURRENCY_GRAPH_SUCCESS";
export const GET_CURRENCY_GRAPH_FAIL = "GET_CURRENCY_GRAPH_FAIL";

//setting

export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL";

//Wallets

export const Wallets_ACTIVATION = "Wallets_ACTIVATION";
export const Wallets_ACTIVATION_SUCCESS = "Wallets_ACTIVATION_SUCCESS";
export const Wallets_ACTIVATION_FAIL = "Wallets_ACTIVATION_FAIL";

//check wallets

export const CHECK_WALLETS = "CHECK_WALLETS";

//Assets for wallets activation

export const ASSETS_FOR_WALLETS_ACTIVATION = "ASSETS_FOR_WALLETS_ACTIVATION";
export const ASSETS_FOR_WALLETS_ACTIVATION_SUCCESS =
  "ASSETS_FOR_WALLETS_ACTIVATION_SUCCESS";
export const ASSETS_FOR_WALLETS_ACTIVATION_FAIL =
  "ASSETS_FOR_WALLETS_ACTIVATION_FAIL";

//update wallets balance

export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_WALLET_BALANCE_SUCCESS = "UPDATE_WALLET_BALANCE_SUCCESS";
export const UPDATE_WALLET_BALANCE_FAIL = "UPDATE_WALLET_BALANCE_FAIL";

//get crypto to assets

export const SAWAP_CRYPTO_TO_ASSETS = "SAWAP_CRYPTO_TO_ASSETS";
export const SAWAP_CRYPTO_TO_ASSETS_SUCCESS = "SAWAP_CRYPTO_TO_ASSETS_SUCCESS";
export const SAWAP_CRYPTO_TO_ASSETS_FAIL = "SAWAP_CRYPTO_TO_ASSETS_FAIL";

//get crypto to assets

export const GET_ASSETS_TO_ASSETS = "GET_ASSETS_TO_ASSETS";
export const GET_ASSETS_TO_ASSETS_SUCCESS = "GET_ASSETS_TO_ASSETS_SUCCESS";
export const GET_ASSETS_TO_ASSETS_FAIL = "GET_ASSETS_TO_ASSETS_FAIL";

//swap crypto to assets

export const NATIVE_TOKEN_SWAP = "NATIVE_TOKEN_SWAP";
export const NATIVE_TOKEN_SWAP_SUCCESS = "NATIVE_TOKEN_SWAP_SUCCESS";
export const NATIVE_TOKEN_SWAP_FAIL = "NATIVE_TOKEN_SWAP_FAIL";

export const TOKEN_CLEAN_UP = "TOKEN_CLEAN_UP";

//swap assyes to asstes swap

export const ASSETS_TOKEN_SWAP = "ASSETS_TOKEN_SWAP";
export const ASSETS_TOKEN_SWAP_SUCCESS = "ASSETS_TOKEN_SWAP_SUCCESS";
export const ASSETS_TOKEN_SWAP_FAIL = "ASSETS_TOKEN_SWAP_FAIL";

//customer registration

export const CUSTOMER_REGISTRATION = "CUSTOMER_REGISTRATION";
export const CUSTOMER_REGISTRATION_SUCCESS = "CUSTOMER_REGISTRATION_SUCCESS";
export const CUSTOMER_REGISTRATION_FAIL = "CUSTOMER_REGISTRATION_FAIL";

//wallet activation via fiat

export const WALLET_ACTIVE_VIA_FIAT = "WALLET_ACTIVE_VIA_FIAT";
export const WALLET_ACTIVE_VIA_FIAT_SUCCESS = "WALLET_ACTIVE_VIA_FIAT_SUCCESS";
export const WALLET_ACTIVE_VIA_FIAT_FAIL = "WALLET_ACTIVE_VIA_FIAT_FAIL";

export const CUSTOMER_CLEANUP = "CUSTOMER_CLEANUP";

// user info

export const USER_info = "USER_info";

// Subscription

export const SUBSCRIPTION_DATA = "SUBSCRIPTION_DATA";
export const YOUR_SUBSCRIPTION = "YOUR_SUBSCRIPTION";
export const SUBSCRIPTION_STATUS = "SUBSCRIPTION_STATUS";
export const SUBSCRIPTION_CLEANUP = "SUBSCRIPTION_CLEANUP";

// create account
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";

// save remittance address

export const REMITTANCE_ADDRESS = "REMITTANCE_ADDRESS";
export const SIGNLE_REMITTANCE_ADDRESS = "SIGNLE_REMITTANCE_ADDRESS";
export const DIRECT_WIRE_LIST = "DIRECT_WIRE_LIST";
export const SAVE_BANK_DETAIL = "SAVE_BANK_DETAIL";
export const CLEAR_REMITTANCE_ADDRESS_STATE = "CLEAR_REMITTANCE_ADDRESS_STATE";

//network

export const GET_NETWORK = "GET_NETWORK";

//token types

export const OPPERTUNITY_TOKEN = "OPPERTUNITY_TOKEN";

//marketplace
export const MARKETPLACE_LISTING = "MARKETPLACE_LISTING";
