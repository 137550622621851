import React, { useEffect } from "react";
import styles from "./Achievements.module.scss";
import Data from "./data";
import Aos from "aos";

function Achievements() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {Data.map((cardData) => {
            const { id, heading, subHeading, subHeading2 } = cardData;
            return (
              <div
                className={styles.card}
                key={id}
                data-aos="flip-left"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1200"
              >
                <div className={styles.heading}>{heading}</div>
                <div className={styles.subHeading}>{subHeading}</div>
                <div className={styles.subHeading2}>{subHeading2}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Achievements;
