import {
  SAWAP_CRYPTO_TO_ASSETS,
  SAWAP_CRYPTO_TO_ASSETS_SUCCESS,
  SAWAP_CRYPTO_TO_ASSETS_FAIL,
  GET_ASSETS_TO_ASSETS,
  GET_ASSETS_TO_ASSETS_SUCCESS,
  GET_ASSETS_TO_ASSETS_FAIL,
  NATIVE_TOKEN_SWAP,
  NATIVE_TOKEN_SWAP_SUCCESS,
  NATIVE_TOKEN_SWAP_FAIL,
  TOKEN_CLEAN_UP,
  ASSETS_TOKEN_SWAP,
  ASSETS_TOKEN_SWAP_SUCCESS,
  ASSETS_TOKEN_SWAP_FAIL,
  SWAP_LOGOUT_CLEANUP,
} from "../types";

const initialState = {
  loading: false,
  error: null,
  sawapInfo: null,

  assetSwaoInfo: null,
  assetsSwapInfoError: null,
  assetSwaoInfoLoader: null,

  // crypto to asstes-------------------
  nativeTokenSwap: null,
  nativeTokenLoading: false,
  nativeTokenError: null,
  nativeTokenSuccess: null,

  // crypto to asstes-------------------

  assetsTokenSwap: null,
  assetsTokenLoading: false,
  assetsTokenError: null,
  assetsTokenSuccess: null,
};

const sawapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAWAP_CRYPTO_TO_ASSETS:
      return { ...state, error: null, loading: true };
    case SAWAP_CRYPTO_TO_ASSETS_SUCCESS:
      return { ...state, sawapInfo: action?.payload?.data, loading: false };
    case SAWAP_CRYPTO_TO_ASSETS_FAIL:
      return {
        ...state,
        sawapInfo: null,
        error: action.error?.response?.data?.message,
        loading: false,
      };
    //get assets swap info

    case GET_ASSETS_TO_ASSETS:
      return { ...state, assetsSwapInfoError: null, assetSwaoInfoLoader: true };
    case GET_ASSETS_TO_ASSETS_SUCCESS:
      return {
        ...state,
        assetSwaoInfo: action?.payload?.data,
        assetSwaoInfoLoader: false,
      };
    case GET_ASSETS_TO_ASSETS_FAIL:
      return {
        ...state,
        assetSwaoInfo: null,
        assetsSwapInfoError: action.error?.response?.data?.message,
        assetSwaoInfoLoader: false,
      };

    //native token swap-------------------------

    case NATIVE_TOKEN_SWAP:
      return { ...state, nativeTokenError: null, nativeTokenLoading: true };
    case NATIVE_TOKEN_SWAP_SUCCESS:
      return {
        ...state,
        nativeTokenSwap: action?.payload?.data,
        nativeTokenSuccess: "Token Swap successfully",
        nativeTokenLoading: false,
      };
    case NATIVE_TOKEN_SWAP_FAIL:
      return {
        ...state,
        nativeTokenSwap: null,
        nativeTokenError: action.error?.response?.data?.message,
        nativeTokenLoading: false,
      };

    //assets token swap-------------------------

    case ASSETS_TOKEN_SWAP:
      return { ...state, assetsTokenError: null, assetsTokenLoading: true };
    case ASSETS_TOKEN_SWAP_SUCCESS:
      return {
        ...state,
        assetsTokenSwap: action?.payload?.data,
        assetsTokenSuccess: action?.payload?.data?.message,
        assetsTokenLoading: false,
      };
    case ASSETS_TOKEN_SWAP_FAIL:
      return {
        ...state,
        assetsTokenSwap: null,
        assetsTokenError: action.error?.response?.data?.message,
        assetsTokenLoading: false,
      };
    case TOKEN_CLEAN_UP:
      return {
        ...state,
        error: null,
        assetsTokenError: null,
        assetsTokenSuccess: null,
        nativeTokenError: null,
        nativeTokenSuccess: null,
      };

    case SWAP_LOGOUT_CLEANUP:
      return {
        ...state,
        loading: false,
        error: null,
        sawapInfo: null,

        assetSwaoInfo: null,
        assetsSwapInfoError: null,
        assetSwaoInfoLoader: null,

        // crypto to asstes-------------------
        nativeTokenSwap: null,
        nativeTokenLoading: false,
        nativeTokenError: null,
        nativeTokenSuccess: null,

        // crypto to asstes-------------------

        assetsTokenSwap: null,
        assetsTokenLoading: false,
        assetsTokenError: null,
        assetsTokenSuccess: null,
      };

    default:
      return state;
  }
};

export default sawapReducer;
