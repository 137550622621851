import styles from "./popup.module.scss";
import xSymbolBlack from "../../../../Assets/symbols/xSymbolBlack.png";
import cSymbolBlack from "../../../../Assets/symbols/cSymbolBlack.png";

const CqrvestWhitepaper = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* <label>CQR Vest Whitepaper</label> */}
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            NEW INNOVATIONS COMING SOON!
          </p>
        </div>
      </div>
    </>
  );
};
export default CqrvestWhitepaper;
