import styles from "./popup.module.scss";
import q from "../../../../Assets/symbols/qSymbolBlack.png";
const LiquidityToken = () => {
  const url = "https://www.law.cornell.edu/uscode/text/12/411";

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <label>CQR Liquidity Tokens</label>
          <p>
            CQR Dollars or “<img src={q} alt="" />
            ”are CQR Vault’s private fungible <span>
              “Liquidity Tokens”
            </span>{" "}
            valued by Bona Fide Members at a ratio of <img src={q} alt="" />
            1.00 CQR : $1.00 USD. Members may exchange <img
              src={q}
              alt=""
            />{" "}
            between each other via self-directed transaction resulting in a
            lawful redemption (
            <a href={url} target="_blank" rel="noreferrer">
              Title 12 U.S. Code § 411
            </a>
            ) and inter-exchange of like kind values within the CQR Vault DeFi &
            CQR Vest DEX platforms.
          </p>
        </div>
      </div>
    </>
  );
};
export default LiquidityToken;
