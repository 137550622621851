import React from "react";
// import style from "./contactus.module.scss";
import About from "./About/About";
import Images from "./Images/Images";
import Contact from "./contact/contact";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - CQR Vault</title>
        <meta
          name="description"
          content="We're here to help and answer any question you might have. We look forward to hearing from you."
        />
        <link rel="canonical" href="https://cqrvault.org/contact-us" />
      </Helmet>
      {/* <About />
      <Images /> */}
      <Contact />
    </>
  );
};

export default ContactUs;
