import React, { useState, useEffect, useContext } from "react";
import DashboardChart from "../../../../_Common/DashboardChart/DashboardChart";
import Table from "../../../../_Common/DashboardTable/Table";
import DashboardButton from "../../../../_Common/DashboardButton/Button";
import styles from "./CQRVault.module.scss";
import Modal from "../../../../_Common/Modal/Modal";
import Popup from "../../../../_Common/DashboardComponents/popup/popup";
import { useHistory } from "react-router-dom";
import useCryptoCurrency from "../CryptoCurrency/useCryptoCurrency";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../../../_Common/DashboardComponents/alert/alert";
import refrsh from "../../../../../Assets/icons/refrsh.svg";
import { HTTP_CLIENT } from "../../../../../utils/services";
import $ from "jquery";
import {
  getCurrencyDetail,
  currencyCleanUp,
  getSetting,
  AseetsForWalletActivation,
  UpdateWalletBalance,
} from "../../../../../redux/action/currencyDetailAction";
import { SocketContext } from "../../../../../Context/Socket";
import { useAlert } from "react-alert";

function CQRVault() {
  const alert = useAlert();
  const userEmail = useSelector((state) => state?.user?.userInfo?.email);
  const currencySelector = useSelector((state) => state.currency);
  const [isHideBalance, setHideBalance] = useState(false);
  const [active, setActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [totalBalance, settotalBalance] = useState(0);
  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  useEffect(() => {
    if (currencySelector.error) {
      alert(currencySelector.error);
      dispatch(currencyCleanUp());
    }
  }, [currencySelector.error]);

  const [popupvisible, setpopupvisible] = useState(false);
  const [number, setNumber] = useState(4);

  const togglepopup = (e, num) => {
    e.preventDefault();
    setNumber(num);
    setpopupvisible((preview) => !preview);
  };

  const hideBalance = (balance) => {
    balance = String(balance);
    const [bal] = balance.split(" ");
    return balance == 0 && isHideBalance
      ? "*"
      : isHideBalance
      ? `${new Array(bal.length).join("*")}`
      : balance;
  };

  // const getInfo = async () => {
  //   await HTTP_CLIENT.get("user/info")
  //  }

  useEffect(() => {
    //  getInfo()
    dispatch(getCurrencyDetail());
  }, [currencySelector?.walletUpdateBalance]);
  const handleRefresh = () => {
    setActive(true);
    dispatch(getCurrencyDetail());
    dispatch(getSetting());
    dispatch(UpdateWalletBalance());
    dispatch(AseetsForWalletActivation());

    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  useEffect(() => {
    const sum = currencySelector?.currencyInfo?.nativeWallets.reduce(
      (partialSum, a) => partialSum + parseFloat(a?.USD),
      0
    );
    settotalBalance(Number(sum?.toFixed(3)));
  }, [currencySelector]);
  const socket = useContext(SocketContext);
  useEffect(() => {
    socket.on(userEmail, (data) => {
      if (data?.type == "nativeWalletUpdate") {
        dispatch(getCurrencyDetail(data?.wallet));
        return;
      }
    });
  }, []);
  const detailData = (data) => {
    history.push({
      pathname: "/vault-graph",
      state: { detail: data },
    });
  };

  const toggleBalance = () => {
    setHideBalance(!isHideBalance);
  };

  //----Withdrawal Function-----//

  const handleWithdrawal = () => {
    if (currencySelector?.currencyInfo?.user?.isWalletActivated) {
      history.push("/withdrawal");
    } else {
      history.push("/error");
    }
  };

  //----Withdrawal Function-----//
  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <Popup data={number} />
      </Modal>

      <div className={styles.container}>
        <div className={styles.loaderWrapper}>
          Refresh
          {active ? (
            <img
              onClick={handleRefresh}
              className={styles.active}
              src={refrsh}
              alt=""
            />
          ) : (
            <img
              onClick={handleRefresh}
              className={styles.refresh}
              src={refrsh}
              alt=""
            />
          )}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <Alert
              AlertShow={true}
              handleClick={(e) => togglepopup(e, 4)}
              Click={(e) => togglepopup(e, 5)}
            />
          </div>
          <DashboardChart
            currency={currencySelector?.currencyInfo?.nativeWallets}
            num={totalBalance}
            toggleBalance={toggleBalance}
            hideBalance={hideBalance}
            isHideBalance={isHideBalance}
          />
          <div className={styles.buttonWrapper}>
            <DashboardButton
              name={"Withdrawal"}
              clickHandler={() => handleWithdrawal()}
            />
            <DashboardButton
              name={"Top-Up"}
              clickHandler={(e) => togglepopup(e, 4)}
            />
            <DashboardButton
              name={"Send"}
              clickHandler={(e) => togglepopup(e, 5)}
            />
            <DashboardButton
              name={"Swap"}
              clickHandler={(e) => togglepopup(e, 6)}
            />
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableWrapper}>
              <Table
                CQRData={currencySelector?.currencyInfo?.nativeWallets}
                recieveData={detailData}
                // onclick={
                //   detailData

                //   //   () => {
                //   //   history.push("/bitcoin-table");
                //   // }
                // }
                load={
                  currencySelector.loading && !currencySelector?.currencyInfo
                }
                dataFile={2}
                isHideBalance={isHideBalance}
                // load={currencySelector.loading}
                hideBalance={hideBalance}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CQRVault;
