const Data = [
  {
    id: 1,
    video:
      "https://res.cloudinary.com/df9x7ac77/video/upload/v1650448022/CQR_Vault_Intro_v2.mp4_-_Powered_by_Box_vm5jzd.mp4",
    // heading: "Dale Hewett Collier",
    // subHeading: " CQR Strategic Philanthropist",
    // subHeading2: "  CQR Programming Architect",
  },
  // {
  //   id: 2,
  //   video:
  //     "https://res.cloudinary.com/df9x7ac77/video/upload/v1650448022/CQR_Vault_Intro_v2.mp4_-_Powered_by_Box_vm5jzd.mp4",
  //   heading: "Dale Hewett Collier",
  //   subHeading: " CQR Strategic Philanthropist",
  //   subHeading2: "  CQR Programming Architect",
  // },
  // {
  //   id: 3,
  //   video:
  //     "https://res.cloudinary.com/df9x7ac77/video/upload/v1650448022/CQR_Vault_Intro_v2.mp4_-_Powered_by_Box_vm5jzd.mp4",
  //   heading: "Dale Hewett Collier",
  //   subHeading: " CQR Strategic Philanthropist",
  //   subHeading2: "  CQR Programming Architect",
  // },
  // {
  //   id: 4,
  //   video:
  //     "https://res.cloudinary.com/df9x7ac77/video/upload/v1650448022/CQR_Vault_Intro_v2.mp4_-_Powered_by_Box_vm5jzd.mp4",
  //   heading: "Dale Hewett Collier",
  //   subHeading: " CQR Strategic Philanthropist",
  //   subHeading2: "  CQR Programming Architect",
  // },
  // {
  //   id: 5,
  //   video:
  //     "https://res.cloudinary.com/df9x7ac77/video/upload/v1650448022/CQR_Vault_Intro_v2.mp4_-_Powered_by_Box_vm5jzd.mp4",
  //   heading: "Dale Hewett Collier",
  //   subHeading: " CQR Strategic Philanthropist",
  //   subHeading2: "  CQR Programming Architect",
  // },
  // {
  //   id: 6,
  //   video:
  //     "https://res.cloudinary.com/df9x7ac77/video/upload/v1650448022/CQR_Vault_Intro_v2.mp4_-_Powered_by_Box_vm5jzd.mp4",
  //   heading: "Dale Hewett Collier",
  //   subHeading: " CQR Strategic Philanthropist",
  //   subHeading2: "  CQR Programming Architect",
  // },
];
export default Data;
