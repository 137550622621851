import React from "react";
import styles from "./Images.module.scss";
import ContactBottom from "../../../../Assets/image/ContactBottom.png";
import ContactLeft from "../../../../Assets/image/ContactLeft.png";
import ContactTop from "../../../../Assets/image/ContactTop.png";

function Images() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.imgWrapper}>
            <div className={styles.img1}>
              <img src={ContactLeft} alt="" />
            </div>
            <div className={styles.img2}>
              <img src={ContactTop} alt="" />
            </div>
            <div className={styles.img3}>
              <img src={ContactBottom} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Images;
