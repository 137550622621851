import { HTTP_CLIENT } from "./services";

const postCustomer = async (params) => {
  return await HTTP_CLIENT.post("coinqvest/customer", params);
};
const handleStartCheckout = async (params) => {
  return await HTTP_CLIENT.post("coinqvest/start-checkout", params);
};
const handlePayPalCheckout = async (params) => {
  return await HTTP_CLIENT.post("paypal/order/create", params);
};
export { postCustomer, handleStartCheckout, handlePayPalCheckout };
