import React, { useState } from "react";
import Styles from "./footer.module.scss";
import { Link } from "react-router-dom";
import logo from "../../../Assets/image/logo.svg";
import Modal from "../../_Common/Modal/Modal";
import AssetTokens from "./dataFile/assetTokens";
import OpportunityTokens from "./dataFile/opportunityTokens";
import LiquidityTokens from "./dataFile/liquidityTokens";
import CqrvaultWhitepaper from "./dataFile/cqrVaultWhitepaper";
import CqrvestWhitepaper from "./dataFile/cqrVestWhitepaper";
import axios from "axios";
import { BASE_URL } from "../../../utils/AppConstants";
import { useAlert } from "react-alert";

// import {Base_url}
const Footer = () => {
  const [popupvisible, setpopupvisible] = useState(false);
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const alert = useAlert();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!email) {
      alert.show("please enter email first", { type: "error" });
      return;
    }
    try {
      let params = {
        email,
      };
      const res = await axios.post(`${BASE_URL}newsletter`, params);
      alert.show(res.data, { type: "success" });
      setEmail("");
    } catch (err) {
      alert.show(err.response.data.message, { type: "error" });
    }
  };

  const togglepopup = (data) => {
    setpopupvisible((preview) => !preview);
    if (data === "1") {
      setNumber(1);
    } else if (data === "2") {
      setNumber(2);
    } else if (data === "3") {
      setNumber(3);
    } else if (data === "4") {
      setNumber(4);
    } else if (data === "5") {
      setNumber(5);
    }
  };

  const url = "https://transfer.app.box.com/s/itb74fi26xdks6bic9ebky3ym2txqywe";
  const tokenUrl =
    "https://transfer.box.com/s/6h3pbxtud4wc73uuyrmksk9of2cizuhc";
  const privacyLink =
    "https://transfer.app.box.com/s/04tyfkj0ev77t4495jnsa694yygy2cpz";

  return (
    <>
      <Modal
        visible={popupvisible}
        onClose={() => setpopupvisible(false)}
        outerStyle={{
          background: "white",
          maxWidth: "900px",
        }}
        showModal2={true}
      >
        {number == 2 ? (
          <AssetTokens />
        ) : number == 3 ? (
          <OpportunityTokens />
        ) : number == 1 ? (
          <LiquidityTokens />
        ) : number == 4 ? (
          <CqrvaultWhitepaper />
        ) : number == 5 ? (
          <CqrvestWhitepaper />
        ) : (
          ""
        )}
      </Modal>
      <div className={Styles.container}>
        <div className={Styles.wrapper}>
          <div className={Styles.textWrapper}>
            <div className={Styles.topWrapper}>
              <div className={Styles.logoClass}>
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <div className={Styles.lableClass}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => handleChange(e)}
                  placeholder="Your Email"
                />
                <button onClick={handleSubmit}>Subscribe</button>
              </div>
            </div>
            <div className={Styles.bottomWrapper}>
              <div className={Styles.firstWrapper}>
                <div className={Styles.heading}>About</div>
                <div className={Styles.subHeading}>
                  <ul>
                    <li onClick={() => togglepopup("1")}>
                      CQR Liquidity Tokens
                    </li>
                    <li onClick={() => togglepopup("2")}>CQR Asset Tokens</li>
                    <li onClick={() => togglepopup("3")}>
                      CQR Opportunity Tokens
                    </li>
                    <a
                      href="https://transfer.box.com/s/wdr8soglqhf23hvjl6ru8fg082m44fdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CQR Vault Whitepaper{" "}
                      {/* <span className={Styles.tooltiptext}>Comming Soon</span> */}
                    </a>
                    <li>
                      <a
                        href="https://transfer.box.com/s/54cj63ww63vryy6l01ckpe06eo52unyo"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CQR Vest Whitepaper
                        {/* <span className={Styles.tooltiptext}>Comming Soon</span> */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={Styles.firstWrapper}>
                <div className={Styles.heading}>RRSC</div>
                <div className={Styles.subHeading}>
                  CQR Vault is Not an investment platform. All active Members
                  agree to all&nbsp;
                  <a href={url} target="_blank" rel="noreferrer">
                    Terms & Conditions of Use
                  </a>
                  &nbsp;in addition to their respective&nbsp;
                  <a
                    href="https://transfer.box.com/s/hqhv1wyhqza41wrokzc6b75jyfh3esvw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Relationship and Royalty Sharing Covenant
                  </a>
                  &nbsp;(s) made effective in acquiescence upon the activation
                  of each bona fide participant Member's CQR Vault account.
                </div>
              </div>
              <div className={Styles.secWrapper}>
                <div className={Styles.heading}>Let's Get Social!</div>
                <div className={Styles.iconsHeading}>
                  <div className={Styles.text}>
                    <ul>
                      <li>
                        Check us out on{" "}
                        <a onClick={() => togglepopup("5")}>Facebook</a>
                      </li>
                      <li>
                        Check us out on{" "}
                        <a onClick={() => togglepopup("5")}>Telegram</a>
                      </li>
                      <li>
                        Check us out on{" "}
                        <a onClick={() => togglepopup("5")}>Twitter</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={Styles.firstWrapper} style={{ rowGap: "1.5rem" }}>
                <div className={Styles.heading}>Member/User Agreements</div>
                <div
                  className={Styles.subHeading}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "0",
                  }}
                >
                  <a href={tokenUrl} target="_blank" rel="noreferrer">
                    CQR Tokens Terms & Conditions of Use{" "}
                  </a>{" "}
                  <a href={url} target="_blank" rel="noreferrer">
                    {" "}
                    CQR Vault Terms & Conditions of Use
                  </a>
                  {/* <br /> */}
                  <span>
                    <a href={privacyLink} target="_blank" rel="noreferrer">
                      {" "}
                      CQR Vault Privacy Policy
                    </a>{" "}
                    <br />{" "}
                    <a
                      href="https://transfer.box.com/s/hqhv1wyhqza41wrokzc6b75jyfh3esvw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Relationship & Royalty Sharing Covenant
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.decWrapper}>
            <div className={Styles.dec}>
              <label>NOTICE:</label>&nbsp;
              <span>
                {" "}
                NOTICE: CQRVault.org | .com | .io is a website owned and managed
                by Ecclesiastical State Interdependent Auxiliary operating by
                rule of necessity as Unincorporated Faith Based Organization
                (F.B.O.)(hereinafter, “CQR Vault”) and administratively managed
                by CQR International LLC. We are a Private Member Association
                Not-for-Profit F.B.O. qualified via Title 26 U.S. Code §
                508c(1)a; Non-Assumptive 28 U.S. Code § 1746(1) without the
                UNITED STATES. By accessing this website and any pages thereof,
                you agree to the &nbsp;
                <a href={url} target="_blank" rel="noreferrer">
                  Terms & Conditions
                </a>{" "}
                of Use of all CQR Vault Policies & Agreements, as each may be
                amended from time to time. CQR Vault is not a registered broker,
                dealer, investment adviser, investment manager or registered
                funding portal. Any and all private offerings shared on this
                site are available only to Private Members by Subscription, and
                are without contravention of Rule 506(b) of Regulation D, as
                “safe harbor” under Section 4(a)(2) of the Securities Act,
                pursuant all CQR Vault &nbsp;
                <a href={url} target="_blank" rel="noreferrer">
                  Terms & Conditions
                </a>{" "}
                of Use and{" "}
                <a
                  href="https://transfer.box.com/s/hqhv1wyhqza41wrokzc6b75jyfh3esvw"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Relationship & Royalty Sharing Covenant&nbsp;
                </a>{" "}
                made in acquiescence by each Private Member’s cognizant
                self-directed intention at the time of any exchange of like kind
                value with goods, services, and/or Member subscriptions.
              </span>
            </div>
          </div>
          <div className={Styles.bWrapper}>
            <span>Version:1.0.0 (Build:#1.0.8)</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
