import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import noDataGip from "../../../../../Assets/gif/noData.gif";
import { getWithdrawalHistoryData } from "../../../../../services/paymentServices";
import { handleDirectwireHistory } from "../../../../../services/withdrawService";
import { Loader } from "../../../../_Common/loader/Loader";
import styles from "./withdrawalHistory.module.scss";

const WithdrawalHistory = ({ setActive, directwire, location }) => {
  const alert = useAlert();
  const [withdrawalHistory, setWithdrawalHistory] = useState("");
  const [loading, setloading] = useState(false);

  const handleWithdrawalHistory = async () => {
    try {
      setloading(true);
      const res = await getWithdrawalHistoryData();
      setWithdrawalHistory(res.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      alert.show(error.response.data.message, { type: "error" });
    }
  };

  const handleWithdrawalDirectWire = async () => {
    try {
      setloading(true);
      const res = await handleDirectwireHistory(location?.state?.detail?._id);
      setWithdrawalHistory(res.data?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      alert.show(error.response.data.message, { type: "error" });
    }
  };
  useEffect(() => {
    if (directwire) {
      handleWithdrawalDirectWire();
    } else {
      handleWithdrawalHistory();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <p>Redeem History</p>
        </div>
        {loading ? (
          <Loader />
        ) : !Boolean(withdrawalHistory?.length) ? (
          <img src={noDataGip} className={styles.noData} />
        ) : (
          <div className={styles.wrapper}>
            <table>
              <tr>
                <td>Tokens</td>
                <td>Status</td>
                <td>Reason</td>
                <td>Amount</td>
              </tr>
              {withdrawalHistory?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className={styles.nameWrapper}>
                      <img
                        src={
                          item?.sourceAsset?.icon?.url
                            ? item?.sourceAsset?.icon?.url
                            : item?.token?.icon?.url
                        }
                        alt=""
                      />
                      <div>
                        <div className={styles.heading}>
                          {item?.sourceAsset?.shortName
                            ? item?.sourceAsset?.shortName
                            : item?.token?.displayName}
                        </div>
                        <div className={styles.description}>
                          {moment(item?.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss A"
                          )}
                        </div>
                        <div className={styles.description}>
                          {item?.streetAddress}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className={styles.nameWrapper}>
                      <div
                        className={`${
                          item?.status == "accepted" ||
                          item?.status == "completed"
                            ? styles.green
                            : item?.status == "expired"
                            ? styles.red
                            : item?.status == "pending"
                            ? styles.purple
                            : item?.status == "rejected"
                            ? styles.red
                            : ""
                        }`}
                      >
                        {item?.status == "accepted"
                          ? "Accepted"
                          : item?.status == "expired"
                          ? "Expired"
                          : item?.status == "pending"
                          ? "Pending"
                          : item?.status == "rejected"
                          ? "Rejected"
                          : item?.status == "completed"
                          ? "Approved"
                          : ""}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.nameWrapper}>
                      <div className={styles.symbol}>
                        {item?.rejectionReason?.length > 40
                          ? `${item?.rejectionReason.substring(0, 40)}...`
                          : item?.rejectionReason}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.nameWrapper}>
                      <div className={styles.symbol}>
                        {item?.amount.toFixed(
                          item?.sourceAsset?.decimals
                            ? item?.sourceAsset?.decimals
                            : ""
                        )}{" "}
                        <img
                          src={
                            item?.sourceAsset?.displaySymbol
                              ? item?.sourceAsset?.displaySymbol
                              : item?.token?.displaySymbol
                          }
                          alt="token"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default WithdrawalHistory;
