import React from "react";
import HistoryTable from "./MyAssetsTable/AssetsTable";
import BackImg from "../../../../../../Assets/icons/historyBack.svg";
import styles from "./MyAssets.module.scss";
import { useHistory } from "react-router-dom";

const MyAssets = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.mainHeading}>
            <img src={BackImg} alt="" onClick={() => history.goBack()} />
            Opportunity Ledger
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableWrapper}>
              <HistoryTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAssets;
