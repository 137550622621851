import { HTTP_CLIENT } from "../utils/services";

const addDirectwireAddress = async (params) => {
  return await HTTP_CLIENT.post("/direct-wires/withdraw/address", params);
};
const getDirectwireAddress = async () => {
  return await HTTP_CLIENT.get("/direct-wires/withdraw/address");
};
const handleWithdrawDirectwire = async (params) => {
  return await HTTP_CLIENT.post("/direct-wires/withdraw", params);
};
const handleWithdrawLegacy = async (params) => {
  return await HTTP_CLIENT.post(
    "/request/create-legacy-withdraw-request",
    params
  );
};
const handleDirectwireHistory = async (id) => {
  return await HTTP_CLIENT.get(`direct-wires/withdraw/${id}`);
};
const updateDirectWireAddress = async (id, params) => {
  return await HTTP_CLIENT.put(`/direct-wires/withdraw/address/${id}`, params);
};
export {
  addDirectwireAddress,
  getDirectwireAddress,
  handleWithdrawDirectwire,
  handleDirectwireHistory,
  handleWithdrawLegacy,
  updateDirectWireAddress,
};
