import React, { useEffect } from "react";
import style from "./BarChart.module.scss";
import data2 from "./data1";
import data from "./data";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";

const BarChart = ({ defaultColor, dataFile, data, currencyGraph }) => {
  return (
    <div className={style.container}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={1000}
          height={300}
          data={currencyGraph}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="price" tick={false} axisLine={false} />

          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor="#F89F36" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#F89F36" stopOpacity={0} />
            </linearGradient>

            <linearGradient id="colorUvW" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor="#00EAEA" stopOpacity={1} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>

          <Tooltip />
          <Area
            className={style.wrapper}
            type="monotone"
            dataKey="price"
            stackId="1"
            stroke={`${defaultColor ? "#F89F36 " : "#00EAEA"}`}
            fill={`url(#${defaultColor ? "colorUv " : "colorUvW"})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
