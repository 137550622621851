import team from "../../../../Assets/image/teamprofile.png";
import hector from "../../../../Assets/image/hector.png";
const Data = [
  {
    id: 1,
    image: team,
    image2: hector,
    hectorprofile: "Graham Bells",
    desigination: "CEO",
    title: "Alex Wales",
    subHeading: "Founder & CEO",
    dec:
      "Dicta omnes ius ut, ei atomorum voluptatum definitionem per. Zril petentium sit at, vel at quis corrumpit. At facilisi per. Sed wisi persius ut.",
  },
  {
    id: 2,
    image: team,
    image2: hector,
    hectorprofile: "Graham Bells",
    desigination: "CEO",
    title: "Alex Wales",
    subHeading: "Founder & CEO",
    dec:
      "Dicta omnes ius ut, ei atomorum voluptatum definitionem per. Zril petentium sit at, vel at quis corrumpit. At facilisi per. Sed wisi persius ut.",
  },
  {
    id: 3,
    image: team,
    image2: hector,
    hectorprofile: "Graham Bells",
    desigination: "CEO",
    title: "Alex Wales",
    subHeading: "Founder & CEO",
    dec:
      "Dicta omnes ius ut, ei atomorum voluptatum definitionem per. Zril petentium sit at, vel at quis corrumpit. At facilisi per. Sed wisi persius ut.",
  },
];
export default Data;
