import axios from "axios";
// import {Alert} from 'react-native';
// import {store} from '../store';
// import defaultConfig from './config';
import { BASE_URL } from "./AppConstants";
import { Router } from "react-router-dom";
// const alert = useAlert();
import { useHistory } from "react-router-dom";

export const HTTP_CLIENT = axios.create({
  baseURL: BASE_URL,
});
// HTTP_CLIENT.interceptors.response.use(
//   res => {
//     return res;
//   },
//   err => {
//     if (err.message == 'Network Error') {
//         alert.show(
//         'Request Failed',
//         'Please check your internet and try again!',
//         [{text: 'OK', onclick: () => console.log('OK Pressed')}],
//       );
//     }
//     throw err;
//   },
// );

export const setupAxios = () => {
  HTTP_CLIENT.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response?.status == 401) {
        // window.location.reload();
        localStorage.clear();
      }
      throw err;
    }
  );
  HTTP_CLIENT.interceptors.request.use(
    (config) => {
      let authToken = localStorage.getItem("authToken");

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (err) => {
      Promise.reject(err);
    }
  );
};
