import React from "react";
import HistoryTable from "./TokenHistoryTable/AssetsTable";
import BackImg from "../../../../../../../Assets/icons/historyBack.svg";
import styles from "./TokenHistory.module.scss";
import { useHistory } from "react-router-dom";

const TokenHistory = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.mainHeading}>
            <img src={BackImg} alt="" onClick={() => history.goBack()} />
            Token History
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableWrapper}>
              <HistoryTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenHistory;
