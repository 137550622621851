import {
  SUBSCRIPTION_DATA,
  SUBSCRIPTION_CLEANUP,
  YOUR_SUBSCRIPTION,
  SUBSCRIPTION_STATUS,
} from "../types";

export const saveSubscriptionData = (data) => {
  return {
    type: SUBSCRIPTION_DATA,
    payload: data,
  };
};

export const yourSubscription = (data) => {
  return {
    type: YOUR_SUBSCRIPTION,
    payload: data,
  };
};

export const subscriptionStatus = (data) => {
  return {
    type: SUBSCRIPTION_STATUS,
    payload: data,
  };
};

export const subscriptionCleanUp = () => {
  return {
    type: SUBSCRIPTION_CLEANUP,
  };
};
