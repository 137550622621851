import React from "react";
import { Redirect, Route } from "react-router-dom";
// import { checkAuthState } from "../../services/authServces";
// import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // let auth = useSelector((state) => state.auth);
  // let res = checkAuthState(auth.password);

  let res = localStorage.getItem("authToken");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (res) return <Component {...props} />;
        if (!res) return <Redirect to="/" />;
        // if (res === "REGISTER") return <Redirect to="/register" />;
      }}
    />
  );
};

export default PrivateRoute;
