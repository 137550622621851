import {
  SAVE_SIGN,
  SAVE_FORM_DATA,
  USER_KYC,
  USER_CLEANUP,
  IMG_UPLOAD,
  IMG_UPLOAD_USER_INFO,
  KYC_CLEANUP_UP,
  USER_info,
  CREATE_ACCOUNT,
  SET_CHECKED,
  SEND_DOLLAR_HISTORY,
  SEND_TOKEN_HISTORY,
  USER_FCM_TOKEN,
} from "../types";

export const saveFormData = (data) => {
  return {
    type: SAVE_FORM_DATA,
    payload: data,
  };
};

export const saveFcmToken = (data) => {
  return {
    type: USER_FCM_TOKEN,
    payload: data,
  };
};

export const saveSign = (data) => {
  return {
    type: SAVE_SIGN,
    payload: data,
  };
};

export const userInfo = (data) => {
  return {
    type: USER_info,
    payload: data,
  };
};

export const setChecked = (data) => {
  return {
    type: SET_CHECKED,
    payload: data,
  };
};

export const userKyc = (data) => {
  return {
    type: USER_KYC,
    payload: {
      request: {
        method: "post",
        url: "gpalevel/save",
        data: data,
      },
    },
  };
};

export const imgUpload = (data) => {
  return {
    type: IMG_UPLOAD,
    payload: {
      request: {
        method: "post",
        url: "storage/upload",
        data: data,
      },
    },
  };
};

export const imgUploadviaUserInfo = (data) => {
  return {
    type: IMG_UPLOAD_USER_INFO,
    payload: {
      request: {
        method: "post",
        url: "user/update-profile-picture",
        data: data,
      },
    },
  };
};

export const userCleanUp = () => {
  return {
    type: USER_CLEANUP,
  };
};

export const KYCCleanUp = () => {
  return {
    type: KYC_CLEANUP_UP,
  };
};
// export const getUserInfo = () => {
//   return {
//     type: GET_USER_INFO,
//     payload: {
//       request: {
//         method: "get",
//         url: "user/info",
//       },
//     },
//   };
// };

// export const userCleanUp = () => {
//   return {
//     type: USER_CLEANUP,
//   };
// };
