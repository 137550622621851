import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { AiOutlineDelete } from "react-icons/ai";
import noDataGif from "../../../../../Assets/gif/noData.gif";
import Remmitanceplus from "../../../../../Assets/icons/remmitanceplus.svg";
import back from "../../../../../Assets/icons/historyBack.svg";

import { getDirectwireAddress } from "../../../../../services/withdrawService";
import { HTTP_CLIENT } from "../../../../../utils/services";
import Modal from "../../../../_Common/Modal/Modal";
import { Loader, WaveClone } from "../../../../_Common/loader/Loader";
import styles from "./withdrawAddress.module.scss";
import DirectwireAddress from "../../../Dashboard/DashboardInnerComponent/WithdrawalDirectwire/directwireAddress";
import NoData from "../../../../_Common/noData";
import SkeltonTable from "./skelton/skeltonTable";

const WithdrawAddresslist = ({ setRemittance }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listloading, setlistLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [todoId, setTodoId] = useState("");
  const [allAddress, setAllAddress] = useState("");
  const [addressData, setAddressData] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const alert = useAlert();

  //------------------get all Address-----------------//

  const allWithdrawAddress = async () => {
    setlistLoading(true);
    try {
      const res = await getDirectwireAddress();
      setAllAddress(res?.data?.data);
      setlistLoading(false);
    } catch (error) {
      setlistLoading(false);
    }
  };
  useEffect(() => {
    allWithdrawAddress();
  }, [reload]);

  //-----------------Delete Address-----------------//

  const deleteAddress = async (id) => {
    try {
      setLoading(true);
      const response = await HTTP_CLIENT.delete(`direct-wires/withdraw/${id}`);
      setDeleteShow(false);
      setReload(!reload);
      setLoading(false);
      setAddressData(null);
      alert.show(response?.data?.message, { type: "success" });
    } catch (error) {
      alert.show(error?.response?.data?.message, { type: "error" });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (deleteShow) {
      setShow(false);
    }
  }, [deleteShow]);
  const loadingSkelton = ["1", "2", "3"];
  return (
    <>
      <Modal
        visible={show}
        btn
        onClose={() => {
          setShow(false);
          setAddressData(null);
        }}
      >
        <DirectwireAddress
          addressData={addressData}
          setAllAddress={setAllAddress}
          setShow={setShow}
        />
      </Modal>
      <Modal
        visible={deleteShow}
        btn
        onClose={() => {
          setDeleteShow(false);
          // onClose();
        }}
      >
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Are you sure you want to delete this Address?</p>
          </div>
          <div className={styles.buttonset}>
            {loading ? (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => {
                  // DeleteData();
                }}
              >
                <WaveClone />
              </button>
            ) : (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => {
                  // DeleteData();
                  deleteAddress(todoId);
                }}
              >
                Delete
              </button>
            )}

            <button
              className={styles.Delete}
              style={{ marginLeft: "3rem" }}
              onClick={() => setDeleteShow(false)}
            >
              {" "}
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.addbutton}>
            <div className={styles.backWrapper}>
              <img src={back} alt="back" onClick={() => setRemittance(0)} />
              <label>Direct Wire Remittance Address</label>
            </div>
            <img
              onClick={() => setShow(true)}
              src={Remmitanceplus}
              alt="plus"
            />
          </div>

          <div className={styles.tableWrapper}>
            {listloading ? (
              <table>
                {loadingSkelton?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <SkeltonTable />
                    </tr>
                  );
                })}
              </table>
            ) : allAddress == 0 ? (
              <div className={styles.noDataWrapper}>
                <NoData />
              </div>
            ) : (
              <table>
                <tr>
                  <th>Name on Bank Account</th>
                  <th>Account Number</th>
                  <th>Zellepay Address</th>
                  <th></th>
                </tr>

                {allAddress?.map((item, index) => (
                  <tr
                    onClick={() => {
                      setAddressData(item);
                      setShow(true);
                    }}
                  >
                    <td>{item?.accountTitle}</td>
                    <td>{item?.accountNumber}</td>
                    <td>{item?.zellePay}</td>
                    <td
                      onClick={() => {
                        setDeleteShow(true);
                        setTodoId(item._id);
                      }}
                    >
                      <AiOutlineDelete className={styles.icon} />
                    </td>
                  </tr>
                ))}
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawAddresslist;
