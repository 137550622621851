import React, { useEffect, useState } from "react";
import styles from "./settings.module.scss";
import showImg from "../../../../Assets/icons/show.svg";
import hideImg from "../../../../Assets/icons/hide.svg";
import Modal from "../../../_Common/Modal/Modal";
import InputField from "../../../_Common/input/input";
import { HTTP_CLIENT } from "../../../../utils/services";
import { WaveClone } from "../../../_Common/loader/Loader";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import {
  remittanceCleanUp,
  saveBankDetail,
} from "../../../../redux/action/remittanceAction";
import { getBankDetail } from "../../../../services/paymentServices";
import {
  KYCCleanUp,
  userCleanUp,
  userInfo,
} from "../../../../redux/action/userAction";
import { currencyLogoutCleanUp } from "../../../../redux/action/currencyDetailAction";
import { swapLogoutCleanUp } from "../../../../redux/action/sawapAction";
import { subscriptionCleanUp } from "../../../../redux/action/subscriptionAction";
const ChangePasswordScreen = ({
  showChangePassword,
  closeChangePasswordModal,
}) => {
  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  const [active, setactive] = useState(true);
  const [passwordshow, setPasswordshow] = useState(false);
  const [passwordshow2, setPasswordshow2] = useState(false);
  const [passwordshow3, setPasswordshow3] = useState(false);
  const [load, setload] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    resetpassword: "",
    olPasswordValidation: "",
    passwordValidation: "",
    confirmValidation: "",
  });

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [name === "newPassword" ? "passwordValidation" : "confirmValidation"]: "",
      [name === "newPassword" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [name === "newPassword" ? "confirmValidation" : "passwordValidation"]: "",
    }));
    if (inputs?.newPassword.length > 8 && inputs?.newPassword.length < 16) {
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "",
      }));
    }
    if (inputs?.oldPassword.length > 8 && inputs?.oldPassword.length < 16) {
      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "",
      }));
    }
  };

  let isValidated = () => {
    let response = true;
    if (inputs?.oldPassword?.length > 16 || "") {
      response = false;

      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "Password can't be greater than 16.",
      }));
    }
    if (inputs?.oldPassword?.length < 8 || "") {
      response = false;

      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "Password can't be less than 8.",
      }));
    }
    if (!inputs?.oldPassword) {
      response = false;

      setInputs((prev) => ({
        ...prev,
        olPasswordValidation: "Password Field can't be empty.",
      }));
    }
    if (inputs?.newPassword?.length > 16 || "") {
      response = false;

      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be greater than 16.",
      }));
    }
    if (inputs?.resetpassword?.length > 16 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be greater than 16.",
      }));
    }
    if (inputs?.newPassword?.length < 8 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password can't be less than 8 characters.",
      }));
    }
    if (inputs?.resetpassword?.length < 8 || "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat password can't be less than 8 characters.",
      }));
    }
    if (!inputs.newPassword) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password Field can't be empty.",
      }));
    }
    if (!inputs.resetpassword) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Repeat Password can't be empty.",
      }));
    }
    if (
      inputs?.newPassword !== "" &&
      inputs?.resetpassword !== "" &&
      inputs?.newPassword !== inputs?.resetpassword
    ) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        confirmValidation: "Passwords do not match.",
      }));
    }
    return response;
  };

  let handleSubmit = async (e) => {
    if (!isValidated()) {
      return;
    }
    setload(true);
    let data = {
      oldPassword: inputs?.oldPassword,
      newPassword: inputs?.newPassword,
    };
    try {
      let res = await HTTP_CLIENT.post(`auth/change-password`, data);

      dispatch(userInfo(res?.data));
      setload(false);
      alert.show("Password updated successfully", {
        type: "success",
      });
      localStorage?.clear();
      closeChangePasswordModal();
      history.push("/");
      // setactive(false);
    } catch (err) {
      if (err?.response?.data?.message) {
        alert.show(err.response.data.message, { type: "error" });
      }
      setload(false);
    }
  };

  const fetchBankDetail = async () => {
    try {
      const response = await getBankDetail();
      dispatch(saveBankDetail(response?.data));
    } catch (error) {
      let modifiyError = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Something went wrong";
      alert.show(modifiyError, { type: "error" });
    }
  };

  useEffect(() => {
    fetchBankDetail();
  }, []);
  return (
    <>
      <Modal visible={showChangePassword}>
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Change Password</p>
          </div>
          <div className={styles.inputfields}>
            <InputField
              onclick={(e) => handleInputChange(e)}
              Name="oldPassword"
              value={inputs?.oldPassword}
              inputName="Current Password"
              name="Old Password"
              type={passwordshow ? "text" : "password"}
              img2={passwordshow ? showImg : hideImg}
              changeOne={() => setPasswordshow(!passwordshow)}
            />

            {inputs?.olPasswordValidation ? (
              <p style={{ color: "rgb(160, 4, 4)" }}>
                {inputs?.olPasswordValidation}
              </p>
            ) : (
              ""
            )}
            <InputField
              onclick={(e) => handleInputChange(e)}
              Name="newPassword"
              inputName="New Password"
              name="New Password"
              type={passwordshow2 ? "text" : "password"}
              img2={passwordshow2 ? showImg : hideImg}
              changeOne={() => setPasswordshow2(!passwordshow2)}
            />
            {inputs?.passwordValidation ? (
              <p style={{ color: "rgb(160, 4, 4)" }}>
                {inputs?.passwordValidation}
              </p>
            ) : (
              ""
            )}
            <InputField
              onclick={(e) => handleInputChange(e)}
              Name="resetpassword"
              inputName="Confirm New Password"
              name="Confirm password"
              type={passwordshow3 ? "text" : "password"}
              img2={passwordshow3 ? showImg : hideImg}
              changeOne={() => setPasswordshow3(!passwordshow3)}
            />
            {inputs?.confirmValidation ? (
              <p style={{ color: "rgb(160, 4, 4)" }}>
                {inputs?.confirmValidation}
              </p>
            ) : (
              ""
            )}
          </div>
          {load ? (
            <button>
              <WaveClone />
            </button>
          ) : (
            <button onClick={handleSubmit}>Confirm</button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordScreen;
