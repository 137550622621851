import React, { useMemo } from "react";
import style from "./Table.module.scss";
// import Data from "../../../Data/Dashboard/data";
// import DataSec from "../../../Data/Dashboard/data2";
import { Loader } from "../../_Common/loader/Loader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Table({
  dataFile,
  recieveData,
  currencyData,
  CQRData,
  load,
  hideBalance,
  isHideBalance,
}) {
  // const dataobj = {
  //   1: Data,
  //   2: DataSec,
  // };

  const arrToDisplay = useMemo(() => {
    return currencyData?.map((currency) => {
      let a = currency?.balance * currency?.marketData?.rate;
      currency.USD = a;
      return currency;
    });
  }, [currencyData]);

  var arrCQRDisplay = useMemo(() => {
    let arr = CQRData?.map((CQR) => {
      let a = parseFloat(CQR?.balance) * CQR.price;
      CQR.USD = isNaN(a) ? 0 : a;
      return CQR;
      // array.push(...currencyData, { useBalance: a });
    });

    arr = arr?.sort((v1, v2) => v1.orderIndex - v2.orderIndex).map((v) => v);

    return arr;
  }, [CQRData]);

  // const data = dataobj[dataFile];

  const handleclick = (data, name) => {
    if (name === "currency") {
      recieveData(data);
    } else {
      recieveData(data);
    }
  };
  function numberWithCommas(n) {
    var parts = n.toString().split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "")
    );
  }
  return load ? (
    <>
      <Skeleton height={40} baseColor="#20233F" />
      <Skeleton height={70} style={{ marginTop: 32 }} baseColor="#20233F" />
      <Skeleton height={70} baseColor="#20233F" style={{ marginTop: 32 }} />
      <Skeleton height={70} style={{ marginTop: 32 }} baseColor="#20233F" />
      <Skeleton height={70} baseColor="#20233F" style={{ marginTop: 32 }} />
    </>
  ) : (
    <div className={style.container}>
      <div className={style.wrapper}>
        <table cellspacing="0" cellpadding="0">
          <tr>
            <td>Name</td>
            <td>{CQRData ? "Symbol " : ""}</td>
            <td>Balance</td>
            <td>Value in USD</td>
            <td>{CQRData ? "Value" : "Value"}</td>
            <td>{CQRData ? "" : "24h %"}</td>
            <td>{CQRData ? "" : "Total Volume"}</td>
            {/* <td>{CQRData ? "" : "Low 24h"}</td> */}
            <td>{CQRData ? "" : "Market Cap"}</td>
            {CQRData ? <td></td> : ""}

            {/* <td>Circulating Supply</td> */}
          </tr>
          {CQRData
            ? arrCQRDisplay?.map((cqr, index) => {
                return (
                  <tr onClick={() => handleclick(cqr, "currency")} key={index}>
                    <td>
                      <div className={style.firstimage}>
                        <img src={cqr?.icon?.url} alt="" />
                        <span>{cqr?.shortName}</span>
                      </div>
                    </td>
                    <td>
                      <img
                        src={cqr?.displaySymbol}
                        style={{ height: "22px" }}
                      />
                    </td>
                    <td>
                      {Number(cqr?.balance) == "0"
                        ? hideBalance(Number(cqr?.balance))
                        : cqr?.coinSymbol === "Q"
                        ? numberWithCommas(
                            hideBalance(
                              Number(cqr?.balance)?.toFixed(cqr?.decimals)
                            )
                          )
                        : numberWithCommas(
                            hideBalance(
                              Number(cqr?.balance)?.toFixed(cqr?.decimals)
                            )
                          )}

                      <label></label>
                    </td>

                    <td>
                      {isHideBalance ? "*" : "$"}{" "}
                      {Number(cqr?.USD) == "0"
                        ? numberWithCommas(hideBalance(Number(cqr?.USD)))
                        : cqr?.coinSymbol === "Q"
                        ? numberWithCommas(
                            hideBalance(
                              Number(cqr?.USD)?.toFixed(cqr?.decimals)
                            )
                          )
                        : numberWithCommas(
                            hideBalance(
                              Number(cqr?.USD)?.toFixed(cqr?.decimals)
                            )
                          )}
                    </td>
                    <td>{Number(cqr?.price)?.toFixed(cqr?.decimals)} </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    {/* <td>{m?.marketData?.rate}</td>
      <td>{m?.marketData?.change24h}</td>
      <td>{m?.marketData?.high24h}</td> */}
                    {/* <td>
        <span>{m?.marketData?.low24h}</span>
        <p>{m.marketData.marketCap}</p>
      </td>
      <td>
        <div className={style.bottomimage}>
          {m?.marketData?.marketCap}
          <img
            src={img2}
            alt=""
          />
        </div>
      </td> */}
                  </tr>
                );
              })
            : arrToDisplay?.map((currency, index) => {
                return (
                  <tr onClick={() => handleclick(currency, "")} key={index}>
                    <td>
                      <div className={style.firstimage}>
                        <img src={currency?.icon?.url} alt="" />
                        <span>{currency?.name}</span>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      {Number(currency?.balance) == "0"
                        ? numberWithCommas(
                            hideBalance(Number(currency?.balance))
                          )
                        : numberWithCommas(
                            hideBalance(Number(currency?.balance)?.toFixed(5))
                          )}

                      <label></label>
                    </td>
                    <td>
                      {" "}
                      {isHideBalance ? "*" : "$"}{" "}
                      {Number(currency?.USD) == "0"
                        ? numberWithCommas(hideBalance(Number(currency?.USD)))
                        : numberWithCommas(
                            hideBalance(Number(currency?.USD)?.toFixed(5))
                          )}
                    </td>
                    <td>
                      $
                      {currency?.marketData?.rate
                        .toFixed(3)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td
                      style={{
                        color:
                          currency?.marketData?.changePercentage24h > 0
                            ? "green"
                            : "red",
                      }}
                    >
                      {currency?.marketData?.changePercentage24h
                        .toFixed(4)
                        ?.toString()}
                    </td>
                    <td>
                      {currency?.marketData?.totalVolume

                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>

                    <td>
                      <div className={style.bottomimage}>
                        {currency?.marketData?.marketCap
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <img
                          // src={img2}
                          alt=""
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
        </table>
      </div>
    </div>
  );
}

export default Table;
