import React, { useState } from "react";
import styles from "./YourSubscriptions.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { HTTP_CLIENT } from "../../../../../utils/services";
import { useAlert } from "react-alert";
import { userInfo } from "../../../../../redux/action/userAction";
import { BouncingBalls } from "react-cssfx-loading";
import { Loader } from "../../../../_Common/loader/Loader";
import { subscriptionStatus } from "../../../../../redux/action/subscriptionAction";
import Qsymbol from "../../../../../Assets/image/qsymbol.png";
import moment from "moment";
import Modal from "../../../../_Common/Modal/Modal";
import BackTo from "../../../../../Assets/icons/backk.svg";
import NoData from "../../../../_Common/noData";

const YourSubscriptions = ({
  data,
  subLoading,
  Status,
  setYoursubscription,
  setloading,
  setCurrentSubscription,
}) => {
  const subscriptionSelector = useSelector(
    (state) => state.subscription.yourSubscrption
  );

  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState("");

  const [cancelSub, setCancelSub] = useState(false);

  //-------------cancel Subscription----------------//

  const handleCancel = async () => {
    setloading(true);
    let Array = [];
    setLoading(true);
    try {
      const res = await HTTP_CLIENT.post(
        `/subscription/cancel/${subscriptionData?._id}`
      );
      setCancelSub(true);
      if (
        res.data.message ===
        "Subscription has been cancelled successfully but you can still buy NFTs until your current acquired plan expired"
      ) {
        alert.show(
          "Subscription has been cancelled successfully. However, you can still acquire AP tokens until your current subscription expires.",
          { type: "success" }
        );
      } else {
        alert.show(res.data.message, { type: "success" });
      }

      const resp = await HTTP_CLIENT.get("subscription/user-subscriptions");
      setYoursubscription(resp.data);
      await dispatch(subscriptionStatus(Array));
      setLoading(false);
      setDeleteShow(false);
      setloading(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setLoading(false);
      setDeleteShow(false);
      setloading(false);
    }
  };
  const cancelPopup = (data) => {
    setSubscriptionData(data);
    setDeleteShow(true);
  };
  return (
    <>
      <Modal
        visible={deleteShow}
        btn
        onClose={() => {
          setDeleteShow(false);
          // onClose();
        }}
      >
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Are you sure you want to cancel your subscription?</p>
          </div>
          <div className={styles.buttonset}>
            {loading == true ? (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
              >
                <BouncingBalls color="white" duration="0.5s" />
              </button>
            ) : (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel Subscription
              </button>
            )}

            <button
              className={styles.Delete}
              style={{ marginLeft: "3rem" }}
              onClick={() => setDeleteShow(false)}
            >
              Back
            </button>
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        <div className={styles.widthWrapper}>
          <div className={styles.headingWrapper}>
            <img
              src={BackTo}
              alt="back arrow"
              onClick={() => setCurrentSubscription(false)}
            />
            <div className={styles.textWrapper}>Your Subscriptions</div>
          </div>
        </div>
        <div className={styles.cardsWrapper}>
          {subLoading ? (
            <Loader />
          ) : data?.length === 0 ? (
            <NoData />
          ) : (
            data?.map((data) => {
              return (
                <div className={styles.Card}>
                  <div className={styles.cardWrapper}>
                    <div className={styles.imgWrapper}>
                      <img src={data?.planId?.logo} alt="" />
                    </div>
                    <div className={styles.heading}>
                      <label style={{ color: "#40a1dc" }}>Name</label>
                      <p>{data?.planId?.title}</p>
                    </div>
                    <div className={styles.peragraph}>
                      <div className={styles.leftSide}>
                        <label>Subscribe date</label>
                        <p>
                          {moment(data?.subscriptionOn).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                          {/* {data?.subscriptionOn} */}
                        </p>
                      </div>
                      <div className={styles.rightSide}>
                        <label>Duration</label>
                        <p>
                          {data?.planId?.duration === "month"
                            ? "Monthly"
                            : data?.planId?.duration}
                        </p>
                      </div>
                    </div>
                    <div className={styles.peragraph}>
                      <div className={styles.leftSide}>
                        <label>Value</label>
                        <div style={{ display: "flex", columnGap: "10px" }}>
                          <img
                            style={{
                              width: "17px",
                              height: "17px",
                              marginTop: "6px",
                            }}
                            src={Qsymbol}
                          />{" "}
                          <p> {data?.planId?.priceUSD}.00</p>
                        </div>
                      </div>

                      <div className={styles.rightSide}>
                        <label>Expiry Date</label>

                        <p>
                          {moment(data?.renewOn).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={styles.btn}>
                      {!data?.isCancelled &&
                      data?.planId?.duration !== "lifetime" ? (
                        <button onClick={() => cancelPopup(data)}>
                          Cancel Subscription
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default YourSubscriptions;
