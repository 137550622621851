import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import emptyImg from "../../../../../../../Assets/gif/empty.svg";
import { SocketContext } from "../../../../../../../Context/Socket";
import { getCurrencyDetail } from "../../../../../../../redux/action/currencyDetailAction";
import CQRComponent from "../../../../../../_Common/CQRComponent/CQRComponent";
import styles from "./AssetsTable.module.scss";

const HistoryTable = ({ recieveData, hideBalance, isHideBalance, opper }) => {
  const alert = useAlert();

  const [textChange, setTextChange] = useState(false);
  const [indexChange, setIndexChange] = useState(null);
  const socket = useContext(SocketContext);
  const allNfts = useSelector(
    (state) => state?.currency?.currencyInfo?.nftWallets
  );
  const userEmail = useSelector((state) => state?.user?.userInfo?.email);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   AssetsHistory();
  // }, []);
  //------nftBalanceUpdated Socket------
  useEffect(() => {
    socket.on(userEmail, (data) => {
      if (data.type == "nftBalanceUpdated") {
        dispatch(getCurrencyDetail());
        return;
      }
    });
  }, []);

  //------nftBalanceUpdated Socket------
  // const AssetsHistory = async (data) => {
  //   try {
  //     if (data) {
  //       setLoading(false);
  //     } else {
  //       setLoading(true);
  //     }
  //     setEmptyArray(true);
  //     let allNFT = await HTTP_CLIENT.get("/nft-token");
  //     let response = await HTTP_CLIENT.get("nft-wallet/user-nfts");
  //     let nftWallets = response.data.nftWallets;
  //     let loanWallets = response.data.loanWallets;
  //     let completedLoanWallets = response.data?.completedLoanWallets;

  //     const final = allNFT.data.map((wall) => {
  //       let nft = nftWallets.find((nft) => nft.nftToken._id == wall._id);
  //       let loan = loanWallets.find((loan) => loan.token == wall._id);
  //       let completed = completedLoanWallets.find(
  //         (comp) => comp.token._id == wall._id
  //       );
  //       let returnObj;
  //       if (nft) {
  //         returnObj = { ...nft, nftToken: wall };
  //       } else {
  //         returnObj = {
  //           balance: 0,
  //           nftIndex: wall.index,
  //           nftToken: wall,
  //         };
  //       }
  //       if (loan) {
  //         returnObj.loanWallet = loan;
  //       }
  //       if (completed) {
  //         returnObj.balance = Number(nft.balance) + Number(completed.balance);
  //       }
  //       return returnObj;
  //     });

  //     // let Loanresponse = await HTTP_CLIENT.get("nft-wallet/user-nfts");
  //     setAssetsData(final);
  //     dispatch(oppertunityToken(final));
  //     // // setLoanData(response?.data?.loanWallets.reverse());

  //     // alert.show("Assets retrieved successfully", { type: "success" });
  //     setEmptyArray(false);
  //     setLoading(false);
  //   } catch (err) {
  //     alert.show(err?.response?.data?.message, { type: "error" });
  //     setLoading(false);
  //   }
  // };
  function numberWithCommas(n) {
    var parts = n.toString().split(".");
    return (
      parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : ".00")
    );
  }

  const handleclick = (data, name) => {
    if (name === "currency") {
      recieveData(data);
    } else {
      recieveData(data);
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <table cellspacing="0" cellpadding="0">
            <tr>
              <td>Opportunity</td>
              <td>Balance</td>

              <td>
                C<CQRComponent />R Value
              </td>
              <td>Per Unit</td>
              <td>Acquisition Date</td>
              <td></td>
            </tr>
            {allNfts?.length == 0 ? (
              <div className={styles.emptyImg}>
                <img src={emptyImg} alt="" />
              </div>
            ) : (
              allNfts?.map((item, index) => {
                return (
                  <tr key={item?._id} onClick={() => handleclick(item)}>
                    <td>
                      <div className={styles.nameWrapper}>
                        <img src={item?.image} alt="" />
                        <div>
                          <div className={styles.heading}>{item?.name}</div>
                          <div
                            className={`${
                              textChange && index == indexChange
                                ? styles.changedDescription
                                : styles.description
                            }`}
                          >
                            {item?.description}
                          </div>
                          {/* {item?.nftToken?.description.length > 35 && (
                              <span
                                style={{ color: "#fff" }}
                                onClick={() => {
                                  setTextChange(!textChange);
                                  setIndexChange(index);
                                }}
                              >
                                {textChange && index == indexChange
                                  ? "See less"
                                  : "See more"}
                              </span>
                            )} */}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className={styles.amountWrapper}>
                        {/* {isHideBalance ? "*" : "$"}{" "} */}
                        {hideBalance(item?.balance)}&nbsp;
                        {!isHideBalance ? "Units" : "*"}
                        {/* {item?.balance ? (
                            <>
                              {" "}
                              <span style={{ color: "rgb(194, 67, 67)" }}>
                                +{item?.balance} Loan
                              </span>
                              <p style={{ fontSize: "12px" }}>
                                <label style={{ fontSize: "15px" }}>
                                  Loan Expiry
                                </label>
                                &nbsp;
                                {moment(item?.loanWallet?.dueDate).format(
                                  "MMMM Do YYYY,h:mm:ss a"
                                )}
                              </p>
                            </>
                          ) : (
                            ""
                          )} */}
                        {item?.dueDate ? (
                          <div className={styles.amountWrapper}>
                            {moment(item?.dueDate).format(
                              "MMMM Do YYYY,h:mm:ss a"
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>

                    <td>
                      <div className={styles.estApyWrapper}>
                        <CQRComponent />
                        &nbsp;
                        {Number(item?.pricePerShare) * Number(item?.balance)}
                        {/* {item?.balance ? (
                            <p style={{ color: "rgb(194, 67, 67)" }}>
                              ( +
                              {Number(item?.balance) *
                                Number(item?.pricePerShare)}
                              )
                            </p>
                          ) : (
                            ""
                          )} */}
                      </div>
                    </td>
                    <td>
                      <div className={styles.estApyWrapper}>
                        <CQRComponent /> &nbsp;
                        {numberWithCommas(item?.pricePerShare)}
                      </div>
                    </td>
                    <td>
                      <div className={styles.estApyWrapper}>
                        {moment(item?.createdAt).format("MMMM Do YYYY")}
                      </div>
                    </td>
                    <td style={{ color: "#b1fa42" }}>
                      <div className={styles.seemore}>See More</div>
                    </td>
                  </tr>
                );
              })
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default HistoryTable;
