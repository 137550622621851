import React from "react";
import Swap from "../../../../../_Common/DashboardComponents/CryptoToAssets/swap";
import Btc from "../../../../../../Assets/icons/Btc.svg";
import ETH from "../../../../../../Assets/icons/ETH.svg";

function FiatSwap() {
  return (
    <div>
      <Swap
        topHeading="I have 1.8924 Bitcoin"
        TopImg={Btc}
        TopP="BTC"
        topLabel="0.65 BTC"
        bottomHeading="I want Ethereum"
        BottomImg={ETH}
        bottomP="ETH"
        bottomLabel="0.65 ETH"
        swapHistoryUrl={false}
      />
    </div>
  );
}

export default FiatSwap;
