import styles from "./popup.module.scss";
import A from "../../../../Assets/symbols/aSymbolBlack.png";
import Q from "../../../../Assets/symbols/qSymbolBlack.png";

const opportunityTokens = () => {
  const url = "https://www.law.cornell.edu/uscode/text/26/508";
  const url2 = "https://www.law.cornell.edu/uscode/text/26/6033";
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <label>CQR Opportunity Tokens</label>
          <p>
            CQR Vest’s <span>“Opportunity Tokens”</span> represent Asset Pools
            or (“AP” / “
            <img src={A} alt="" />
            ”).These are private Non-Fungible Tokens generally divided (i.e.
            1,000,000 to 1,000,000,000 fractional units) and distributed as
            Fractional Non-Fungible Tokens (“FNFT”). Each FNFT smart covenant
            represents the lawful exchange of a “Digital Certificate of
            Authenticity” representing one Beneficial Interest Unit (“BIU”)
            formed by Private Association Title Deed and corresponding
            Relationship & Royalty Sharing Covenant (“RRSC”) established upon
            the acquisition of a real-world asset(s). Royalties are determined
            by the FNFT’s corresponding real world cash flow asset after
            acquisition in accordance with the terms outlined by the Private
            Member Association’s CQR Vault RRSC Exhibit “1.” The CQR Vault
            Social Exchange platform is revolutionizing private equity benefits
            as a function of cash flow! CQR Vault Tokens are not classified as
            currencies, nor are they considered security instruments. In fact,
            they are legally defined as private “property.” Generally AP Token
            royalties are calculated from the ratio of AP Tokens held by each
            qualified Participating Procurement Member (“PPM”), and disseminated
            as a value percentage weighed in ratio against the number of tokens
            distributed to all bona fide PPMs at the time royalty calculations
            and settlements are made. Ongoing royalty values are generated and
            determined from the net-positive cash flow created by each
            respective AP F.B.O. Private Member Association independently
            managed by its Board of Directors. All PPM royalties are qualified
            U.S. Code Title 26 §508(c)(1)(A){" "}
            <a href={url} target="_blank" rel="noreferrer">
              &nbsp;(Click Here)
            </a>{" "}
            and§6033(a)(3)(A){" "}
            <a href={url2} target="_blank" rel="noreferrer">
              &nbsp;(Click Here)
            </a>{" "}
            distributions, made in the form of “Mandatory Excepted” CQR Vault
            Liquidity Tokens, and credited to each PPM’s CQR Vault pursuant
            further self-directed redemption rights held in RRSC.
            {/* These
            are public Non-Fungible Tokens generally divided (i.e. 1,000,000 to
            1,000,000,000 fractional units) and distributed as Fractional
            Non-Fungible Tokens (“FNFT”). Each FNFT smart contract represents
            the lawful exchange of a “Certificate of Authenticity” of a
            Beneficial Interest Unit (“BIU”) formed by a Trust’s Title Deed and
            Royalty Benefit Covenant which is established upon the acquisition
            of a real world asset. Royalties are determined by the FNFT’s
            corresponding real world cash flow asset after acquisition in
            accordance with the terms outlined by the Trust. The CQR Vault
            Decentralized Finance and CQR Vest Decentralized Exchange platforms
            are certainly going to revolutionize the use of private equity in
            real estate and business as a function of residual cash flow! CQR
            Tokens are not classified as currencies nor are they considered
            security instruments; in fact, they are actually legally defined as
            “property.” Each CQR Token represents the allocation of measured
            benefit in distribution of any Asset Pool Royalties calculated
            according to percentage that each qualified Participating
            Procurement Member (“PPM”) legally a Bona Fide Participant Member
            holds. Residual royalties are generated from the net-positive cash
            flows created by each CQR syndicated AP Ecclesiastical Trust
            formation, and distributed to our private Member base by right
            of&nbsp;
            <span>USC Title 26 § 508(c)(1)(A)</span>
            <a href={url} target="_blank" rel="noreferrer">
              &nbsp;(Click Here)
            </a>{" "}
            and
            <span>§ 6033(a)(3)(A)</span>&nbsp;
            <a href={url2} target="_blank" rel="noreferrer">
              (Click Here)
            </a>
            &nbsp; in the form of “Mandatory Tax-Excepted” digital{" "}
            <img src={Q} alt="" /> Tokens remitted to each PPM’s CQR Vault
            digital vault for their further self-directed redemption rights. */}
          </p>
        </div>
      </div>
    </>
  );
};
export default opportunityTokens;
