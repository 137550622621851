import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSkelton = () => {
  return (
    <div>
      <Skeleton height={250} width={260} baseColor="#20233F" />
      <Skeleton height={24} style={{ marginTop: 22 }} baseColor="#20233F" />
      <Skeleton
        height={20}
        baseColor="#20233F"
        style={{ marginTop: 15, marginBottom: 15 }}
      />
      <Skeleton
        height={14}
        count={2}
        style={{ marginTop: 4 }}
        baseColor="#20233F"
      />
      <Skeleton height={41} baseColor="#20233F" style={{ marginTop: 14 }} />
    </div>
  );
};

export default CardSkelton;
