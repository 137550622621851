import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { userInfo } from "../../../../../../redux/action/userAction";
import { handleDirectWire } from "../../../../../../services/paymentServices";
import { HTTP_CLIENT } from "../../../../../../utils/services";
import CheckRemittanceAddressModal from "../../../../../_Common/checkRemittanceAddressModal/OpenAccount";
import ConfirmDirectWire from "../../../../../_Common/confirmDirectWire/confirmDirectWire";
import CQRComponent from "../../../../../_Common/CQRComponent/CQRComponent";
import Input from "../../../../../_Common/input/input";
import { Loader } from "../../../../../_Common/loader/Loader";
import styles from "./directWire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../../../../utils/AppConstants";
import { despositLegacy } from "../../../../../../services/depositService";
import moment from "moment";
import { getAssetsToAssetsSawap } from "../../../../../../redux/action/sawapAction";

const Directwire = () => {
  const url = "https://transfer.app.box.com/s/xm2jsd5di6w8e82oj3z88jv3rwy9gv2u";

  // const CountryOptions = CountryOption;
  // const [PhyCountry, setPhyCountry] = useState({ name: "United States" });
  const location = useLocation();
  const alert = useAlert();
  const history = useHistory();
  let [agree, setAgree] = useState(false);
  let [showText, setShowText] = useState(false);
  let [dueDate, setDueDate] = useState("");
  let [qAmount, setQAmount] = useState("");

  const [swap, setSwap] = useState("deposit");
  const [legacySignature, setLegacySignature] = useState("");

  const [detail, setDetail] = useState({});
  // const [bankDetail, setBankDetail] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const currencySelector = useSelector((state) => state.currency);
  const selected = currencySelector?.currencyInfo?.nativeWallets?.filter(
    (item) => item?.coinSymbol === "Q"
  );
  const isActivate = useSelector(
    (state) => state?.user?.userInfo?.isWalletActivated
  );
  const { signleRemittanceAddress, bankDetail } = useSelector(
    (state) => state?.remittance
  );
  const sawapSelector = useSelector((state) => state?.sawap);

  let { amount, to, tokenAmount, tokenRate } = location?.state?.detail;
  let { activationAmount, type } = location?.state;
  const [showConfirm, setShowConfirm] = useState(false);
  const handleSubmit = async () => {
    let params = {
      amount: amount,
      tokenAmount: tokenAmount,
      tokenRate: Number(tokenRate),

      token: to?.to?.coin ? to?.to?.coin : to,
      type,
      remittanceAddress: signleRemittanceAddress._id,
    };
    let param = {
      coin: to?.to?.coin,
      tokenAmount: tokenAmount?.toString(),
      tokenRate: tokenRate,

      amount: String(amount),
      to: to?.to?.coin ? to?.to?.coin : to,
      type: "legacy",
      remittanceAddress: signleRemittanceAddress._id,
    };

    if (agree === false) {
      setShowText(true);
    } else {
      setShowText(false);
    }
    if (agree === true) {
      setloading(true);

      try {
        if (location?.state?.detail?.to?.to?.type === "sdira") {
          const res = await despositLegacy(param);
          setDetail(res?.data?.data?.data);
          setLegacySignature(res?.data?.data?.remittance?.signatureUrl);
          alert.show(res.data.message, { type: "success" });
          if (res) {
            setShowConfirm(true);
          }
        } else {
          const response = await handleDirectWire(params);
          setDetail(response.data.data);
          alert.show(response.data.message, { type: "success" });
          if (response) {
            setShowConfirm(true);
          }
        }
        await getUserInfo();

        setloading(false);
        setAgree(false);
      } catch (error) {
        alert.show(error?.response?.data?.message, { type: "error" });
        setloading(false);
        setAgree(false);
      }
    }
  };
  const getUserInfo = async () => {
    try {
      let resr = await HTTP_CLIENT.get(`${BASE_URL}user/info`);
      if (resr) {
        dispatch(userInfo(resr.data.user));
      }
    } catch (error) {
      // alert.show(error?.response?.data?.message, { type: "error" });
    }
  };
  const checkboxValue = () => {
    setAgree((prevCheck) => !prevCheck);
    if (agree === false) setShowText(false);
  };
  const directWireFee = useSelector((state) => state?.remittance?.bankDetail);
  useEffect(() => {
    const currentDate = moment();

    const eightDaysFromToday = currentDate?.add(
      Number(directWireFee?.directWireExpiryDays),
      "days"
    );
    setDueDate(eightDaysFromToday);
    if (!signleRemittanceAddress) {
      setShowModal(true);
    }
  }, []);

  //----------------get Q amount if wallet is not activated-----------------------//

  const getQAmount = () => {
    let data = {
      selected: selected[0],
      amount: "1",
    };
    try {
      dispatch(getAssetsToAssetsSawap(data, swap));
    } catch (error) {}
  };

  useEffect(() => {
    getQAmount();
  }, []);
  useEffect(() => {
    setQAmount(sawapSelector?.assetSwaoInfo * activationAmount);
  }, [sawapSelector]);

  return (
    <>
      <CheckRemittanceAddressModal
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
      {!showConfirm ? (
        <div className={styles.container}>
          {loading ? (
            <div className={styles.loader}>
              {" "}
              <Loader />{" "}
            </div>
          ) : (
            <div className={styles.wrapper}>
              <div className={styles.leftSide}>
                <div className={styles.leftHeading}>Remittance details</div>
                <div className={styles.inputField}>
                  <Input
                    name="First name *"
                    inputName="John"
                    type="text"
                    disable={true}
                    value={signleRemittanceAddress?.name}
                    //  config={formik.getFieldProps("gpaDisplayname")}
                  />
                </div>
                <div className={styles.lowerInput}>
                  <Input
                    name="Country *"
                    inputName="country"
                    type="text"
                    disable={true}
                    value={signleRemittanceAddress?.country}
                    // config={formik.getFieldProps("gpaDisplayname")}
                  />
                  <Input
                    name="Email *"
                    inputName="email"
                    type="text"
                    disable={true}
                    value={signleRemittanceAddress?.email}
                    // config={formik.getFieldProps("gpaDisplayname")}
                  />
                  <Input
                    name="State *"
                    inputName="state"
                    type="text"
                    disable={true}
                    value={signleRemittanceAddress?.state}
                    // config={formik.getFieldProps("gpaDisplayname")}
                  />
                  <Input
                    name="Street Address *"
                    inputName="John"
                    type="text"
                    disable={true}
                    value={signleRemittanceAddress?.streetAddress}
                  />
                  <Input
                    name="Town /city *"
                    inputName="John"
                    type="text"
                    disable={true}
                    value={signleRemittanceAddress?.city}
                    // config={formik.getFieldProps("gpaDisplayname")}
                  />
                  <Input
                    name="Zip code *"
                    inputName="John"
                    type="text"
                    disable={true}
                    value={signleRemittanceAddress?.zipCode}
                    // config={formik.getFieldProps("gpaDisplayname")}
                  />
                  {/* <Input
                    name="state *"
                    inputName="state"
                    disable={true}
                    value={signleRemittanceAddress?.state}
                    type="text" */}
                  {/* // config={formik.getFieldProps("gpaDisplayname")}
                  // /> */}
                </div>
                <div className={styles.sugnature}>
                  {/* <label>E-signature</label>
                  <p>
                    Click here for the IVA Disclaimer and indemnity Agreement
                    <br />
                    Click here for the Relationship & Royalty Sharing Covenant
                  </p> */}
                  <span>
                    By affixing my e-signature below, I confirm that i have read
                    and agree with the term and conditions stated in the IVA
                    Disclaimer and indemnity Agreement & Royalty Sharing
                    Covenant
                  </span>
                  <label>Your Signature</label>
                  <div className={styles.signaturePad}>
                    <img src={signleRemittanceAddress?.signatureUrl} />
                    {/* <SignaturePad
    // ref={sigPad}
    penColor="#40a1dc"
    canvasProps={{
      width: 500,
      height: 100,
      className: "sigCanvas",
    }}
  /> */}
                  </div>
                  {/* <div className={styles.parentButton}>
  <button>Clear</button>
  <button>Upload</button>
</div> */}
                </div>
              </div>
              <div className={styles.rightSide}>
                <div className={styles.rightHeading}>Your Covestment</div>
                <div className={styles.rightContainer}>
                  <p onClick={() => history.push("./remittance")}>
                    Change Address
                  </p>

                  <div className={styles.rightwrapper}>
                    <div className={styles.text}>Contribution</div>
                    <div className={styles.text}>Subtotal</div>
                  </div>
                  <div className={styles.rightwrapper}>
                    <div className={styles.text}>
                      {to?.to?.shortName
                        ? to?.to?.shortName
                        : "CQR Vault Activation"}
                    </div>
                    <div className={styles.text}>
                      {!isActivate
                        ? Number(qAmount)?.toFixed(2)
                        : amount
                        ? to?.to?.coinSymbol == "Q"
                          ? tokenAmount?.toFixed(to?.to?.decimals)
                          : tokenAmount?.toFixed(2)
                        : to?.amount
                        ? to?.amount
                        : activationAmount.toFixed(to?.to?.decimals)}
                    </div>
                  </div>
                  {/* <div className={styles.rightwrapper}>
                    <div className={styles.text}>Fee</div>
                    <div className={styles.text}>${directWireFee}</div>
                  </div> */}
                  <div className={styles.rightwrapper}>
                    <div className={styles.text}>Total Amount</div>
                    <div className={styles.text}>
                      USD $
                      {amount
                        ? to?.to?.coinSymbol == "Q"
                          ? amount.toFixed(to?.to?.decimals)
                          : amount.toFixed(to?.to?.decimals)
                        : to?.(amount)
                        ? to?.(amount).toFixed(to?.to?.decimals)
                        : activationAmount.toFixed(to?.to?.decimals)}
                    </div>
                  </div>
                  <div className={styles.rightwrapper}>
                    <div className={styles.text}>Due Date</div>
                    <div className={styles.text}>
                      {dueDate ? dueDate?.format("MM-DD-YYYY") : ""}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.contribution}
                  style={{ flexDirection: "column" }}
                >
                  <div className={styles.textArea}>
                    <label>Direct Wire Contribution</label>
                    <p>
                      By clicking “Submit”, I intend to remit the value
                      specified above within 3 days (72 hours) as a
                      self-directed contribution made in exchange for CQR
                      Dollars (aka “CQR” and/or “<CQRComponent />
                      ”) added to the balance of my CQR Vault digital vault. I
                      hereby agree to all{" "}
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "#40a1dc",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        Terms & Conditions*
                      </a>
                      , and my Relationship & Royalty Sharing Covenants as is
                      congruent with my intended exchange made in covenant this
                      day. I further agree to remit my contribution via Direct
                      Bank Wire and/or ZellePay in adherence to the banking
                      coordinates provided herein (presented on the left).
                      Please approve, assign, and record the value of my
                      exchange as indicated above to my CQR Vault digital vault
                      upon confirming CQR Vault’s receipt of funds remitted. I
                      understand that my contribution may not immediately
                      reflect on my balance until the accounting has been
                      administratively reviewed and approved for allocation and
                      recordation.
                    </p>
                  </div>
                </div>
                <div
                  className={styles.contribution}
                  style={{ flexDirection: "column" }}
                >
                  <div className={styles.textArea}>
                    <div className={styles.p2}>
                      Your personal data will be used to process your order,
                      support your experience at CQR Vault, and for other
                      purposes described in our <span>privacy policy</span>
                    </div>
                    <div className={styles.check}>
                      <input
                        value={agree}
                        onChange={checkboxValue}
                        type="checkbox"
                      />
                      &nbsp; I have read and agree to all of the CQR Vault and
                      CQR Tokens
                      <span> terms & conditions * </span>
                      {showText ? (
                        <small
                          style={{ color: "#E5516B", textAlign: "center" }}
                        >
                          You need to agree to our terms and conditions.
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className={styles.button}>
                      <button onClick={handleSubmit}>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <ConfirmDirectWire
          to={to}
          legacySignature={legacySignature}
          activationAmountToken={qAmount}
          data={detail}
          bankDetail={bankDetail}
          activationAmount={activationAmount}
        />
      )}
    </>
  );
};

export default Directwire;
