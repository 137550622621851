import {
  CUSTOMER_REGISTRATION,
  CUSTOMER_CLEANUP,
  WALLET_ACTIVE_VIA_FIAT,
} from "../types";

export const customerRegistration = (data) => {
  return {
    type: CUSTOMER_REGISTRATION,
    payload: {
      request: {
        method: "post",
        url: "go-cardless/create-customer",
        data: {
          company_name: data.CompanyName,
          email: data.Email,
          family_name: data.FamilyName,
          given_name: data.GivenName,
          language: data?.Language?.name,
          phone_number: data?.PhoneNumber,
          address_line1: data?.Address,
          city: data?.City,
          postal_code: data?.PostalCode,
        },
      },
    },
  };
};

export const walletActiveViaFiat = (data) => {
  return {
    type: WALLET_ACTIVE_VIA_FIAT,
    payload: {
      request: {
        method: "post",
        url: "native-token/fiat-to-native-token",
        data: data,
      },
    },
  };
};

export const customerCleanup = () => {
  return {
    type: CUSTOMER_CLEANUP,
  };
};
