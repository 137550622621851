import React, { useState, useEffect } from "react";
import styles from "./revelution.module.scss";
import Revelutionicon from "../../../../Assets/image/revelution.png";
import Signup from "../../Signup/Signup";
import Loginsignup from "../../../_Common/loginSignup/Loginsignup";
import { CreateAccountCheck } from "../../../../redux/action/CreateAccountAction";
import { useDispatch, useSelector } from "react-redux";
import Download from "../../../../Assets/icons/download.png";
import $ from "jquery";
import Modal from "../../../_Common/Modal/Modal";
import ReactPlayer from "react-player";
const Revelution = ({ signinhandle }) => {
  const [AccesToken, setAccesToken] = useState("");
  const [checkAccount, setcheckAccount] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const dispatch = useDispatch();
  const createAccountCheck = useSelector(
    (state) => state.account.createAccountCheck
  );

  useEffect(() => {
    let res = localStorage.getItem("authToken");
    setAccesToken(res);
  }, []);

  const getStarted = () => {
    dispatch(CreateAccountCheck(!createAccountCheck));
  };
  const togglePopup = (e, num) => {
    setPopupVisible((preview) => !preview);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            className={styles.leftsection}
            data-aos="fade-up-right"
            // data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="700"
          >
            <label>
              Sound <span>Money</span>
            </label>
            <h6>
              Sound <span>Mind</span>
            </h6>
            <div className={styles.clrLeft} />
            <div className={styles.textsection}>
              <p>
                A true "Stablekoinonia" with global transparent accountancy
                <br />
                built on inflation-resistant precious metals <br />
                Redefine your private equity as a function of cash flow!
              </p>
              <ul>
                <li>Highly secure cross-border digital exchange</li>
                <li>Community cash flow opportunities</li>
                <li>No costly fees</li>
              </ul>
            </div>
            <div className={styles.btndiv}>
              {AccesToken ? (
                <div
                  className={styles.watchVideo}
                  onClick={() => {
                    togglePopup();
                  }}
                >
                  <img src={Download} alt="download" />
                  <p>Watch Video</p>
                </div>
              ) : (
                <>
                  <button
                    className={styles.button1}
                    onClick={() => {
                      getStarted();
                    }}
                  >
                    Create New Account
                  </button>
                  <div
                    className={styles.watchVideo}
                    onClick={() => {
                      togglePopup();
                    }}
                  >
                    <img src={Download} alt="download" />
                    <p>Watch Video</p>
                  </div>
                  {/* <button
                    className={styles.button1}
                    onClick={() => {
                      togglePopup();
                    }}
                  >
                    About CQR
                  </button> */}
                </>
              )}
            </div>
          </div>

          <div
            className={styles.rightsection}
            data-aos="fade-up-left"
            data-aos-easing="ease-in-sine"
            data-aos-duration="700"
          >
            <img src={Revelutionicon} alt="Revelution" />
          </div>
        </div>
        <Modal
          video="video"
          visible={popupVisible}
          onClose={() => setPopupVisible(false)}
        >
          <video
            
            autoPlay={true}
            muted={false}
            controls={true}
            width="100%"
          >
            <source
              src="https://d1brp5cmiud168.cloudfront.net/videos/CQR+Vault+Promo+Video+v2+20222312.mp4"
              type="video/mp4"
            />
          </video>
        </Modal>
      </div>
    </>
  );
};

export default Revelution;
