const Data = [
  {
    id: 1,
    heading: "$7 Billion+",
    subHeading: "Assets Under Stewardship ",
    subHeading2:
      "We've only just begun, and many have already discovered the unique value & benefits the CQR Vault's Ecclesiastical jurisdiction has to offer",
  },
  {
    id: 2,
    heading: "3-25% Subscription",
    subHeading: "For 100% Project Funding",
    subHeading2:
      "CQR Vest in an interactive FNFT platform that presents vetted cash flow opportunities via syndicated Asset Pool Digital Certificates",
  },
  {
    id: 3,
    heading: "90% Leverage",
    subHeading: "Collateralize Your Assets",
    subHeading2:
      "CQR Group family offers Qualified CQR Vault Members the ability to leverage their CQR Asset Token upto 90% of their Cash Value",
  },
];
export default Data;
