import {
  REMITTANCE_ADDRESS,
  SIGNLE_REMITTANCE_ADDRESS,
  DIRECT_WIRE_LIST,
  SAVE_BANK_DETAIL,
  CLEAR_REMITTANCE_ADDRESS_STATE,
} from "../types";

const initialState = {
  remittanceAddressList: null,
  signleRemittanceAddress: null,
  directWireList: null,
  bankDetail: null,
};

const remittanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMITTANCE_ADDRESS:
      return { ...state, remittanceAddressList: action.payload };
    case SIGNLE_REMITTANCE_ADDRESS:
      return { ...state, signleRemittanceAddress: action.payload };
    case DIRECT_WIRE_LIST:
      return { ...state, directWireList: action.payload };
    case SAVE_BANK_DETAIL:
      return { ...state, bankDetail: action.payload };
    case CLEAR_REMITTANCE_ADDRESS_STATE:
      return {
        ...state,
        remittanceAddressList: null,
        signleRemittanceAddress: null,
        directWireList: null,
        bankDetail: null,
      };
    default:
      return state;
  }
};
export default remittanceReducer;
