import React, { useEffect, useState } from "react";
import { BarWave } from "react-cssfx-loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDebounce } from "use-debounce";
import Inbox from "../../../../../../Assets/icons/inbox.svg";
import { HTTP_CLIENT } from "../../../../../../utils/services";
import { Wave, WaveNew } from "../../../../../_Common/loader/Loader";
import styles from "./newContact.module.scss";
const NewUserModal = ({
  setpopupvisible,
  userMessages,

  setPopupVisibleDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);

  const [searchContact, setSearchContact] = useState("");
  const [debounce] = useDebounce(searchContact, 2000);

  const generateNewChatRoom = async (otherUserId) => {
    setImgLoading(true);
    let payload = {
      otherUserId,
    };
    try {
      const res = await HTTP_CLIENT.post("chat/room/create", payload);
      userMessages(res?.data);
      setPopupVisibleDelete(false);
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
    }
  };
  //----get users----------//
  const searchUsers = async () => {
    setUserData([]);
    const perPage = 30;

    const page = 1;
    setLoading(true);
    try {
      const res = await HTTP_CLIENT.get(
        `user/search?page=${page}&limit=${perPage}&keyword=${searchContact}`
      );

      setLoading(false);
      setDataTotal(res?.data?.total);
      setUserData(res?.data?.data);
    } catch (error) {
      setLoading(false);
    }
  };
  const searchUsersPagination = async () => {
    const perPage = 30;
    const page = Math.ceil(userData?.length / perPage) + 1;
    // setLoading(true);
    try {
      const res = await HTTP_CLIENT.get(
        `user/search?page=${page}&limit=${perPage}&keyword=${searchContact}`
      );

      setLoading(false);
      setDataTotal(res?.data?.total);
      setUserData([...userData, ...res?.data?.data]);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (debounce !== "") {
      searchUsers();
    }
  }, [debounce]);
  const fetchMoreData = () => {
    searchUsersPagination();
  };
  return (
    <>
      <div
        className={styles.container}
        id="scrollableDiv"
        style={{
          height: "400px",
          overflow: "auto",
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.heading}>Add Contact</div>
          <input
            autoFocus
            value={searchContact}
            onChange={(e) => setSearchContact(e.target.value)}
            type="text"
            placeholder="Search Contact by Name/Email"
          />
          <InfiniteScroll
            dataLength={userData.length}
            next={fetchMoreData}
            hasMore={
              dataTotal === userData?.length || dataTotal === 0 ? false : true
            }
            scrollableTarget="scrollableDiv"
            loader={<h4>Loading....</h4>}
          >
            {loading ? (
              <div className={styles.loader}>
                <Wave />
              </div>
            ) : (
              userData?.map((user, index) => {
                return (
                  <div key={index} className={styles.userWrapper}>
                    <p>{user?.name}</p>

                    <img
                      src={Inbox}
                      alt="chat"
                      onClick={() => generateNewChatRoom(user?._id)}
                    />
                  </div>
                );
              })
            )}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default NewUserModal;
