import React, { useEffect, useState } from "react";
import BitcoinGraph from "../../CryptoCurrency/CryptoTableGraph/Graph/Graph";
// import Graph from "./Graph/Graph";
// import Tabel from "./Tabel/Tabel";
import { useLocation } from "react-router-dom";
import BackTo from "../../../../../../Assets/icons/backk.svg";
import Rcv from "../../../../../../Assets/image/rcv.svg";
import Send from "../../../../../../Assets/image/send.svg";
import Swap from "../../../../../../Assets/image/swap.svg";
import Popup from "../../../../../_Common/DashboardComponents/popup/popup";
import Modal from "../../../../../_Common/Modal/Modal";
import styles from "./VaultGraph.module.scss";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrencyGraph,
  getTransactionDetail,
} from "../../../../../../redux/action/currencyDetailAction";
import CryptoTotal from "../../CryptoCurrency/CryptoTableGraph/CryptoTotal";
// import CryptoTotal from "./CryptoTotal";
import Transactions from "../../../../../_Common/DashboardComponents/Transactions/Transactions";
function CryptoTableGraph({ history }) {
  const [coinDetail, setCoinDetail] = useState();
  const [popupvisiblee, setpopupvisiblee] = useState(false);

  const location = useLocation();

  const orignalSymbol = location?.state?.detail;
  const sysmbol = location?.state?.detail?.graphKey;
  const tokenCheck = location?.state?.detail?.type;

  const dispatch = useDispatch();
  const currencySelector = useSelector((state) => state.currency);
  // const graphData = useSelector((state) => state.currency);
  const togglepopup = () => {
    if (tokenCheck === "sdira") {
      history.push({
        pathname: "/withdrawal-directwire",
        state: { detail: location?.state?.detail },
      });
    } else {
      setpopupvisiblee(true);
    }
    // e.preventDefault();
    // setNumber(num);
  };
  useEffect(() => {
    // result: 'some_value'
    // setCoinDetail(location.state.detail);
    setCoinDetail(currencySelector.graphData);
    if (coinDetail) {
      dispatch(
        getTransactionDetail(coinDetail?.address, coinDetail?.coinSymbol)
      );
      dispatch(getCurrencyGraph(coinDetail?.coinSymbol));
    }
  }, [location, coinDetail]);

  //  const fetchData = async () => {
  //   try {
  //     let res = await HTTP_CLIENT.get(`transaction/${coinDetail?.address}/${coinDetail?.coinSymbol}/txs`);
  //     // alert.show("successfully", { type: "success" });

  //   } catch (err) {
  //     // alert.show(err.response.data.message, { type: "error" });
  //   }
  //  }

  const swapAssets = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/assets-swap",
        state: { detail: location?.state?.detail },
      });
    } else {
      history.push("/error");
    }
  };
  const ExchangeWallet = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/silent-wave",
        state: { detail: location },
      });
    } else {
      history.push("/error");
    }
  };
  const swapLegacy = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/legacy-swap",
        state: { detail: location },
      });
    } else {
      history.push("/error");
    }
  };
  const send = () => {
    history.push({
      pathname: "/cqr-asset-tokens",
      state: { detail: location?.state?.detail },
    });
  };
  const detailDataa = () => {
    history.push({
      pathname: "/liquidity-tokens",
      state: { detail: location?.state?.detail },
    });
  };
  const withdrawAssest = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/withdrawal",
        state: { detail: location?.state?.detail },
      });
    } else {
      history.push("/error");
    }
  };
  const withdrawAssestDirectWire = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/withdrawal-directwire",
        state: { detail: location?.state?.detail },
      });
    } else {
      history.push("/error");
    }
  };

  return (
    <>
      <Modal
        visible={popupvisiblee}
        // btn
        onClose={() => setpopupvisiblee(false)}
      >
        <div className={styles.modalWrapper}>
          <label>Please Select Redeem Method</label>
          <div className={styles.buttonWrapper}>
            <button onClick={() => withdrawAssest()}>CoinQvest</button>
            <button onClick={() => withdrawAssestDirectWire()}>
              Direct wire
            </button>
          </div>
        </div>
      </Modal>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <img
              src={BackTo}
              alt=""
              onClick={() => {
                history.push({
                  pathname: "/cryptocurrency",
                  state: { activeState: location?.state?.detail?.activeButton },
                });
              }}
            />
            {/* <label>{location.state.detail.name}</label> */}
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.leftWrapper}>
              <CryptoTotal location={location} />
              {tokenCheck == "sdira" ? (
                <div className={styles.buttonWrapper}>
                  <button onClick={() => send()}>
                    <p>Send Request</p>
                    <img src={Send} alt="send" />
                  </button>
                  <button onClick={() => swapAssets()}>
                    <p>Swap Request</p>
                    <img src={Swap} alt="swap" />
                  </button>
                  <button onClick={() => detailDataa()}>
                    {" "}
                    <p> Top-Up Request</p>
                    <img src={Rcv} alt="recieve" />
                  </button>
                  <button onClick={() => togglepopup()}>
                    <p>Redeem</p>
                    <img src={Send} alt="send" />
                  </button>
                </div>
              ) : (
                // <div className={styles.buttonWrapper}>
                //   <button onClick={() => swapLegacy()}> Request Swap</button>

                //   <button onClick={() => withdrawAssest()}>
                //     <p>Request Withdraw</p>

                //   </button>

                // </div>
                <div className={styles.buttonWrapper}>
                  <button onClick={() => detailDataa()}>
                    {" "}
                    <p> Top-Up</p>
                    <img src={Rcv} alt="recieve" />
                  </button>
                  <button onClick={() => send()}>
                    <p>Send</p>
                    <img src={Send} alt="send" />
                  </button>
                  <button onClick={() => togglepopup()}>
                    <p>Redeem</p>
                    <img src={Send} alt="send" />
                  </button>
                  {/* <Link
                to={
                  currencySelector.currencyInfo.user.isWalletActivated
                    ? "/crypto-swap"
                    : "/error"
                }
              > */}
                  <button onClick={() => swapAssets()}>
                    <p>Swap</p>
                    <img src={Swap} alt="swap" />
                  </button>
                  <button onClick={() => ExchangeWallet()}>
                    <p>Exchange</p>
                    <img src={Send} alt="send" />
                  </button>
                </div>
              )}
              {/* </Link> */}
              {/* {tokenCheck == "sdira" ? (
                ""
              ) : ( */}
              <div className={styles.headingWrapper}>
                <span>Transaction History</span>
                {/* <p>20 Oct, 2021</p> */}
              </div>

              {/* {tokenCheck == "sdira" ? (
                ""
              ) : ( */}
              <Transactions orignalSymbol={orignalSymbol} location={location} />
            </div>

            <div className={styles.rightWrapper}>
              <div className={styles.graphWraper}>
                <TradingViewWidget
                  symbol={sysmbol}
                  theme={Themes.DARK}
                  locale="en"
                  width="100%"
                  height="400"
                />
              </div>
              {tokenCheck == "sdira" ? (
                <p> {location?.state?.detail?.description}</p>
              ) : (
                <p>
                  Each ICO Ag Asset Token represents the value of a single
                  Government Minted Legal Tender 1oz 0.999 Fine Silver Coin
                  domiciled in Sovereign Ecclesiastical Vault for community
                  redeem in the Americas, and redeemable worldwide.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CryptoTableGraph;
