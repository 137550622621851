import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsExclamationCircleFill } from "react-icons/bs";
import "../../../Assets/fonts/TokenSymbols.ttf";
import Cqrcommon from "../../_Common/CQRComponent/CQRComponent";
import styles from "./input.module.scss";
function input({
  img,
  inputName,
  img2,
  type,
  onclick,
  onchange,
  disable,
  value,
  config,
  Name,
  name,
  changeOne,
  style2,
  pStyle,
  imgQ,
  img3,
  exclamationMark,
  tooltopStatus,
  BalanceStatus,
  InputBalance,
  InfoDatePicker,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p style={pStyle}>
          {imgQ ? "Amount C" : ""}
          {imgQ ? <Cqrcommon spanStyle={{ width: "14px" }} /> : name}
          {imgQ ? "R *" : ""}
          &nbsp;{" "}
          {exclamationMark && (
            <span>
              <BsExclamationCircleFill className={styles.exclamationMark} />
              {tooltopStatus && (
                <div className={styles.status}>{tooltopStatus}</div>
              )}
            </span>
          )}
        </p>
        <div className={styles.inputWrapper} style={style2}>
          {img && <img src={img} alt="" />}
          {InfoDatePicker ? (
            <DatePicker
              selected={value}
              onChange={(date) => config(date)}
              className="picker"
              dateFormat="MM/dd/yyyy"
            />
          ) : (
            <input
              type={type}
              placeholder={inputName}
              value={value}
              disabled={disable}
              name={Name}
              // required
              onChange={(e) => onclick(e)}
              {...config}
            />
          )}
          {img2 && (
            <span onClick={changeOne}>
              <img src={img2} alt="" />
            </span>
          )}
          {img3 && <div className={styles.spanContainter}>2</div>}
        </div>
      </div>
      {BalanceStatus && (
        <div className={styles.lastChild}>
          <div className={styles.text}>
            {" "}
            Token Amount to be Deducted ≈
            <span>{InputBalance ? Number(InputBalance).toFixed() : "0"}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default input;
