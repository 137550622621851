import React, { useEffect, useRef, useState } from "react";
import styles from "./sort.module.scss";
import SortIcon from "../../../../../Assets/icons/sort.svg";
// import NotificationImag from "../../../Assets/image/notification-image.svg";

import moment from "moment";

const Sort = (props) => {
  const ref = useRef(null);
  const [notification, setnotification] = useState(false);
  const [active, setActive] = useState("");

  const [colneNotification, setCloneNotification] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState();
  const [notificationData, setNotificationData] = useState([]);

  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setnotification(notification);
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper} ref={ref}>
          <div
            className={styles.notification}
            onClick={() => setnotification(!notification)}
          >
            <button>
              <img src={SortIcon} alt="Sort" />
              Sort
            </button>
            {notificationNumber ? (
              <div className={styles.notificationbadge}>
                <span>6</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {notification ? (
            <div className={styles.bellmessage}>
              <div className={styles.heading}>
                <span>Sort by</span>
              </div>

              <div className={styles.card}>
                {/* <div className={styles.left}>
                        <img src={NotificationImag} alt="Sort" />
                      </div> */}
                <div
                  className={styles.sortingWrapper}
                  onClick={() => props.setActive("recently_listed")}
                >
                  <label>Recently listed</label>
                  <div
                    className={
                      props.active === "recently_listed"
                        ? styles.filledCircle
                        : styles.circle
                    }
                  ></div>
                  {/* <h6>Yogle Ishmada</h6> */}
                </div>
                <div
                  className={styles.sortingWrapper}
                  onClick={() => props.setActive("price_low_to_high")}
                >
                  <label>Value Low to High</label>
                  <div
                    className={
                      props.active === "price_low_to_high"
                        ? styles.filledCircle
                        : styles.circle
                    }
                  ></div>
                  {/* <h6>Yogle Ishmada</h6> */}
                </div>
                <div
                  className={styles.sortingWrapper}
                  onClick={() => props.setActive("price_high_to_low")}
                >
                  <label>Value High to Low</label>
                  <div
                    className={
                      props.active === "price_high_to_low"
                        ? styles.filledCircle
                        : styles.circle
                    }
                  ></div>
                  {/* <h6>Yogle Ishmada</h6> */}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Sort;
