import React, { useState } from "react";
import styles from "./popup.module.scss";
import { useDispatch } from "react-redux";
import { userCleanUp, KYCCleanUp } from "../../../redux/action/userAction";
import { currencyLogoutCleanUp } from "../../../redux/action/currencyDetailAction";
import { swapLogoutCleanUp } from "../../../redux/action/sawapAction";
import { subscriptionCleanUp } from "../../../redux/action/subscriptionAction";
import { useHistory } from "react-router-dom";
import { WaveClone } from "../loader/Loader";
import Modal from "../Modal/Modal";

const Popup = ({
  Name,
  src,
  para,
  btn,
  tokenData,
  // onPressButton,
  handleCoinQvest,
  onPressinnerButton,
  activationAmount,
  btn1,
  BtnShow,
  handleOk,
  QVestbtn,
  Paypalbtn,
  handlePayPalRes,
  loading,
  // history,
}) => {
  const handleCardless = () => {
    onPressinnerButton();
  };

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [status, setStatus] = useState("");
  const handleSubmit = (item) => {
    if (item == "handleok") {
      handleOk();
    } else if (item == "cardless") {
      handleCardless();
    } else if (item == "coinqvest") {
      handleCoinQvest();
    } else if (item == "paypal") {
      // handleCardless();
      handlePayPalRes();
    }
  };

  return (
    <>
      <Modal
        visible={Boolean(selectedMethod)}
        onClose={() => setSelectedMethod(null)}
        btn
      >
        <div className={styles.passwordmodal}>
          <div className={styles.heading}>
            <p>Are you sure you want to perform this action using {status}?</p>
          </div>
          <div className={styles.buttonset}>
            {loading ? (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
              >
                <WaveClone />
              </button>
            ) : (
              <button
                className={styles.Delete}
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                onClick={() => handleSubmit(selectedMethod)}
                disabled={loading ? true : false}
              >
                {" "}
                Confirm
              </button>
            )}
            <button
              className={styles.Delete}
              style={{ marginLeft: "3rem" }}
              onClick={() => setSelectedMethod(null)}
            >
              {" "}
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        {src ? <img src={src} alt="" /> : ""}
        {Name ? <label>{Name}</label> : ""}
        {para && (
          <>
            <p>{para}</p>
            <div className={styles.btndiv}>
              <button
                onClick={() => {
                  setStatus("Direct Wire");
                  setSelectedMethod("handleok");
                }}
                disabled={loading ? true : false}
              >
                {btn}
              </button>
              {BtnShow && (
                <>
                  <button
                    onClick={() => {
                      setStatus("Go Cardless");
                      setSelectedMethod("cardless");
                    }}
                    type="submit"
                    className={styles.cancelbtn}
                    disabled={loading ? true : false}
                  >
                    {btn1}
                  </button>
                </>
              )}
              {QVestbtn && (
                <>
                  <button
                    onClick={() => {
                      setStatus("COINQVEST");
                      setSelectedMethod("coinqvest");
                    }}
                    type="submit"
                    className={styles.Qvestbtn}
                    disabled={loading ? true : false}
                  >
                    {QVestbtn}
                  </button>
                </>
              )}
              {Paypalbtn && (
                <>
                  <button
                    onClick={() => {
                      setStatus("Paypal");
                      setSelectedMethod("paypal");
                    }}
                    type="submit"
                    className={styles.Qvestbtn}
                    disabled={loading ? true : false}
                  >
                    {Paypalbtn}
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Popup;
